import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'

import {
  OutsideSliderButtonContainer
} from './components'

import arrowDownPath from '../../../assets/icons/arrow_down.svg'

const OutsideSliderButton = ({
  onClick,
  position
}) => (
  <OutsideSliderButtonContainer
    data-test='OutsideSliderButtonContainer'
    onClick={onClick}
    position={position}
  >
    <Icon
      data-test='OutsideSliderButtonIcon'
      icon={arrowDownPath}
    />
  </OutsideSliderButtonContainer>
)

export default OutsideSliderButton

OutsideSliderButton.propTypes = {
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right'])
}
