import React, { useState } from 'react'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import ChooseImageField from '../../../../components/Form/FormFields/ChooseImageField'

const CompanyLogoUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: '',
    reload: false
  })

  const handleChange = (file, onSuccess) => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    ApiService.fetch(companyAPI.uploadLogo(formData))
      .then((data) => {
        setState({ ...state, isLoading: false, reload: !state.reload })
        handleField('logoUrl', data.url)
        onSuccess()
      })
      .catch(err => setState({ ...state, apiError: err }))
  }

  return (
    <ChooseImageField
      buttonSelectLabel='Wybierz logo (PNG/JPG)'
      chooseFileButtonDescription='Dodaj Logo Twojej firmy.'
      chooseFileButtonFieldlabel='Logotyp Twojej firmy'
      croppedImageHeadingText='Tak twoje logo będzie wyglądać na stronie:'
      errorMessage={error}
      hasError={error && touched}
      image={value}
      isLoading={state.isLoading}
      onChange={handleChange}
      required
      withHash
    />
  )
}

export default CompanyLogoUploadContainer
