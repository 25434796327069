import cv1_en from './cv-1_en'
import cv1_pl from './cv-1_pl'
import cv2_en from './cv-2_en'
import cv2_pl from './cv-2_pl'
import cv3_en from './cv-3_en'
import cv3_pl from './cv-3_pl'
import cv4_en from './cv-4_en'
import cv4_pl from './cv-4_pl'
import cv5_en from './cv-5_en'
import cv5_pl from './cv-5_pl'
import cv6_en from './cv-6_en'
import cv6_pl from './cv-6_pl'
import cv7_en from './cv-7_en'
import cv7_pl from './cv-7_pl'
import cv10_en from './cv-10_en'
import cv10_pl from './cv-10_pl'

const translations = {
  'cv-1-dark': {
    en: cv1_en,
    pl: cv1_pl
  },
  'cv-1-light': {
    en: cv1_en,
    pl: cv1_pl
  },
  'cv-2-dark': {
    en: cv2_en,
    pl: cv2_pl
  },
  'cv-2-light': {
    en: cv2_en,
    pl: cv2_pl
  },
  'cv-3-dark': {
    en: cv3_en,
    pl: cv3_pl
  },
  'cv-3-light': {
    en: cv3_en,
    pl: cv3_pl
  },
  'cv-4-dark': {
    en: cv4_en,
    pl: cv4_pl
  },
  'cv-4-light': {
    en: cv4_en,
    pl: cv4_pl
  },
  'cv-5-dark': {
    en: cv5_en,
    pl: cv5_pl
  },
  'cv-5-light': {
    en: cv5_en,
    pl: cv5_pl
  },
  'cv-6-dark': {
    en: cv6_en,
    pl: cv6_pl
  },
  'cv-6-light': {
    en: cv6_en,
    pl: cv6_pl
  },
  'cv-7-dark': {
    en: cv7_en,
    pl: cv7_pl
  },
  'cv-7-light': {
    en: cv7_en,
    pl: cv7_pl
  },
  'cv-10-dark': {
    en: cv10_en,
    pl: cv10_pl
  },
  'cv-10-light': {
    en: cv10_en,
    pl: cv10_pl
  }
}

export default (id, lang) => {
  if (!translations.hasOwnProperty(id)) {
    return {}
  }

  return translations[id][lang] ?? {}
}
