import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { Base64 } from 'js-base64'

import ChooseColorContainer from '../../../containers/RegisterContainers/ChooseColorContainer'
import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'
import cvTemplates from '../../../stabs/cvTemplates'

import ChooseCvContainer from '../../../containers/RegisterContainers/ChooseCvContainer'
import PricingAndDownload from '../../../containers/CvCreatorContainers/PricingAndDownload'

import ButtonComponent from '../../../components/Buttons/ButtonComponent'

import {
  CreatorCvStepThreeChooseColorContainer, CreatorCvStepThreeIframe, CvPreviewContainer, HalfWidth, Clear, RodoContainer, CvWaterMark, CreatorCvStepOneButtonContainer
} from './components'

import translationsList from '../../../stabs/translationsList'

import { myUserSelectors } from '../../../store/ducks/my-user'

import {
  useDispatch,
  useSelector
} from 'react-redux'
import useDidMount from '@rooks/use-did-mount'
import useDidUpdate from '@rooks/use-did-update'
import {
  cvActions,
  cvSelectors
} from '../../../store/ducks/my-cv'
import useCvGenerator from '../../../store/hooks/useCvGenerator'
import AddRodoFormContainer from '../../../containers/CvCreatorContainers/AddRodoFormContainer'

import ApiService from '../../../store/services/ApiService'
import * as recruitmentApi from '../../../store/api/recruitment'

import male from '../../../assets/img/male.jpg'
import female from '../../../assets/img/female.jpg'

import detect from 'detect-gender'



const CreatorCvStepThree = () => {
  const [cvHtml, setCvHtml] = React.useState('')
  const [cvHtmlContent, setCvHtmlContent] = React.useState('')
  const { generateCv } = useCvGenerator({ onlyHtml: true })
  const cv = useSelector(cvSelectors.getCv)
  const dispatch = useDispatch()

  const [gender, setGender] = React.useState('male')

  let cvId = sessionStorage.getItem('cv')
  const { cvId: id } = useParams()

  if (id) {
    cvId = id
    sessionStorage.setItem('cv', id)
  }

  const [state, setState] = useState({
    isLoading: false,
    error: null
  })


useDidMount(() => {
  if (!cv.isFetched) {
    dispatch(cvActions.fetchCvRequested(cvId))
  }
  if (cv.isFetched) {
    if (cv.data.educations.length == 0) {
      const education = {
        degree: "Wyższe - magister",
        institutionName: "Wroclaw University of Technology",
        startDate: "09.2004",
        stillOngoing: true,
        specialisation: " The analyzes of possibility of realization the equipment for testing the peripheral modules in ARM microcontroller.",
        field: "Faculty of Electronics / Electronics and Telecommunication"
      }
      ApiService.fetch(recruitmentApi.addEducation(cvId, education))
    }

    if (cv.data.courses.length == 0) {
      const newValues = {
        name: 'Kurs online Career Academy - "Skuteczne CV"',
        dateStart: '03.2020',
        dateEnd: '02.2021'
      }
      ApiService.fetch(recruitmentApi.addCourse(cvId, newValues))
      const newValues2 = {
        name: 'Szkolenie ze sprzedaży bezpośredniej',
        dateStart: '05.2018',
        dateEnd: '05.2018'
      }
      ApiService.fetch(recruitmentApi.addCourse(cvId, newValues2))
      const newValues3 = {
        name: 'Kurs autoprezentacji',
        dateStart: '10.2017',
        dateEnd: '11.2017'
      }
      ApiService.fetch(recruitmentApi.addCourse(cvId, newValues3))
    }

    if (cv.data.jobs.length == 0) {
      const Job1 = {
        "stillOngoing" : false,
        "position" : "Specjalista ds. Obsługi Klienta ",
        "location" : "Wrocław",
        "companyName" : "Career Academy",
        "startDate" : "03.2009",
        "endDate" : "04.2017",
        "description" : "<ul><li>bieżący kontakt z klientami i rozwiązywanie ich problemów, </li><li>odpowiadanie na zapytania ofertowe, </li><li>pozyskiwanie nowych klientów </li><li>przygotowywanie ofert sprzedażowych </li></ul>",
        "haveLearned" : "<p>Praca na tym stanowisku nauczyła mnie przede wszystkim profesjonalnego i rzetelnego podejścia do obsługi klienta zarówno biznesowego jak i indywidualnego. Pozwoliła mi także wykształcić umiejętności interpersonalne i negocjacyjne. </p>",
        "biggestSuccess" : "<p>Opracowanie systemu obsługi mailowej, dzięki któremu średni czas rozwiązywania problemu klienta, skrócił się o 30%. </p><p>Uzyskanie dla firmy transakcji na ponad 2 mln zł. </p>"
      }

      ApiService.fetch(recruitmentApi.addJob(cvId, Job1))

      const Job2 = {
        "stillOngoing" : false,
        "position" : "Specjalista ds. Sprzedaży ",
        "location" : "Wrocław",
        "companyName" : "Skills Day ",
        "startDate" : "04.2017",
        "endDate" : "01.2021",
        "description" : "<ul><li>odpowiadanie na zapytania ofertowe,</li><li>pozyskiwanie nowych klientów,</li><li>przygotowywanie ofert sprzedażowych,</li><li>optymalizacja ofert sprzedażowych,</li><li>optymalizacja kosztów funkcjonowania działu sprzedaży.</li></ul>",
        "haveLearned" : "<p>Praca na tym stanowisku nauczyła mnie sumiennego i konsekwentnego pozyskiwania klientów oraz rozwinęła moje umiejętności sprzedażowe. </p><br />",
        "biggestSuccess" : "<p>Pozyskanie i sfinalizowanie z klientem zagranicznym na transakcję o wartości ponad 10 mln euro.</p>"
      }

      ApiService.fetch(recruitmentApi.addJob(cvId, Job2))
    }

    if( cv.data.languages.length == 0 ) {
      const Lang1 = { level:4, name: 'Angielski' }

      ApiService.fetch(recruitmentApi.addLanguage(cvId, Lang1))

      const Lang2 = { level: 3, name: 'Niemiecki' }

      ApiService.fetch(recruitmentApi.addLanguage(cvId, Lang2))
    }

    if( cv.data.skills.length == 0 ) {
      const Skill1 = { level: 2, name: 'Pakiet Adobe' }

      ApiService.fetch(recruitmentApi.addSkill(cvId, Skill1))

      const Skill2 = { level: 3, name: 'Pakiet Office' }

      ApiService.fetch(recruitmentApi.addSkill(cvId, Skill2))

      const Skill3 = { level: 3, name: 'Programy księgowe' }

      ApiService.fetch(recruitmentApi.addSkill(cvId, Skill3))
    }

    if( cv.data.hobbies.length == 0 ) {
      ApiService.fetch(recruitmentApi.updateMyCv(cvId, { hobbies: "jazda na rowerze, jazda na nartach, jazda konna, pływanie, gotowanie", }))
    }

    ApiService.fetch(recruitmentApi.updateMyCv(cvId, { avatar: 'https://cvworks.pl/images/'+gender+'.jpg' })).then(data => {

    });


  }
}, [dispatch, cv])


const user = useSelector(myUserSelectors.getMyUserData)

  useDidUpdate(async () => {
    if (cv.isFetched && !cv.isHtmlFetched && cv.data.templateId) {
      dispatch(cvActions.setHtmlAsFetched())

      const newCvHtml = await generateCv()

      setCvHtmlContent(newCvHtml)

      setCvHtml(Base64.encode(newCvHtml))

      if( user.firstName.endsWith('a') ) {
        setGender('female')
      } else {
        setGender('male')
      }

      


    }
  }, [dispatch, cv])

  function createPreview () { return { __html: cvHtmlContent } };

  

  const cev = useSelector(cvSelectors.getCvData)
  const [prefix, selectedCvId, selectedCvVariant] = cev.templateId.split('-')
  const selectedCvTemplate = cvTemplates.find((template) => template.id === `${prefix}-${selectedCvId}`)

  return (
    <>
      <Subtitle
        data-test='SubtitleChooseCv'
        icon='star'
        label='Wybierz i dostosuj swój szablon CV'
      />

      <ChooseCvContainer data-test='ChooseCvContainer' />


       <Subtitle
        data-test='SubtitleColors'
        icon='badge'
        label={translationsList.form.stepThree.subtitle}
        id="colors"
       />

       <CreatorCvStepThreeChooseColorContainer data-test='CreatorCvStepThreeChooseColorContainer'>
        <ChooseColorContainer data-test='ChooseColorContainer' />
       </CreatorCvStepThreeChooseColorContainer>



      <Clear/>

      {/* <CreatorCvStepThreeChooseColorContainer data-test='CreatorCvStepThreeChooseColorContainer'> */}
      {/*  <ChooseColorContainer data-test='ChooseColorContainer' /> */}
      {/* </CreatorCvStepThreeChooseColorContainer> */}

      <Subtitle
        data-test='SubtitlePreview'
        icon='badge'
        label='Podgląd (dane uzupełnisz w kolejnym kroku)'
        id="preview"
      />

      {/* <CvPreviewContainer id="elo" dangerouslySetInnerHTML={createPreview()} /> */}

      <CvWaterMark subscriptonIsActive={user.subscriptionIsActive}>
        <CreatorCvStepThreeIframe src={`data:text/html;charset=utf-8;base64,${cvHtml}`} data-test='CreatorCvStepThreeIframe' />
      </CvWaterMark>

      <CreatorCvStepOneButtonContainer data-test='CreatorCvStepOneButtonContainer'>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='buttonComponent'
          display='inline-flex'
          isLoading={state.isLoading}
          fontBold
          label={'Dalej'}
          size='sizeL'
          id={'nextStepButton'}
          link='/rejestracja/metryka'
          type='internal'
        />
      </CreatorCvStepOneButtonContainer>
    </>
  )
}

export default CreatorCvStepThree
