import React from 'react'
import PropTypes from 'prop-types'

import FooterMenuItem from './FooterMenuItem'

import {
  FooterMenuContainer,
  FooterMenuTitle
} from './components'

const FooterMenu = ({
  itemsList,
  title
}) => (
  <>
    {title &&
      <FooterMenuTitle>{title}</FooterMenuTitle>}

    {itemsList &&
      <FooterMenuContainer>
        {itemsList.map((menuItem, id) =>
          <FooterMenuItem
            key={id}
            inNewWindow={menuItem.inNewWindow}
            label={menuItem.label}
            link={menuItem.link}
            type={menuItem.type}
            footer={true}
          />
        )}
      </FooterMenuContainer>}
  </>
)

export default FooterMenu

FooterMenu.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      inNewWindow: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.oneOf(['external', 'internal', 'onClick'])
    })
  ),
  title: PropTypes.string
}
