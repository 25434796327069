import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import useDidMount from '@rooks/use-did-mount'

import ApiService from '../../../store/services/ApiService'
import { myUserActions, myUserSelectors } from '../../../store/ducks/my-user'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'
import * as recruitmentAPI from '../../../store/api/recruitment'

import AddUserForm from '../../../components/Form/FormComponents/AddUserFormMetrics'

import translate from '../../../stabs/errorMessagesTranslations'
import handleField from '../../../utils/forms/handleField'
import AuthService from '../../../store/services/AuthService'
import ButtonComponent from '../../../components/Buttons/ButtonComponent'
import translationsList from '../../../stabs/translationsList'
import { CreatorCvStepOneButtonContainer } from '../../../pages/CreatorCvSteps/StepOne/components'
import handleMultiSelect from '../../../utils/forms/handleMultiSelect'

const AddBasicUserDataCVFormContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(myUserSelectors.getMyUserData)
  const cv = useSelector(cvSelectors.getCv)

  const cvId = sessionStorage.getItem('cv')

  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  useEffect(() => {
    window.scrollTo(0,0)
  })

  const cv_filled_metrics = sessionStorage.getItem('cv_filled_metrics')

  let initialValues

  if( cv_filled_metrics !== null ) {
    initialValues = {
      avatar: user.avatar,
      firstName: user.firstName,
      position: cv.data.position,
      title: 'Pierwsze CV',
      lastName: user.lastName,
      phone: user.phone,
      email: user.email !== cv.data.email && cv.data.email !== '' ? cv.data.email : user.email,
      birthday: user.birthday,
      nationality: user.nationality,
      summary: cv.data.summary,
      cityOfLiving: user.cityOfLiving,
      country: user.country,
      linkedinUrl: user.linkedinUrl,
      portfolioUrl: cv.data.portfolioUrl,
      cvLanguage: 'pl'
    }
  } else {
    initialValues = {
      avatar: '',
      firstName: user.firstName,
      position: '',
      title: 'Pierwsze CV',
      lastName: user.lastName,
      phone: '',
      email: user.email !== cv.data.email && cv.data.email !== '' ? cv.data.email : user.email,
      birthday: '',
      nationality: '',
      summary: '',
      cityOfLiving: '',
      country: '',
      linkedinUrl: '',
      portfolioUrl: '',
      cvLanguage: 'pl'
    }
  }

  const clearProfile = () => {
    Object.keys(cv.data.educations).map( (element, index) => {
      ApiService.fetch(recruitmentAPI.deleteEducation(cvId, cv.data.educations[element]._id))
    })

    Object.keys(cv.data.courses).map( (element, index) => {
      ApiService.fetch(recruitmentAPI.deleteCourse(cvId, cv.data.courses[element]._id))
    })

    Object.keys(cv.data.jobs).map( (element, index) => {
      ApiService.fetch(recruitmentAPI.deleteJob(cvId, cv.data.jobs[element]._id))
    })

    Object.keys(cv.data.languages).map( (element, index) => {
      ApiService.fetch(recruitmentAPI.deleteLanguage(cvId, cv.data.languages[element]._id))
    })

    Object.keys(cv.data.skills).map( (element, index) => {
      ApiService.fetch(recruitmentAPI.deleteSkill(cvId, cv.data.skills[element]._id))
    })

    ApiService.fetch(recruitmentAPI.updateMyCv(cvId, { hobbies: "" }))
  }

  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })

    if( cv_filled_metrics === null ) {
      clearProfile()
    }

    sessionStorage.setItem('cv_filled_metrics', true)

    const endpoint = cvId === null ? recruitmentAPI.createMyCV(values) : recruitmentAPI.updateMyCv(cvId, values)

    // ApiService.fetch(recruitmentAPI.registerCA({ chatUrl:'test' })).catch(error => {
    //     setSubmitting(false)
    //     console.log(error)
    //     console.log(error.details)
    //   })

    ApiService
      .fetch(endpoint)
      .then(data => {
        setSubmitting(false)
        setState({ error: null, isLoading: false })

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data.cv, 'edit'))
        dispatch(myUserActions.fetchMyUserSuccess(data.user))

        sessionStorage.setItem('cv', data.cv._id)

        if (!AuthService.loggedIn()) {
          AuthService.setToken(data.token)
        }

        history.push('/rejestracja/profil')
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BasicUserDataCVSchema}
      onSubmit={onSubmit}
      enableReinitialize
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit} id="FormStart">
          <AddUserForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
            handleMultiSelect={(fieldName, item, isMulti) =>
              handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
            touched={touched}
            values={values}
            isEditing={initialValues.summary !== ''}
          />

          <CreatorCvStepOneButtonContainer data-test='CreatorCvStepOneButtonContainer'>
            <ButtonComponent
              align='center'
              bgColor='pinkColor'
              color='whiteColor'
              data-test='buttonComponent'
              display='inline-flex'
              isLoading={state.isLoading}
              fontBold
              label={translationsList.form.labels.buttonNextStepLabel}
              buttonType='submit'
              size='sizeL'
              id={'nextStepButton'}
            />
          </CreatorCvStepOneButtonContainer>
        </form>
      )}
    />
  )
}


Yup.addMethod(Yup.string, 'mail', function(field) {
    const { message } = field;
    return this.test('test-name', message, function(value) {
        const { path, createError } = this;
        
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(value).toLowerCase());

        if( isValid ) {
          return true;
        } else {
          return createError({ path, message: field });
        }
    });
});

const BasicUserDataCVSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Pole jest wymagane'),
  title: Yup.string()
    .required('Pole jest wymagane'),
  cvLanguage: Yup.string()
    .required('Pole jest wymagane'),
  lastName: Yup.string()
    .required('Pole jest wymagane'),
  email: Yup.string()
    .mail('Błędny Email')
    .required('Pole jest wymagane'),
  linkedinUrl: Yup.string(),
  portfolioUrl: Yup.string()
    .url('Podany adres musi być poprawnym linkiem')
})

export default AddBasicUserDataCVFormContainer
