import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { valuesSelectors, valuesActions } from '../../../../store/ducks/values'

import SelectableItems from '../../../../components/SelectableItems/SelectableItems'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { toast } from 'react-toastify'

const SelectCompanyValuesContainer = () => {
  const valuesState = useSelector(valuesSelectors.getValuesState)
  const myCompany = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  useEffect(() => {
    if (valuesState.isLoading === false) {
      if (valuesState.data === null || valuesState.data.length === 0) {
        dispatch(valuesActions.fetchValuesRequested())
      }
    }
  }, [dispatch, valuesState])

  const values = valuesState.data.map((value) => ({
    ...value,
    isActive: myCompany.values.includes(value._id)
  }))

  const handleOnClick = (id) => {
    const value = values.filter((el) => el._id === id)[0]

    if (value.isActive) {
      dispatch(myCompanyActions.fetchMyCompanySuccess({
        ...myCompany,
        values: myCompany.values.filter((value) => value !== id)
      }))

      ApiService.fetch(companyAPI.removeValue(id))
        .catch(() => {
          toast.error('Nastąpił błąd podczas wybierania wartości!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })

          dispatch(myCompanyActions.fetchMyCompanySuccess({
            ...myCompany,
            values: [...myCompany.values, id]
          }))
        })
    } else {
      dispatch(myCompanyActions.fetchMyCompanySuccess({
        ...myCompany,
        values: [...myCompany.values, id]
      }))

      ApiService.fetch(companyAPI.addValue(id))
        .catch(() => {
          toast.error('Nastąpił błąd podczas wybierania wartości!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })

          dispatch(myCompanyActions.fetchMyCompanySuccess({
            ...myCompany,
            values: myCompany.values.filter((value) => value !== id)
          }))
        })
    }
  }

  return (
    <SelectableItems
      align='center'
      isEditable
      itemsList={values} // TODO
      onClick={handleOnClick}
    />
  )
}

export default SelectCompanyValuesContainer
