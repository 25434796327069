import React from 'react'
import PropTypes from 'prop-types'

import AuthService from '../../store/services/AuthService'

import BannerItem from '../../components/BannerItem'
import ButtonComponent from '../../components/Buttons/ButtonComponent'
import ChooseDayContainer from '../../containers/ComponentContainers/ChooseDayContainer'
import CompanyBox from '../../components/Boxes/CompanyBox'
// import ControlsContainer from '../../containers/ComponentContainers/ControlsContainer'
import Grid from '../../components/Grid'
import Image from '../../components/Form/FormBasicElements/Image'
import IndustriesContainer from '../../containers/ComponentContainers/IndustriesContainer'
import InfoBox from '../../components/InfoBox'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import RecruitersContainer from '../../containers/ComponentContainers/RecruitersContainer' // TODO Delete me and helpers.jsx when data connected
import Section from '../../components/Section'
import SelectableTagsContainer from '../../containers/ComponentContainers/TagsContainer'
import Site from '../../components/Wrappers/Site'
import SpacingLine from '../../components/SpacingLine'
import Steps from '../../components/Steps/Steps'
import Subtitle from '../../components/Subtitle'
import TrainingsContainer from '../../containers/ComponentContainers/TrainingsContainer'

import {
  CompaniesContainer,
  SectionCommonContainer,
  SectionCompanies,
  SectionInfoBox,
  SectionSteps,
  SectionTopButtonContainer,
  SectionTopContainer,
  SectionTopContent,
  SectionTopImage,
  SectionTopImageContainer,
  SectionTrainings,
  StepsButtonContainer
  // TrainingsControlsContainer
} from './components'

import homepageCover from '../../assets/img/homepageBg.svg'
import isExpoDay from '../../utils/isExpoDay'
import liveImagePath from '../../assets/img/live.gif'
import translationsList from '../../stabs/translationsList'

import {
  companiesPremium,
  companiesPrestige,
  companiesStandard
} from './helpers'

const EventPage = ({
  companies,
  event,
  trainings
}) => (
  <>
    <Site
      isLoggedOn={AuthService.loggedIn()}
      menuItemsList={isExpoDay()
        ? translationsList.topMenu.linksList_live
        : translationsList.topMenu.linksList}
      withNav
      eventPage
    >
      <InnerWrapper>
        <SectionTopContainer>
          <SectionTopContent>
            <Section
              description={isExpoDay()
                ? translationsList.sectionOne.description_live
                : translationsList.sectionOne.description}
              title={isExpoDay()
                ? translationsList.sectionOne.title_live
                : translationsList.sectionOne.title}
            />

            <SectionTopButtonContainer>
              <ButtonComponent
                bgColor='pinkColor'
                color='whiteColor'
                display='inline-flex'
                fontBold
                label={translationsList.register}
                link={isExpoDay()
                  ? '#companies'
                  : '/rejestracja'}
                size='sizeL'
                type='internal'
              />
            </SectionTopButtonContainer>
          </SectionTopContent>

          <SectionTopImageContainer>
            <SectionTopImage src={homepageCover} />
          </SectionTopImageContainer>
        </SectionTopContainer>

        {
          isExpoDay() && (
            <Image
              alt='Live!'
              source={liveImagePath}
              scaleMe={false}
            />
          )
        }

        <SectionInfoBox>
          <Subtitle
            asH1
            icon='pin'
            label={translationsList.sectionTwo.title}
            level='h1'
          />

          <InfoBox
            calendarUrl={event.calendarUrl}
            dates={event.dates}
          />
        </SectionInfoBox>

        <>
          <SpacingLine
            id='companies'
            isFixed
          />

          <SectionCommonContainer>
            <Section
              alignCenter
              description={translationsList.sectionFive.description}
              level='h2'
              title={translationsList.sectionFive.title}
            />

            <Subtitle
              icon='star'
              label={translationsList.sectionFive.subtitle.text}
            />
          </SectionCommonContainer>

          <IndustriesContainer />

          <SectionCompanies>
            <CompaniesContainer>
              {companiesPrestige(companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Top pracodawcy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={1}
                    noOutsideMargin
                  >
                    {
                      companiesPrestige(companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showTop={isExpoDay() && company.chatUrl}
                          showRightColumn
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videos[0] !== undefined ? company.videos[0].url : ''}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )}

              {
                companiesPremium(companies).length !== 0 && (
                  <>
                    <SectionCommonContainer>
                      <Subtitle
                        icon='star'
                        label='Rekomendowani pracodawcy'
                      />
                    </SectionCommonContainer>

                    <Grid
                      columns={2}
                      noOutsideMargin
                    >
                      {
                        companiesPremium(companies).map((company, index) => (
                          <CompanyBox
                            buttonLabel='Poznaj firmę'
                            image={company.logoUrl}
                            isFast={company.hasQuickRecruitment}
                            isFavourite={company.isFavourite}
                            key={index}
                            link={`/pracodawcy/${company.slug}`}
                            name={company.name}
                            // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                            // showRightColumn={company.showRightColumn}
                            showTop={isExpoDay() && company.chatUrl}
                            value='Jesteśmy online'
                            valueLabel={company.text}
                            videoUrl={company.videoUrl}
                            withIsFastButton={company.withIsFastButton}
                          />
                        ))
                      }
                    </Grid>
                  </>
                )
              }

              {companiesStandard(companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Pozostali pracodawcy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={4}
                    noOutsideMargin
                  >
                    {
                      companiesStandard(companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showTop={isExpoDay() && company.chatUrl}
                          showRightColumn={company.showRightColumn}
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videoUrl}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )}
            </CompaniesContainer>
          </SectionCompanies>
        </>

        <>
          <SpacingLine
            id='recruitment'
            isFixed
          />

          <SectionCommonContainer>
            <Section
              alignCenter
              description={translationsList.sectionRecruitment.description}
              level='h2'
              title={translationsList.sectionRecruitment.title}
            />

            <Subtitle
              icon='tag'
              label='Rekruterzy Top Pracodawców'
            />
          </SectionCommonContainer>

          <RecruitersContainer
            premium={companiesPremium(companies)}
            prestige={companiesPrestige(companies)}
            standard={companiesStandard(companies)}
          />
        </>

        <>
          <SpacingLine
            id='trainings'
            isFixed
          />

          <SectionCommonContainer>
            <Section
              alignCenter
              description={isExpoDay()
                ? translationsList.sectionThree.description_live
                : translationsList.sectionThree.description}
              level='h2'
              title={isExpoDay()
                ? translationsList.sectionThree.title_live
                : translationsList.sectionThree.title}
            />

            <Subtitle
              icon='tag'
              label={translationsList.sectionThree.subtitle.text}
            />

            <SelectableTagsContainer />
          </SectionCommonContainer>

          <ChooseDayContainer />

          <SectionTrainings>
            <TrainingsContainer
              dates={event.dates}
            />

            {/* <TrainingsControlsContainer> */}
            {/*  <ControlsContainer type='trainings' /> */}
            {/* </TrainingsControlsContainer> */}
          </SectionTrainings>
        </>

        <>
          <SpacingLine isFixed />

          <BannerItem
            buttonLabel={translationsList.banners.bannersList[0].buttonLabel}
            description={translationsList.banners.bannersList[0].description}
            link={translationsList.banners.bannersList[0].link}
            title={translationsList.banners.bannersList[0].title}
          />

          <SectionSteps>
            <Steps
              itemsList={translationsList.steps.landingPageSteps.stepsList}
              stepsTitle={translationsList.steps.landingPageSteps.labels.titleLabel}
            />

            <StepsButtonContainer>
              <ButtonComponent
                align='center'
                bgColor='pinkColor'
                color='whiteColor'
                display='inline-flex'
                fontBold
                label={translationsList.register}
                link='/rejestracja'
                size='sizeL'
                type='internal'
              />
            </StepsButtonContainer>
          </SectionSteps>
        </>
      </InnerWrapper>
    </Site>
  </>
)

EventPage.propTypes = {
  event: PropTypes.shape({
    _id: PropTypes.string,
    calendarUrl: PropTypes.string,
    city: PropTypes.string,
    date: PropTypes.string,
    drivingUrl: PropTypes.string,
    name: PropTypes.string,
    place: PropTypes.string,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string
  })
}

export default EventPage
