import React from 'react'
import PropTypes from 'prop-types'

import ButtonCircle from '../Buttons/ButtonCircle'
import Image from '../Form/FormBasicElements/Image'

import {
  ManageCvButtonsContainer,
  ManageCvContainer,
  ManageCvImageContainer,
  ManageCvTitle
} from './components'

// import copyIconPath from '../../assets/icons/copy.svg'
// import previewIconPath from '../../assets/icons/preview.svg'
import deleteIconPath from '../../assets/icons/delete_red.svg'
import editIconPath from '../../assets/icons/edit_blue.svg'
import saveIconPath from '../../assets/icons/save.svg'

const ManageCv = ({
  alt,
  image,
  onCopyClick,
  onDeleteClick,
  onEditClick,
  onPreviewClick,
  onSaveClick,
  title,
  withButtons
}) => (
  <ManageCvContainer data-test='ManageCvContainer'>
    <ManageCvTitle data-test='ManageCvTitle'>
      {title}
    </ManageCvTitle>

    <ManageCvImageContainer data-test='ManageCvImageContainer'>
      <Image
        alt={alt}
        data-test='ManageCvImage'
        scaleMe={false}
        source={image}
      />
    </ManageCvImageContainer>

    {
      withButtons && (
        <ManageCvButtonsContainer data-test='ManageCvButtonsContainer'>
          {/* <ButtonCircle */}
          {/*  data-test='ManageCvButtonPreview' */}
          {/*  icon={previewIconPath} */}
          {/*  onClick={onPreviewClick} */}
          {/* /> */}

          {/* <ButtonCircle */}
          {/*  data-test='ManageCvButtonCopy' */}
          {/*  icon={copyIconPath} */}
          {/*  onClick={onCopyClick} */}
          {/* /> */}

          <ButtonCircle
            data-test='ManageCvButtonSave'
            icon={saveIconPath}
            onClick={onSaveClick}
          />

          <ButtonCircle
            data-test='ManageCvButtonEdit'
            icon={editIconPath}
            onClick={onEditClick}
          />

          <ButtonCircle
            data-test='ManageCvButtonDelate'
            icon={deleteIconPath}
            onClick={onDeleteClick}
          />
        </ManageCvButtonsContainer>
      )
    }
  </ManageCvContainer>
)

export default ManageCv

ManageCv.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  onCopyClick: PropTypes.func,
  onDelateClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  title: PropTypes.string,
  withButtons: PropTypes.bool
}

ManageCv.defaultProps = {
  withButtons: false
}
