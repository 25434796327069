import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../assets/styles/colors'

export const TestimonialContainer = styled.div`
  @media (min-width: 576px) {
    margin: 0 auto;
    max-width: 400px;
  }
`

export const TestimonialImageContainer = styled.div`
  margin: 0 auto;

  & > div {
    padding: 0;
  }

  & img {
    border-radius: 20px;
  }

  @media (min-width: 768px) {
    height: 160px;
    width: 160px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    height: 120px;
    width: 120px;
  }
`

export const TestimonialInfoContainer = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 0;
  text-align: center;
`

export const TestimonialSignatureContainer = styled.div`
  color: ${PurpleColor};
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 0;
`
