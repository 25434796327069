import React from 'react'
import styled from 'styled-components'
import Image from '../../Form/FormBasicElements/Image'

import CloseBtn from './CloseBtn';

import {
  StyledJoyRide,
  JoyrideImage
} from './components'


const JoyrideTooltipContainer = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  size,
  skipProps
}) => {

const { back, close, last, next, skip } = step.locale;

const output = {
  primary: close,
};

function HtmlContent() { return {__html: step.content}; };


if (continuous) {
  output.primary = isLastStep ? last : next;

if (step.showProgress) {
    output.primary = (
      <span>
        {output.primary} ({index + 1}/{size})
      </span>
    );
  }
}

if (step.showSkipButton && !isLastStep) {
  output.skip = (
    <button
      style={step.styles.buttonSkip}
      type="button"
      data-test-id="button-skip"
      aria-live="off"
      {...skipProps}
    >
      {skip}
    </button>
  );
}

if (!step.hideBackButton && index > 0) {
  output.back = (
    <button style={step.styles.buttonBack} type="button" data-test-id="button-back" {...backProps}>
      {back}
    </button>
  );
}

output.close = !step.hideCloseButton && (
  <CloseBtn styles={step.styles.buttonClose} data-test-id="button-close" {...closeProps} />
);

  return (
<StyledJoyRide>
  <div
    key="JoyrideTooltip"
    className="react-joyride__tooltip"
    style={step.styles.tooltip}
    {...tooltipProps}
  >
  { (step.image && step.imagePosition == 'top') ?
    <JoyrideImage width={step.imageWidth}>
      <Image
        source={step.image}
        scaleMe={false}
      />
    </JoyrideImage>
    : <> </>
  }
    <div style={step.styles.tooltipContainer}>
      {step.title && (
        <h4 style={step.styles.tooltipTitle} aria-label={step.title}>
          {step.title}
        </h4>
      )}
      <div style={step.styles.tooltipContent}>
        { (step.image && step.imagePosition == 'absolute') ?
          <JoyrideImage width={step.imageWidth} imagePosition={step.imagePosition}>
            <Image
              source={step.image}
              scaleMe={false}
            />
          </JoyrideImage>
          : <> </>
        }
        {step.isHtml 
          ? <div dangerouslySetInnerHTML={HtmlContent()} />
          : step.content
        }
      </div>
    </div>
    {!step.hideFooter && (
      <div style={step.styles.tooltipFooter}>
        
        {output.back}
        <button
          style={step.styles.buttonNext}
          type="button"
          data-test-id="button-primary"
          {...primaryProps}
        >
          {output.primary}
        </button>
      </div>
    )}
    {output.close}
  </div>
</StyledJoyRide>
)};

export default JoyrideTooltipContainer