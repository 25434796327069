import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMount from '@rooks/use-did-mount'
import { useLocation } from 'react-router-dom'
import * as qs from 'query-string';

import { cvActions } from '../../../store/ducks/my-cv'
import { myUserActions, myUserSelectors } from '../../../store/ducks/my-user'
import useStripe from '../../../store/hooks/useStripe'
import useCvGenerator from '../../../store/hooks/useCvGenerator'

import FormPanel from '../../../components/Form/FormWrappers/FormPanel'
import Grid from '../../../components/Grid'
import Input from '../../../components/Form/FormBasicElements/Input'
import PricingBox from '../../../components/Boxes/PricingBox'
import { CreatorCvStepThreeButtonContainer } from '../../../pages/CreatorCvSteps/StepThree/components'
import ButtonComponent from '../../../components/Buttons/ButtonComponent'

import iconPathCheck from '../../../assets/icons/check_inBox.svg'
import iconPathStar from '../../../assets/icons/subtitle/subtitle_star.svg'
import translationsList from '../../../stabs/translationsList'
import { 
  CouponWrapper,
  BenefitsElement,
  BenefitsList,
  PricingContainer
} from './Atoms'

const PricingAndDownload = () => {
  const { generateCv, isLoading } = useCvGenerator()
  const [coupon, setCoupon] = React.useState('');

  const dispatch = useDispatch()
  const cvId = sessionStorage.getItem('cv')

  const user = useSelector(myUserSelectors.getMyUserData)
  const [isUserFetched, setUserFetched] = React.useState(false)

  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }

    if (isUserFetched) {
      dispatch(myUserActions.fetchMyUserRequested())
      setUserFetched(true)
    }
  })

  const hasSubscription = user.subscriptionIsActive

  const stripe = useStripe()

  if (hasSubscription) {
    return (
      <CreatorCvStepThreeButtonContainer data-test='CreatorCvStepThreeButtonContainer'>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='ButtonComponentPayments'
          display='inline-flex'
          fontBold
          label='Pobierz CV'
          size='sizeL'
          type='onClick'
          isLoading={isLoading}
          onClick={generateCv}
        />
      </CreatorCvStepThreeButtonContainer>
    )
  }

  return (
    <PricingContainer>
      <Grid
        columns={2}
        data-test='StepFourGrid'
        alignCenter
      >
        {
          translationsList.creatorCv.pricingList.map((pricingElem, index) => (
            <FormPanel
              data-test='StepFourFormPanel'
              key={index}
              paddingSize='sizeS'
              height='100%'
              selected={pricingElem.selected}
            >
              <PricingBox
                benefitsListTypeOne={pricingElem.benefitsList.filter(benefitTypeOne => benefitTypeOne.type === 1)}
                benefitsListTypeTwo={pricingElem.benefitsList.filter(benefitTypeOne => benefitTypeOne.type === 2)}
                buttonLabel='Zapłać teraz i pobierz CV'
                currency='zł'
                data-test='StepFourPricingBox'
                iconTypeOne={iconPathCheck}
                iconTypeTwo={iconPathStar}
                price={pricingElem.price}
                salePrice={pricingElem.salePrice}
                subTitle={pricingElem.subTitle}
                extraText={pricingElem.extraText}
                buttonOnClick={() => {
                  stripe.createSubscription(pricingElem.type, coupon)
                }}
              />
            </FormPanel>
          ))
        }
      </Grid>
    </PricingContainer>
  )
}

export default PricingAndDownload
