const errors = {
  pl: {
    'User does not exist': 'Użytkownik nie istnieje.',
    'User with that email is existing': 'Użytkownik o takim emailu już istnieje. <a href="/logowanie">Zaloguj się lub przypomnij hasło.</a>',
    'Wrong email or password': 'Błędny email lub hasło.',
    'User was not updated': 'Użytkownik nie został zaaktualizowany.',
    'You need to confirm email': 'Musisz potwierdzić swój email.'
  }
}

export default (lang, msg) => {
  if (msg.includes('User was not created: MongoError: E11000 duplicate key error')) {
    return errors[lang]['User with that email is existing']
  }
  if (errors[lang][msg] === undefined) {
    return msg
  }

  return errors[lang][msg]
}
