import React from 'react'
import useDidMount from '@rooks/use-did-mount'
import { useDispatch } from 'react-redux'

import { cvActions } from '../../../store/ducks/my-cv'

import AddEducationFormContainer from '../../../containers/CvCreatorContainers/AddEducationFormContainer'
import AddExperienceFormContainer from '../../../containers/CvCreatorContainers/AddExperienceFormContainer'
import AddLanguageFormContainer from '../../../containers/CvCreatorContainers/AddLanguageFormContainer'
import AddSkillFormContainer from '../../../containers/CvCreatorContainers/AddSkillFormContainer'
import ButtonComponent from '../../../components/Buttons/ButtonComponent'
import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'

import {
  CreatorCvStepTwoButtonContainer,
  CreatorCvStepTwoDescriptionContainer
} from './components'

import translationsList from '../../../stabs/translationsList'
import AddHobbyFormContainer from '../../../containers/CvCreatorContainers/AddHobbyFormContainer'
import AddCourseFormContainer from '../../../containers/CvCreatorContainers/AddCourseFormContainer'

const CreatorCvStepTwo = () => {
  const dispatch = useDispatch()
  const cvId = sessionStorage.getItem('cv')
  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  return (
    <>
      <Subtitle
        data-test='SubtitleExperience'
        icon='experience'
        label={'Uzupełnij profil zawodowy'}
      />

      <div id="expContainer"><AddExperienceFormContainer data-test='AddExperienceFormContainer' /></div>

      <SpacingLine
        data-test='SpacingLineEducation'
        isFixed
        option='SizeS'
      />

      <Subtitle
        data-test='SubtitleEducation'
        icon='education'
        label={translationsList.form.stepThree.subtitleThree}
      />

      <div id="eduContainer"><AddEducationFormContainer data-test='AddEducationFormContainer' /></div>

      <SpacingLine option='SizeS' isFixed />

      <Subtitle
        icon='education'
        label='Kursy'
      />

      <CreatorCvStepTwoDescriptionContainer>
        {'Możesz pochwalić się jakimiś kursami? Zrób to tutaj.'}
      </CreatorCvStepTwoDescriptionContainer>

      <div id="coursesContainer"><AddCourseFormContainer /></div>

      <SpacingLine
        data-test='SpacingLineLanguage'
        isFixed
        option='SizeS'
      />

      <Subtitle
        data-test='SubtitleLanguage'
        icon='language'
        label={translationsList.form.stepThree.subtitleFive}
      />

      <div id="langContainer"><AddLanguageFormContainer data-test='AddLanguageFormContainer' /></div>

      <SpacingLine
        data-test='SpacingLineSkill'
        isFixed
        option='SizeS'
      />

      <Subtitle
        data-test='SubtitleSkill'
        icon='skill'
        label={translationsList.form.stepThree.subtitleSix}
      />

      <div id="skilContainer"><AddSkillFormContainer data-test='AddSkillFormContainer' /></div>

      <SpacingLine
        data-test='SpacingLineHobby'
        isFixed
        option='SizeS'
      />

      <Subtitle
        data-test='SubtitleSkill'
        icon='skill'
        label={translationsList.form.stepThree.subtitleHobby}
      />

      <div id="hobbyContainer"><AddHobbyFormContainer /></div>

      <CreatorCvStepTwoButtonContainer data-test='CreatorCvStepTwoButtonContainer'>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='CreatorCvStepTwoButtonComponent'
          display='inline-flex'
          fontBold
          label={translationsList.form.labels.buttonNextStepLabel}
          link='/rejestracja/podsumowanie'
          type='internal'
          size='sizeL'
        />
      </CreatorCvStepTwoButtonContainer>
    </>
  )
}

export default CreatorCvStepTwo
