import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../../../assets/styles/colors'

export const TopNavButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  user-select: none;
`

export const TopNavButtonImageContainer = styled.div`
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;

  & > div {
    padding: 0;
  }

  @media (min-width: 1200px) {
    height: 50px;
    margin-right: 12px;
    width: 50px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 44px;
    margin-right: 10px;
    width: 44px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 36px;
    margin-right: 10px;
    width: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    height: 36px;
    margin-right: 6px;
    width: 36px;
  }
`

export const TopNavButtonPersonalDataContainer = styled.div`
  flex-direction: column;
  justify-content: center;

  @media (min-width: 576px) {
    display: flex;
    margin: 0 5px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: none;
  }
`

export const TopNavButtonNameAndSurnameContainer = styled.div`
  color: ${PurpleColor};
  display: flex;
  flex-wrap: nowrap;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
`

export const TopNavButtonName = styled.span`
  flex-shrink: 0;
  margin-right: 5px;
`

export const TopNavButtonSurname = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TopNavButtonPositionContainer = styled.div`
  text-align: left;
`

export const TopNavButtonPosition = styled.span`
  color: ${PurpleColorM};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 14px;
  }
`

export const TopNavButtonIconContainer = styled.div`
  flex-shrink: 0;
  width: 30px;
`

export const TopNavButtonIconCollapsedContainer = styled.div`
  transform: rotate(180deg);
`
