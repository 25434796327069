import styled from 'styled-components'

export const OutsideSliderElementWrapperContainer = styled.div`
  @media (min-width: 768px) {
    margin: 15px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 10px;
  }

  @media (max-width: 320px) and (max-width: 575px) {
    margin: 5px;
  }
`
