import React from 'react'
import PropTypes from 'prop-types'

import {
  DescriptionContainer
} from './components'

const Description = ({
  alignCenter,
  description,
  id,
  html
}) => (
  <>
    {
      description && (
        html
        ? <DescriptionContainer alignCenter={alignCenter} id={id} dangerouslySetInnerHTML={{__html: description}}></DescriptionContainer>
        : <DescriptionContainer alignCenter={alignCenter} id={id}>{description}</DescriptionContainer>
      )
    }
  </>
)

export default Description

Description.propTypes = {
  alignCenter: PropTypes.bool,
  html: PropTypes.bool,
  description: PropTypes.string,
  id: PropTypes.string
}
