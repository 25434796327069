import styled from 'styled-components'

import {
  PinkButtonBoxShadowColor,
  PinkColor,
  PinkColorM,
  PinkColorS,
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  PurpleColorXS,
  SilverColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const ButtonComponentContainer = styled.button` 
  align-items: center; 
  border-radius: 40px; 
  border: 0 solid transparent; 
  display: inline-flex; 
  justify-content: center; 
  user-select: none; 
 
  ${props => {
  switch (props.bgColor) {
    case 'pinkColor': return `background-color: ${PinkColor};`
    case 'pinkColorM': return `background-color: ${PinkColorM};`
    case 'purpleColor': return `background-color: ${PurpleColor};`
    case 'purpleColorM': return `background-color: ${PurpleColorM};`
    case 'purpleColorS': return `background-color: ${PurpleColorS};`
    case 'purpleColorXS': return `background-color: ${PurpleColorXS};`
    case 'whiteColor': return `background-color: ${WhiteColor};`
    default: return ''
  }
}} 
 
  ${props => {
  switch (props.border) {
    case 'pinkColorS': return `border: 2px solid ${PinkColorS};`
    default: return ''
  }
}} 
 
  ${props => {
  switch (props.boxShadow) {
    case 'pink': return `box-shadow: 0 20px 15px 0 ${PinkButtonBoxShadowColor};`
    default: return ''
  }
}} 
 
  ${props => {
    switch (props.color) {
      case 'pinkColorM': return `color: ${PinkColorM};`
      case 'purpleColor': return `color: ${PurpleColor};`
      case 'whiteColor': return `color: ${WhiteColor};`
      default: return ''
    }
  }}

  ${({ disabled }) => disabled
      ? `
          background-color: ${SilverColor};
          cursor: not-allowed;
        `
      : 'cursor: pointer;'
    }


  ${props => {
  switch (props.fontBold) {
    case true: return 'font-weight: 600;'
    default: return 'font-weight: 300;'
  }
}} 
 
  ${props => {
  switch (props.fullWidth) {
    case true: return 'width: 100%;'
    default: return ''
  }
}} 
 
  ${props => {
  switch (props.size) {
    case 'sizeM': return ` 
        line-height: 21px; 
        font-size: 14px; 
 
        @media (min-width: 1200px) { 
          padding: 20px 50px; 
        } 
 
        @media (min-width: 768px) and (max-width: 1199px) { 
          padding: 15px 30px; 
        } 
 
        @media (min-width: 576px) and (max-width: 767px) { 
          padding: 12px 30px; 
        } 
 
        @media (max-width: 575px) { 
          padding: 10px 20px; 
        } 
      `
    case 'sizeL': return ` 
        line-height: 28px; 
        font-size: 18px; 
 
        @media (min-width: 1200px) { 
          padding: 24px 60px; 
        } 
 
        @media (min-width: 768px) and (max-width: 1199px) { 
          padding: 20px 40px; 
        } 
 
        @media (min-width: 576px) and (max-width: 767px) { 
          padding: 17px 40px; 
        } 
 
        @media (max-width: 575px) { 
          padding: 15px 30px; 
        } 
      `
    default: return ''
  }
}} 
`

export const ButtonComponentContent = styled.div` 
  position: relative; 
`

export const ButtonIsLoadingContainer = styled.div` 
  position: absolute; 
  top: 0; 
  left: 0; 
  bottom: 0; 
  width: 42px; 
 
  & path { 
    ${props => {
  switch (props.isLoadingColor) {
    case 'greyColor': return 'fill: #eaeaea;'
    case 'purpleColorS': return `fill: ${PurpleColorS};`
    case 'yellowColor': return 'fill: #fbd0a6;'
    default: return ''
  }
}} 
  } 
 
  @media (min-width: 576px) and (max-width: 1199px) { 
    width: 36px; 
  } 
   
  @media (max-width: 575px) { 
    width: 32px; 
  } 
`

export const ButtonComponentText = styled.div` 
  ${props => props.isLoading && 'padding: 0 0 0 50px;'} 

  ${props => props.hasIcon && 
    `
    display:flex; 
    padding: 5px 10px;
    font-size: 14px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
      img {
        height: 16px;
        width: auto;
        margin-right: 5px;
      }`
    }
`
