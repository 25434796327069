import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import Icon from '../Form/FormBasicElements/Icon'
import LinkComponent from '../Buttons/LinkComponent'

import {
  NavigationContainer,
  NavigationContent,
  NavigationItemContainer,
  NavigationMobileButton
} from './Atoms'

import menuIconPath from '../../assets/icons/menu_bars.svg'

class Navigation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleClick(link, e) {
    if( !link.includes('#') ) {
      window.location.href = link;
    }

    e.preventDefault();    
    let id = link.replace('#', '')
    var el = document.getElementById(id)

    if( el ) {
      const headerEl = document.getElementById('siteHeader');
      const y = el.getBoundingClientRect().top + window.pageYOffset - headerEl.offsetHeight;
      window.scrollTo({top: y, behavior: 'smooth'});

      // el.scrollIntoView(true);
      // el.scrollTop -= 90;
      this.toggleShow();
    }
  }

  render () {
    const {
      itemsList
    } = this.props

    const handleOnClick = this.toggleShow

    return (
      <NavigationContainer data-test='NavigationContainer'>
        <NavigationMobileButton
          data-test='NavigationMobileButton'
          onClick={handleOnClick}
        >
          <Icon
            data-test='NavigationIcon'
            icon={menuIconPath}
          />
        </NavigationMobileButton>

        <NavigationContent
          data-test='NavigationContent'
          isOpen={this.state.isOpen}
        >
          {
            itemsList.map((item, id) =>
              <NavigationItemContainer
                data-test='NavigationItemContainer'
                key={id}
              >
                <LinkComponent
                  colorText='purpleColorM'
                  data-test='NavigationLinkComponent'
                  fontBold={item.fontBold}
                  fontSize='sizeXL'
                  inNewWindow={item.inNewWindow}
                  label={item.label}
                  link={item.link}
                  type='onClick'
                  onClick={(e) => this.handleClick(item.link, e)}
                />
              </NavigationItemContainer>
            )
          }
        </NavigationContent>
      </NavigationContainer>
    )
  }
}

export default Navigation

Navigation.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      fontBold: PropTypes.bool,
      inNewWindow: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.string
    })
  )
}

Navigation.defaultProps = {
  itemsList: []
}
