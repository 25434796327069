export default {
  label_aboutMe: 'O mnie',
  label_biggestSuccess: 'Moje największe osiągnięcia',
  label_contact: 'Kontakt',
  label_courses: 'Kursy',
  label_education: 'Edukacja',
  label_experience: 'Doświadczenie',
  label_haveLearned: 'Ta praca nauczyła mnie',
  label_hobbies: 'Zainteresowania',
  label_info: 'Info',
  label_languages: 'Języki',
  label_skills: 'Umiejętności',
  label_socialMedia: 'Social media',
  label_stillGoing: 'Nadal trwa',
  user_address: 'Adres',
  user_city: 'miasto',
  user_country: 'kraj',
  user_dateOfBirth: 'data urodzenia',
  user_email: 'e-mail',
  user_nationality: 'Obywatelstwo',
  user_phone: 'tel'
}
