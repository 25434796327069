import React from 'react'
import PropTypes from 'prop-types'

import FormPanel from '../Form/FormWrappers/FormPanel'
import Image from '../Form/FormBasicElements/Image'

import {
  TestimonialContainer,
  TestimonialImageContainer,
  TestimonialInfoContainer,
  TestimonialSignatureContainer
} from './Atoms'

const Testimonial = ({
  image,
  info,
  signature
}) => (
  <TestimonialContainer data-test='TestimonialContainer'>
    <FormPanel
      data-test='FormPanel'
      paddingSize='sizeS'
    >
      <TestimonialImageContainer data-test='TestimonialImageContainer'>
        <Image
          alt=''
          data-test='TestimonialImage'
          scaleMe={false}
          source={image}
        />
      </TestimonialImageContainer>

      <TestimonialInfoContainer data-test='TestimonialInfoContainer'>
        {info}
      </TestimonialInfoContainer>

      <TestimonialSignatureContainer data-test='TestimonialSignatureContainer'>
        {signature}
      </TestimonialSignatureContainer>
    </FormPanel>
  </TestimonialContainer>
)

export default Testimonial

Testimonial.propTypes = {
  image: PropTypes.string,
  info: PropTypes.string,
  signature: PropTypes.string
}
