import styled from 'styled-components'

import { PurpleColor } from '../../assets/styles/colors'

export const SpacerTop = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0 0;
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    margin: 40px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 30px 0 0;
  }
`

export const HeadingText = styled.h2`
  color: ${PurpleColor};
  font-weight: 600;
  text-align: center;

  @media (min-width: 1200px) {
    margin: 0 0 30px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 25px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 20px;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 10px;
  }
`

export const DownloadsManageCvContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0 0;
`
export const DownloadsManageCvSection = styled.div`
  margin-top: 50px;
`

export const ButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0 0;
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    margin: 40px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 30px 0 0;
  }
`
