import React from 'react'

import CheckBoxWithTextLinkText from '../../FormBasicElements/CheckBoxWithTextLinkText'
import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import MultiSelectDropDownField from '../../FormFields/MultiSelectDropDownField'
import TwoMultiSelectDropDownsField from '../../FormFields/TwoMultiSelectDropDownsField'

import {
  AddEducationFormCheckBoxWithTextLinkTextContainer
} from './components'

import months from '../../../../stabs/months'
import generateYears from '../../../../utils/generateYears'
import degrees from '../../../../stabs/degrees'
import AddedItem from '../../../List/AddedItem'

const AddEducationForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleMultiSelect,
  handleCheckbox,
  handleEdit,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel errors={apiError}>
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}
    <FormTwoColumns>
      <InputField
        data-test='schoolName'
        errorMessage={errors.institutionName}
        hasError={errors.institutionName && touched.institutionName}
        id='schoolName'
        label='Nazwa szkoły'
        name='institutionName'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz nazwę szkoły i miasto…'
        required
        value={values.institutionName}
      />

      <MultiSelectDropDownField
        errorMessage={errors.degree}
        hasError={errors.degree && touched.degree}
        name='degree'
        itemsList={degrees}
        label='Wykształcenie'
        onClick={handleMultiSelect}
        placeholder={values.degree === '' ? 'Wybierz' : values.degree}
        value={values.degree}
        required
        closeOnClick
      />

      <TwoMultiSelectDropDownsField
        data-test='twoMultiSelectDropDownsFieldFrom'
        errors={errors}
        touched={touched}
        hasErrorFirst={errors.startDateMonth && touched.startDateMonth}
        hasErrorSecond={errors.startDateYear && touched.startDateYear}
        itemsListFirst={months}
        itemsListSecond={generateYears}
        label='Od'
        nameFirst='startDateMonth'
        nameSecond='startDateYear'
        handleMultiSelect={handleMultiSelect}
        placeholderFirst={values.startDateMonth !== '' ? months.filter((month) => month.value === values.startDateMonth)[0].label : 'Miesiąc'}
        placeholderSecond={values.startDateYear !== '' ? values.startDateYear : 'Rok'}
        required
        values={values}
        type='education'
      />

      <TwoMultiSelectDropDownsField
        data-test='twoMultiSelectDropDownsFieldFrom'
        errors={errors}
        touched={touched}
        hasErrorFirst={errors.endDateMonth && touched.endDateMonth}
        hasErrorSecond={errors.endDateYear && touched.endDateYear}
        itemsListFirst={months}
        itemsListSecond={generateYears}
        label='DO'
        nameFirst='endDateMonth'
        nameSecond='endDateYear'
        handleMultiSelect={handleMultiSelect}
        placeholderFirst={values.endDateMonth !== '' ? months.filter((month) => month.value === values.endDateMonth)[0].label : 'Miesiąc'}
        placeholderSecond={values.endDateYear !== '' ? values.endDateYear : 'Rok'}
        values={values}
        type='education'
      />

      <InputField
        data-test='fieldOfStudy'
        errorMessage={errors.field}
        hasError={errors.field && touched.field}
        id='fieldOfStudy'
        label='Kierunek'
        name='field'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz kierunek'
        value={values.field}
      />

      <InputField
        data-test='specialization'
        errorMessage={errors.specialisation}
        hasError={errors.specialisation && touched.specialisation}
        id='specialization'
        label='Specjalizacja'
        name='specialisation'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz specializację'
        value={values.specialisation}
      />
    </FormTwoColumns>

    <AddEducationFormCheckBoxWithTextLinkTextContainer>
      <CheckBoxWithTextLinkText
        checked={values.stillOngoing}
        onChange={() => handleCheckbox('stillOngoing')}
        textBeforeLink='nadal trwa'
      />
    </AddEducationFormCheckBoxWithTextLinkTextContainer>
  </FormPanel>
)

export default AddEducationForm

// TODO FIX TRANSLATIONS, ADD PROPER DROPDOWNS DATA AND ONCLICK
