export default [
  { label: 'Styczeń', value: '01' },
  { label: 'Luty', value: '02' },
  { label: 'Marzec', value: '03' },
  { label: 'Kwiecień', value: '04' },
  { label: 'Maj', value: '05' },
  { label: 'Czerwiec', value: '06' },
  { label: 'Lipiec', value: '07' },
  { label: 'Sierpień', value: '08' },
  { label: 'Wrzesień', value: '09' },
  { label: 'Październik', value: '10' },
  { label: 'Listopad', value: '11' },
  { label: 'Grudzień', value: '12' }
]
