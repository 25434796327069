import styled from 'styled-components'

export const SpacingLineContainer = styled.div`
  background-color: transparent;
  height: ${({ heightSize }) => heightSize};
  width: 100%;

  ${props => {
    switch (props.option) {
      case 'NoMargin': return 'margin: 0;'
      case 'SizeS': return `
        @media (min-width: 576px) {
          margin: 40px 0;
        }

        @media (min-width: 320px) and (max-width: 575px) {
          margin: 30px 0;
        }
      `
      default: return `
        @media (min-width: 1600px) {
          margin: 150px 0;
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
          margin: 130px 0;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 120px 0;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 100px 0;
        }

        @media (min-width: 576px) and (max-width: 767px) {
          margin: 90px 0;
        }

        @media (min-width: 320px) and (max-width: 575px) {
          margin: 80px 0;
        }
      `
    }
  }}
`

export const SpacingLineFixed = styled.div`
  background-color: transparent;
  height: ${({ heightSize }) => heightSize};
  left: 0;
  position: absolute;
  right: 0;
`
