import React, { useState } from 'react'

import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import { Formik } from 'formik'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'
import ApiService from '../../store/services/ApiService'
import * as companiesAPI from '../../store/api/company'
import { toast } from 'react-toastify'
// import { useDispatch } from 'react-redux'

const AdminQuickCompanyCreationContainer = () => {
  const [state, setState] = useState({
    error: null,
    isLoading: false
  })

  // const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService.fetch(companiesAPI.quickCreateCompany(values))
      .then((company) => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        resetForm()
        setState({
          error: null,
          isLoading: false
        })
      }).catch((error) => {
        setSubmitting(false)
        console.log(error)
        toast.error('Bład!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <Formik
      initialValues={{
        name: '',
        website: '',
        email: '',
        password: '',
        recommendations: true,
        city: 'Wrocław',
        status: 'allowAll',
        marketingApproval: true
      }}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={null}
            noMargin
          >
            <FormTwoColumns data-test='FormTwoColumnsOne'>
              <InputField
                errorMessage={errors.name}
                hasError={errors.name && touched.name}
                label='Nazwa'
                name='name'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj nazwe Firmy...'
                value={values.name}
                required
              />
              <InputField
                errorMessage={errors.website}
                hasError={errors.website && touched.website}
                label='Strona'
                name='website'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj strone www Firmy...'
                value={values.website}
                required
              />
              <InputField
                errorMessage={errors.email}
                hasError={errors.email && touched.email}
                label='Email'
                name='email'
                type='email'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj email właściciela konta...'
                value={values.email}
                required
              />
              <InputField
                errorMessage={errors.password}
                hasError={errors.password && touched.password}
                label='Hasło'
                name='password'
                type='password'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj hasło właściciela konta...'
                value={values.password}
                required
              />

            </FormTwoColumns>

            <ResetAndSaveButtons
              data-test='ResetAndSaveButtonsOne'
              onClickReset={handleReset}
              disabledSave={isSubmitting}
              isLoadingSave={state.isLoading}
              isSaveSubmitting
            />
          </FormPanel>
        </form>
      )}
    />
  )
}

export default AdminQuickCompanyCreationContainer
