import React from 'react'
import PropTypes from 'prop-types'

import ArticleItem from './ArticleItem'
import Label from '../../Form/FormBasicElements/Label'
import SelectableItems from '../../SelectableItems/SelectableItems'
import VideoItem from './VideoItem'

import {
  GeneralOurMission,
  GeneralSectionContainer,
  GeneralText
} from './components'

import {
  ArticlesWrapperContainer
} from './ArticlesWrapper/components'
import {
  VideosWrapperContainer
} from './VideosWrapper/components'

import translationsList from '../../../stabs/translationsList'

const General = ({
  articlesList,
  ourMission,
  text,
  valueTags,
  videosList
}) => (
  <>
    {
      (ourMission.trim() !== '') && (
        <GeneralSectionContainer data-test='OurMissionContainer'>
          <Label
            data-test='OurMission'
            label={translationsList.companyPanel.ourMission}
          />

          <GeneralOurMission dangerouslySetInnerHTML={{ __html: ourMission }} />
        </GeneralSectionContainer>
      )
    }

    {
      valueTags.length !== 0 && (
        <GeneralSectionContainer data-test='HowWeDoItContainer'>
          <Label
            data-test='HowWeDoIt'
            label={translationsList.companyPanel.howWeDoIt}
          />

          <SelectableItems itemsList={valueTags} />
        </GeneralSectionContainer>
      )
    }

    {
      text.trim() !== '' && (
        <GeneralSectionContainer data-test='AboutUsContainer'>
          <Label
            data-test='AboutUs'
            label={translationsList.companyPanel.aboutUs}
          />

          <GeneralText dangerouslySetInnerHTML={{ __html: text }} />
        </GeneralSectionContainer>
      )
    }

    {
      articlesList.filter(article => article.isPublished).length !== 0 && (
        <GeneralSectionContainer data-test='ArticlesContainer'>
          <Label
            data-test='Articles'
            label={translationsList.companyPanel.articles}
          />

          <ArticlesWrapperContainer>
            {
              articlesList.map((article, id) => (
                <ArticleItem
                  date={article.date}
                  isPublished={article.isPublished}
                  key={id}
                  link={`/artykuly-eksperckie/${article.slug}`}
                  thumbnailUrl={article.articleThumbnailUrl}
                  title={article.title}
                />
              ))
            }
          </ArticlesWrapperContainer>
        </GeneralSectionContainer>
      )
    }

    {
      videosList.length !== 0 && (
        <GeneralSectionContainer data-test='VideosContainer'>
          <Label
            data-test='Videos'
            label={translationsList.companyPanel.videoGallery}
          />

          <VideosWrapperContainer>
            {
              videosList.map((video, id) => (
                <VideoItem
                  key={id}
                  url={video.url}
                  thumbnailUrl={video.thumbnailUrl}
                  title={video.title}
                />
              ))
            }
          </VideosWrapperContainer>
        </GeneralSectionContainer>
      )
    }
  </>
)

export default General

General.propTypes = {
  articlesList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      isPublished: PropTypes.bool,
      text: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      title: PropTypes.string
    })
  ),
  ourMission: PropTypes.string,
  text: PropTypes.string,
  valueTags: PropTypes.arrayOf(
    PropTypes.shape({
      isMostImportant: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  videosList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  )
}
