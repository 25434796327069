import React from 'react'
import { withRouter } from 'react-router-dom'

import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'

import {
  FormCompanyStepThreeButtonContainer
} from './components'

import AddWhoWeAreLookingForFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddWhoWeAreLookingForFormContainer'
import ChangeQrContainer from '../../../containers/PlatformContainer/CompanyStepsForms/ChangeQrContainer'
import ChangeStepButton from '../../../containers/PlatformContainer/CompanyStepsForms/ChangeStepButton'
import AddRecruiterFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddRecruiterFormContainer'
import AddJobOfferFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddJobOfferFormContainer'
import StepOneIndustriesContainer
  from '../../../containers/PlatformContainer/CompanyStepsForms/SelectCompanyIndustriesContainer'

const FormCompanyStepThree = () => (
  <>
    <Subtitle
      data-test='subtitleOne'
      icon='user'
      label='Osoby odpowiedzialne za rekrutacje'
    />

    <AddRecruiterFormContainer />

    <SpacingLine
      data-test='spacingLineOne'
      isFixed
    />

    <Subtitle
      data-test='subtitleTwo'
      icon='skill'
      label='Kogo szukacie? Jakie macie wymagania?'
    />

    <AddWhoWeAreLookingForFormContainer />

    <SpacingLine
      data-test='spacingLineTwo'
      isFixed
    />

    <Subtitle
      data-test='subtitleThree'
      label='Do jakich działów rekrutujesz?'
      icon='star'
    />

    <StepOneIndustriesContainer />

    <SpacingLine
      data-test='spacingLineTwo'
      isFixed
    />

    <Subtitle
      data-test='subtitleTwo'
      icon='experience'
      label='Oferty Pracy'
    />

    <AddJobOfferFormContainer />

    <SpacingLine
      data-test='spacingLineTwo'
      isFixed
    />

    <Subtitle
      data-test='subtitleThree'
      icon='skill'
      label='Weź udział w Szybkich Rekrutacjach i zrekrutuj pożądanych kandydatów już na targach!'
    />

    <ChangeQrContainer />

    <FormCompanyStepThreeButtonContainer>
      <ChangeStepButton step='companyStep4' />
    </FormCompanyStepThreeButtonContainer>
  </>
)

export default withRouter(FormCompanyStepThree)

// TODO Fix translations
