import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify'

import CreatorCvStepOne from '../../../pages/CreatorCvSteps/StepOne'
import CreatorCvStepThree from '../../../pages/CreatorCvSteps/StepThree'
import CreatorCvStepTwo from '../../../pages/CreatorCvSteps/StepTwo'
import Description from '../../Section/Description'
import FormCompanyStepFive from '../../../pages/PlatformCompanySteps/CompanyStepFive'
import FormCompanyStepFour from '../../../pages/PlatformCompanySteps/CompanyStepFour'
import FormCompanyStepOne from '../../../pages/PlatformCompanySteps/CompanyStepOne'
import FormCompanyStepThree from '../../../pages/PlatformCompanySteps/CompanyStepThree'
import FormCompanyStepTwo from '../../../pages/PlatformCompanySteps/CompanyStepTwo'
import FormUserStepOne from '../../../pages/PlatformUserSteps/UserStepOne'
import FormUserStepThree from '../../../pages/PlatformUserSteps/UserStepThree'
import FormUserStepTwo from '../../../pages/PlatformUserSteps/UserStepTwo'
import InnerWrapper from '../InnerWrapper'
import LinkComponent from '../../Buttons/LinkComponent'
import Site from '../Site'
import Steps from '../../Steps/Steps'
// import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// import Joyride, { ACTIONS, EVENTS, STATUS } from '../../../components/Joy';

import Joyride, {
  BeaconRenderProps,
  CallBackProps,
  Step,
  StoreHelpers,
  TooltipRenderProps,
  STATUS,
} from 'react-joyride';

import JoyrideTooltipContainer from '../../../components/JoyrideSteps/Tooltip';

import ImgProfesor from '../../../assets/img/profesor.png'
import questionMark from '../../../assets/img/question.png'

import {
  FormStepsWrapperArrowNextContainer,
  FormStepsWrapperArrowPrevContainer,
  FormStepsWrapperArrowsContainer,
  FormStepsWrapperContainer,
  FormStepsWrapperDescriptionContainer,
  FormStepsWrapperStepsContainer
} from './components'

import arrowIconPath from '../../../assets/icons/arrow_right.svg'
import isExpoDay from '../../../utils/isExpoDay'
import translationsList from '../../../stabs/translationsList'
import AuthService from '../../../store/services/AuthService'

const selectStep = (
  option,
  primaryEvent
) => {
  switch (option) {
    case 'companyStep1': return <FormCompanyStepOne primaryEvent={primaryEvent} />
    case 'companyStep2': return <FormCompanyStepTwo primaryEvent={primaryEvent} />
    case 'companyStep3': return <FormCompanyStepThree primaryEvent={primaryEvent} />
    case 'companyStep4': return <FormCompanyStepFour primaryEvent={primaryEvent} />
    case 'companyStep5': return <FormCompanyStepFive primaryEvent={primaryEvent} />
    case 'creatorCvStep1': return <CreatorCvStepOne />
    case 'creatorCvStep2': return <CreatorCvStepTwo />
    case 'creatorCvStep3': return <CreatorCvStepThree />
    case 'userStep1': return <FormUserStepOne primaryEvent={primaryEvent} />
    case 'userStep2': return <FormUserStepTwo primaryEvent={primaryEvent} />
    case 'userStep3': return <FormUserStepThree primaryEvent={primaryEvent} />
    default: return null
  }
}

const selectDescriptionText = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepOne.description
    case 'companyStep2': return translationsList.form.companySteps.companyStepTwo.description
    case 'companyStep3': return translationsList.form.companySteps.companyStepThree.description
    case 'companyStep4': return translationsList.form.companySteps.companyStepFour.description
    case 'companyStep5': return translationsList.form.companySteps.companyStepFive.description
    case 'creatorCvStep1': return ''
    case 'creatorCvStep2': return translationsList.form.creatorCv.creatorCvStepTwo.description
    case 'creatorCvStep3': return translationsList.form.creatorCv.creatorCvStepThree.description
    case 'userStep1': return translationsList.form.stepOne.description
    case 'userStep2': return translationsList.form.stepTwo.description
    case 'userStep3': return translationsList.form.stepThree.description
    default: return ''
  }
}

const selectLeftLabel = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return translationsList.stepsController.back
    case 'companyStep3': return translationsList.stepsController.back
    case 'companyStep4': return translationsList.stepsController.back
    case 'companyStep5': return translationsList.stepsController.back
    case 'creatorCvStep1': return null
    case 'creatorCvStep2': return translationsList.stepsController.back
    case 'creatorCvStep3': return translationsList.stepsController.back
    case 'userStep1': return null
    case 'userStep2': return translationsList.stepsController.back
    case 'userStep3': return translationsList.stepsController.back
    default: return ''
  }
}

const selectLeftLink = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return '/platforma/podstawowe-dane'
    case 'companyStep3': return '/platforma/szczegoly'
    case 'companyStep4': return '/platforma/rekrutacja'
    case 'companyStep5': return '/platforma/benefity-i-wyroznienia'
    case 'creatorCvStep1': return '/'
    case 'creatorCvStep2': return '/kreator-cv/podstawowe-dane'
    case 'creatorCvStep3': return '/kreator-cv/doswiadczenie'
    case 'userStep1': return null
    case 'userStep2': return '/platforma/podstawowe-dane'
    case 'userStep3': return '/platforma/moje-szkolenia'
    default: return ''
  }
}

const selectRightLabel = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return translationsList.stepsController.skip
    case 'companyStep3': return translationsList.stepsController.skip
    case 'companyStep4': return translationsList.stepsController.skip
    case 'companyStep5': return translationsList.stepsController.homePage
    case 'creatorCvStep1': return translationsList.stepsController.next
    case 'creatorCvStep2': return translationsList.stepsController.next
    case 'creatorCvStep3': return translationsList.stepsController.myCv
    case 'userStep1': return null
    case 'userStep2': return translationsList.stepsController.skip
    case 'userStep3': return translationsList.stepsController.homePage
    default: return ''
  }
}

const selectRightLink = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return '/platforma/rekrutacja'
    case 'companyStep3': return '/platforma/benefity-i-wyroznienia'
    case 'companyStep4': return '/platforma/edukacja'
    case 'companyStep5': return '/'
    case 'creatorCvStep1': return '/kreator-cv/doswiadczenie'
    case 'creatorCvStep2': return '/kreator-cv/szablon'
    case 'creatorCvStep3': return '/moje-cv'
    case 'userStep1': return null
    case 'userStep2': return '/platforma/pobierz-bilet'
    case 'userStep3': return '/'
    default: return ''
  }
}

const selectStepsList = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepsList1
    case 'companyStep2': return translationsList.form.companySteps.companyStepsList2
    case 'companyStep3': return translationsList.form.companySteps.companyStepsList3
    case 'companyStep4': return translationsList.form.companySteps.companyStepsList4
    case 'companyStep5': return translationsList.form.companySteps.companyStepsList5
    case 'creatorCvStep1': return translationsList.form.creatorCv.creatorCvSteps.stepsList1
    case 'creatorCvStep2': return translationsList.form.creatorCv.creatorCvSteps.stepsList2
    case 'creatorCvStep3': return translationsList.form.creatorCv.creatorCvSteps.stepsList3
    case 'userStep1': return isExpoDay()
      ? translationsList.form.stepsList1_live
      : translationsList.form.stepsList1
    case 'userStep2': return isExpoDay()
      ? translationsList.form.stepsList2_live
      : translationsList.form.stepsList2
    case 'userStep3': return isExpoDay()
      ? translationsList.form.stepsList3_live
      : translationsList.form.stepsList3
    default: return []
  }
}

const selectStepsTitle = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep2': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep3': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep4': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep5': return translationsList.form.companySteps.companyStepsTitle
    case 'creatorCvStep1': return translationsList.form.creatorCv.creatorCvTitle
    case 'creatorCvStep2': return translationsList.form.creatorCv.creatorCvTitle
    case 'creatorCvStep3': return translationsList.form.creatorCv.creatorCvTitle
    case 'creatorCvStep4': return translationsList.form.creatorCv.creatorCvTitle
    case 'userStep1': return translationsList.form.labels.titleLabel
    case 'userStep2': return translationsList.form.labels.titleLabel
    case 'userStep3': return translationsList.form.labels.titleLabel
    default: return ''
  }
}


const FormStepsWrapper = ({
  option,
  primaryEvent
}) => { 

const locale = { back: 'Wstecz', close: 'Zamknij', last: 'Zamknij', next: 'Dalej', skip: 'Pomiń' }

let steps = null
let stepsImage = null
let stepsLinked = null
let stepsEmail = null
let stepsCel = null
let stepsExp = null
let stepsEdu = null
let stepsCou = null
let stepsLang = null
let stepsSkills = null
let stepsHobby = null

const cookies = new Cookies();

let cvwCookies = cookies.get('cvw_tour_opened');

const [state, setState] = useState({
  stepIndex: 0,
  run: true,
  runImage: true,
  runLinked: true,
  runEmail: true,
  runCel: true,
  runExp: true,
  runEdu: true,
  runCou: true,
  runLang: true,
  runSkills: true,
  runHobby: true,
  conti: true
})


let helpersImage = ''
let helpersLinked = ''
let helpersEmail = ''
let helpersCel = ''
let helpersExp = ''
let helpersEdu = ''
let helpersCou = ''
let helpersLang = ''
let helpersSkills = ''
let helpersHobby = ''

const setHelpersImage = (StoreHelpers) => {
  helpersImage = StoreHelpers;
};

const setHelpersLinked = (StoreHelpers) => {
  helpersLinked = StoreHelpers;
};

const setHelpersEmail = (StoreHelpers) => {
  helpersEmail = StoreHelpers;
};

const setHelpersCel = (StoreHelpers) => {
  helpersCel = StoreHelpers;
};

const setHelpersExp = (StoreHelpers) => {
  helpersExp = StoreHelpers;
};

const setHelpersEdu = (StoreHelpers) => {
  helpersEdu = StoreHelpers;
};

const setHelpersCou = (StoreHelpers) => {
  helpersCou = StoreHelpers;
};

const setHelpersLang = (StoreHelpers) => {
  helpersLang = StoreHelpers;
};

const setHelpersSkills = (StoreHelpers) => {
  helpersSkills = StoreHelpers;
};


const setHelpersHobby = (StoreHelpers) => {
  helpersHobby = StoreHelpers;
};

const handleJoyrideCallbackImage = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersImage

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackLinked = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersLinked

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackEmail = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersEmail

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackCel = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersCel

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackExp = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersExp

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackEdu = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersEdu

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackCou = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersCou

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackLang = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersLang

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackSkills = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersSkills

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}

const handleJoyrideCallbackHobby = data => {
  const { action, index, status, type } = data;
  const { reset } = helpersHobby

  if( type == 'tour:end' ) {
    setTimeout(function(){
      reset('true')
    }, 200)
  }
}


const handleJoyrideCallback = data => {
  const { action, index, status, type } = data;

  if( type == 'tour:end' ) {
    cookies.set('cvw_tour_opened', true, { path: '/' });
  }
};

const isInView = ( id ) => {
  var element = document.getElementById(id);
  const top = element.getBoundingClientRect().top;

  return top;
}

if( option == 'creatorCvStep1' ) {
  stepsLinked = [
    {
      target: '#linkedIn',
      content: 'Umieść tu link do Twojego profilu na portalu LinkedIn. Sprawdź, czy wszystkie informacje, które tam umieściłeś są zgodne z CV. ',
      placement: 'right',
      title: 'Część rekruterów będzie chciała sprawdzić Cię w sieci. ',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      // disableBeacon: true,
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: 600,
          padding: '70px 30px 30px',
          fontSize: 17,
          top: 115
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }
  ]

  stepsEmail = [
    {
      target: '#userEmail',
      content: 'Powinien on być profesjonalny. Rekomendowany format to na przykład: imię.nazwisko@..., i.nazwsko@... zamiast pinokio1300@buziaczek.pl',
      placement: 'left',
      title: 'Umieść adres e-mail, który regularnie sprawdzasz.',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      // disableBeacon: true,
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: 600,
          padding: '70px 30px 30px',
          fontSize: 17,
          top: 115
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }
  ]

  stepsCel = [
    {
      target: '#celZawodowy',
      content: 'Napisz, w jakim obszarze się rozwijasz (lub chcesz się rozwijać), co Cię wyróżnia i jakimi sukcesami możesz się już pochwalić. Wytłuść najważniejsze rzeczy i pisz językiem korzyści.',
      placement: 'top',
      title: 'W tej sekcji skup się na wartości, jaką dostarczasz jako pracownik.',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      // disableBeacon: true,
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: 600,
          padding: '70px 30px 30px',
          fontSize: 17
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }
  ]

  stepsImage = [
    {
      target: '#yourImage',
      content: 'Zdjęcie powinno podkreślać Twój profesjonalizm i być wysokiej jakości (zachowana odpowiednia ostrość i oświetlenie). Ubiór powinien być schludny i dopasowany do stanowiska, na jakie aplikujesz (np. garnitur w przypadku aplikacji na stanowisko prawnicze). <br/><br/>Nie dodawaj zdjęcia z wakacji, selfie, w okularach przeciwsłonecznych, zbyt poważnego. Nie dodawaj zdjęcia całej sylwetki (od stóp do głów), a jedynie od ramion w górę. Nie wycinaj siebie ze zdjęć ze znajomymi - najczęściej jest to widoczne i nie jest to pozytywnie postrzegane. Jeśli nie masz profesjonalnego zdjęcia, po prostu go nie dodawaj. <br/><br/>Nie dodawaj zdjęcia, jeśli aplikujesz na stanowisko do kraju, w którym powszechną praktyką jest wysyłanie CV bez zdjęcia, np. w Wielkiej Brytanii.',
      placement: 'right',
      title: 'Zdjęcie w CV pomoże Ci lub całkowicie zaszkodzi! ',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      // disableBeacon: true,
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: 900,
          padding: '70px 30px 30px',
          fontSize: 17,
          top: 115
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }
  ]

  steps = [
    {
      target: 'body',
      content: 'Jeśli chcesz coś zmienić w swojej karierze, to dobrze trafiłeś, bo będziemy tworzyć Twoje nowe, lepsze CV. Podążaj za moimi radami, a zdobędziesz TĘ pracę. Mam świetny plan... ;)',
      disableBeacon: true,
      isFixed: true,
      placement: 'center',
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        tooltip: {
          width: 600,
          padding: '50px 30px 30px',
          fontSize: 17
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        tooltipFooter: {
          justifyContent: 'center'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        overlay: {
          zIndex: 99999
        }
      },
      title: 'Cześć, mówią na mnie Profesor!',
      image: ImgProfesor,
      imageWidth: '50%',
      imagePosition: 'top',
      locale: { 
        next: 'Chcę stworzyć nowe CV!'
      }
    },
    {
      target: 'body',
      content: 'Idealne CV to składowa <strong>odpowiednio formatu i struktury, konkretnego przekazu oraz wyjątkowej osobowości.</strong><br/></br>Korzystając z mojego kreatora nie musisz się martwić o perfekcyjny format i układ treści - już o to zadbałem! Konkretny przekaz zbudujemy wspólnie - będę podpowiadał Ci, na co potrzebujesz zwrócić szczególną uwagę. Natomiast wyjątkową osobowość tworzysz Ty sam i pamiętaj o tym tworząc ten dokument. To pomoże Ci się wyróżnić wśród pozostałych CV!',
      disableBeacon: true,
      isFixed: true,
      placement: 'center',
      styles: {
        tooltipContent: {
          textAlign: 'justify',
          display: 'flex',
          alignItems: 'center'
        },
        tooltip: {
          width: 900,
          padding: '70px 30px 30px',
          fontSize: 17
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        overlay: {
          zIndex: 99999
        }
      },
      title: 'Zatem zaczynamy...',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true
    }
  ];
}
if( option == 'creatorCvStep2' ) {
  stepsExp = [
    {
      target: '#expContainer',
      content: "Zacznij od doświadczenia najbardziej aktualnego. Jeśli to jest pierwsza praca, warto dodać wakacyjne praktyki czy staże. Jednak jeśli jesteś specjalistą z doświadczeniem, skup się przede wszystkim na pracy związanej bezpośrednio ze stanowiskiem i pomiń mało istotne etapy Twojej kariery zawodowej, jak np. fizyczna praca za granicą.<br/><br/>Zakres swoich obowiązków wypisz „od punktów”. Każdy punkt kończ średnikiem lub przecinkiem, a ostatni zakończ kropką. Dzięki temu CV będzie miało atrakcyjną i poprawną strukturę. Z kolei „Największe sukcesy” opisz w jednej lini, po przecinku.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }
    ]

    stepsEdu = [
    {
      target: '#eduContainer',
      content: "Zacznij od edukacji, która jest najbardziej aktualna. Jeżeli masz ukończone studia, nie warto dodawać szkoły podstawowej czy też gimnazjum. Liceum czy też inna edukacja może być jednak wartościowa, jeżeli jest związana z Twoim aktualnym profilem zawodowym.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }]

    stepsCou = [
    {
      target: '#coursesContainer',
      content: "Możesz pochwalić się jakimiś kursami? Zrób to tutaj.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }]

    stepsLang = [
    {
      target: '#langContainer',
      content: "Upewnij się, że podajesz realny poziom znajomości języka. Bardzo prawdopodobne, że podczas rozmowy rekrutacyjnej te umiejętności zostaną sprawdzone przez rekrutera. Musisz mieć pewność, że sobie poradzisz.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }]

    stepsSkills = [
    {
      target: '#skilContainer',
      content: "Pochwal się, co potrafisz. Twoje umiejętności to Twoje aktywa. Po prostu wybierz daną umiejętność i określ jej poziom.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }]

    stepsHobby = [
    {
      target: '#hobbyContainer',
      content: "Wypisz swoje zainteresowania - te związane z pracą i te z życia prywatnego. Jest to sekcja, która dużo mówi o Tobie i może pomóc Ci dodatkowo zapunktować w rozmowie z rekruterem, jeśli okaże się, że dzielicie podobne pasje.",
      placement: 'top',
      image: ImgProfesor,
      imageWidth: '150px',
      imagePosition: 'absolute',
      isHtml: true,
      styles: {
        tooltipContent: {
          textAlign: 'justify'
        },
        buttonNext: {
          borderRadius: 40,
          fontWeight: 600,
          fontSize: 14,
          padding: '20px 50px',
          outline: 0
        },
        tooltip: {
          width: '70%',
          padding: '70px 30px 30px',
          fontSize: 17,
          margin: '0 auto'
        },
        tooltipContainer: {
          lineHeight: 1.5
        },
        spotlight: {
          width: 'auto'
        },
        beaconOuter: {
          backgroundSize: 'cover',
          border: 0,
          backgroundImage: 'url('+questionMark+')'
        },
        beaconInner: {
          backgroundColor: 'transparent'
        },
        overlay: {
          zIndex: 99999
        }
      },
    }]
}

const changeStep = (link) => {
  console.log(link)
  let yourImage = document.getElementById('yourImage')

  if( yourImage ) {
    let form = document.getElementsByTagName("form")[0];
    let elements = document.body.getElementsByTagName('label')
    let inputIDarray = []
    let filled = true
    let email = true

    for(var i = 0, max = elements.length; i < max; i++) {
      if( elements[i].hasChildNodes()) {
        if( elements[i].firstChild.nodeType == 1 ) {
          var inputID = elements[i].getAttribute('for')
          inputIDarray.push(inputID);
        }
      } 
    }

    inputIDarray.forEach(function(item, index, array) {
      let element = document.getElementById(item);
      if( element.value == '' ) {
        filled = false;
      }

      if( element.getAttribute('type') == 'email' ) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        email = re.test(String(element.value).toLowerCase());
      }
    });

    console.log(filled)

    if (!filled && email ) {
      toast.error('Musisz uzupełnić wymagane pola zanim przejdziesz do następnego kroku!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }

    let next = document.getElementById('nextStepButton')
    next.click()
  } else {
    window.location.href = link
  }

  
}

if( cvwCookies == 'true' ) {
  steps = null
}

let stepIndex = 0
  return (
  <Site
    menuItemsList={
      option.includes('company')
        ? [{
          fontBold: false,
          inNewWindow: true,
          label: 'Złóż zamówienie na targi',
          link: 'https://dla-firm.careerexpo.pl/targi/zamowienie/'
        }]
        : []
    }
    withNav={option.includes('company')}
    isLoggedOn={AuthService.loggedIn()}
  >

  { option == 'creatorCvStep1' ?
  <>
  { steps &&
  <Joyride
      steps={steps}
      run={state.run}
      callback={handleJoyrideCallback}
      continuous='true'
      locale={locale}
      styles={{
        options: {
          zIndex: 9999,
          primaryColor: '#FD007A',
          padding: '50px'
        }
      }}
      tooltipComponent={JoyrideTooltipContainer}
    />
  }

    <Joyride
      steps={stepsImage}
      run={state.runImage}
      continuous={state.conti}
      callback={handleJoyrideCallbackImage}
      locale={locale}
      styles={{
        options: {
          zIndex: 9999,
          primaryColor: '#FD007A',
          padding: '50px'
        }
      }}
      tooltipComponent={JoyrideTooltipContainer}
      getHelpers={setHelpersImage}
    />

    <Joyride
      steps={stepsLinked}
      run={state.runLinked}
      continuous='true'
      locale={locale}
      styles={{
        options: {
          zIndex: 9999,
          primaryColor: '#FD007A',
          padding: '50px'
        }
      }}
      tooltipComponent={JoyrideTooltipContainer}
      callback={handleJoyrideCallbackLinked}
      getHelpers={setHelpersLinked}
    />

    <Joyride
      steps={stepsEmail}
      run={state.runEmail}
      continuous='true'
      locale={locale}
      styles={{
        options: {
          zIndex: 9999,
          primaryColor: '#FD007A',
          padding: '50px'
        }
      }}
      tooltipComponent={JoyrideTooltipContainer}
      callback={handleJoyrideCallbackEmail}
      getHelpers={setHelpersEmail}
    />

    <Joyride
      steps={stepsCel}
      run={state.runCel}
      continuous='true'
      locale={locale}
      styles={{
        options: {
          zIndex: 9999,
          primaryColor: '#FD007A',
          padding: '50px'
        }
      }}
      tooltipComponent={JoyrideTooltipContainer}
      callback={handleJoyrideCallbackCel}
      getHelpers={setHelpersCel}
    />

    </>
    : ''
  }
  { option == 'creatorCvStep2' ?
    <>
      <Joyride
        steps={stepsExp}
        run={state.runExp}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackExp}
        getHelpers={setHelpersExp}
      />

      <Joyride
        steps={stepsEdu}
        run={state.runEdu}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackEdu}
        getHelpers={setHelpersEdu}
      />

      <Joyride
        steps={stepsCou}
        run={state.runCou}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackCou}
        getHelpers={setHelpersCou}
      />

      <Joyride
        steps={stepsLang}
        run={state.runLang}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackLang}
        getHelpers={setHelpersLang}
      />

      <Joyride
        steps={stepsSkills}
        run={state.runSkills}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackSkills}
        getHelpers={setHelpersSkills}
      />

      <Joyride
        steps={stepsHobby}
        run={state.runHobby}
        continuous='true'
        locale={locale}
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: '#FD007A',
            padding: '50px'
          }
        }}
        tooltipComponent={JoyrideTooltipContainer}
        callback={handleJoyrideCallbackHobby}
        getHelpers={setHelpersHobby}
      />
    </>
    : ''
  }
  
    <InnerWrapper data-tesst='InnerWrapper'>
      <FormStepsWrapperStepsContainer data-test='FormStepsWrapperStepsContainer'>
        <Steps
          data-test='Steps'
          itemsList={selectStepsList(option)}
          title={selectStepsTitle(option)}
          id={option}
        />
      </FormStepsWrapperStepsContainer>

      <FormStepsWrapperDescriptionContainer data-test='FormStepsWrapperDescriptionContainer'>
        <Description
          alignCenter
          data-test='Description'
          description={selectDescriptionText(option)}
        />
      </FormStepsWrapperDescriptionContainer>

      {
        option !== 'userStep1' &&
        option !== 'companyStep1' && (
          <FormStepsWrapperArrowsContainer data-test='FormStepsWrapperArrowsContainer'>
            {
              option !== 'creatorCvStep1' && (
                <FormStepsWrapperArrowPrevContainer>
                  <LinkComponent
                    colorStroke='purpleColor'
                    colorText='purpleColor'
                    data-test='LinkComponentLeft'
                    fontSize='sizeXL'
                    iconBefore={arrowIconPath}
                    label={selectLeftLabel(option)}
                    link={selectLeftLink(option)}
                    rotateIcons='180'
                    type='internal'
                  />
                </FormStepsWrapperArrowPrevContainer>
              )
            }

            {
              option !== 'creatorCvStep3' && (
                <FormStepsWrapperArrowNextContainer data-test='FormStepsWrapperArrowNextContainer'>
                  <LinkComponent
                    colorStroke='purpleColor'
                    colorText='purpleColor'
                    data-test='LinkComponentRight'
                    fontSize='sizeL'
                    iconAfter={arrowIconPath}
                    label={selectRightLabel(option)}
                    link={selectRightLink(option)}
                    type='onClick'
                    onClick={(e) => changeStep(selectRightLink(option))}
                  />
                </FormStepsWrapperArrowNextContainer>
              )
            }
          </FormStepsWrapperArrowsContainer>
        )
      }

      <FormStepsWrapperContainer data-test='FormStepsWrapperContainer'>
        {selectStep(option, primaryEvent)}
      </FormStepsWrapperContainer>
    </InnerWrapper>
  </Site>
)}

export default FormStepsWrapper

FormStepsWrapper.propTypes = {
  option: PropTypes.string
}
