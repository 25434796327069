import exampleImagePath1 from '../../assets/img/contact/staff_anna_scislowska.png'
import exampleImagePath3 from '../../assets/img/homepageBg.svg'

export const hardcodedRecruitersList = [
  {
    avatarUrl: exampleImagePath3,
    chatURL: 'chatURL',
    companyName: 'Long Long Long companyName companyName companyName companyName',
    firstName: 'Long Long Long firstName firstName firstName',
    lastName: 'Long Long Long lastName lastName lastName',
    position: 'Long Long Long position position position position'
  },
  {
    avatarUrl: exampleImagePath3,
    chatURL: 'chatURL',
    companyName: 'companyName',
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position'
  },
  {
    avatarUrl: exampleImagePath1,
    chatURL: 'chatURL',
    companyName: 'Long Long Long companyName companyName companyName companyName',
    firstName: 'Long Long Long firstName firstName firstName',
    lastName: 'Long Long Long lastName lastName lastName',
    position: 'Long Long Long position position position position'
  },
  {
    avatarUrl: exampleImagePath1,
    chatURL: 'chatURL',
    companyName: 'companyName',
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position'
  }
]

export const companiesPrestige = (companies) => companies.filter((company) => company.levelOfHighlighting === 'prestige')
export const companiesPremium = (companies) => companies.filter((company) => company.levelOfHighlighting === 'premium')
export const companiesStandard = (companies) => companies.filter((company) => company.levelOfHighlighting !== 'prestige').filter((company) => company.levelOfHighlighting !== 'premium')
