import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../Buttons/ButtonComponent'
import CountdownComponent from '../CountdownComponent'
import FormPanel from '../Form/FormWrappers/FormPanel'
import Label from '../Form/FormBasicElements/Label'

import isExpoDay from '../../utils/isExpoDay'
import translationsList from '../../stabs/translationsList'

import {
  InfoBoxButtonContainer,
  InfoBoxContent,
  InfoBoxDate,
  InfoBoxRemainedContainer
} from './Atoms'

const InfoBox = ({
  calendarUrl,
  dates
}) => (
  <FormPanel
    data-test='FormPanel'
    noMargin
  >
    <InfoBoxContent data-test='InfoBoxContent'>
      <>
        <Label
          data-test='LabelDateAndTime'
          label={translationsList.locations.labels.dateAndTimeLabel}
        />

        <InfoBoxDate data-test='InfoBoxDate'>
          {dates[0].date + ' ' + dates[0].start + ' - ' + dates[0].end}
          <br />
          {dates[1].date + ' ' + dates[1].start + ' - ' + dates[1].end}
        </InfoBoxDate>
      </>

      <InfoBoxRemainedContainer data-test='InfoBoxRemainedContainer'>
        <Label
          data-test='LabelRemained'
          label={isExpoDay()
            ? 'Pozostało do końca targów'
            : 'Pozostało'}
        />

        <CountdownComponent
          data-test='CountdownComponent'
          date={isExpoDay() ? new Date(2020, 3, 2, 20, 0, 0) : new Date(2020, 3, 1, 14, 0, 0)}
        />
      </InfoBoxRemainedContainer>

      <InfoBoxButtonContainer data-test='InfoBoxButtonContainer'>
        <ButtonComponent
          bgColor='pinkColor'
          color='whiteColor'
          display='inline-flex'
          data-test='ButtonComponent'
          fontBold
          inNewWindow
          label={translationsList.locations.labels.addToCalendarButtonLabel}
          link={calendarUrl}
          size='sizeM'
          type='external'
        />
      </InfoBoxButtonContainer>
    </InfoBoxContent>
  </FormPanel>
)

export default InfoBox

InfoBox.propTypes = {
  calendarUrl: PropTypes.string,
  dateTime: PropTypes.shape({
    date: PropTypes.string,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string
  })
}

InfoBox.defaultProps = {
  calendarUrl: '',
  dateTime: {
    date: '',
    timeEnd: '',
    timeStart: ''
  }
}
