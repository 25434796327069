import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { cvActions } from '../../store/ducks/my-cv'

import BannerItem from '../../components/BannerItem'
import ButtonComponent from '../../components/Buttons/ButtonComponent'
import ChooseCV from '../../components/ChooseCV'
import ChooseCvContainer from '../../containers/CvCreatorContainers/ChooseCvContainer'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import Grid from '../../components/Grid'
import GuidlineStep from '../../components/GuidlineStep'
import InfoIconText from '../../components/InfoIconText'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import OutsideSlider from '../../components/Sliders/OutsideSlider'
import OutsideSliderElementWrapper from '../../components/Sliders/OutsideSliderElementWrapper'
import PricingBox from '../../components/Boxes/PricingBox'
import Section from '../../components/Section'
import Site from '../../components/Wrappers/Site'
import SpacingLine from '../../components/SpacingLine'
import Testimonial from '../../components/Testimonial'

import Image from '../../components/Form/FormBasicElements/Image'

import CvCreatorHome from '../../containers/CvCreatorHome'

import easyScroll from 'easy-scroll';

import {
  CvCreatorPageBannerContainer,
  CvCreatorPageButtonContainer,
  CvCreatorPageChooseCvSectionContainer,
  CvCreatorPageGuidlineStepsContainer,
  CvCreatorPageGuidlineStepsSectionContainer,
  CvCreatorPageLeftColumn,
  CvCreatorPageRightColumn,
  CvCreatorPageTestimonialsContainer,
  CvCreatorPageTestimonialsContent,
  CvCreatorPageChooseCvContainer,
  CvCreatorPageChooseCvContainerButton,
  CvCreatorBenefitsBoxes,
  CvCreatorPageVideoContainer,
  CvCreatorPageVideoContainerRight,
  CvCreatorPageVideoContainerHeading,
  CvCreatorPageVideoContainerList,
  CvCreatorPageVideoContainerListElement,
  CvCreatorPageVideoContainerListElementIndex,
  CvCreatorPageSolid,
  CvCreatorPageSolidList,
  CvCreatorPageSolidImage,
  CvCreatorPageSolidListElement,
  BenefitsElement,
  BenefitsList,
  PricingContainer,
  CvCreatorPageVideoButton,
  ChooseCvContainerMobile,
  ChooseCvContainerDesktop,
  CvShortVideoSectionContainer,
  CvShortVideoSectionIframe
} from './Atoms'

import { testimonialSliderSettings } from './consts'

import AuthService from '../../store/services/AuthService'

import iconPathCheck from '../../assets/icons/check_inBox.svg'
import iconPathStar from '../../assets/icons/subtitle/subtitle_star.svg'
import translationsList from '../../stabs/translationsList'
import Subtitle from '../../components/Subtitle'
import ModalController from '../../components/ModalController'

import HomepageCv1Dark from '../../assets/img/cv_templates/Berlin - d.png'
import HomepageCv1Light from '../../assets/img/cv_templates/Berlin - j.png'
import HomepageCv2Dark from '../../assets/img/cv_templates/CV3 - d.png'
import HomepageCv2Light from '../../assets/img/cv_templates/CV3 - j.png'
import HomepageCv3Dark from '../../assets/img/cv_templates/Lizbona - d.png'
import HomepageCv3Light from '../../assets/img/cv_templates/Lizbona - j.png'
import HomepageCv4Dark from '../../assets/img/cv_templates/Nairobi - d.png'
import HomepageCv4Light from '../../assets/img/cv_templates/Nairobi - j.png'
import HomepageCv5Dark from '../../assets/img/cv_templates/Oslo - d.png'
import HomepageCv5Light from '../../assets/img/cv_templates/Oslo - j.png'
import HomepageCv6Dark from '../../assets/img/cv_templates/Palermo - d.png'
import HomepageCv6Light from '../../assets/img/cv_templates/Palermo - j.png'
import HomepageCv7Dark from '../../assets/img/cv_templates/Rio - d.png'
import HomepageCv7Light from '../../assets/img/cv_templates/Rio - j.png'
import HomepageCv10Dark from '../../assets/img/cv_templates/Tokio - d.png'
import HomepageCv10Light from '../../assets/img/cv_templates/Tokio - j.png'

import HomepageBenefitsImageOne from '../../assets/icons/homepage/templates.svg'
import HomepageBenefitsImageTwo from '../../assets/icons/homepage/dostep-premium.svg'
import HomepageBenefitsImageThree from '../../assets/icons/homepage/gwarancja.svg'

const CvCreatorPage = ({ modal }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <>
      <Site
        creatorCvPage
        data-test='CvCreatorPageSite'
        eventPage={false}
        isLoggedOn={AuthService.loggedIn()}
        menuItemsList={translationsList.topMenu.linksListCreatorCV}
        withNav
      >
        <InnerWrapper data-test='CvCreatorPageInnerWrapper'>
          <CvCreatorPageChooseCvSectionContainer data-test='CvCreatorPageChooseCvSectionContainer'>
            <CvCreatorPageLeftColumn data-test='CvCreatorPageLeftColumn'>
              <Section
                data-test='CvCreatorPageSectionChooseCv'
                description='Wybierz piękny szablon, dowiedz się, co wpisać w <i>celu zawodowym</i> czy <i>doświadczeniu</i>, a potem zdobądź każdą pracę!'
                title='Kreator Skutecznego CV'
                html
              />

              <CvCreatorPageButtonContainer data-test='CvCreatorPageButtonContainer'>
                <ButtonComponent
                  bgColor='pinkColor'
                  buttonType='button'
                  color='whiteColor'
                  data-test='CvCreatorPageButtonComponent'
                  fontBold
                  inNewWindow
                  label='STWÓRZ TERAZ CV!'
                  onClick={() => {
                    sessionStorage.removeItem('cv')
                    dispatch(cvActions.clearCV())
                    if( AuthService.loggedIn() ) {
                      history.push('/kreator-cv/podstawowe-dane') 
                    } else {
                      window.location.href = '/rejestracja' 
                    }
                  }}
                  size='sizeL'
                  type='onClick'
                />
              </CvCreatorPageButtonContainer>
            </CvCreatorPageLeftColumn>

            <CvCreatorPageRightColumn data-test='CvCreatorPageRightColumn'>
              <OutsideSlider
                data-test='CvCreatorPageOutsideSliderChooseCv'
                withDots
              >
                <ChooseCV
                  data-test='CvCreatorPageChooseCV-1-d'
                  halfWidth
                  id='id'
                  image={HomepageCv1Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Tokio - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-1-l'
                  halfWidth
                  id='id'
                  image={HomepageCv1Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Tokio - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-2-d'
                  halfWidth
                  id='id'
                  image={HomepageCv2Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Berlin - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-2-l'
                  halfWidth
                  id='id'
                  image={HomepageCv2Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Berlin - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-3-d'
                  halfWidth
                  id='id'
                  image={HomepageCv3Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='CV-3 - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-3-l'
                  halfWidth
                  id='id'
                  image={HomepageCv3Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='CV-3 - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-4-d'
                  halfWidth
                  id='id'
                  image={HomepageCv4Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Nairobi - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-4-l'
                  halfWidth
                  id='id'
                  image={HomepageCv4Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Nairobi - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-5-d'
                  halfWidth
                  id='id'
                  image={HomepageCv5Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Rio - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-5-l'
                  halfWidth
                  id='id'
                  image={HomepageCv5Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Rio - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-6-d'
                  halfWidth
                  id='id'
                  image={HomepageCv6Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Oslo - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-6-l'
                  halfWidth
                  id='id'
                  image={HomepageCv6Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Oslo - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-7-d'
                  halfWidth
                  id='id'
                  image={HomepageCv7Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Lizbona - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-7-l'
                  halfWidth
                  id='id'
                  image={HomepageCv7Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='Lizbona - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-10-d'
                  halfWidth
                  id='id'
                  image={HomepageCv10Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='CV-10 - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-10-l'
                  halfWidth
                  id='id'
                  image={HomepageCv10Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => window.location.href = '/rejestracja' }
                  selected={false}
                  title='CV-10 - L'
                />
              </OutsideSlider>
            </CvCreatorPageRightColumn>
          </CvCreatorPageChooseCvSectionContainer>

          <SpacingLine
            data-test='CvCreatorPageInfoIconTextSpacingLine'
            id='shortVideo'
            isFixed
            option='SizeS'
          />

          <CvShortVideoSectionContainer data-test='CvCreatorPageInfoIconTextSectionContainer'>
            <Section
              alignCenter
              data-test='CvCreatorPageSectionInfoIconTextSteps'
              description='Obejrzyj krótkie video i dowiedz się, jak pomożemy Ci stworzyć skuteczne CV'
              level='h2'
              title='Zobacz CVworks w akcji'
              html
            />

            <CvShortVideoSectionIframe dangerouslySetInnerHTML={{__html: translationsList.creatorCv.shortVideo.iframe }}></CvShortVideoSectionIframe>
          </CvShortVideoSectionContainer>

          <SpacingLine
            data-test='CvCreatorPageInfoIconTextSpacingLine'
            id=''
            isFixed
            option='SizeS'
          />

          <CvCreatorPageGuidlineStepsSectionContainer data-test='CvCreatorPageInfoIconTextSectionContainer'>
            <Section
              alignCenter
              data-test='CvCreatorPageSectionInfoIconTextSteps'
              description='Proces tworzenia CV to nie bieg na 100 m. Ono ma pomóc Ci zrealizować Twój cel - zdobyć lepszą pracę! Jeśli chcesz mieć pewność, że tak się stanie, zainwestuj czas i energię, żeby zadbać o jego najważniejsze aspekty.'
              level='h2'
              title='Czy uważasz, że skuteczne CV można zrobić<br/> w 3 / 5 / 10 minut?'
              id='better'
              html
            />
          </CvCreatorPageGuidlineStepsSectionContainer>

          <CvCreatorPageGuidlineStepsContainer data-test='CvCreatorPageInfoIconTextContainer'>
            <Grid
              columns={3}
              data-test='CvCreatorPageInfoIconTextGrid'
            >
              {
                translationsList.creatorCv.beneflistList.map((benefit, index) => (
                  <InfoIconText
                    data-test='CvCreatorPageInfoIconText'
                    description={benefit.description}
                    icon={benefit.icon}
                    key={index}
                    title={benefit.title}
                  />
                ))
              }
            </Grid>
          </CvCreatorPageGuidlineStepsContainer>

          <SpacingLine
            data-test='CvCreatorPageGuidlineStepsSpacingLine'
            id='dlaczego-warto'
            isFixed
            option='SizeS'
          />

          <CvCreatorPageGuidlineStepsSectionContainer data-test='CvCreatorPageGuidlineStepsSectionContainer'>
            <Section
              alignCenter
              data-test='CvCreatorPageGuidlineStepsSection'
              description='CVworks to nie tylko kreator! Dajemy Ci narzędzie do stworzenia atrakcyjnego CV, ale także dostęp do sprawdzonej wiedzy, która pozwoli Ci odkryć Twoje mocne strony, znaleźć cel zawodowy i konstruować przekonujące rekrutera argumenty. <br/><br><strong>Przejdziemy z Tobą przez pierwszy etap rekrutacji od A do Z!</strong>'
              level='h2'
              title='"Ok, więc dlaczego mam wybrać właśnie<br/> Wasz kreator CV?"'
              html
            />
          </CvCreatorPageGuidlineStepsSectionContainer>

          <CvCreatorPageGuidlineStepsContainer data-test='CvCreatorPageGuidlineStepsContainer'>
            {
              translationsList.creatorCv.guidlineSteps.map((step, key) =>
                <GuidlineStep
                  data-test='CvCreatorPageGuidlineStep'
                  description={step.description}
                  key={key}
                  number={step.number}
                  title={step.title}
                  html={step.html}
                />
              )
            }
          </CvCreatorPageGuidlineStepsContainer>

          

          <SpacingLine
            data-test='CvCreatorPageChooseCVSpacingLine'
            id='video'
            isFixed
            option='SizeS'
          />

          <Subtitle
            data-test='CvCreatorPageChooseCVSubtitle'
            icon='star'
            label='Chcesz sprawdzić, czy na pewno znamy się na rzeczy?'
          />

          <Section
            alignCenter
            data-test='CvCreatorPageChooseCVSection'
            description='Zobacz fragment jednej z lekcji kursu online “Skuteczne CV”, do którego pełen dostęp dostaniesz wraz z kreatorem CV.'
            title='Uważaj, jeśli jesteś wege! W tym kursie jest samo mięso!'
          />

          <CvCreatorPageVideoContainer>
            <Grid
              columns={2}
              data-test='CvCreatorPageVideoContainerGrid'
            >
              <div dangerouslySetInnerHTML={{__html: translationsList.creatorCv.videoSection.iframe }}></div>
              <CvCreatorPageVideoContainerRight>
                <CvCreatorPageVideoContainerHeading>
                  {translationsList.creatorCv.videoSection.heading}
                </CvCreatorPageVideoContainerHeading>
                <CvCreatorPageVideoContainerList>
                  {
                    translationsList.creatorCv.videoSection.list.map((element, index) => (
                      <CvCreatorPageVideoContainerListElement><CvCreatorPageVideoContainerListElementIndex>{index + 1+'. '}</CvCreatorPageVideoContainerListElementIndex>{element}</CvCreatorPageVideoContainerListElement>
                    ))
                  }
                </CvCreatorPageVideoContainerList>
              </CvCreatorPageVideoContainerRight>
            </Grid>
            <CvCreatorPageVideoButton>
              <ButtonComponent
                  bgColor='pinkColor'
                  buttonType='button'
                  color='whiteColor'
                  data-test='CvCreatorPageButtonComponent'
                  fontBold
                  inNewWindow
                  label='Chcę stworzyć nowe, lepsze CV!'
                  onClick={() => {
                    sessionStorage.removeItem('cv')
                    dispatch(cvActions.clearCV())
                    window.location.href = '/rejestracja'
                  }}
                  size='sizeL'
                  type='onClick'
                  align="center"
                />
              </CvCreatorPageVideoButton>
          </CvCreatorPageVideoContainer>

          <Subtitle
            data-test='CvCreatorPageChooseCVSubtitle'
            icon='star'
            label='Wolisz mieć to “na papierze”?'
            id="ebook"
          />

          <Section
            alignCenter
            data-test='CvCreatorPageChooseCVSection'
            description='W 66-stronicowym eBooku “Jak stworzyć piekielnie skuteczne CV” znajdziesz m.in.:'
            title='Ten poradnik ułatwi Ci pracę nad Twoim CV!'
          />

          <CvCreatorPageSolid>
            <Grid
              columns={2}
              data-test='CvCreatorPageVideoContainerGrid'
            >
              <CvCreatorPageSolidList>
                {
                  translationsList.creatorCv.solidSection.list.map((element, index) => (
                    <CvCreatorPageSolidListElement>{element}</CvCreatorPageSolidListElement>
                  ))
                }
              </CvCreatorPageSolidList>
              <CvCreatorPageSolidImage>
                <Image source={translationsList.creatorCv.solidSection.ebookImage} />
              </CvCreatorPageSolidImage>
            </Grid>
            <CvCreatorPageVideoButton>
              <ButtonComponent
                  bgColor='pinkColor'
                  buttonType='button'
                  color='whiteColor'
                  data-test='CvCreatorPageButtonComponent'
                  fontBold
                  inNewWindow
                  label='Chcę stworzyć nowe, lepsze CV!'
                  onClick={() => {
                    sessionStorage.removeItem('cv')
                    dispatch(cvActions.clearCV())
                    window.location.href = '/rejestracja' 
                  }}
                  size='sizeL'
                  type='onClick'
                  align="center"
                />
              </CvCreatorPageVideoButton>
          </CvCreatorPageSolid>

          <SpacingLine
            data-test='CvCreatorPageChooseCVSpacingLine'
            id='stworz-cv'
            isFixed
            option='SizeS'
          />

          <Subtitle
            data-test='CvCreatorPageChooseCVSubtitle'
            icon='star'
            label='Zastanawiasz się, jakie możliwości daje kreator CVworks?'
          />

          <Section
            alignCenter
            data-test='CvCreatorPageChooseCVSection'
            description='Klasyczne jasne tło, a może ekskluzywne czarne? Pobaw się ustawieniami szablonów i wybierz ten, który najbardziej Ci się podoba... a potem kolejny!'
            title='Tak może wyglądać Twoje wyjątkowe CV. Sprawdź!'
          />

          {/*<CvCreatorHome />*/}

          <CvCreatorPageChooseCvContainer data-test='CvCreatorPageChooseCvContainerContainer'>


          <ChooseCvContainerDesktop>
          <ChooseCvContainer disabled data-test='CvCreatorPageChooseCvContainer' isHome />
          </ChooseCvContainerDesktop>

          <ChooseCvContainerMobile>
            <OutsideSlider
                data-test='CvCreatorPageOutsideSliderChooseCv'
                withDots
              >
                <ChooseCV
                  data-test='CvCreatorPageChooseCV-1-d'
                  halfWidth
                  id='id'
                  image={HomepageCv1Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Tokio - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-1-l'
                  halfWidth
                  id='id'
                  image={HomepageCv1Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Tokio - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-2-d'
                  halfWidth
                  id='id'
                  image={HomepageCv2Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Berlin - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-2-l'
                  halfWidth
                  id='id'
                  image={HomepageCv2Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Berlin - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-3-d'
                  halfWidth
                  id='id'
                  image={HomepageCv3Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='CV-3 - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-3-l'
                  halfWidth
                  id='id'
                  image={HomepageCv3Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='CV-3 - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-4-d'
                  halfWidth
                  id='id'
                  image={HomepageCv4Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Nairobi - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-4-l'
                  halfWidth
                  id='id'
                  image={HomepageCv4Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Nairobi - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-5-d'
                  halfWidth
                  id='id'
                  image={HomepageCv5Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Rio - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-5-l'
                  halfWidth
                  id='id'
                  image={HomepageCv5Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Rio - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-6-d'
                  halfWidth
                  id='id'
                  image={HomepageCv6Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Oslo - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-6-l'
                  halfWidth
                  id='id'
                  image={HomepageCv6Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Oslo - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-7-d'
                  halfWidth
                  id='id'
                  image={HomepageCv7Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Lizbona - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-7-l'
                  halfWidth
                  id='id'
                  image={HomepageCv7Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='Lizbona - L'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-10-d'
                  halfWidth
                  id='id'
                  image={HomepageCv10Dark}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='CV-10 - D'
                />

                <ChooseCV
                  data-test='CvCreatorPageChooseCV-10-l'
                  halfWidth
                  id='id'
                  image={HomepageCv10Light}
                  key='key'
                  noMargin
                  noWidth
                  onClick={() => null}
                  selected={false}
                  title='CV-10 - L'
                />
              </OutsideSlider>
          </ChooseCvContainerMobile>



            <CvCreatorPageChooseCvContainerButton>
            {'Do wyboru już teraz masz aż 180 perfekcyjnych wariantów CV!'}

              <ButtonComponent
                bgColor='pinkColor'
                buttonType='button'
                color='whiteColor'
                data-test='CvCreatorPageButtonComponent'
                fontBold
                inNewWindow
                label='Chcę stworzyć nowe, lepsze CV!'
                onClick={() => {
                  sessionStorage.removeItem('cv')
                  dispatch(cvActions.clearCV())
                  window.location.href = '/rejestracja'
                }}
                size='sizeL'
                type='onClick'
                align='center'
              />
            </CvCreatorPageChooseCvContainerButton>
          </CvCreatorPageChooseCvContainer>

          <SpacingLine
            data-test='CvCreatorPageChooseCVSpacingLine'
            id='strong'
            isFixed
            option='SizeS'
          />

          <CvCreatorPageTestimonialsContainer data-test='CvCreatorPageTestimonialsContainer'>
            <Section
              alignCenter
              data-test='CvCreatorPageTestimonialsSection'
              description='Poznaj sukcesy naszych Użytkowników!'
              level='h2'
              title='Mocne strony CVworks'
            />

            <CvCreatorPageTestimonialsContent data-test='CvCreatorPageTestimonialsContent'>
              <OutsideSlider
                data-test='CvCreatorPageTestimonialsOutsideSlider'
                overrideSettings={testimonialSliderSettings}
              >
                {
                  translationsList.creatorCv.testimonialsList.map((testimonial, index) => (
                    <OutsideSliderElementWrapper
                      data-test='CvCreatorPageElementWrapperOutsideSlider'
                      key={index}
                    >
                      <Testimonial
                        data-test='CvCreatorPageTestimonial'
                        image={testimonial.image}
                        info={testimonial.info}
                        signature={`'${testimonial.signature}'`}
                      />
                    </OutsideSliderElementWrapper>
                  ))
                }
              </OutsideSlider>
            </CvCreatorPageTestimonialsContent>
          </CvCreatorPageTestimonialsContainer>

          <SpacingLine
            data-test='CvCreatorPagePricingBoxSpacingLine'
            id='cennik'
            isFixed
            option='SizeS'
          />

          <Subtitle
            data-test='CvCreatorPageChooseCVSubtitle'
            icon='star'
            label='Ile to kosztuje?'
          />

          <Section
            alignCenter
            data-test='CvCreatorPageSectionPricingBox'
            description='Nie udajemy, że to za darmo. Pierwsze 7 dni testujesz wszystko za 7 zł. Zostając z nami na dłużej za dostęp do biblioteki szkoleń i kreatora zapłacisz tylko 39 zł/mc. Zrezygnujesz, kiedy nie będziesz ich potrzebował. To całkiem fair, prawda?'
            level='h2'
            title='Twoja inwestycja'
          />

          <PricingContainer>
            <Grid
              columns={3}
              data-test='CvCreatorPagePricingBoxGrid'
              alignCenter
            >
              <FormPanel
                data-test='CvCreatorPagePricingBoxFormPanel'
                paddingSize='sizeS'
                height='100%'
              >
                <BenefitsList>
                  {
                    translationsList.creatorCv.pricingBenefits.map((elem, index) => (
                      <BenefitsElement>{elem}</BenefitsElement>
                    ))
                  }
                </BenefitsList>
              </FormPanel>
              {
                translationsList.creatorCv.pricingList.map((elem, index) => (
                  <FormPanel
                    data-test='CvCreatorPagePricingBoxFormPanel'
                    key={index}
                    paddingSize='sizeS'
                    height='100%'
                    selected={elem.selected}
                  >
                    {elem.slogan}
                    <PricingBox
                      benefitsListTypeOne={elem.benefitsList.filter(benefitTypeOne => benefitTypeOne.type === 1)}
                      benefitsListTypeTwo={elem.benefitsList.filter(benefitTypeOne => benefitTypeOne.type === 2)}
                      buttonLabel='Chcę stworzyć nowe, lepsze CV!'
                      currency='zł'
                      data-test='CvCreatorPagePricingBox'
                      buttonOnClick={() => {
                        sessionStorage.setItem('prefferedPricing', elem.price === 7 ? 'monthly' : 'half-year')
                        sessionStorage.removeItem('cv')
                        dispatch(cvActions.clearCV())
                        window.location.href = '/rejestracja'
                      }}
                      iconTypeOne={iconPathCheck}
                      iconTypeTwo={iconPathStar}
                      price={elem.price}
                      subTitle={elem.subTitle}
                      salePrice={elem.salePrice}
                      extraText={elem.extraText}
                    />
                  </FormPanel>
                ))
              }
            </Grid>
          </PricingContainer>

          <CvCreatorBenefitsBoxes>
            <Grid
              columns={3}
              data-test='CvCreatorPageBenefitsBoxGrid'
            >
              <InfoIconText
                data-test='CvCreatorPageInfoIconText'
                icon={HomepageBenefitsImageOne}
                key='0'
                title='Natychmiastowy dostęp do kreatora CV, kursu i ebooka'
              />

              <InfoIconText
                data-test='CvCreatorPageInfoIconText'
                icon={HomepageBenefitsImageTwo}
                key='0'
                title='Możliwość edycji i pobierania CV bez limitu'
              />

              <InfoIconText
                data-test='CvCreatorPageInfoIconText'
                icon={HomepageBenefitsImageThree}
                key='0'
                title='Gwarancja satysfakcji (7 dni) albo zwrot pieniędzy'
              />
            </Grid>
          </CvCreatorBenefitsBoxes>

          

          <CvCreatorPageBannerContainer data-test='CvCreatorPageBannerContainer'>
            <BannerItem
              buttonLabel={translationsList.creatorCv.bannersList[0].buttonLabel}
              data-test='CvCreatorPageBannerItem'
              description={translationsList.creatorCv.bannersList[0].description}
              link={translationsList.creatorCv.bannersList[0].link}
              title={translationsList.creatorCv.bannersList[0].title}
            />
          </CvCreatorPageBannerContainer>
        </InnerWrapper>
      </Site>

      <ModalController type={modal} />
    </>
  )
}

export default CvCreatorPage
