import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  WhiteColor
} from '../../assets/styles/colors'

export const ManageCvContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  margin: 10px;
  padding: 20px;
  width: 280px;
`

export const ManageCvTitle = styled.div`
  color: ${PurpleColor};
  font-size: 16px;
  line-height: 24px;
`

export const ManageCvImageContainer = styled.div`
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  margin: 20px 0 0;

  & > div {
    padding: 0;
  }
`

export const ManageCvButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  margin: 20px 0 0;
`
