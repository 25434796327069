import React, { useEffect } from 'react'

import Subtitle from '../../../components/Subtitle'
// import UserStepFourPageContainer from '../../../containers/PlatformContainer/UserStepsForms/StepFourContainer'
// import StepFourCompaniesContainer from '../../../containers/PlatformContainer/UserStepsForms/StepFourCompaniesContainer'
// import StepThreeControlsContainer from '../../../containers/PlatformContainer/UserStepsForms/StepThreeControlsContainer'

import translationsList from '../../../stabs/translationsList'
// import CompanyItems from '../../../components/Companies/CompanyItems'
import { useSelector } from 'react-redux'
import { companiesSelectors } from '../../../store/ducks/companies'
// import { flagsSelectors } from '../../../store/ducks/flags'
import IndustriesContainer from '../../../containers/ComponentContainers/IndustriesContainer'
import {
  CompaniesContainer,
  SectionCommonContainer,
  SectionCompanies
} from '../../EventPage/components'
import { companiesPremium, companiesPrestige, companiesStandard } from '../../EventPage/helpers'
import Grid from '../../../components/Grid'
import CompanyBox from '../../../components/Boxes/CompanyBox'
import isExpoDay from '../../../utils/isExpoDay'

const FormUserStepThree = () => {
  const companies = useSelector(companiesSelectors.getCompanies)
  // const showFlag = useSelector(flagsSelectors.getFlag('platformShowCompanies'))

  useEffect(() => {
    window.mt('send', 'pageview', { userstep3: true })
  })

  return (
    (
      <>
        <Subtitle
          id='#bilet'
          data-test='subtitleTwo'
          icon='star'
          label={translationsList.userStepThree.companies}
        />

        <IndustriesContainer />

        <SectionCompanies>
          <CompaniesContainer>
            {
              companiesPrestige(companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Top pracodawcy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={1}
                    noOutsideMargin
                  >
                    {
                      companiesPrestige(companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showRightColumn
                          showTop={isExpoDay() && company.chatUrl}
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videos[0] !== undefined ? company.videos[0].url : ''}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )
            }

            {
              companiesPremium(companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Rekomendowani pracodawcy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={2}
                    noOutsideMargin
                  >
                    {
                      companiesPremium(companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          // showRightColumn={company.showRightColumn}
                          showTop={isExpoDay() && company.chatUrl}
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videoUrl}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )
            }

            {
              companiesStandard(companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Pozostali pracodawcy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={4}
                    noOutsideMargin
                  >
                    {
                      companiesStandard(companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showRightColumn={company.showRightColumn}
                          showTop={isExpoDay() && company.chatUrl}
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videoUrl}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )
            }
          </CompaniesContainer>
        </SectionCompanies>

        {/* <StepThreeControlsContainer /> */}

        {/* <UserStepFourPageContainer renderCompanies={StepFourCompaniesContainer} /> */}
      </>
    )
  )
}

export default FormUserStepThree
