import styled from 'styled-components'

import {
  PinkColor,
  PurpleBoxShadowColor,
  PurpleColorXS,
  Transition,
  WhiteColor,
  PurpleColorM
} from '../../../assets/styles/colors'

export const OutsideSliderButtonContainer = styled.div`
  background-color: ${PurpleColorM};
  border-radius: 50%;
  box-shadow: ${PurpleBoxShadowColor};
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  outline: none;
  padding: 5px;
  position: absolute;
  z-index: 20;

  ${props => {
    switch (props.position) {
      case 'left': return `
        transform: rotate(90deg);

        @media (min-width: 768px) {
          left: -25px;
        }

        @media (max-width: 767px) {
          left: -33px;
        }
      `
      case 'right': return `
        transform: rotate(270deg);

        @media (min-width: 768px) {
          right: -25px;
        }

        @media (max-width: 767px) {
          right: -33px;
        }
      `
      default: return ''
    }
  }}

  & path:first-child {
    fill: ${WhiteColor};
    transition: ${Transition};
  }

  &:hover {
    & path:first-child {
      fill: ${PurpleColorXS};
    }
  }

  @media (min-width: 768px) {
    height: 50px;
    top: calc(50% - 25px);
    width: 50px;
  }

  @media (max-width: 767px) {
    height: 44px;
    top: calc(50% - 22px);
    width: 44px;
  }
`
