export default [
  'Albański',
  'Angielski',
  'Arabski',
  'Białoruski',
  'Bośniacki',
  'Bułgarski',
  'Chiński',
  'Chorwacki',
  'Czeski',
  'Duński',
  'Estoński',
  'Fiński',
  'Francuski',
  'Grecki',
  'Gruziński',
  'Hebrajski',
  'Hiszpański',
  'Indonezyjski',
  'Irlandzki',
  'Islandzki',
  'Japoński',
  'Koreański',
  'Litewski',
  'Macedoński',
  'Niderlandzki',
  'Niemiecki',
  'Norweski',
  'Perski',
  'Polski',
  'Portugalski',
  'Rosyjski',
  'Rumuński',
  'Słowacki',
  'Słoweński',
  'Szwedzki',
  'Tajski',
  'Turecki',
  'Tybetański',
  'Ukraiński',
  'Uzbecki',
  'Węgierski',
  'Wietnamski',
  'Włoski'
].map((lang) => ({ label: lang }))
