import React from 'react'

import { SectionCommonContainer } from '../../../pages/EventPage/components'

import Grid from '../../../components/Grid'
import RecruiterBox from '../../../components/Boxes/RecruiterBox'
import Subtitle from '../../../components/Subtitle'
import isExpoDay from '../../../utils/isExpoDay'

const RecruitersContainer = ({ prestige, premium, standard }) => {
  const topRecruiters = prestige.map((company) => company.recruiters.map((rec) => ({ ...rec, companyName: company.name, slug: company.slug }))).flat()
  const premiumRecruiters = premium.map((company) => company.recruiters.map((rec) => ({ ...rec, companyName: company.name, slug: company.slug }))).flat()
  const standardRecruiters = standard.map((company) => company.recruiters.map((rec) => ({ ...rec, companyName: company.name, slug: company.slug }))).flat()

  return (
    <>
      <Grid
        columns={4}
        noOutsideMargin
      >
        {
          topRecruiters.map((recruiter, index) => (
            <RecruiterBox
              avatarUrl={recruiter.avatarUrl}
              companyName={recruiter.companyName}
              firstName={recruiter.firstName}
              key={index}
              lastName={recruiter.lastName}
              position={recruiter.position}
              chatURL={isExpoDay() ? '/pracodawcy/' + recruiter.slug : undefined}
            />
          ))
        }
      </Grid>

      <SectionCommonContainer>
        <Subtitle
          icon='tag'
          label='Rekomendowani rekruterzy'
        />
      </SectionCommonContainer>

      <Grid
        columns={4}
        noOutsideMargin
      >
        {
          premiumRecruiters.map((recruiter, index) => (
            <RecruiterBox
              avatarUrl={recruiter.avatarUrl}
              companyName={recruiter.companyName}
              firstName={recruiter.firstName}
              key={index}
              lastName={recruiter.lastName}
              position={recruiter.position}
              chatURL={isExpoDay() ? '/pracodawcy/' + recruiter.slug : undefined}
            />
          ))
        }
      </Grid>

      <SectionCommonContainer>
        <Subtitle
          icon='tag'
          label='Pozostali rekruterzy'
        />
      </SectionCommonContainer>

      <Grid
        columns={4}
        noOutsideMargin
      >
        {
          standardRecruiters.map((recruiter, index) => (
            <RecruiterBox
              avatarUrl={recruiter.avatarUrl}
              companyName={recruiter.companyName}
              firstName={recruiter.firstName}
              key={index}
              lastName={recruiter.lastName}
              position={recruiter.position}
              chatURL={isExpoDay() ? '/pracodawcy/' + recruiter.slug : undefined}
            />
          ))
        }
      </Grid>
    </>
  )
}

export default RecruitersContainer
