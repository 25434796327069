import styled from 'styled-components'

import {
  PinkColor,
  PurpleColor,
  PurpleColorS,
  PurpleColorXS,
  SilverColor,
  SilverColorS,
  WhiteColor
} from '../../../assets/styles/colors'

export const SelectableItemContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.isSelected && props.isMostImportant
      ? PinkColor
      : props.isSelected ? PurpleColorS
        : SilverColorS};
  line-height: 21px;
  font-size: 14px;
  color: ${props =>
    props.isSelected && props.isMostImportant
      ? WhiteColor
      : props.isSelected ? PurpleColor
        : SilverColor};
  border-radius: 37.5px;

  ${props => {
    switch (props.isRemoveable) {
      case true: return 'padding: 14px 20px;'
      default: return 'padding: 14px 42px;'
    }
  }}

  ${props => {
    switch (props.isSelected) {
      case true: return 'font-weight: 600;'
      default: return 'font-weight: 300;'
    }
  }}

  @media (min-width: 992px) {
    margin: 0 15px 15px 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: ${props =>
    props.isSelected ? '5px 8px'
      : '5px 16px'};
  }
`

export const SelectableItemText = styled.div`
  white-space: nowrap;
`

export const SelectableItemCloseContainer = styled.div`
  width: 21px;
  height: 21px;
  padding: 5px;
  border-radius: 50%;

  ${props => {
    switch (props.isPinkColor) {
      case true: return `
        background-color: #FF7AC0;
        
        & g {
          stroke: ${PinkColor};
        }
      `
      default: return `
        background-color: ${PurpleColorXS};

        & g {
          stroke: ${PurpleColor};
        }
      `
    }
  }}

  @media (min-width: 992px) {
    margin: 0 0 0 10px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin-left: 8px;
  }
`
