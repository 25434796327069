import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import MultiSelectDropDownField from '../../FormFields/MultiSelectDropDownField'

import languages from '../../../../stabs/languages'
import langaugeLevels from '../../../../stabs/langaugeLevels'
import AddedItem from '../../../List/AddedItem'

const AddLanguageForm = ({
  apiError,
  errors,
  handleDelete,
  handleMultiSelect,
  handleEdit,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel errors={apiError}>
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}

    <FormTwoColumns>
      <MultiSelectDropDownField
        data-test='language'
        errorMessage={errors.language}
        hasError={errors.language && touched.language}
        itemsList={languages}
        label='Język'
        name='language'
        onClick={handleMultiSelect}
        placeholder={values.language === '' ? 'Wybierz język…' : values.language}
        required
        closeOnClick
        value={values.language}
      />

      <MultiSelectDropDownField
        errorMessage={errors.level}
        hasError={errors.level && touched.level}
        itemsList={langaugeLevels}
        label='Poziom'
        name='level'
        onClick={handleMultiSelect}
        placeholder={values.level === '' ? 'Wybierz poziom…' : langaugeLevels.filter((level) => level.value === values.level)[0].label}
        required
        closeOnClick
        value={values.level}
      />
    </FormTwoColumns>
  </FormPanel>
)

export default AddLanguageForm
