import React from 'react'

import CompanyItem from '../../../../components/Companies/CompanyItem'
import { useSelector } from 'react-redux'
import { companiesSelectors } from '../../../../store/ducks/companies'
import translationsList from '../../../../stabs/translationsList'

const SimiliarCompaniesContainer = ({ slug }) => {
  const companies = useSelector(companiesSelectors.getCompaniesContent)

  const [selectedCompany] = companies.filter((company) => company.slug === slug)

  let filteredCompanies = []

  if (selectedCompany !== undefined) {
    filteredCompanies = companies
      .filter((company) => company._id !== selectedCompany._id)
      .map((company) => {
        let similarity = 0

        company.values.forEach((value) => {
          if (selectedCompany.values.includes(value)) {
            similarity += 1
          }
        })

        return {
          ...company,
          similarity
        }
      })
      .sort((companyA, companyB) => companyB.similarity - companyA.similarity)
      .slice(0, 2)
  }

  return (
    <>
      {filteredCompanies.map((company, id) => (
        <CompanyItem
          buttonLabel={translationsList.companyPanel.moreButton}
          image={company.logoUrl}
          key={id}
          link={`/pracodawcy/${company.slug}`}
          name={company.name}
          withSize
        />
      ))}
    </>
  )
}

export default SimiliarCompaniesContainer
