import Cv1Dark from '../assets/img/cv_templates/Tokio - d.png'
import Cv1Light from '../assets/img/cv_templates/Tokio - j.png'

import Cv2Dark from '../assets/img/cv_templates/Berlin - d.png'
import Cv2Light from '../assets/img/cv_templates/Berlin - j.png'

import Cv3Dark from '../assets/img/cv_templates/CV3 - d.png'
import Cv3Light from '../assets/img/cv_templates/CV3 - j.png'

import Cv4Dark from '../assets/img/cv_templates/Nairobi - d.png'
import Cv4Light from '../assets/img/cv_templates/Nairobi - j.png'

import Cv5Dark from '../assets/img/cv_templates/Rio - d.png'
import Cv5Light from '../assets/img/cv_templates/Rio - j.png'

import Cv6Dark from '../assets/img/cv_templates/Oslo - d.png'
import Cv6Light from '../assets/img/cv_templates/Oslo - j.png'

import Cv7Dark from '../assets/img/cv_templates/Lizbona - d.png'
import Cv7Light from '../assets/img/cv_templates/Lizbona - j.png'

import Cv10Dark from '../assets/img/cv_templates/Palermo - d.png'
import Cv10Light from '../assets/img/cv_templates/Palermo - j.png'

import example_photo from '../assets/img/cv_templates/Palermo - j.png'

export default [
  {
    id: 'cv-1',
    title: 'Tokio',
    default: 'light',
    variants: {
      dark: { image: Cv1Dark, title: 'Ciemny' },
      light: { image: Cv1Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-2',
    title: 'Berlin',
    default: 'light',
    variants: {
      dark: { image: Cv2Dark, title: 'Ciemny' },
      light: { image: Cv2Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-3',
    title: 'Nikozja',
    default: 'light',
    variants: {
      dark: { image: Cv3Dark, title: 'Ciemny' },
      light: { image: Cv3Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-4',
    title: 'Nairobi',
    default: 'light',
    variants: {
      dark: { image: Cv4Dark, title: 'Ciemny' },
      light: { image: Cv4Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-5',
    title: 'Rio',
    default: 'light',
    variants: {
      dark: { image: Cv5Dark, title: 'Ciemny' },
      light: { image: Cv5Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-6',
    title: 'Oslo',
    default: 'light',
    variants: {
      dark: { image: Cv6Dark, title: 'Ciemny' },
      light: { image: Cv6Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-7',
    title: 'Lizbona',
    default: 'light',
    variants: {
      dark: { image: Cv7Dark, title: 'Ciemny' },
      light: { image: Cv7Light, title: 'Jasny' }
    }
  },
  {
    id: 'cv-10',
    title: 'Palermo',
    default: 'light',
    variants: {
      dark: { image: Cv10Dark, title: 'Ciemny' },
      light: { image: Cv10Light, title: 'Jasny' }
    }
  }
]
