import styled from 'styled-components'

import { PurpleColorM } from '../../../assets/styles/colors'

export const DescriptionContainer = styled.div`
  color: ${PurpleColorM};

  ${props => {
    switch (props.alignCenter) {
      case true: return 'text-align: center;'
      default: return 'text-align: left;'
    }
  }}

  @media (min-width: 1200px) {
    line-height: 40px;
    font-size: 22px;
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    line-height: 28px;
    font-size: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    line-height: 26px;
    font-size: 18px;
  }
`
