import styled from 'styled-components'

import chevron from '../../../assets/img/chevron.svg'

import {PricingBoxContainer} from '../../../components/Boxes/PricingBox/Atoms'

import {
  PinkColor,
  PurpleColorM
} from '../../../assets/styles/colors'

export const StepFourContainer = styled.div`
  @media (min-width: 992px) {
    margin: 50px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 40px 0 0;
  }

  @media (max-width: 575px) {
    margin: 30px 0 0;
  }
`

export const CouponWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top:70px;
  font-size: 20px;

  & > input {
    &::placeholder {
      color: #6B677E;
    }
  }
  
  @media (min-width: 992px) {
   & > input {
      max-width: 35vw;
    }
  }
`;

export const BenefitsList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
`

export const BenefitsElement = styled.div`
  line-height: 1em;
  color: ${PurpleColorM};
  display: flex;
  font-size: 1.2em;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px;

  @media screen and (max-width:768px) {
    line-height: 1.5em;
    padding: 10px 0;
  }

  &:before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(${chevron});
    background-size: cover;
    transform: rotate(-90deg) translateX(8px);
    flex-shrink: 0;

    @media screen and (max-width:768px) {
      margin-top: 5px;
    }
  }

`

export const PricingContainer = styled.div`
  button {
    font-size: 21px;

    @media screen and (max-width: 768px) {
      font-size:20px;
      margin-top: 10px;
    }
  }

  ${PricingBoxContainer} {
    flex-direction: column;

    @media screen and (max-width: 768px) {
      > div {
        flex: 1;
      }
    }
  }

  > div {
    > div {
      > div {
        &:nth-child(1) {
          width: 55%;

          @media screen and (max-width:768px) {
            width:100%;
          }
        }
      }
    }
  }
`