import ApiService from '../services/ApiService'
import asUpload from '../../utils/asUpload'

export const getAllPublishedArticles = () => new ApiService('GET', '/articles')
export const getAllArticles = () => new ApiService('GET', '/articles?unpublished')

export const addArticle = (data) => new ApiService('POST', '/articles', data, { withAuth: true, allowGhost: true })
export const updateArticle = (id, data) => new ApiService('PUT', `/articles/${id}`, data, { withAuth: true, allowGhost: true })
export const removeArticle = (id) => new ApiService('DELETE', `/articles/${id}`, {}, { withAuth: true, allowGhost: true })

export const uploadArticleThumbnail = (body) => asUpload(new ApiService('POST', '/articles/uploads/article-thumbnail', {}, { withAuth: true, allowGhost: true }), body)
export const uploadArticleAuthorAvatar = (body) => asUpload(new ApiService('POST', '/articles/uploads/article-author-avatar', {}, { withAuth: true, allowGhost: true }), body)
export const uploadArticleImage = (body) => asUpload(new ApiService('POST', '/articles/uploads/article-image', {}, { withAuth: true, allowGhost: true }), body)
