import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const ButtonCircleContainer = styled.div`
  align-items: center;
  background-color: ${WhiteColor};
  border-radius: 50%;
  box-shadow: ${PurpleBoxShadowColor};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    border: 2px solid ${PurpleColor};
  }
`

export const ButtonCircleIconContainer = styled.div`
  height: 20px;
  width: 20px;
`
