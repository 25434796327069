import testImage from '../assets/img/szkolenia.jpg'

import annaScislowskaImagePath from '../assets/img/contact/staff_anna_scislowska.png'
import jacekCiechanowskiImagePath from '../assets/img/contact/staff_jacek_ciechanowski.png'
import karolinaMerdaImagePath from '../assets/img/contact/staff_karolina_merda.jpg'

import HomepageCardClassroomIconPath from '../assets/icons/homepage/classroom.svg'
import HomepageCardSuccessIconPath from '../assets/icons/homepage/success.svg'
import HomepageCardWorkIconPath from '../assets/icons/homepage/work.svg'

import HomepageCvTemplate1ImagePath from '../assets/img/homepage/1.jpg'
import HomepageCvTemplate2ImagePath from '../assets/img/homepage/2.jpg'
import HomepageCvTemplate3ImagePath from '../assets/img/homepage/3.jpg'

import HomepageSquarePanel1IconPath from '../assets/icons/homepage/squarePanel1.svg'
import HomepageSquarePanel2IconPath from '../assets/icons/homepage/squarePanel2.svg'
import HomepageSquarePanel3IconPath from '../assets/icons/homepage/squarePanel3.svg'

import HomepageEmployersAmazonImagePath from '../assets/icons/homepage/employers/amazon.png'
import HomepageEmployersCeneoImagePath from '../assets/icons/homepage/employers/ceneo.png'
import HomepageEmployersHpImagePath from '../assets/icons/homepage/employers/hp.png'
import HomepageEmployersImpelImagePath from '../assets/icons/homepage/employers/impel.png'
import HomepageEmployersQatarImagePath from '../assets/icons/homepage/employers/qatar.png'
import HomepageEmployersVolvoImagePath from '../assets/icons/homepage/employers/volvo.png'

const translationsListEN = {
  ticketAdditionalInfo: 'A personal ticket authorizes one person to enter the fair. The ticket is valid only by confirmation with an identity document.',
  banners: {
    bannersList: [
      {
        buttonLabel: 'Create a CV',
        description: ' Use our CV creator. Choose one from many templates and adjust it to your needs.',
        link: '/rejestracja',
        title: 'Create your own unique CV'
      }
    ]
  },
  buttonSwitch: {
    itemsList: [
      {
        isActive: true,
        label: 'All'
      },
      {
        isActive: false,
        label: 'Saved'
      }
    ]
  },
  buttons: {
    labels: {
      addNewButtonLabel: 'Add another'
    }
  },
  chooseColor: {
    labels: {
      buttonLabel: 'create your own',
      headerLabel: 'Choose the main color of your CV',
      subtitleLabel: 'Be exceptional'
    }
  },
  chooseImage: {
    labels: {
      chooseImageInfo: 'Maximum size of the image is 320x320 (500kb)',
      chooseImageRemoveButtonLabel: 'Remove',
      chooseImageSelectButtonLabel: 'Choose image',
      imageLabel: 'Your image'
    }
  },
  cities: {
    labels: {
      titleLabel: 'Choose city'
    },
    citiesList: [
      {
        _id: 0,
        label: 'Wrocław',
        isSelected: false
      }
    ]
  },
  companies: {
    labels: {
      employer: 'employer',
      found: 'Found'
    }
  },
  controls: {
    labels: {
      buttonHide: 'Hide',
      buttonShowMore: 'Show more'
    }
  },
  employerProfiles: {
    labels: {
      complianceLabel: 'compliance',
      subtitleSimilarEmployersLabel: 'Similar employers'
    }
  },
  errorsMessages: {
    userDataForm: {
      userAddressErrorMessage: 'Enter correct address',
      userCityErrorMessage: 'Enter correct city',
      userCountryErrorMessage: 'Enter correct country',
      userDateOfBirthErrorMessage: 'Ented correct date of birth',
      userEmailErrorMessage: 'Enter correct e-mail address',
      userNameErrorMessage: 'Enter correct name',
      userNationalityErrorMessage: 'Enter correct nationality',
      userPhoneErrorMessage: 'Enter correct phone number',
      userPostCodeErrorMessage: 'Enter correct postcode',
      userSocialMediaDescriptionErrorMessage: 'Enter correct description',
      userSocialMediaErrorMessage: 'Enter correct link',
      userSurnameErrorMessage: 'Enter correct surname'
    },
    notSaved: 'There has been a saving error. Try again later. If the error occurs again, contact us.',
    validationError: 'Wrong data entered.',
    userExist: 'User with this e-mail address already exists.',
    userNotExist: 'Wrong e-mail or password.'
  },
  events: {
    labels: {
      addressLabel: 'Address',
      dateLabel: 'Date',
      nameAndSurnameLabel: 'Name and surname',
      placeLabel: 'Place',
      positionLabel: 'Position',
      timeLabel: 'Hour',
      titleLabel: 'Welcome'
    },
    eventsList: [
      {
        eventAdditionalInfo: 'A personal ticket authorizes one person to enter the fair. The ticket is valid only by confirmation with an identity document.',
        eventAddress: 'Hala Stulecia, Wystawowa 1, Wrocław',
        eventDate: '20.05.2018',
        eventTimeEnd: '18:00',
        eventTimeStart: '10:00'
      }
    ]
  },
  faq: {
    labels: {
      faqPage: 'Help'
    },
    itemsList: [
      {
        title: '01. Co muszę zrobić, żeby wziąć udział w targach Career EXPO online?',
        text: 'Wystarczy, że zarejestrujesz się za pośrednictwem naszej strony internetowej i utworzysz swoje konto użytkownika, wypełnisz swój profil i możesz korzystać ze wszystkich aktywności.'
      },
      {
        title: '02. Czy udział w targach jest płatny?',
        text: 'Targi online odbywać się będą w godzinach 14:00-20:00, zarówno pierwszego jak i drugiego dnia. (1 i 2 kwietnia 2020).'
      },
      {
        title: '03. Czy udział w targach online jest płatny?',
        text: 'Nie, udział w Career EXPO, jak zwykle, jest bezpłatny.'
      },
      {
        title: '04. Czy mogę zalogować się za pomocą tego samego konta, które zakładałem w celu pobrania biletu?',
        text: 'Dokładnie tak! Profil, który stworzyłeś na potrzeby pobrania biletu na targi, będzie teraz służył jako Twój panel użytkownika podczas targów ONLINE.'
      },
      {
        title: '05. Czy muszę założyć konto przed targami?',
        text: 'Spokojnie, możesz założyć konto w dowolnym momencie. Wcześniejsze zarejestrowanie się i uzupełnienie podstawowych danych umożliwi Ci jednak szybsze i sprawniejsze korzystanie ze wszystkich możliwości, jakie oferują targi pracy online - m.in. rozmów z rekruterami na czatach i swobodne dołączanie do szkoleń online.'
      },
      {
        title: '06. Co zrobić, jeśli nie mogę się zarejestrować?',
        text: 'Jeśli masz problemy z rejestracją i nie znalazłeś odpowiedzi w Pomoc, napisz do nas za pomocą naszego fanpage na Facebooku (https://www.facebook.com/pl.careerEXPO) i opisz dokładnie problem, który napotkałeś. Postaramy się jak najszybciej pomóc. W trakcie wydarzenia online uruchomimy też dedykowany czat, na którym będziesz mógł na żywo uzyskać poradę od członków zespołu Career EXPO.'
      },
      {
        title: '07. Czy będę mógł wziąć udział w Career EXPO online z telefonu?',
        text: 'Rekomendujemy korzystanie z naszego serwisu podczas targów z poziomu komputera. Na pewno wpłynie to na komfort korzystania ze wszystkich aktywności. Jeśli jednak zechcesz dołączyć do wydarzenia wykorzystując stronę mobilną, wciąż będziesz mógł/mogła to zrobić, jednak nie gwarantujemy, że komfort będzie równie wysoki.'
      },
      {
        title: '08. Can I participate at the online event, when I’m english speaking person?',
        text: `Yes, you can! :) Most of our exhibitors speak english or other languages and they look for employees with language skills. Our team is english-speaking as well so you can ask us whatever you want. So feel free to take part in Career EXPO online. If you’ve got any issues just write us by facebook fanpage or mail.
During the event will also run direct livechat where you can talk with our team about every problems.`
      },
      {
        title: '09. Czy na rozmowy online z rekruterami muszę się wcześniej zapisać? ',
        text: 'Nie musisz! Wystarczy, że w dniach trwania targów, zalogujesz się, wybierzesz interesującą Cię firmę, a także rekrutera i rozpoczniesz z nim bezpośrednią rozmowę. Być może będziesz musiał/a chwilę poczekać, jeśli rekruter będzie akurat zajęty. W oczekiwaniu na rozmowę, możesz jednak dołączyć do któregoś z trwających webinarów :)'
      },
      {
        title: '10. Czy rekruterzy będą mnie widzieć?',
        text: 'Nie, podczas tej edycji rekruterzy będą rozmawiać z Tobą na czatach, więc nie będziecie się widzieć. Pracodawcy mają jednak możliwość zaproszenia do video-rozmowy najbardziej obiecujących kandydatów. Możesz zatem swobodnie korzystać z możliwości targowych, siedząc w swojej ulubionej piżamie, ale na wszelki wypadek miej pod ręką przynajmniej górę stroju :)'
      },
      {
        title: '11. Czy mogę kontaktować się z więcej niż jednym rekruterem na raz?',
        text: `I tak i nie ;) Otwarcie czatu z wybraną firmą uniemożliwia Ci dołączenie w tym samym czasie do rozmowy z rekruterem innej firmy. Możesz jednak otworzyć naszą stronę w nowej karcie przeglądarki i tym sposobem rozpocząć czat z innym rekruterem. /n
Rekomendujemy jednak skupienie się na rozmowie z jednym rekruterem, by uzyskać i przekazać maksymalnie dużo informacji, a jednocześnie nie blokować dostępu innym użytkownikom`
      },
      {
        title: '12. Jak przygotować się do udziału w targach online?',
        text: `Zapoznaj się z profilami wystawców i ofertami pracy na naszej stronie, wybierz wcześniej te, którymi jesteś zainteresowany/a. Dowiedz się czegoś więcej o wybranych pracodawcach, zrób notatki - to zaprocentuje podczas rozmowy online! Przygotuj się do niej jak do rozmowy kwalifikacyjnej, bądź pewny siebie i znaj swoją wartość. 
Czas i formuła czatu działają na Twoją korzyść - możesz przygotować i zapisać wcześniej gotowe pytania do rekruterów, zaplanować scenariusz całej rozmowy, a w trakcie czatu posiłkować się nawet ściągami ;)`
      },
      {
        title: '13. Czy powinienem przygotować wcześniej swoje dokumenty aplikacyjne?',
        text: `Dobrze mieć przygotowane przed targami CV oraz pozostałe dokumenty aplikacyjne w wersji gotowej do przesłania online (najlepiej plik .pdf). Z dużym prawdopodobieństwem rekruterzy podczas rozmowy poproszą Cię o ich przesłanie celem weryfikacji Twojej kandydatury. 
Jeśli martwisz się o bezpieczeństwo swoich danych osobowych, bądź pewny, że zarówno Career EXPO, jak i pracodawcy mają na uwadze standardy RODO`
      },
      {
        title: '14. Co zrobić, by wziąć udział w szkoleniach online (webinarach)? Czy muszę się wcześniej zapisywać?',
        text: `Wystarczy, że zalogujesz się do systemu i naciśniesz przycisk “Zapisz się” przy wybranych szkoleniach z aktualnej listy. Wybrane szkolenia zapiszą się w Twoim profilu użytkownika, gdzie będziesz mógł/a nimi zarządzać. Po zapisaniu się, otrzymasz też od nas maila z potwierdzeniem i bezpośrednim linkiem do webinaru.
Nie martw się, że zapomnisz o szkoleniu. Odpowiednio wcześnie przypomnimy Ci o jego rozpoczęciu za pomocą wiadomości email. Dla pewności, warto jednak zapisać jego termin w kalendarzu :) 
Pamiętaj, że wszystkie szkolenia odbywają się na żywo!`
      },
      {
        title: '15. Co zrobić, spóźniłem się na rozpoczęcie szkolenia online?',
        text: 'Nic takiego, możesz dołączyć i opuścić je w dowolnym momencie jego trwania, nikomu nie przeszkadzając :)'
      },
      {
        title: '16. Czy szkolenia są certyfikowane? ',
        text: 'Szkolenia nie są certyfikowane, jednak wszystkie prowadzone są przez specjalistów z dużym doświadczeniem i ogromną dawką wiedzy do przekazania! Z pewnością da się z nich wiele wyciągnąć, do czego serdecznie namawiamy!'
      },
      {
        title: '17. Czy mogę wziąć udział w więcej niż jednym szkoleniu online? ',
        text: 'Oczywiście! Jeśli chcesz, możesz wziąć udział we wszystkich szkoleniach! Podczas tej edycji nie ma limitu ilości szkoleń, w których możesz uczestniczyć. Śledź na bieżąco harmonogram i dołączaj do webinarów!'
      },
      {
        title: '18. Gdzie znajdę więcej wskazówek, jak znaleźć pracę na targach?',
        text: `Z pewnością warto zajrzeć do zakładki “Wiedza” na naszej stronie internetowej https://careerexpo.pl/artykuly-eksperckie. Znajdziesz tam sporo ciekawych tekstów, porad i praktycznych informacji, które przybliżą Ci specyfikę rynku pracy i oczekiwania pracodawców, a także podpowiedzą Ci, jak skutecznie aplikować. Zapraszamy również na nasz kanał na YouTube - gdzie znajdziesz znajdziesz materiały wideo z poprzednich edycji, a także rozmowy z naszymi ekspertami czy uczestnikami targów. 
Śledź także na bieżąco nasz fanpage na Facebooku (www.facebook.com/pl.careerEXPO) Polub go, zapisz się do wydarzenia i zaproś znajomych - niech również skorzystają ze swojej szansy!`
      },
      {
        title: '19. Jakie branże znajdą pracę na Career EXPO?',
        text: `To co z pewnością wyróżnia nasze targi to ich multibranżowość. Firmy, które się u nas wystawiają reprezentują rozmaite branże. Na Career EXPO pracy mogą szukać zarówno finansiści, marketingowcy czy programiści, ale także osoby z branży logistycznej czy produkcyjnej. 
Ilość branż i różnorodnych ofert pracy, na różnych stanowiskach sprawia że na targach szukają pracy zarówno osoby doświadczone, jak i te dopiero rozpoczynające swoją karierę. 

Zachęcamy do zapoznania się z profilami firm i aktualnymi ofertami pracy na naszej stronie.`
      },
      {
        title: '20. Czy po targach od razu dostanę pracę? ',
        text: 'Wszystko zależy od Twojego zaangażowania i podejścia oraz dopasowania do oferowanych stanowisk pracy. Oprócz rozmów z rekruterami, na naszej stronie będą dostępne oferty pracy od firm biorących udział w Career EXPO. Warto przejrzeć je jeszcze raz, bo są na bieżąco aktualizowane. Rekruterzy są mocno nastawieni na znalezienie nowych, kompetentnych pracowników, dlatego istnieje wysoka szansa, na to, że uda Ci się znaleźć pracę bardzo szybko. :)'
      },
      {
        title: '21. Nie znalazłem odpowiedzi na moje pytanie - z kim mogę się skontaktować?',
        text: `Jeśli nie możesz znaleźć rozwiązania swojego problemu, napisz do nas wiadomość prywatną na naszym fanpage: www.facebook.com/pl.careerEXPO lub wyślij maila z pytaniem na adres: info@careerexpo.pl. Postaramy pomóc się jak najszybciej! 
  W trakcie wydarzenia online uruchomimy też na stronie głównej dedykowany czat, na którym będziesz mógł na żywo uzyskać pomoc od członków zespołu Career EXPO.`
      }
    ]
  },
  footer: {
    leftColumn: {
      linksList: [
        {
          label: 'Terms and conditions',
          link: '/regulamin',
          type: 'internal'
        }
      ]
    },
    centerColumn: {
      linksList: [
        {
          label: 'Privacy policy',
          link: '/polityka-prywatnosci',
          type: 'internal'
        }
      ]
    },
    rightColumn: {
      linksList: [
        {
          label: 'Contact us',
          link: '/kontakt',
          type: 'internal'
        }
      ]
    }
  },
  form: {
    creatorCv: {
      creatorCvTitle: 'TODO',
      creatorCvStepOne: {
        description: 'TODO'
      },
      creatorCvStepTwo: {
        description: 'TODO'
      },
      creatorCvStepThree: {
        description: 'TODO'
      },
      creatorCvSteps: {
        stepsList1: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Basic data',
            type: 'isCurrent',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenia',
            number: '2',
            title: 'Experience',
            type: 'isNext',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Theme',
            type: 'isNext',
            withLinks: true
          }
        ],
        stepsList2: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Basic data',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenia',
            number: '2',
            title: 'Experience',
            type: 'isCurrent',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Theme',
            type: 'isNext',
            withLinks: true
          }
        ],
        stepsList3: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Basic data',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenia',
            number: '2',
            title: 'Experience',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Theme',
            type: 'isCurrent',
            withLinks: true
          }
        ]
      }
    },
    companySteps: {
      companyStepOne: {
        description: 'Even the greatest journey starts with the first step. Enter your data and show your company off to the world.'
      },
      companyStepTwo: {
        description: 'Why do you do what you do? But what’s most important - why would others join you? It’s the perfect time for inspiration.'
      },
      companyStepThree: {
        description: 'Who are you looking for and who is responsible for recruitment? Don’t be anonymous - it’s your chance to attract genuine talent'
      },
      companyStepFour: {
        description: 'Show the candidates what your company has to offer in turn for their skills (page: Benefits). It pays to show off your company’s success ;-) (page: Distinctions)'
      },
      companyStepFive: {
        description: 'We all want to keep growing and work with the best for the best. Share your knowledge and experience to help them choose the right career path.',
        guidelinesLabel: 'Guidelines',
        guidelinesText: 'Articles and information about the author are added by representatives of the companies themselves. Article length - article length: min. 5500 characters, max 8500 characters with spaces. It is recommended to attach graphics and photos to the supplementing content (if this is omitted, the website administrator will match the appropriate materials for publication). The header graphic should be 930x380 pixels. The texts are verified on an ongoing basis and published after acceptance. We reserve the right not to publish an article that does not meet the guidelines (e.g. shorter than 5500 characters, advertising, containing spelling or logic errors, without the author\'s biography, etc.)',
        howArticleShouldLookLabel: 'What should an article look like?',
        howArticleShouldLookText: 'Expert articles should be substantive, not informative. We encourage you to raise topics that will be interesting for visitors. Articles written so far can be found at: https://careerexpo.pl/wiedza/eksperci. They can serve you as a hint.',
        howArticleShouldNotLookLabel: 'What should an article NOT look like?',
        howArticleShouldNotLookText: 'Expert articles cannot be strictly advertising, and therefore cannot relate to the company\'s history, products, as well as its internship programs, recruitment processes and other issues directly related to this particular organization. Such articles will not pass verification.',
        visibleText: 'Expert articles are materials placed in the "Knowledge" section, whose task is to prepare visitors for trade fairs and increase their professional awareness. The author plays the role of a professional advisor representing a given organization. He writes a text on a selected issue within the circle of topics related to recruitment, the labor market and personal development. Industry texts and case studies are also welcome. It is an attractive form supporting the image of an expert, which inspires the trust of both job seekers and business partners.'
      },
      companyStepsList1: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Basic data',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Details',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Recruitment',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefits and distinctions',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Education',
          type: 'isNext',
          withLinks: false
        }
      ],
      companyStepsList2: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Basic data',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Details',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Recruitment',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefits and distinctions',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Education',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList3: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Basic data',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Details',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Recruitment',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefits and distinctions',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Education',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList4: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Basic data',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Details',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Recruitment',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefits and distinctions',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Education',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList5: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Basic data',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Details',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Recruitment',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefits and distinctions',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Education',
          type: 'isCurrent',
          withLinks: false
        }
      ],
      companyStepsTitle: 'Create your own unique Employer’s profile in 5 steps'
    },
    labels: {
      buttonNextLabel: 'Next',
      buttonNextStepLabel: 'Next step',
      buttonPrevLabel: 'Back',
      buttonPreview: 'Preview',
      buttonResetLabel: 'Clear',
      buttonSaveLabel: 'Save',
      buttonSelectLabel: 'Choose',
      buttonSkipLabel: 'Skip',
      fastRecruitment: 'Fast recruitment',
      favouriteEmployers: 'Favorite employers',
      requiredFieldsText: '* Mandatory field',
      titleLabel: 'Prepare for the Career EXPO job fair in 3 steps!',
      trainingsTimetableLabel: 'Trainings timetable'
    },
    userDataFormLabels: {
      userAddressLabel: 'Address',
      userCityLabel: 'City',
      userCountryLabel: 'Country',
      userCustomTextLabel: 'Tell us something about yourself',
      userDateOfBirthLabel: 'Date of birth',
      userEmailLabel: 'E-mail address',
      userNameLabel: 'Name',
      userNationalityLabel: 'Nationality',
      userPhoneLabel: 'Phone number',
      userPictureLabel: 'Your picture',
      userPostCodeLabel: 'Postcode',
      userSocialMediaDescriptionLabel: 'Social Media',
      userSocialMediaDescriptionText: '',
      userSocialMediaLinkLabel: 'Link',
      userSocialMediaTitleLabel: 'Social Media profile',
      userSurnameLabel: 'Surname'
    },
    stepsList1: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Basic data',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Trainings enrolment',
        type: 'isNext',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Download the ticket',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList2: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Basic data',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Trainings enrolment',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Download the ticket',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList3: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Basic data',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Trainings enrolment',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Download the ticket',
        type: 'isCurrent',
        withLinks: false
      }
    ],
    stepOne: {
      description: 'Provide your basic data to be able to participate in the fair. In the next step you will sign up for trainings and then download a free admission ticket.',
      subtitle: 'Stadion Wrocław'
    },
    stepTwo: {
      description: 'We will help you become better. Choose the areas that interest you, and then sign up for trainings that will raise your competences!',
      subtitle: 'Choose the areas of training and lectures that interest you:'
    },
    stepThree: {
      description: 'Download a free admission ticket to the Career EXPO job fair:',
      descriptionTwo: 'Complete the data and get points for completing all forms',
      subtitle: 'Be unique',
      subtitleTwo: 'Basic data',
      subtitleThree: 'Education (your last most important schools)',
      subtitleFour: 'Experience',
      subtitleFive: 'Foreign languages',
      subtitleSix: 'Skills'
    },
    stepFour: {
      description: 'We will help you find the perfect company that suits your values. First, however, determine what you would like your professional future to look like!',
      employersLabel: 'employers',
      employersAboveLabel: 'employers above',
      foundLabel: 'Found',
      subtitleValues: 'First select 10 values ​​that are important to you..',
      subtitleImportantValues: 'Then choose the top 3 from these 10 (change color to raspberry).',
      subtitleTwo: 'Choose the industry you would like to co-create and develop:',
      valueLabel: 'values'
    },
    stepFive: {
      description: 'A lot of interesting events await you at Career EXPO. Download the timetable for this special day!',
      buttonDownloadSummary: 'Download summary',
      getTicket: 'Download ticket',
      subtitle: 'Your unique ticket',
      subtitleTwo: 'See what Employers you will have the opportunity to talk to during the Career EXPO job fair!'
    }
  },
  locations: {
    labels: {
      addToCalendarButtonLabel: 'Add to calendar',
      buttonLabel: 'Find connection',
      dateAndTimeLabel: 'Date and hour',
      placeLabel: 'Place'
    }
  },
  registryAndLogin: {
    labels: {
      doYouAlreadyHaveAnAccountLabel: 'Already have an account?',
      forgotPassword: 'I forgot the password',
      gotAcquaintedWithLabel: 'I got acquanited with',
      loginLabel: 'Log in',
      privacyPolicyLabel: 'Privacy Policy',
      registryLabel: 'Create a profile',
      regulationsLabel: 'Terms and conditions',
      rightSideDescriptionLabel: 'Do you want to improve your qualifications, and maybe learn what is the secret of success of business leaders? Choose something for yourself from our list of trainings and lectures with interesting guests - sign up and absorb knowledge.',
      rightSideTitleLabel: 'Lectures and trainings',
      siteNameLabel: 'Career EXPO',
      youDontHaveAnAccountLabel: 'Don’t have an account?'
    }
  },
  recruitment: {
    labels: {
      assessmentCenterLabel: 'Assesment Center',
      departmentsLabel: 'Sections',
      employmentTypesLabel: 'Type of employment',
      jobInterviewLabel: 'Interview',
      needsCvLabel: 'Curriculum Vitae (CV)',
      needsMotivationalListLabel: 'Cover letter',
      placeLabel: 'Places of work',
      preferredCandidatesLabel: 'Preferred candidates',
      preferredDegreeCoursesLabel: 'Studies majors',
      recruiterLabel: 'Responsible person for recruitment',
      whoWeAreLookingForLabel: 'Who we’re looking for'
    }
  },
  SocialMediaCE: {
    facebook: 'https://www.facebook.com/pl.careerEXPO',
    linkedin: 'https://www.linkedin.com/company/career-expo-poland',
    twitter: 'https://www.twitter.com/plCareerEXPO',
    youtube: 'https://www.youtube.com/user/plCareerEXPO'
  },
  subPages: {
    contact: {
      companyAddress: 'ul. Kazimierza Wielkiego 15/5',
      companyCity: '50-077 Wrocław',
      companyKRS: '0000464874',
      companyNIP: '8992744913',
      companyName: 'Career EXPO Poland Sp. z o.o.',
      companyREGON: '022157517',
      pageTitle: 'Contact us',
      staffList: [
        {
          email: 'karolina.merda@careerexpo.pl',
          image: karolinaMerdaImagePath,
          name: 'Karolina',
          phone: '+48781103305',
          phoneLabel: '+ 48 781 103 305',
          position: 'Promotion and Public Relations',
          surname: 'Merda'
        },
        {
          email: 'jacek.ciechanowski@careerexpo.pl',
          image: jacekCiechanowskiImagePath,
          name: 'Jacek',
          phone: '+ 48516007713',
          phoneLabel: '+ 48 516 007 713',
          position: 'Participation in the fair - Employers',
          surname: 'Ciechanowski'
        },
        {
          email: 'help@careerexpo.pl',
          image: annaScislowskaImagePath,
          name: 'Anna',
          phone: '+48883219900',
          phoneLabel: '+ 48 883 219 900',
          position: 'Participation in the fair - Participants',
          surname: 'Ścisłowska'
        }
      ]
    }
  },
  summary: {
    labels: {
      buttonBackToStepLabel: 'Go back to step',
      fastRecruitment: 'Fast Recruitment',
      favouriteEmployers: 'favorite employers',
      trainings: 'trainings',
      trainingsLabel: 'Trainings timetable',
      youHave: 'You have',
      youHaveAssignedFor: 'You signed up for',
      youParticipateIn: 'You participate in'
    }
  },
  steps: {
    landingPageSteps: {
      labels: {
        titleLabel: 'So what’s it gonna be, see you at the fair?',
        buttonLabel: 'Get the ticket'
      },
      stepsList: [
        {
          description: 'and fill out basic data',
          number: '1',
          title: 'Create account',
          type: 'isCurrent'
        },
        {
          description: 'Take part in our online trainings that will increase your value in the labor market!',
          number: '2',
          title: 'Get knowledge',
          type: 'isNext'
        },
        {
          description: 'Check the opportunities Employers in your region offer you and participate in chats with live recruiters.',
          number: '3',
          title: 'Apply!',
          type: 'isNext'
        }
      ]
    }
  },
  tabs: {
    tabsList: [
      {
        label: 'General',
        value: 'general'
      },
      {
        label: 'Recruitment',
        value: 'recruitment'
      },
      {
        isBold: true,
        label: 'Job offers',
        value: 'jobs'
      },
      // {
      //   label: 'News',
      //   value: 'general'
      // },
      {
        label: 'Benefits',
        value: 'benefits'
      },
      {
        label: 'Distinctions',
        value: 'awards'
      },
      {
        label: 'Offices',
        value: 'offices'
      }
    ]
  },
  topMenu: {
    linksList: [
      {
        inNewWindow: false,
        label: 'Employers',
        link: '#companies'
      },
      {
        inNewWindow: false,
        label: 'Recruitment',
        link: '#recruitment'
      },
      {
        inNewWindow: false,
        label: 'Job Offers',
        link: '/oferty-pracy'
      },
      {
        inNewWindow: false,
        label: 'Trainings',
        link: '#trainings'
      },
      {
        inNewWindow: false,
        label: 'Help',
        link: '/help'
      },
      {
        fontBold: true,
        inNewWindow: true,
        label: 'Join the exhibitors!',
        link: 'https://careerexpo.pl/wspolpraca'
      }
    ],
    linksListJobs: [
      {
        inNewWindow: false,
        label: 'Employers',
        link: '/#companies'
      },
      {
        inNewWindow: false,
        label: 'Recruitment',
        link: '/#recruitment'
      },
      {
        inNewWindow: false,
        label: 'Job Offers',
        link: '/oferty-pracy'
      },
      {
        inNewWindow: false,
        label: 'Trainings',
        link: '/#trainings'
      },
      {
        inNewWindow: false,
        label: 'FAQ',
        link: '/faq'
      },
      {
        fontBold: true,
        inNewWindow: true,
        label: 'Join the exhibitors!',
        link: 'https://careerexpo.pl/wspolpraca'
      }
    ]
  },
  trainings: {
    labels: {
      trainingsButtonBuyLabel: 'Sign up',
      trainingsButtonUnBuyLabel: 'Sign off',
      trainingsDescriptionLabel: 'Description',
      trainingsHourFieldLabel: 'Hour',
      trainingsPlaceLabel: 'Stage',
      trainingsSpeakersFieldLabel: 'Lecturers',
      trainingsSubjectFieldLabel: 'Topic',
      trainingsTagsListLabel: 'Tags'
    }
  },
  login: 'Log in',
  logout: 'Log out',
  register: 'Register',
  sectionOne: {
    title: 'We will help you find the job of your dreams',
    description: 'A hundred Employers, valuable trainings, free career and CV consultations - Career EXPO is the most developmental event in Wrocław.'
  },
  sectionTwo: {
    title: 'Job Fair Wrocław',
    icon: 'pin',
    alt: 'icon pin'
  },
  sectionThree: {
    title: 'Lectures and trainings',
    description: 'Do you want to improve your qualifications, and maybe learn what is the secret of success of business leaders? Choose something for yourself from our list of trainings and lectures with interesting guests - sign up and absorb knowledge.',
    subtitle: {
      alt: 'icon tag',
      icon: 'tag',
      text: 'What interests you? Choose the areas of your development:'
    }
  },
  sectionFour: {
    title: 'Lectures and trainings',
    description: 'Do you want to improve your qualifications, and maybe learn what is the secret of success of business leaders? Choose something for yourself from our list of trainings and lectures with interesting guests - sign up and absorb knowledge!',
    legend: {
      hour: 'Hour',
      subject: 'Topic',
      speakers: 'Lecturers'
    }
  },
  sectionFive: {
    title: 'Employers',
    description: 'Meet the leading employers from Lower Silesia and convince them to hire you!',
    subtitle: {
      alt: 'icon star',
      icon: 'star',
      text: 'Choose industry below:'
    }
  },
  sectionSeven: {
    title: 'Zones',
    description: 'See why it\'s still worth coming to the Career EXPO job fair!'
  },
  sectionEight: {
    createCV: 'Create CV'
  },
  sectionRecruitment: {
    description: 'Będziemy dostępni dla Ciebie online - zapytaj o kwestie dotyczące rekrutacji już 1-2 kwietnia od 14-20.',
    subtitle: {
      alt: 'icon star',
      icon: 'star',
      text: 'Jesteśmy dostępni dla Ciebie już dziś od 11:00 do 19:00. Porozmawiaj z nami o Twojej karierze w naszych organizacjach!'
    },
    title: 'Rekrutacja online'
  },
  copyright: '© Copyright by Career EXPO Poland sp. z o.o.. All rights reserved.',
  loginAndRegisterSliderItems: [
    {
      image: testImage,
      isSelected: false,
      text: 'Career EXPO attracts the best Employers from the Lower Silesia region who are focused on one goal: to find the best colleagues. Come and convince them to hire you!',
      title: 'Hundred of Employers - apply!'
    },
    {
      image: testImage,
      isSelected: true,
      text: 'Do you want to improve your qualifications, and maybe learn what is the secret of success of business leaders? Choose something for yourself from the list of lectures and trainings - sign up and absorb knowledge! Career EXPO attracts the best trainers.',
      title: 'Learn from the best'
    },
    {
      image: testImage,
      isSelected: false,
      text: 'Generate a beautiful and effective CV, and then consult it with our experts directly at the event. You will also take a professional picture. In a word - you will maximize your chances!',
      title: 'Generate and consult your CV!'
    }
  ],
  homepage: {
    cards: [
      {
        description: 'The recruiter only needs 14 seconds to reject your CV and 4 minutes to accept it. Make him interested in your candidacy and invite you to the next stage of recruitment!',
        icon: HomepageCardClassroomIconPath,
        title: 'Create an effective CV'
      },
      {
        description: 'For one, an international company is an advantage, and for another: a disadvantage. Remote work can be a salvation or anguish. We will show you which Employers have what you are looking for!',
        icon: HomepageCardSuccessIconPath,
        title: 'Describe traits of the perfect Employer'
      },
      {
        description: 'Come to the Career EXPO job fair and convince your chosen Employer to hire you! Take part in Fast Recruitment, free training and career consultations.',
        icon: HomepageCardWorkIconPath,
        title: 'Get to know each other and… apply!'
      }
    ],
    cvTemplates: [
      {
        id: 1,
        image: HomepageCvTemplate1ImagePath,
        selected: false,
        title: 'Formal'
      },
      {
        id: 2,
        image: HomepageCvTemplate2ImagePath,
        selected: false,
        title: 'Casual'
      },
      {
        id: 3,
        image: HomepageCvTemplate3ImagePath,
        selected: false,
        title: 'Modern'
      }
    ],
    employersList: [
      HomepageEmployersAmazonImagePath,
      HomepageEmployersCeneoImagePath,
      HomepageEmployersVolvoImagePath,
      HomepageEmployersImpelImagePath,
      HomepageEmployersHpImagePath,
      HomepageEmployersQatarImagePath
    ],
    events: {
      labels: {
        dateLabel: 'Date',
        placeLabel: 'Place'
      },
      primaryEvent: {
        date: '21.10.2019',
        name: 'Targi pracy Career EXPO online',
        place: 'Stadion Wrocław'
      }
    },
    guidlineSteps: [
      {
        description: 'Choose one of the many unique templates created by graphic designers and recruiters! Create a CV that will delight your future employer.',
        number: 1,
        title: 'Choose template'
      },
      {
        description: 'Complete the most important information and add a photo. Save the template as one of many and adapt it to the different positions you apply for.',
        number: 2,
        title: 'Enter data'
      },
      {
        description: 'Download your CV to use for external recruitment or apply directly with Career EXPO! Completely free.',
        number: 3,
        title: 'Download your CV'
      }
    ],
    squarePanels: {
      itemsList: [
        {
          description: 'Do you prefer solitary work and formal atmosphere?',
          icon: HomepageSquarePanel1IconPath,
          title: 'We have Employers just like that!'
        },
        {
          description: 'Do you prefer remote work and plenty of trust?',
          icon: HomepageSquarePanel2IconPath,
          title: 'We have Employers just like that!'
        },
        {
          description: 'Do you want to work in a large team and go to world-class conferences?',
          icon: HomepageSquarePanel3IconPath,
          title: 'We have Employers just like that!'
        }
      ],
      selected: {
        selected: true,
        title: 'We have Employers just like that!'
      }
    }
  },
  zones: {
    one: {
      description: 'Apply and get the job of your dreams immediately! The Fast Recruitment Zone is a place for people who expect quick feedback.',
      label: 'Fast Recruitment Zone'
    },
    two: {
      description: 'Experts will tell you what to improve on your CV to make it perfect. You can also take a professional photo here.',
      label: 'CV Consulting Zone'
    },
    three: {
      description: 'Take advantage of mini coaching sessions to answer the questions that don’t let you rest. The answer is in you… You just need to ask the right question.',
      label: 'Coaching Zone'
    },
    four: {
      description: 'Visit the Zone with the latest job offers and see what requirements Employers have.',
      label: 'Job Offers Zone'
    },
    five: {
      description: 'Out of your CVs? Don’t worry. You will print out your application documents with us.',
      label: 'Xerox Zone'
    },
    six: {
      description: 'Tired of traveling through the maze of stands? Rest with coffee and music to regain strength before hunting for the best offers.',
      label: 'Relax Zone'
    }
  },
  days: {
    day1: 'Day 1',
    day2: 'Day 2'
  },
  companyPanel: {
    officesLabel: 'Offices',
    phoneLabel: 'P:',
    ourMission: 'Our Mission',
    howWeDoIt: 'How do we do it?',
    aboutUs: 'About us',
    videoGallery: 'Video Gallery',
    articles: 'Expert Articles',
    moreButton: 'More',
    dateAndPlaceLabel: 'Date and Place',
    prizelabel: 'Prize',
    jobBack: 'Back to all offers'
  },
  partners: {
    supportingPartners: 'Supporting Partners',
    honoraryPartners: 'Honorary Partners ',
    medialPartners: 'Media Partners',
    partners: 'Partners'
  },
  contactUsTitle: 'Contact us',
  knowledge: {
    title: 'Expert artciles',
    subTitle: 'Read and develop - just take care of your professional future',
    tagTitle: 'What interests you? Choose!',
    latest: 'Latest',
    mostInteresting: 'Most interesting',
    recommended: 'Recommended'
  },
  jobOffers: {
    backButton: 'Back to all offers',
    title: 'Job Offers',
    show: 'Show',
    apply: 'Apply'
  },
  activation: {
    success: 'The email has been sent successfully!',
    error: 'There is a problem!'
  },
  unsubscribe: {
    success: 'Unsubscribed!',
    error: 'There is a problem!',
    errorTitle: 'Failed to unsubscribe',
    errorDescription: 'Try again. If the error persists, please contact us.'
  },
  registerSuccess: {
    title: 'Thank you for registering!',
    body: 'Check your e-mail inbox and click on the confirmation link. If the e-mail does not appear within a few minutes, please check the SPAM folder and then make sure you have entered the correct e-mail address (you can do this by trying the next registration by entering the same e-mail address).',
    greetings: 'We wish you nothing but success!',
    teamCE: 'Career EXPO Team',
    sendEmail: 'Send e-mail again',
    sendEmailSuccess: 'Sent!',
    sendEmailError: 'Something went wrong, try again.',
    or: 'or',
    goBack: 'Back to registration'
  },
  resetPassword: {
    error: 'There is a problem!',
    title: 'Set New Password',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter Password',
    passwordConfirmationLabel: 'Confirm Password',
    passwordConfirmationPlaceholder: 'Enter Password Again',
    send: 'Send',
    validationErrors: {
      tooShort: 'Password is too short!',
      required: 'This field is mandatory',
      equal: 'Passwords must be the same'
    }
  },
  forgotPassword: {
    success: 'Sent, check your email for further instructions!',
    userDoesNotExist: 'User with this e-mail address does not exist!',
    error: 'There is a problem',
    title: 'I forgot my password',
    placeholder: 'Enter E-mail',
    send: 'Send',
    validationErrors: {
      required: 'This field is mandatory',
      email: 'Wrong E-mail'
    }
  },
  expoRegister: {
    title: 'Fast Registration',
    firstNameLabel: 'Name',
    firstNamePlaceholder: 'Enter your name...',
    lastNameLabel: 'Surname',
    lastNamePlaceholder: 'Enter your surname...',
    phoneLabel: 'Phone',
    phonePlaceholder: 'We will remind you about an event! Nothing more.',
    positionLabel: 'Position',
    positionPlaceholder: 'Enter the position you hold / apply for',
    emailPlaceholder: 'Enter Email',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter Password',
    checkboxes: {
      all: 'Select all consents',
      accept: 'Accept',
      statuteDescription: 'in order to use the careerexpo.pl website for free',
      notifications: 'I want to receive reminders about fairs, trainings and other areas related to Career EXPO',
      shareProfile: 'I want to share my profile with Employers',
      recommendations: 'I want to receive information about recommended candidates'
    },
    saveLabel: 'Register',
    validationErrors: {
      regulations: 'You must accept the Terms and conditions',
      policyPrivacy: 'You must accept the Privacy Policy',
      required: 'This field is mandatory',
      email: 'Wrong E-mail',
      tooShort: 'Password is too short!'
    }
  },
  controlButtons: {
    edit: 'Cancel editing',
    clear: 'Clear form',
    reverse: 'Undo changes'
  },
  registerSuccessMessage: 'Your account has been activated! You can log in.',
  uploadAvatar: {
    buttonSelectLabel: 'Choose imahge (PNG/JPG)',
    chooseFileButtonDescription: 'Select area of ​​the photo:',
    chooseFileButtonFieldlabel: 'Select file:',
    chooseFileButtonLabel: 'I’m selecting file:',
    chooseFileButtonSendLabel: 'Send image',
    chooseFileTitle: 'You are adding an image to your CV!',
    croppedImageHeadingText: 'Preview:'
  },
  userStepTwo: {
    signUpSuccess: 'You have been signed up for the training',
    noPlaces: 'There are no vacancies for this training!',
    unSignUpSuccess: 'You have been signed out of the training',
    error: 'There was an error while signing out!',
    nextPageError: 'An error occurred while navigating to the next page!',
    allTrainings: 'All trainings',
    saved: 'My trainings',
    showTrainings: 'See a list of available trainings',
    noTrainings: 'You have not signed up for any training yet!'
  },
  userStepThree: {
    companies: 'These companies are waiting for your application. Find out more about them:',
    more: 'More'
  },
  stepsController: {
    back: 'Back',
    homePage: 'Home page',
    next: 'Następny',
    skip: 'Skip'
  }
}

export default translationsListEN
