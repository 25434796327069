import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import MultiSelectDropDownField from '../../FormFields/MultiSelectDropDownField'
import AddedItem from '../../../List/AddedItem'

import skillsLevels from '../../../../stabs/skillsLevels'

const AddSkillForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleMultiSelect,
  handleEdit,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel errors={apiError}>
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}

    <FormTwoColumns>
      <InputField
        errorMessage={errors.skill}
        hasError={errors.skill && touched.skill}
        id='Skills'
        label='Umiejętności'
        name='skill'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz, co potrafisz (np. Microsoft Excel)…'
        required
        value={values.skill}
      />

      <MultiSelectDropDownField
        errorMessage={errors.level}
        hasError={errors.level && touched.level}
        itemsList={skillsLevels}
        label='Poziom'
        name='level'
        onClick={handleMultiSelect}
        placeholder={values.level === '' ? 'Wybierz poziom…' : skillsLevels.filter((level) => level.value === values.level)[0].label}
        required
        closeOnClick
        value={values.level}
      />
    </FormTwoColumns>
  </FormPanel>
)

export default AddSkillForm
