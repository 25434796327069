import styled from 'styled-components'

import {
  PurpleColorL,
  PurpleColorXS,
  WhiteColor
} from '../../assets/styles/colors'

export const ChooseColorListContainer = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 8px 0 0;
  flex-wrap: wrap;
  margin-bottom: -10px;
  justify-content: center;
`

export const ChooseColorListElement = styled.li`
  display: flex;
  align-items: center;
  margin: 0 30px 10px 0;

  &:last-child {
    margin: 0 0 0 32px;
    display: none;
  }
`

export const ChooseColorAddNewButtonContainer = styled.div`
  width: 21px;
  height: 21px;
  padding: 5px;
  background-color: ${PurpleColorXS};
  border-radius: 50%;
  transform: rotate(45deg);

  & g {
    stroke: ${WhiteColor};
  }
`

export const ChooseColorAddNewInput = styled.input`
  border-radius: 50%;
  border: 0 solid transparent;
  cursor: pointer;
  height: 21px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 21px;
`

export const ChooseColorAddNewText = styled.div`
  padding: 0 0 0 12px;
  line-height: 21px;
  font-size: 12px;
  color: ${PurpleColorL};
`
