import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../../assets/styles/colors'

export const PricingBoxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const PricingBoxHeaderContainer = styled.div`
  /*@media (min-width: 768px) {
    margin: 0 0 20px;
  }

  @media (max-width: 767px) {
    margin: 0 0 15px;
  }*/

  ${props => {
    switch (props.sale) {
      case 'true': return `
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        /*align-items: baseline;*/

        > div {
          &:nth-last-child(2) {
            margin-top: -20px;
            @media (max-width: 768px) {
              margin-top: -15px;
              flex: 100%;
            }
          }
        }
      `
      default: return `
      `
    }
  }}
`

export const PricingBoxPriceContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: center;

  ${props => {
    switch (props.sale) {
      case 'true': return `
        margin-right: 10px;
        > div {
          text-decoration: line-through;

          &:nth-child(1) {
            font-size: 20px;
          }
        }
      `
      default: return `
      `
    }
  }}
`

export const PricingBoxPrice = styled.div`
  color: ${PurpleColor};
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const PricingBoxPriceCurrency = styled.div`
  margin-left: 5px;
`

export const PricingBoxSubTitle = styled.div`
  color: ${PurpleColorM};

  span {
    display: block;
    color: #3D3270;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const PricingBoxContent = styled.div`
  @media (min-width: 768px) {
    margin-top: auto;
  }

  @media (max-width: 767px) {
    flex: 100%;
  }
`

export const PricingBoxBenefitsTypeOneContainer = styled.div``

export const PricingBoxListElement = styled.div`
  align-items: flex-start;
  display: flex;
  text-align: left;

  & + div {
    margin-top: 10px;
  }
`

export const PricingBoxListElementIconContainer = styled.div`
  flex-shrink: 0;
  height: 18px;
  margin-right: 10px;
  width: 18px;
`

export const PricingBoxListElementLabel = styled.div``

export const PricingBoxBenefitsTypeTwoContainer = styled.div`
  margin: 20px 0 0;
`

export const PricingBoxButtonContainer = styled.div`
  /*@media (min-width: 1200px) {
    margin: 40px 0 0;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 20px 0 0;
  }*/
`

export const PricingBoxExtraText = styled.div`
  a {
    color: #fd027a;
    text-decoration: none
  }
`
