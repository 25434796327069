import React from 'react'

import TagsContainer from '../../../containers/ComponentContainers/TagsContainer'
import Subtitle from '../../../components/Subtitle'
import FormTrainingsContainer from '../../../containers/PlatformContainer/UserStepsForms/StepTwoContainer'

import translationsList from '../../../stabs/translationsList'

const FormUserStepTwo = () => (
  <>
    <Subtitle
      icon='tag'
      label={translationsList.form.stepTwo.subtitle}
    />

    <TagsContainer />

    <FormTrainingsContainer />
  </>
)

export default FormUserStepTwo
