import React from 'react'
import PropTypes from 'prop-types'

import {
  OutsideSliderElementWrapperContainer
} from './Atoms'

const OutsideSliderElementWrapper = ({
  children
}) => (
  <OutsideSliderElementWrapperContainer data-test='OutsideSliderElementWrapperContainer'>
    {children}
  </OutsideSliderElementWrapperContainer>
)

export default OutsideSliderElementWrapper

OutsideSliderElementWrapper.propTypes = {
  children: PropTypes.any
}
