import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'react-toastify'

import ApiService from '../services/ApiService'
import * as subscriptionsAPI from '../api/subscription'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const useStripe = () => {
  return {
    createSubscription: async (type, coupon) => {
      try {
        const { sessionId } = await fetchCheckoutSession(type, coupon)

        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({
          sessionId
        })

        if (error) {
          handleError(error.message)
        }
      } catch (e) {
        if (e.message.includes('coupon')) {
          toast.error('Nie prawidłowy kupon!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        }
      }
    }
  }
}

const fetchCheckoutSession = async (type, coupon) => {
  const cvId = sessionStorage.getItem('cv')

  try {
    return await ApiService.fetch(subscriptionsAPI.createSubscription( { type, cvId, coupon }))
  } catch (e) {
    if (e.message.includes('coupon')) {
      handleError(e, 'Nie prawidłowy kupon!')
    } else {
      handleError(e)
    }
  }
}

const handleError = (error, message = 'Coś poszło nie tak!') => {
  // console.error(error)
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })
}

export default useStripe
