import ApiService from '../services/ApiService'

export const getMe = () => new ApiService('GET', '/me', {}, { withAuth: true })
export const updateMe = (body) => new ApiService('PUT', '/me', body, { withAuth: true })
export const toggleValue = (valueId, action) => new ApiService('POST', `/me/values/${valueId}`, { action }, { withAuth: true })
export const toggleFavouriteCompany = (companyId, action) => new ApiService('POST', `/me/companies/${companyId}`, { action }, { withAuth: true })

export const unsubscribe = (body) => new ApiService('POST', '/unsubscribe', body)
export const activate = (body) => new ApiService('POST', '/activate', body)
export const sendActivationEmail = (body) => new ApiService('POST', '/send-activation-email', body)
export const changePassword = (body) => new ApiService('POST', '/me/password', body)
export const changePasswordInSettings = (body) => new ApiService('POST', '/me/change-password', body, { withAuth: true })
export const changeMarketingApproval = (body) => new ApiService('PATCH', '/me/marketing-approval', body, { withAuth: true })
export const resetPassword = (body) => new ApiService('POST', '/me/reset-password', body)

export const forwardMailchimp = (body) => new ApiService('POST', '/mailchimp', body)
