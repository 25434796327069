import ApiService from '../services/ApiService'
import asUpload from '../../utils/asUpload'

export const getPrimaryEvent = () => new ApiService('GET', '/events/primary')
export const getAllTags = () => new ApiService('GET', '/tags')

export const getAllTrainings = (eventId) => new ApiService('GET', `/events/${eventId}/trainings?unpublished`, {}, { optionalAuth: true })

export const enrollOnEvent = (eventId) => new ApiService('POST', `/events/${eventId}/enrollment`, {}, { withAuth: true })

export const checkTicket = (eventId, body) => new ApiService('POST', `/events/${eventId}/ticket`, body)

export const enrollOnTraining = (trainingId) => new ApiService('POST', `/trainings/${trainingId}/enrollment`, {}, { withAuth: true })
export const unenrollFromTraining = (trainingId) => new ApiService('DELETE', `/trainings/${trainingId}/enrollment`, {}, { withAuth: true })

export const addCompanyToEvent = (eventId, companyId) => new ApiService('POST', `/events/${eventId}/companies/${companyId}`, {}, { withAuth: true })
export const removeCompanyFromEvent = (eventId, companyId) => new ApiService('DELETE', `/events/${eventId}/companies/${companyId}`, {}, { withAuth: true })

export const createTraining = (eventId, body) => new ApiService('POST', `/events/${eventId}/trainings`, body, { withAuth: true })
export const updateTraining = (trainingId, body) => new ApiService('PUT', `/trainings/${trainingId}`, body, { withAuth: true })
export const removeTraining = (trainingId) => new ApiService('DELETE', `/trainings/${trainingId}`, {}, { withAuth: true })

export const uploadSpeakerAvatar = (body) => asUpload(new ApiService('POST', '/trainings/speaker-avatar', {}, { withAuth: true, allowGhost: true }), body)
export const createSpeaker = (trainingId, body) => new ApiService('POST', `/trainings/${trainingId}/speakers`, body, { withAuth: true })
export const updateSpeaker = (trainingId, speakerId, body) => new ApiService('PUT', `/trainings/${trainingId}/speakers/${speakerId}`, body, { withAuth: true })
export const removeSpeaker = (trainingId, speakerId) => new ApiService('DELETE', `/trainings/${trainingId}/speakers/${speakerId}`, {}, { withAuth: true })
