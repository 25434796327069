import React, {useEffect} from 'react'
import { useDebouncedCallback } from 'use-debounce'

import CVAvatarUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CVAvatarUploadContainer'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import SpacingLine from '../../../SpacingLine'

import {
  AddUserFormCustomDescription,
  AddUserFormCustomTitle,
  AddUserFormNameAndSurnameContainer, CreatorCvStepOneEditorFieldContainer
} from './components'

import translationsList from '../../../../stabs/translationsList'
import { CreatorCvStepOneDescriptionContainer } from '../../../../pages/CreatorCvSteps/StepOne/components'
import TextAreaField from '../../FormFields/TextAreaField'
import MultiSelectDropDown from '../../FormBasicElements/MultiSelectDropDown'
import ApiService from '../../../../store/services/ApiService'
import { forwardMailchimp } from '../../../../store/api/user'

const Test = (errors) => {
  var FormElement = document.getElementById('FormStart');

if( Object.keys(errors).length != 0 && FormElement) {
  var elementPosition = FormElement.offsetTop;

  window.scrollTo({
    top: elementPosition - 70,
    behavior: "smooth"
  });
}
}

const AddUserForm = ({
  apiError,
  errors,
  isEditing,
  handleBlur,
  handleChange,
  handleField,
  handleMultiSelect,
  touched,
  values
}) => {
  const sendToMailchimp = useDebouncedCallback(() => {
    if (/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/.test(values.email) && !isEditing) {
      ApiService.fetch(forwardMailchimp({ email: values.email }))
        .catch((e) => {
          console.error(e)
        })
    }
  }, 1000)

  const handleBlurEmail = (...args) => {
    handleBlur(...args)

    sendToMailchimp.callback()
  }

Test(errors)
  return (
  <FormPanel errors={apiError} data-test='CreatorCvStepOneFormPanelBaseData'>
      <CVAvatarUploadContainer
        error={errors.avatar}
        touched={touched.avatar}
        value={values.avatar}
        handleField={handleField}
        id='test'
      />

      <InputField
        data-test='userSocialMediaPortfolio'
        errorMessage={errors.position}
        hasError={errors.position && touched.position}
        id='userPosition'
        label='Stanowisko'
        name='position'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Stanowisko, na które rekrutujesz'
        value={values.position}
      />

    <FormTwoColumns data-test='CreatorCvStepOneFormTwoColumnsPersonalData'>
      <InputField
        data-test='CreatorCvStepOneUserPhone'
        errorMessage={errors.phone}
        hasError={errors.phone && touched.phone}
        id='userPhone'
        label={translationsList.form.userDataFormLabels.userPhoneLabel}
        name='phone'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz swój numer telefonu…'
        value={values.phone}
      />

      <div id="CVemail">
      <InputField
        data-test='CreatorCvStepOneUserEmail'
        errorMessage={errors.email}
        hasError={errors.email && touched.email}
        id='userEmail'
        label={translationsList.form.userDataFormLabels.userEmailLabel}
        name='email'
        onBlur={handleBlurEmail}
        onChange={handleChange}
        placeholder='Wpisz swój adres e-mail'
        required
        type='email'
        value={values.email}
      />
      </div>

      <InputField
        data-test='CreatorCvStepOneUserDateOfBirth'
        errorMessage={errors.birthday}
        hasError={errors.birthday && touched.birthday}
        id='birthday'
        label={translationsList.form.userDataFormLabels.userDateOfBirthLabel}
        name='birthday'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='RRRR-MM-DD'
        value={values.birthday}
      />

      <InputField
        data-test='CreatorCvStepOneUserNationality'
        errorMessage={errors.nationality}
        hasError={errors.nationality && touched.nationality}
        id='userNationality'
        label={translationsList.form.userDataFormLabels.userNationalityLabel}
        name='nationality'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='np. Polskie'
        value={values.nationality}
      />
    </FormTwoColumns>

    <br />

    <CreatorCvStepOneEditorFieldContainer data-test='CreatorCvStepOneEditorFieldContainer' id="celZawodowy">
      <TextAreaField
        label={translationsList.form.userDataFormLabels.userCustomTextLabel}
        placeholder='Co Cię pasjonuje? Co kieruje Tobą, gdy podejmujesz zawodowe wyzwania? Jakie są Twoje najmocniejsze strony?'
        data-test='CreatorCvStepOneUserCustomText'
        errorMessage={errors.summary}
        hasError={errors.summary && touched.summary}
        name='summary'
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.summary}
      />
    </CreatorCvStepOneEditorFieldContainer>

    <SpacingLine
      data-test='CreatorCvStepOneSpacingLineUserLocation'
      isFixed
      option='SizeS'
    />

    <FormTwoColumns data-test='formTwoColumnsThree'>
      <InputField
        data-test='CreatorCvStepOneUserCity'
        errorMessage={errors.cityOfLiving}
        hasError={errors.cityOfLiving && touched.cityOfLiving}
        id='userCity'
        label={translationsList.form.userDataFormLabels.userCityLabel}
        name='cityOfLiving'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz miasto, w którym aktualnie mieszkasz…'
        value={values.cityOfLiving}
      />

      <InputField
        data-test='CreatorCvStepOneUserCountry'
        errorMessage={errors.country}
        hasError={errors.country && touched.country}
        id='userCountry'
        label={translationsList.form.userDataFormLabels.userCountryLabel}
        name='country'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz kraj w którym żyjesz...'
        value={values.country}
      />
    </FormTwoColumns>

    <SpacingLine
      data-test='CreatorCvStepOneSpacingLineSocialMedia'
      isFixed
      option='SizeS'
    />

    <AddUserFormCustomTitle data-test='CreatorCvStepOneCustomTitleSocialMedia'>{translationsList.form.userDataFormLabels.userSocialMediaTitleLabel}</AddUserFormCustomTitle>

    <AddUserFormCustomDescription data-test='CreatorCvStepOneCustomDescriptionSocialMedia'>{translationsList.form.userDataFormLabels.userSocialMediaDescriptionText}</AddUserFormCustomDescription>

    <FormTwoColumns data-test='CreatorCvStepOneFormTwoColumnsSocialMedia'>
    <div id="linkedIn">
      <InputField
        data-test='CreatorCvStepOneUserSocialMediaLinkedin'
        errorMessage={errors.linkedinUrl}
        hasError={errors.linkedinUrl && touched.linkedinUrl}
        id='userSocialMediaLinkedin'
        label='Linkedin'
        name='linkedinUrl'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Podaj cały link'
        value={values.linkedinUrl}
      />
    </div>

      <InputField
        data-test='CreatorCvStepOneUserSocialMediaPortfolio'
        errorMessage={errors.portfolioUrl}
        hasError={errors.portfolioUrl && touched.portfolioUrl}
        id='userSocialMediaPortfolio'
        label='Portfolio'
        name='portfolioUrl'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Podaj cały link'
        value={values.portfolioUrl}
      />
    </FormTwoColumns>
  </FormPanel>
)}

export default AddUserForm

// TODO Add onClick + Fix Languages
