import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  PurpleColorL,
  WhiteColor
} from '../../../assets/styles/colors'

export const RecruiterBoxContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`

export const RecruiterBoxImageContainer = styled.div`
  display: flex;
  justify-content: center;

  & div {
    padding: 0;
  }

  & img {
    border-radius: 20px;
  }

  @media (min-width: 1200px) {
    height: 240px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 220px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 200px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 180px;
  }

  @media (max-width: 575px) {
    height: 160px;
  }
`

export const RecruiterBoxContent = styled.div``

export const RecruiterBoxNameAndSurnameContainer = styled.div`
  margin: 20px 0 0;

  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
`

export const RecruiterBoxNameAndSurname = styled.h4`
  color: ${PurpleColor};
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const RecruiterBoxPositionContainer = styled.div`
  margin: 6px 0 0;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RecruiterBoxPosition = styled.span`
  color: ${PurpleColorL};
  font-size: 12px;
`

export const RecruiterBoxCompanyContainer = styled.div`
  margin: 6px 0 0;
`

export const RecruiterBoxCompany = styled.h6`
  color: ${PurpleColorL};
  font-size: 14px;

  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
`

export const RecruiterBoxButtonContainer = styled.div`
  margin: 20px 0 0;
`
