import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { flagsActions, flagsSelectors } from '../../../../store/ducks/flags'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddFastRecruitmentForm from '../../../../components/Form/FormComponents/AddFastRecruitmentForm'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { toast } from 'react-toastify'

const ChangeQrContainer = () => {
  const showInfo = useSelector(flagsSelectors.getFlag('qrInfo'))
  const myCompany = useSelector(myCompanySelectors.getMyCompany)
  const dispatch = useDispatch()

  return (
    <AddFastRecruitmentForm
      hasQuickRecruitment={myCompany.hasQuickRecruitment}
      onLearnMore={() => dispatch(flagsActions.changeFlag('qrInfo', !showInfo))}
      onSubmit={() => {
        ApiService
          .fetch(companyAPI.updateMe({ hasQuickRecruitment: !myCompany.hasQuickRecruitment }))
          .then(data => {
            dispatch(myCompanyActions.fetchMyCompanySuccess(data))
          })
          .catch(() => {
            toast.error('Nastąpił błąd podczas wysyłania zgłoszenia do szybkich rekrutacji!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })
          })
      }}
      showInfo={showInfo}
    />
  )
}

export default ChangeQrContainer
