import ApiService from '../services/ApiService'

export const getAllValues = () => new ApiService('GET', '/values')

export const getAllJobOffers = () => new ApiService('GET', '/job-offers')
export const addJobOffer = (body) => new ApiService('POST', '/job-offers', body, { withAuth: true, allowGhost: true })
export const updateJobOffer = (jobOfferId, body) => new ApiService('PUT', `/job-offers/${jobOfferId}`, body, { withAuth: true, allowGhost: true })
export const deleteJobOffer = (jobOfferId) => new ApiService('DELETE', `/job-offers/${jobOfferId}`, {}, { withAuth: true, allowGhost: true })

export const createMyCV = (body) => new ApiService('POST', '/cv', body, { optionalAuth: true })
export const getMyCv = (id) => new ApiService('GET', `/cv/${id}`, {}, { optionalAuth: true })
export const getMyCvs = () => new ApiService('GET', '/cv', {}, { optionalAuth: true })
export const updateMyCv = (id, body) => new ApiService('PUT', `/cv/${id}`, body, { optionalAuth: true })
export const deleteMyCV = (id) => new ApiService('DELETE', `/cv/${id}`, {}, { optionalAuth: true })

export const addLanguage = (id, body) => new ApiService('POST', `/cv/${id}/languages`, body, { withAuth: true })
export const updateLanguage = (id, languageId, body) => new ApiService('PUT', `/cv/${id}/languages/${languageId}`, body, { withAuth: true })
export const deleteLanguage = (id, languageId) => new ApiService('DELETE', `/cv/${id}/languages/${languageId}`, {}, { withAuth: true })

export const addSkill = (id, body) => new ApiService('POST', `/cv/${id}/skills`, body, { withAuth: true })
export const updateSkill = (id, skillId, body) => new ApiService('PUT', `/cv/${id}/skills/${skillId}`, body, { withAuth: true })
export const deleteSkill = (id, skillId) => new ApiService('DELETE', `/cv/${id}/skills/${skillId}`, {}, { withAuth: true })

export const addEducation = (id, body) => new ApiService('POST', `/cv/${id}/educations`, body, { withAuth: true })
export const updateEducation = (id, educationId, body) => new ApiService('PUT', `/cv/${id}/educations/${educationId}`, body, { withAuth: true })
export const deleteEducation = (id, educationId) => new ApiService('DELETE', `/cv/${id}/educations/${educationId}`, {}, { withAuth: true })

export const addJob = (id, body) => new ApiService('POST', `/cv/${id}/jobs`, body, { withAuth: true })
export const updateJob = (id, educationId, body) => new ApiService('PUT', `/cv/${id}/jobs/${educationId}`, body, { withAuth: true })
export const deleteJob = (id, educationId) => new ApiService('DELETE', `/cv/${id}/jobs/${educationId}`, {}, { withAuth: true })

export const addCourse = (id, body) => new ApiService('POST', `/cv/${id}/courses`, body, { withAuth: true })
export const updateCourse = (id, courseId, body) => new ApiService('PUT', `/cv/${id}/courses/${courseId}`, body, { withAuth: true })
export const deleteCourse = (id, courseId) => new ApiService('DELETE', `/cv/${id}/courses/${courseId}`, {}, { withAuth: true })


export const registerCA = (body) => new ApiService('POST', '//dkoczula.pl/wp-admin/admin-ajax.php?action=cvw', body, { withAuth: false, allowGhost: true, external: true })