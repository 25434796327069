import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../Buttons/ButtonComponent'
import Dropdown from '../Form/FormBasicElements/Dropdown'
import Icon from '../Form/FormBasicElements/Icon'
import IconAndTextElements from '../DropdownElements/DropdownMenus/IconAndTextElements'
import InternalOrExternal from '../Buttons/InternalOrExternal'
import LinkComponent from '../Buttons/LinkComponent'
import Navigation from '../Navigation'
import TopNavButton from '../DropdownElements/DropdownButtons/TopNavButton'

import {
  HeaderContainer,
  HeaderLogoContainer,
  HeaderLogoDesktopContainer,
  HeaderLogoMobileContainer,
  HeaderRightSideButtonContainer,
  HeaderRightSideContainer,
  HeaderRightSideContent,
  HeaderRightSideLinkAndButtonContainer,
  HeaderRightSideLinkContainer,
  HeaderRightSideNavigationContainer,
  HeaderRightSideNavigationContent,
  HeaderRightSideLogged
} from './Atoms'

import logoMobilePath from '../../assets/icons/logo_heart.svg'
import logoPath from '../../assets/icons/logo.svg'
import logoCvWorksPath from '../../assets/icons/cvworks-logo.svg'
import translationsList from '../../stabs/translationsList'
import userIconPath from '../../assets/icons/subtitle/subtitle_user.svg'
import { userZoneMenuList } from './consts'

const Header = ({
  creatorCvPage,
  eventPage,
  isLoggedOn,
  menuItemsList,
  userData,
  withNav
}) => (
  <HeaderContainer data-test='HeaderContainer'>
    

    <HeaderRightSideContainer data-test='HeaderRightSideContainer'>
      <HeaderRightSideContent data-test='HeaderRightSideContent'>
        <HeaderRightSideNavigationContainer data-test='HeaderRightSideNavigationContainer'>
          {
            withNav && (
              <HeaderRightSideNavigationContent data-test='HeaderRightSideNavigationContent'>
                <Navigation
                  data-test='HeaderNavigation'
                  itemsList={menuItemsList}
                />
              </HeaderRightSideNavigationContent>
            )
          }
        </HeaderRightSideNavigationContainer>

        <HeaderLogoContainer data-test='HeaderLogoContainer'>
      <InternalOrExternal
        data-test='HeaderInternalOrExternal'
        link='/'
        type='internal'
      >
        <HeaderLogoDesktopContainer data-test='HeaderLogoDesktopContainer'>
          <Icon
            data-test='HeaderLogoDesktopIcon'
            icon={Number(process.env.REACT_APP_CV) === 0 ? logoPath : logoCvWorksPath}
          />
        </HeaderLogoDesktopContainer>

        <HeaderLogoMobileContainer data-test='HeaderLogoMobileContainer'>
          <Icon
            data-test='HeaderLogoMobileIcon'
            icon={logoMobilePath}
          />
        </HeaderLogoMobileContainer>
      </InternalOrExternal>
    </HeaderLogoContainer>

        {
          isLoggedOn
            ? (
            <HeaderRightSideLogged>
              <Dropdown
                Button={TopNavButton}
                MenuContent={IconAndTextElements}
                buttonProps={userData}
                data-test='isLoggedOnDropdown'
                disabled={false} // TODO
                menuContentProps={{
                  itemsList: userZoneMenuList
                }}
                menuContentRight='0'
                containerMinWidth={'200px'}
              />

              <LinkComponent
                colorText='purpleColorM'
                colorStroke='purpleColorM'
                data-test='isLoggedOffLinkComponent'
                fontSize='sizeXL'
                label={'Stwórz CV'}
                link='/kreator-cv/podstawowe-dane'
                type='internal'
              />
            </HeaderRightSideLogged>
            )

            : (
              <>
                <HeaderRightSideLinkAndButtonContainer data-test='HeaderRightSideLinkAndButtonContainer'>
                  <HeaderRightSideLinkContainer data-test='HeaderRightSideLinkContainer'>
                    <LinkComponent
                      colorText='purpleColorM'
                      colorStroke='purpleColorM'
                      data-test='isLoggedOffLinkComponent'
                      fontSize='sizeXL'
                      iconBefore={userIconPath}
                      label={translationsList.login}
                      link='/logowanie'
                      type='internal'
                    />

                    <LinkComponent
                      colorText='purpleColorM'
                      colorStroke='purpleColorM'
                      data-test='isLoggedOffLinkComponent'
                      fontSize='sizeXL'
                      label={'Stwórz CV'}
                      link='/rejestracja'
                      type='internal'
                    />
                  </HeaderRightSideLinkContainer>

                  {/*<HeaderRightSideButtonContainer data-test='HeaderRightSideButtonContainer'>
                    {
                      eventPage && (
                        <ButtonComponent
                          bgColor='pinkColor'
                          color='whiteColor'
                          data-test='eventPageIsLoggedOffButtonComponent'
                          fontBold
                          fullWidth
                          label={translationsList.register}
                          link='/rejestracja'
                          size='sizeM'
                          type='internal'
                        />
                      )
                    }
                  </HeaderRightSideButtonContainer>*/}
                </HeaderRightSideLinkAndButtonContainer>
              </>
            )
        }
      </HeaderRightSideContent>
    </HeaderRightSideContainer>
  </HeaderContainer>
)

export default Header

Header.propTypes = {
  creatorCvPage: PropTypes.bool,
  eventPage: PropTypes.bool,
  isLoggedOn: PropTypes.bool,
  menuItemsList: PropTypes.arrayOf(
    PropTypes.shape({
      fontBold: PropTypes.bool,
      inNewWindow: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.string
    })
  ),
  userData: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.string,
    isMenuOpen: PropTypes.bool,
    name: PropTypes.string,
    position: PropTypes.string,
    surname: PropTypes.string
  }),
  withNav: PropTypes.bool
}

Header.defaultProps = {
  creatorCvPage: false,
  eventPage: true,
  isLoggedOn: false,
  userData: {},
  withNav: false
}
