import React from 'react'
import PropTypes from 'prop-types'

import LinkComponent from '../../../Buttons/LinkComponent'

import {
  FooterMenuItemListElement
} from './components'

const FooterMenuItem = ({
  inNewWindow,
  label,
  link,
  type,
  footer
}) => (
  <>
    {label &&
      <FooterMenuItemListElement>
        <LinkComponent
          colorText='purpleColorM'
          display='inline-flex'
          fontSize='sizeL'
          inNewWindow={inNewWindow}
          label={label}
          link={link}
          type={type}
          footer={footer}
        />
      </FooterMenuItemListElement>}
  </>
)

export default FooterMenuItem

FooterMenuItem.propTypes = {
  inNewWindow: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  footer: PropTypes.bool,
  type: PropTypes.oneOf(['external', 'internal', 'onClick'])
}
