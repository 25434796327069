import styled from 'styled-components'

export const AddExperienceFormCheckBoxWithTextLinkTextContainer = styled.div`
  margin: 10px 0 0;
  text-align: right;
`

export const AddExperienceFormEditorFieldContainer = styled.div`
  & .rdw-inline-wrapper [title="Strikethrough"],
  & .rdw-inline-wrapper [title="Monospace"],
  & .rdw-block-wrapper,
  & .rdw-fontsize-wrapper,
  & .rdw-fontfamily-wrapper,
  & .rdw-list-wrapper [title="Indent"],
  & .rdw-list-wrapper [title="Outdent"],
  & .rdw-text-align-wrapper [title="Center"],
  & .rdw-text-align-wrapper [title="Right"],
  & .rdw-colorpicker-wrapper,
  & .rdw-link-wrapper [title="Unlink"],
  & .rdw-embedded-wrapper,
  & .rdw-emoji-wrapper,
  & .rdw-image-wrapper,
  & .rdw-remove-wrapper {
    display: none;
  }
`
