import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { OutsideSliderContainer } from './Atoms'

import { defaultSettings } from './consts'

const OutsideSlider = ({
  children,
  overrideSettings,
  withDots
}) => (
  <OutsideSliderContainer
    data-test='OutsideSliderContainer'
    withDots={withDots}
  >
    <Slider
      data-test='Slider'
      {...defaultSettings}
      {...overrideSettings}
    >
      {children}
    </Slider>
  </OutsideSliderContainer>
)

export default OutsideSlider

OutsideSlider.propTypes = {
  children: PropTypes.any,
  overrideSettings: PropTypes.object,
  withDots: PropTypes.bool
}
