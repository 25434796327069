import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../Buttons/ButtonComponent/'

import {
  BannerItemButtonContainer,
  BannerItemContainer,
  BannerItemDescription,
  BannerItemTextContainer,
  BannerItemTitle
} from './components'

const BannerItem = ({
  buttonLabel,
  description,
  link,
  title
}) => (
  <BannerItemContainer>
    <BannerItemTextContainer>
      {title &&
        <BannerItemTitle>{title}</BannerItemTitle>}

      {description &&
        <BannerItemDescription>{description}</BannerItemDescription>}
    </BannerItemTextContainer>

    <BannerItemButtonContainer>
      <ButtonComponent
        bgColor='pinkColor'
        color='whiteColor'
        fontBold
        label={buttonLabel}
        size='sizeL'
        type='onClick'
        onClick={() => {
          window.location.href = link
        }}
      />
    </BannerItemButtonContainer>
  </BannerItemContainer>
)

export default BannerItem

BannerItem.propType = {
  buttonLabel: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string
}
