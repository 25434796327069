import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ButtonSwitch from '../../../components/Buttons/ButtonSwitch'

import { ChooseDayWrapper } from './components'
import { flagsSelectors, flagsActions } from '../../../store/ducks/flags'

import translationsList from '../../../stabs/translationsList'

const ChooseDayContainer = () => {
  const selectedDay = useSelector(flagsSelectors.getFlag('selectDay'))

  const dispatch = useDispatch()
  return (
    <ChooseDayWrapper>
      <ButtonSwitch
        isActive={selectedDay === 'day-1'}
        label={translationsList.days.day1}
        onClick={() => dispatch(flagsActions.changeFlag('selectDay', 'day-1'))}
      />

      <ButtonSwitch
        isActive={selectedDay === 'day-2'}
        label={translationsList.days.day2}
        onClick={() => dispatch(flagsActions.changeFlag('selectDay', 'day-2'))}
      />
    </ChooseDayWrapper>
  )
}

export default ChooseDayContainer
