import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMount from '@rooks/use-did-mount'
import { useHistory } from 'react-router-dom'

import ManageCv from '../../components/ManageCv'
import ButtonComponent from '../../components/Buttons/ButtonComponent'
import { ButtonContainer, DownloadsManageCvContainer, DownloadsManageCvSection } from '../../pages/DashboardPage/components'
import Section from '../../components/Section'

import { cvsActions, cvsSelectors } from '../../store/ducks/cvs'
import { cvActions } from '../../store/ducks/my-cv'
import testLogoUrl from '../../assets/img/noPhoto.png'
import cvTemplates from '../../stabs/cvTemplates'

const MyCvContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cvsState = useSelector(cvsSelectors.getCvs)

  useDidMount(() => {
    dispatch(cvsActions.fetchCvsRequested())
  })

  function search(nameKey, myArray){
      for (var i=0; i < myArray.length; i++) {
          if (myArray[i].id === nameKey) {
              return myArray[i];
          }
      }
  }

  // const handleOnCopyClick = id => () => null
  const handleOnDeleteClick = id => () => dispatch(cvsActions.deleteCV(id))
  // const handleOnPreviewClick = id => () => null
  const handleOnEditClick = id => () => {
    sessionStorage.setItem('cv', id)

    history.push('/kreator-cv/podstawowe-dane')
  }

  const handleOnDownloadClick = id => () => {
    sessionStorage.setItem('cv', id)

    history.push('/kreator-cv/szablon')
  }

  const getCvImage = (templateId) => {
    if( !templateId ) {
      return testLogoUrl
    }
    let parts = templateId.split('-');
    let variant = parts.pop();
    let cv_id = parts.join('-');

    let result = search(cv_id, cvTemplates)

    if( !result ) {
      return testLogoUrl
    }

    return result.variants[variant].image
  }

  return (
    <>
      
      <DownloadsManageCvSection>
        <Section
          alignCenter
          data-test='CvCreatorPageChooseCVSection'
          title='Twoje szablony CV'
        />
      </DownloadsManageCvSection>

      <DownloadsManageCvContainer data-test='DownloadsManageCvContainer'>
        {cvsState.data.map((cv, index) => (
          <ManageCv
            alt={cv.title}
            data-test='DownloadsManageCv'
            // image={cvTemplates[cv.templateId]?.image || testLogoUrl}
            image={getCvImage(cv.templateId)}
            key={index}
            onCopyClick={cv.handleOnCopyClick}
            onDeleteClick={handleOnDeleteClick(cv._id)}
            onEditClick={handleOnEditClick(cv._id)}
            onSaveClick={handleOnDownloadClick(cv._id)}
            // onPreviewClick={cv.handleOnPreviewClick}
            title={cv.title}
            withButtons
          />
        ))}
      </DownloadsManageCvContainer>

      <ButtonContainer data-test='DownloadsButtonContainer'>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='DownloadsButtonComponent'
          fontBold
          label='Stwórz nowe CV'
          onClick={() => {
            sessionStorage.removeItem('cv')
            dispatch(cvActions.clearCV())
            history.push('/kreator-cv/podstawowe-dane')
          }}
          size='sizeL'
          type='onClick'
        />
      </ButtonContainer>
    </>
  )
}

export default MyCvContainer
