import React from 'react'

import ButtonComponent from '../../../../Buttons/ButtonComponent'
import CheckBox from '../../../FormBasicElements/CheckBox'
import Icon from '../../../FormBasicElements/Icon'
import InputField from '../../../FormFields/InputField'
import InternalOrExternal from '../../../../Buttons/InternalOrExternal'
import LinkComponent from '../../../../Buttons/LinkComponent'
import SpacingLine from '../../../../SpacingLine'

import {
  RegisterPanelButtonContainer,
  RegisterPanelCheckBoxLink,
  RegisterPanelContent,
  RegisterPanelLinkContainer,
  RegisterPanelLogoContainer,
  RegisterPanelSpan,
  RegisterPanelTextLinkTextBold,
  RegisterPanelTextLinkTextContainer,
  RegisterPanelTextLinkTextSpacingContainer
} from './components'

import { FormPanelErrorsContainer } from '../../../FormWrappers/FormPanel/components'

import logoPath from '../../../../../assets/icons/logo.svg'
import logoCvWorksPath from '../../../../../assets/icons/cvworks-logo.svg'
import translationsList from '../../../../../stabs/translationsList'

const RegisterPanel = ({
  apiError,
  checkboxes,
  displayValidationError,
  errors,
  handleBlur,
  handleChange,
  handleCheckbox,
  handleSubmit,
  handleEmail,
  isLoading,
  isSubmitting,
  isUser,
  touched,
  values
}) => (
  <>
    <RegisterPanelLogoContainer>
      <InternalOrExternal
        data-test='logo'
        link='/'
        type='internal'
      >
        <Icon
          icon={Number(process.env.REACT_APP_CV) === 0 ? logoPath : logoCvWorksPath}
        />
      </InternalOrExternal>
    </RegisterPanelLogoContainer>

    <SpacingLine option='NoMargin' />

    <RegisterPanelContent>
      {
        apiError && (
          <FormPanelErrorsContainer>
            {apiError}
          </FormPanelErrorsContainer>
        )
      }

      {
        displayValidationError.hasError &&
          <FormPanelErrorsContainer>
            {displayValidationError.error}
          </FormPanelErrorsContainer>
      }

      <form onSubmit={handleSubmit}>
        <InputField
          data-test='email'
          errorMessage={errors.email}
          hasError={errors.email && touched.email}
          id='email'
          label='e-mail'
          name='email'
          onBlur={handleBlur}
          onChange={handleEmail}
          placeholder={translationsList.expoRegister.emailPlaceholder}
          required
          type='email'
          value={values.email}
        />

        <InputField
          data-test='password'
          errorMessage={errors.password}
          hasError={errors.password && touched.password}
          id='password'
          label={translationsList.expoRegister.passwordLabel}
          name='password'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.passwordPlaceholder}
          required
          type='password'
          value={values.password}
        />

        

        <RegisterPanelButtonContainer>
          <ButtonComponent
            bgColor='pinkColor'
            color='whiteColor'
            disabled={isSubmitting}
            isLoading={isLoading}
            buttonType='submit'
            fontBold
            fullWidth
            label={translationsList.registryAndLogin.labels.registryLabel}
            size='sizeM'
          />
        </RegisterPanelButtonContainer>
      </form>

      <RegisterPanelLinkContainer>
        <RegisterPanelSpan>{translationsList.registryAndLogin.labels.doYouAlreadyHaveAnAccountLabel}</RegisterPanelSpan>

        <LinkComponent
          colorText='pinkColorM'
          label={translationsList.registryAndLogin.labels.loginLabel}
          link='/logowanie'
          type='internal'
        />
      </RegisterPanelLinkContainer>
    </RegisterPanelContent>
  </>
)

export default RegisterPanel
// TODO Add translations
