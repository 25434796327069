import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'

import ChooseColor from '../../../components/ChooseColor'
import ApiService from '../../../store/services/ApiService'
import * as recruitmentApi from '../../../store/api/recruitment'
import { toast } from 'react-toastify'
import useDidMount from '@rooks/use-did-mount'

import easyScroll from 'easy-scroll';

import { myUserSelectors } from '../../../store/ducks/my-user'

const ChooseColorContainer = () => {
  const cv = useSelector(cvSelectors.getCvData)
  const cvId = sessionStorage.getItem('cv')

  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  const user = useSelector(myUserSelectors.getMyUserData)

  const dispatch = useDispatch()
  const selectColor = (color) => {
    ApiService.fetch(recruitmentApi.updateMyCv(cvId, { color }))
      .then((data) => {
        toast.success('Świetnie! Tak może wyglądać Twoje CV.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        var element = document.getElementById("preview");
        const y = element.getBoundingClientRect().top - 100;

        easyScroll({
            'scrollableDomEle': window,
            'direction': 'bottom',
            'duration': 2000,
            'easingPreset': 'easeInOutCubic',
            'scrollAmount': y,
            onAnimationCompleteCallback: function() {
              if( user.avatar == '' ) {
                // window.location.href += "#preview";
                // window.location.reload();
              }
            }
        });

        

        dispatch(cvActions.fetchCvSuccess(data.cv))
      })
      .catch((error) => {
        console.log(error)
        toast.error('Coś poszło nie tak!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <ChooseColor selected={cv.color} onClick={(color) => selectColor(color)} />
  )
}

export default ChooseColorContainer
