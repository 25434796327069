import slugify from 'slugify'
import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: [],
  display: 63,
  isLoading: true
}

const companiesReducer = createReducer(initialState)({
  [types.LOAD]: (state, action) => ({
    data: [...action.payload
      .filter((item) => item.name !== undefined)
      .map((item) => ({ ...item, slug: slugify(item.name, { lower: true }), jobOffers: item.jobOffers === undefined ? [] : item.jobOffers }))
    ],
    display: 63,
    isLoading: false
  }),

  [types.SHOW_LESS]: (state) => {
    if (state.display - 5 <= 5) {
      return {
        ...state,
        display: 5
      }
    }

    return {
      ...state,
      display: state.display - 5
    }
  },
  [types.SHOW_MORE]: (state) => {
    if (state.display + 5 > state.data.length) {
      return {
        ...state,
        display: state.data.length
      }
    }

    return {
      ...state,
      display: state.display + 5
    }
  }
})

export default companiesReducer
