import styled from 'styled-components'

export const CreatorCvStepThreeChooseColorContainer = styled.div`
  display: inline-flex;
  margin-top: 30px;
`

export const CreatorCvStepThreeDescriptionContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

export const CreatorCvStepThreeIframe = styled.iframe`
  border: 1px solid #f3f3f3;
  box-shadow: 0 10px 15px 0 rgba(217,209,255,0.3);
  display: block;
  height: 810px;
  margin: 0 auto 60px;
  max-width: 810px;
  width: 100%;
`

export const CvWaterMark = styled.div`
  position:relative;

  &:after {
    ${props => {
      switch (props.subscriptonIsActive) {
        case true: return 'content: none;'
        case false: return "content: 'CVworks';"
        default: return ''
      }
    }}
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(331deg);
    -moz-transform: rotate(331deg);
    -o-transform: rotate(331deg);
    transform: rotate(331deg);
    font-size: 12em;
    color: rgba(224, 5,123, 0.2);
  }

  &:before {
    ${props => {
      switch (props.subscriptonIsActive) {
        case true: return 'content: none;'
        case false: return "content: 'Napis zniknie po wybraniu i zrealizowaniu płatności za wybrany pakiet.';"
        default: return ''
      }
    }}
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(331deg);
    -moz-transform: rotate(331deg);
    -o-transform: rotate(331deg);
    transform: rotate(331deg);
    font-size: 1.2em;
    color: rgba(224, 5,123, 0.2);
    top: 4em;
    left: 2em
  }
`

export const CreatorCvStepThreeButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & div + div {
    margin-left: 20px;
  }

  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const CvPreviewContainer = styled.div`
  margin-bottom: 80px;
  box-shadow: 0 10px 15px 0 rgba(217,209,255,0.3);
  border: 1px solid #f3f3f3;
`

export const HalfWidth = styled.div`
  @media (min-width: 890px) {
    width: 50%;
    float: left;
  }

  ${props => {
    switch (props.selected) {
      case undefined: return 'display: none;'
      default: return ''
    }
  }}
`

export const Clear = styled.div`
  clear: both;
`

export const RodoContainer = styled.div`
  margin-bottom: 50px;
`
