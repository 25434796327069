import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: {
    color: '',
    cvLanguage: '',
    email: '',
    courses: [],
    educations: [],
    hobbies: '',
    jobs: [],
    languages: [],
    skills: [],
    socials: [],
    summary: '',
    title: '',
    position: '',
    templateId: '',
    portfolioUrl: '',
    rodo: ''
  },
  error: null,
  isLoading: false,
  isFetched: false,
  isHtmlFetched: false
}

const cvReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: initialState.data, isLoading: false, error: action.payload, isFetched: false, isHtmlFetched: false }),
  [types.FETCH_REQUESTED]: () => ({ data: initialState.data, isLoading: true, error: null, isFetched: false, isHtmlFetched: false }),
  [types.CLEAR]: () => initialState,
  [types.SET_HTML_AS_FETCHED]: (state) => ({ ...state, isHtmlFetched: true }),
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = { ...initialState.data, ...action.payload.data }

    return { data, isLoading: false, error: null, isFetched: true, isHtmlFetched: false }
  }
})

export default cvReducer
