import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../../Buttons/ButtonComponent'
import FormPanel from '../../FormWrappers/FormPanel'
import LinkComponent from '../../../Buttons/LinkComponent'
import TextComponent from '../../FormBasicElements/TextComponent'

import {
  AddFastRecruitmentFormButtonContainer,
  AddFastRecruitmentFormLinkContainer
} from './components'

const AddFastRecruitmentForm = ({
  hasQuickRecruitment,
  onLearnMore,
  onSubmit,
  showInfo
}) => (
  <FormPanel
    errors=''
    hasApiError={false}
  >
    <TextComponent
      data-test='addFastRecruitmentFormOne'
      text='Strefa Szybkich Rekrutacji to specjalnie wyznaczone miejsce na targach, w której Wystawca może przeprowadzić wstępną rekrutację wyjątkowo obiecującego kandydata, którego CV zostało wcześniej zweryfikowane na platformie CareerEXPO.pl. To wyjątkowa szansa na skrócenie czasu rekrutacji i dotarcie do jak największej liczby potencjalnych współpracowników! Szybka Rekrutacja kończy się zaproszeniem na rozmowę kwalifikacyjną do firmy albo informacją zwrotną o brakujących kompetencjach. Kandydat po odbyciu Szybkiej Rekrutacji wychodzi z perspektywą pracy albo jasną informacją, co należy zrobić, aby w przyszłości otrzymać wymarzoną pracę.'
    />

    <AddFastRecruitmentFormButtonContainer>
      <ButtonComponent
        align='center'
        bgColor={!hasQuickRecruitment ? 'pinkColor' : null}
        border={hasQuickRecruitment ? 'pinkColorS' : null}
        color={hasQuickRecruitment ? 'pinkColorM' : 'whiteColor'}
        display='inline-flex'
        fontBold
        label={hasQuickRecruitment ? 'Zgłoszono. Kliknij, aby się wypisać.' : 'Zgłoś firmę do udziału w Szybkiej Rekrutacji'}
        onClick={onSubmit}
        size='sizeL'
      />
    </AddFastRecruitmentFormButtonContainer>

    <AddFastRecruitmentFormLinkContainer>
      <LinkComponent
        align='center'
        colorText='purpleColor'
        display='inline-flex'
        fontSize='sizeM'
        label='lub dowiedz się więcej'
        onClick={onLearnMore}
      />
    </AddFastRecruitmentFormLinkContainer>

    {
      showInfo && (
        <TextComponent
          data-test='addFastRecruitmentFormTwo'
          text='Szybka Rekrutacja to program dla firm, które szybko i skutecznie chcą zapoznać się z potencjalnymi kandydatami jeszcze podczas wydarzenia. Wystarczy, że Wystawca wyrazi chęć uczestnictwa oraz zweryfikuje CV aplikujących do niej osób, a następnie umówi się na konkretną godzinę podczas dnia targowego.Ilość możliwych rekrutacji w ciągu dnia zależna jest od wybranego pakietu (Comfort - 5 rekrutacji, Comfort plus - 8 rekrutacji, Prestige - 10 rekrutacji). Istnieje możliwość zwiększenia liczby rekrutacji, jak i wykupienia tej aktywności dla stoisk typu Standard, a także rezerwacji dedykowanego pomieszczenia. W sprawie szczegółów, prosimy kontaktować się z opiekunem handlowym.'
        />
      )
    }
  </FormPanel>
)

export default AddFastRecruitmentForm

AddFastRecruitmentForm.propTypes = {
  errors: PropTypes.string,
  hasApiError: PropTypes.bool
}

// TODO Fix Translations
