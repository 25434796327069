import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'

import {
  ButtonCircleContainer,
  ButtonCircleIconContainer
} from './components'

const ButtonCircle = ({
  icon,
  onClick
}) => (
  <ButtonCircleContainer
    data-test='ButtonCircleContainer'
    onClick={onClick}
  >
    <ButtonCircleIconContainer data-test='ButtonCircleIconContainer'>
      <Icon
        data-test='ButtonCircleIcon'
        icon={icon}
      />
    </ButtonCircleIconContainer>
  </ButtonCircleContainer>
)

export default ButtonCircle

ButtonCircle.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func
}

ButtonCircle.defaultProps = {
  onClick: () => console.log('Pass me callback') // TODO test me
}
