import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'

import { trainingsActions, trainingsSelectors } from '../../../../store/ducks/trainings'
import * as eventAPI from '../../../../store/api/event'
import * as userAPI from '../../../../store/api/user'
import { myUserActions } from '../../../../store/ducks/my-user'

import ApiService from '../../../../store/services/ApiService'

import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import ButtonSwitch from '../../../../components/Buttons/ButtonSwitch'
import LinkComponent from '../../../../components/Buttons/LinkComponent'
import Subtitle from '../../../../components/Subtitle'
import Trainings from '../../../../components/Trainings'

import {
  FormUserStepTwoButtonContainer,
  FormUserStepTwoButtonSwitchContainer,
  FormUserStepTwoTrainingsContainer
} from '../../../../pages/PlatformUserSteps/UserStepTwo/components'
import { TrainingsText } from './components'

import translationsList from '../../../../stabs/translationsList'

const FormTrainingsContainer = ({ history }) => {
  const [showAll, toggleShowAll] = useState(true)

  useEffect(() => {
    window.mt('send', 'pageview', { userstep2: true })
  })

  const dispatch = useDispatch()
  const trainings = useSelector(trainingsSelectors.getTrainingsAll)
  const trainingsEnrolled = useSelector(trainingsSelectors.getTrainingsEnrolled)

  const signUp = (key) => {
    ApiService.fetch(eventAPI.enrollOnTraining(key))
      .then((data) => {
        dispatch(trainingsActions.toggleEnrollment(key))
        toast.success(translationsList.userStepTwo.signUpSuccess, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        window.mt('send', 'pageview', { zapisanoszkolenie: trainingsEnrolled.length + 1 })
      })
      .catch((error) => {
        if (error.details.msg === 'There is no space on training') {
          toast.error(translationsList.userStepTwo.noPlaces, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        }
      })
  }

  const unSign = (key) => {
    ApiService.fetch(eventAPI.unenrollFromTraining(key))
      .then(() => {
        dispatch(trainingsActions.toggleEnrollment(key))
        toast.success(translationsList.userStepTwo.unSignUpSuccess, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        window.mt('send', 'pageview', { zapisanoszkolenie: trainingsEnrolled.length - 1 })
      })
      .catch(() => {
        toast.error(translationsList.userStepTwo.error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  const handleSubmit = () => {
    ApiService
      .fetch(userAPI.updateMe({ step: 'userStep3' }))
      .then(data => {
        dispatch(myUserActions.fetchMyUserSuccess(data))

        window.mt('send', 'pageview', { userstep3: true })
        history.push('/platforma?pobierz-bilet')
      })
      .catch(() => {
        toast.error(translationsList.userStepTwo.nextPageError, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  const trainingsDay1 = trainings.filter((training) => training.day === 'day-1')
  const trainingsDay2 = trainings.filter((training) => training.day === 'day-2')

  const trainingsEnrolledDay1 = trainingsEnrolled.filter((training) => training.day === 'day-1')
  const trainingsEnrolledDay2 = trainingsEnrolled.filter((training) => training.day === 'day-2')

  return (
    <>
      <FormUserStepTwoButtonSwitchContainer>
        <ButtonSwitch
          isActive={showAll}
          label={translationsList.userStepTwo.allTrainings}
          onClick={() => toggleShowAll(true)}
        />

        <ButtonSwitch
          isActive={!showAll}
          label={translationsList.userStepTwo.saved}
          onClick={() => toggleShowAll(false)}
        />
      </FormUserStepTwoButtonSwitchContainer>

      <Subtitle
        icon='tag'
        label={translationsList.days.day1}
      />

      <FormUserStepTwoTrainingsContainer>
        {
          showAll ? (
            <Trainings
              isEditable
              itemsList={trainingsDay1}
              onClick={(key) => dispatch(trainingsActions.toggleDescription(key))}
              onClickSign={signUp}
              onClickUnsign={unSign}
            />
          )

            : (
              <>
                {
                  trainingsEnrolledDay1.length > 0 ? (
                    <Trainings
                      isEditable
                      itemsList={trainingsEnrolledDay1}
                      onClick={(key) => dispatch(trainingsActions.toggleDescription(key))}
                      onClickSign={signUp}
                      onClickUnsign={unSign}
                    />
                  )

                    : (
                      <>
                        <TrainingsText>{translationsList.userStepTwo.noTrainings}</TrainingsText>

                        <LinkComponent
                          align='left'
                          colorText='purpleColor'
                          display='inline-flex'
                          fontBold
                          fontSize='sizeL'
                          onClick={() => toggleShowAll(true)}
                          label={translationsList.userStepTwo.showTrainings}
                          type='onClick'
                        />
                      </>
                    )
                }
              </>
            )
        }

      </FormUserStepTwoTrainingsContainer>

      <Subtitle
        icon='tag'
        label={translationsList.days.day2}
      />

      <FormUserStepTwoTrainingsContainer>
        {
          showAll ? (
            <Trainings
              isEditable
              itemsList={trainingsDay2}
              onClick={(key) => dispatch(trainingsActions.toggleDescription(key))}
              onClickSign={signUp}
              onClickUnsign={unSign}
            />
          )

            : (
              <>
                {
                  trainingsEnrolledDay2.length > 0 ? (
                    <Trainings
                      isEditable
                      itemsList={trainingsEnrolledDay2}
                      onClick={(key) => dispatch(trainingsActions.toggleDescription(key))}
                      onClickSign={signUp}
                      onClickUnsign={unSign}
                    />
                  )

                    : (
                      <>
                        <TrainingsText>{translationsList.userStepTwo.noTrainings}</TrainingsText>

                        <LinkComponent
                          align='left'
                          colorText='purpleColor'
                          display='inline-flex'
                          fontBold
                          fontSize='sizeL'
                          onClick={() => toggleShowAll(true)}
                          label={translationsList.userStepTwo.showTrainings}
                          type='onClick'
                        />
                      </>
                    )
                }
              </>
            )
        }
      </FormUserStepTwoTrainingsContainer>

      <FormUserStepTwoButtonContainer>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          display='inline-flex'
          fontBold
          label={translationsList.form.labels.buttonNextStepLabel}
          onClick={handleSubmit}
          size='sizeL'
        />
      </FormUserStepTwoButtonContainer>
    </>
  )
}

export default withRouter(FormTrainingsContainer)
