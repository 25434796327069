import React, { useState } from 'react'
import { toast } from 'react-toastify'

import ChooseImageField from '../../../../components/Form/FormFields/ChooseImageField'
import toBase64 from '../../../../utils/toBase64'

const AvatarUploadContainer = ({ value, error, touched, handleField }) => {
  const handleChange = async (file, onSuccess) => {
    const avatar = await toBase64(file)
    handleField('avatar', avatar)
    onSuccess()
    toast.success('Zapisano!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
  }

  return (
      <ChooseImageField
        data-test='CreatorCvStepOneChooseImageFieldBaseData'
        buttonSelectLabel='Wybierz zdjęcie (PNG/JPG)'
        chooseFileButtonDescription=''
        chooseFileTitle=''
        chooseFileButtonFieldlabel='Dodaj Twoje zdjęcie'
        croppedImageHeadingText='Zaznacz obszar swojego zdjęcia, a następnie wyślij plik.'
        errorMessage={error}
        hasError={error && touched}
        image={value}
        isLoading={false}
        onChange={handleChange}
        withHash={false}
        id="yourImage"
      />
  )
}

export default AvatarUploadContainer
