import React from 'react'

import SpacingLine from '../SpacingLine'

import {
  CopyrightsText
} from './components'

import translationsList from '../../stabs/translationsList'

const Copyrights = () => (
  <>
    <SpacingLine option='SizeS' />

    <CopyrightsText>{translationsList.copyright}</CopyrightsText>
  </>
)

export default Copyrights
