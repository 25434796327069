import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import * as eventAPI from '../../store/api/event'
import ApiService from '../../store/services/ApiService'
import { primaryEventSelectors } from '../../store/ducks/primary-event'
import { tagsSelectors } from '../../store/ducks/tags'
import { trainingsActions, trainingsSelectors } from '../../store/ducks/trainings'

import AddedItem from '../../components/List/AddedItem'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import MultiSelectDropDownField from '../../components/Form/FormFields/MultiSelectDropDownField'
import RequiredFieldsText from '../../components/Form/FormBasicElements/RequiredFieldsText'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'
import TextAreaField from '../../components/Form/FormFields/TextAreaField'

import translate from '../../stabs/errorMessagesTranslations'
import handleMultiSelect from '../../utils/forms/handleMultiSelect'
import handleField from '../../utils/forms/handleField'

const AdminTrainingsContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const event = useSelector(primaryEventSelectors.getPrimaryEventData)
  const trainings = useSelector(trainingsSelectors.getTrainingsContent)
  const tags = useSelector(tagsSelectors.getTags)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(state.isEditing ? eventAPI.updateTraining(state.editingItemId, values) : eventAPI.createTraining(event._id, values))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        if (state.isEditing) {
          dispatch(trainingsActions.updateTraining(state.editingItemId, data))
        } else {
          dispatch(trainingsActions.addTraining(data))
        }

        setState({ error: null, isLoading: false, editingItemId: null, isEditing: false })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(eventAPI.removeTraining(itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(trainingsActions.removeTraining(itemId))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = trainings.filter((training) => training._id === itemId)[0]

    const items = {
      title: editingItem.title,
      url: editingItem.url,
      place: editingItem.place,
      day: editingItem.day || '',
      timeStart: editingItem.timeStart,
      timeEnd: editingItem.timeEnd,
      description: editingItem.description,
      tags: editingItem.tags.map((tag) => tag._id),
      limit: editingItem.limit,
      isPayable: false,
      level: 0
    }

    setValues(items)
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const sortItems = (trainings) => trainings.sort((a, b) => {
    const trainingAStart = a.timeStart.split(':')[0]
    const trainingAEnd = a.timeEnd.split(':')[0]
    const trainingBStart = b.timeStart.split(':')[0]
    const trainingBEnd = b.timeEnd.split(':')[0]

    return trainingAStart - trainingBStart || trainingAEnd - trainingBEnd
  })

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: `${item.day === 'day-1' ? 'Dzień 1' : 'Dzień 2'}: ${item.timeStart}-${item.timeEnd} ${item.title}`,
    description: `Miejsce: ${item.place}, Limit: ${item.enrolled} z ${item.limit === 0 ? 'NIELIMITOWANE' : item.limit}, id: ${item._id}`,
    isEditing: state.editingItemId === item._id,
    _id: item._id,
    isPublished: item.isPublished
  }))

  const handleEvent = (id, isPublished) => {
    ApiService
      .fetch(eventAPI.updateTraining(id, { isPublished }))
      .then(data => {
        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(trainingsActions.updateTraining(id, data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
      })
  }

  return (
    <Formik
      initialValues={{
        title: '',
        place: '',
        day: '',
        timeStart: '',
        timeEnd: '',
        description: '',
        tags: [],
        limit: '',
        url: '',
        isPayable: false,
        level: 0
      }}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setValues,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={state.error}
            noMargin
          >
            {mapItemToDisplay(sortItems(trainings)).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickAddToEvent={() => handleEvent(item._id, !item.isPublished)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isInEvent={item.isPublished}
                isLoading={state.isLoading}
              />
            ))}

            <InputField
              data-test='CompanyStreet'
              errorMessage={errors.title}
              hasError={errors.title && touched.title}
              id='CompanyStreet'
              label='Tytuł'
              name='title'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Wpisz tytuł szkolenia...'
              value={values.title}
            />

            <FormTwoColumns>
              <InputField
                data-test='CompanyStreet'
                errorMessage={errors.place}
                hasError={errors.place && touched.place}
                id='CompanyStreet'
                label='Miejsce'
                name='place'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz miejsce szkolenia...'
                value={values.place}
              />

              <InputField
                data-test='CompanyStreet'
                errorMessage={errors.limit}
                hasError={errors.limit && touched.limit}
                id='CompanyStreet'
                label='Limit'
                name='limit'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz limit miejsc na szkolenie (0 oznacza brak limitu)...'
                value={values.limit}
              />

              <MultiSelectDropDownField
                data-test='multiSelectDropDownFieldFive'
                label='Dzień'
                errorMessage={errors.day}
                hasError={errors.day && touched.day}
                name='day'
                itemsList={[{ label: 'Dzień 1', value: 'day-1' }, { label: 'Dzień 2', value: 'day-2' }]}
                onClick={(fieldName, item, isMulti) => { console.log(item); return handleField(fieldName, item.value, { setFieldValue, setFieldTouched }) }}
                placeholder={values.day === '' ? 'Wybierz' : (values.day === 'day-1' ? 'Dzień 1' : 'Dzień 2')}
                value={values.day}
                isMulti={false}
                closeOnClick
              />

              <MultiSelectDropDownField
                data-test='multiSelectDropDownFieldFive'
                label='Tagi'
                errorMessage={errors.tags}
                hasError={errors.tags && touched.tags}
                name='tags'
                itemsList={tags.map((tag) => ({ label: tag.label, value: tag._id }))}
                onClick={(fieldName, item, isMulti) => handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
                placeholder={values.tags.length === 0 ? 'Wybierz' : `Wybrano ${values.tags.length} tagów`}
                value={values.tags}
              />

              <InputField
                data-test='CompanyStreet'
                errorMessage={errors.timeStart}
                hasError={errors.timeStart && touched.timeStart}
                id='CompanyStreet'
                label='Godzina rozpoczęcia'
                name='timeStart'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz godzine rozpoczęcia szkolenia (format: HH-mm)...'
                value={values.timeStart}
              />

              <InputField
                data-test='CompanyStreet'
                errorMessage={errors.timeEnd}
                hasError={errors.timeEnd && touched.timeEnd}
                id='CompanyStreet'
                label='Godzina zakończenia'
                name='timeEnd'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz godzine zakończenia szkolenia (format: HH-mm)...'
                value={values.timeEnd}
              />
            </FormTwoColumns>

            <TextAreaField
              errorMessage={errors.description}
              hasError={errors.description && touched.description}
              id='userCustomText'
              label='Opis szkolenia'
              maxLength={10000}
              name='description'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Opisz szkolnie...'
              required
              value={values.description}
            />

            <InputField
              data-test='url'
              errorMessage={errors.url}
              hasError={errors.url && touched.url}
              id='url'
              label='Link'
              name='url'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Link do szkolenia online'
              value={values.url}
            />

            <RequiredFieldsText />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

export default AdminTrainingsContainer
