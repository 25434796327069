import styled from 'styled-components'

import {Label, Underline} from '../../components/Section/Title/components'
import {DescriptionContainer} from '../../components/Section/Description/components'

import {OutsideSliderContainer} from '../../components/Sliders/OutsideSlider/Atoms'

import chevron from '../../assets/img/chevron.svg'

import {
  PinkColor,
  PurpleColorM
} from '../../assets/styles/colors'

const smallBreakepoints = () => `
  @media (min-width: 1600px) {
    margin: 80px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 70px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 60px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 40px 0 0;
  }
`

const bigBreakepoints = () => `
  @media (min-width: 1600px) {
    margin: 220px 0 0;
  }

  @media (min-width: 1400px) and (max-width: 1599px) {
    margin: 200px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    margin: 180px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 160px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 140px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 120px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 100px 0 0;
  }
`

export const CvCreatorPageChooseCvSectionContainer = styled.div`
  @media (min-width: 1200px) {
    padding: 50px 0 0;
  }

  @media (min-width: 992px) {
    align-items: center;
    display: flex;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 80px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 60px 0 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }
`

export const CvCreatorPageLeftColumn = styled.div`
  @media (min-width: 992px) {
    padding: 0 5% 0 0;
    width: 50%;
  }

  @media (max-width: 767px) {
    ${Label} {
      text-align: center;
    }

    ${DescriptionContainer} {
      text-align: center
    }

    ${Underline} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const CvCreatorPageSubtitleContainer = styled.div`
  & > div {
    justify-content: flex-start;
    margin: 0 0 20px;
  }
`

export const CvCreatorPageButtonContainer = styled.div`
  button {
    margin-top: 15px;
    width: 100%;
    font-size: 22px;

    &[color=pinkColor] {
      color: ${PinkColor};
      border: 2px solid ${PinkColor}
    }
  }

  @media (max-width: 767px) {
    > div {
      justify-content: center;
    }
  }

  ${smallBreakepoints};
`

export const CvCreatorPageRightColumn = styled.div`
  ${OutsideSliderContainer} {
    cursor: pointer;
  }
  .slick-slide {
      cursor: pointer;
    }

  @media (min-width: 992px) {
    width: 50%;
  }

  .slick-dots {
    display: none
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 60px 0 0;
    padding: 0 20%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 40px 0 0;
    padding: 0 10%;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 40px 0 0;
    padding: 0 20px;
  }
`

export const CvCreatorPageGuidlineStepsSectionContainer = styled.div``

export const CvCreatorPageGuidlineStepsContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  @media (max-width: 767px) {
    > div > div > div:nth-child(2),
    > div > div > div:nth-child(3) {
      margin-top: 30px;
    }
  }

  ${smallBreakepoints};
`

export const CvCreatorPageChooseCvContainer = styled.div`
  ${smallBreakepoints}  
`

export const CvCreatorPageTestimonialsContainer = styled.div``

export const CvCreatorPageTestimonialsContent = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  ${smallBreakepoints}
`

export const CvCreatorPageBannerContainer = styled.div`
  ${bigBreakepoints};

  @media (min-width: 1400px) {
    margin: 100px 0 0;
  }
`
export const CvCreatorPageChooseCvContainerButton = styled.div`
  margin-top: 50px;

  > div {
    margin-top: 15px;
  }
`

export const CvCreatorBenefitsBoxes = styled.div`
  margin-top: 150px;
`

export const CvCreatorPageVideoContainer = styled.div`
  margin-top: 50px;

  > div {
    > div {
      > div {
        width: auto;

        &:first-of-type {
          flex: 1;

          > div {
            height: 100%;

            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
`

export const CvCreatorPageVideoContainerRight = styled.div`
  text-align: left;
`
export const CvCreatorPageVideoContainerHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1em
`
export const CvCreatorPageVideoContainerList = styled.div`
  
`

export const CvCreatorPageVideoContainerListElement = styled.div`
  line-height: 2em;
  color: ${PurpleColorM};
  display: flex;
`

export const CvCreatorPageVideoContainerListElementIndex = styled.div`
  margin-right: 10px;
  font-weight: bold;
  color: ${PinkColor}
`

export const CvCreatorPageSolid = styled.div`
  > div {
    > div {
      > div {
        /*width: auto;*/
        display: flex;
        align-items: center;

        &:last-of-type {
          /*flex: 1;*/
        }
      }
    }
`
export const CvCreatorPageSolidList = styled.div`
  text-align: left;
`
export const CvCreatorPageSolidImage = styled.div`

`
export const CvCreatorPageSolidListElement = styled.div`
  line-height: 2em;
  color: ${PurpleColorM};
  display: flex;
  font-size: 1.2em;
  /*align-items: center;*/

  &:before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(${chevron});
    background-size: cover;
    transform: rotate(-90deg);
    flex-shrink: 0;
  }
`
export const BenefitsList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
`

export const BenefitsElement = styled.div`
  line-height: 1em;
  color: ${PurpleColorM};
  display: flex;
  font-size: 1.2em;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px;

  @media screen and (max-width:768px) {
    line-height: 1.5em;
    padding: 10px 0;
  }

  &:before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(${chevron});
    background-size: cover;
    transform: rotate(-90deg) translateX(8px);
    flex-shrink: 0;

    @media screen and (max-width:768px) {
      margin-top: 5px;
    }
  }

`

export const ChooseCvContainerMobile = styled.div`
  padding: 0 20px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  ${OutsideSliderContainer} {
    ul {
      display: none;
    }
  }
`

export const ChooseCvContainerDesktop = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const CvCreatorPageVideoButton = styled.div`
  margin-top: 1em
`

export const PricingContainer = styled.div`
  > div {
    > div {
      > div {
        &:nth-child(1) {
          width: 55%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
`

export const CvShortVideoSectionContainer = styled.div`

`

export const CvShortVideoSectionIframe = styled.div`
  margin-top: 50px;

  iframe {
    width: 70%;
    height: 450px;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 300px;
    }
  }
`