import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import * as recruitmentAPI from '../../../../store/api/recruitment'
import ApiService from '../../../../store/services/ApiService'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddedItem from '../../../../components/List/AddedItem'
import FormPanel from '../../../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../../../components/Form/FormFields/InputField'
import RequiredFieldsText from '../../../../components/Form/FormBasicElements/RequiredFieldsText'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../../stabs/errorMessagesTranslations'
import EditorField from '../../../../components/Form/FormFields/EditorField'
import handleEditor from '../../../../utils/forms/handleField'
import { jobOfferActions } from '../../../../store/ducks/job-offers'

const AddJobOfferFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    const newValues = {
      title: values.title,
      body: values.body,
      applyUrl: values.applyUrl
    }

    ApiService
      .fetch(state.isEditing ? recruitmentAPI.updateJobOffer(state.editingItemId, newValues) : recruitmentAPI.addJobOffer(newValues))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        if (state.isEditing) {
          const newJobOffers = [...company.jobOffers.filter((el) => el._id !== data._id), data]
          dispatch(myCompanyActions.fetchMyCompanySuccess({ ...company, jobOffers: newJobOffers }))
          dispatch(jobOfferActions.updateJobOffer(data))
        } else {
          const newJobOffers = [...company.jobOffers, data]
          dispatch(myCompanyActions.fetchMyCompanySuccess({ ...company, jobOffers: newJobOffers }))
          dispatch(jobOfferActions.addJobOffer(data))
        }
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(recruitmentAPI.deleteJobOffer(itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        const newJobOffers = company.jobOffers.filter((el) => el._id !== itemId)

        dispatch(myCompanyActions.fetchMyCompanySuccess({ ...company, jobOffers: newJobOffers }))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = company.jobOffers.filter((jobOffer) => jobOffer._id === itemId)[0]

    setValues(editingItem)
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: `${item.title}`,
    description: `<a target="_blank" href="/oferty-pracy/${item.slug}">Pokaż oferte</a>`,
    isEditing: state.editingItemId === item._id,
    _id: item._id
  }))

  return (
    <Formik
      initialValues={{ title: '', body: '', applyUrl: '', _id: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={state.error}
            noMargin
          >
            {mapItemToDisplay(company.jobOffers).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isLoading={state.isLoading}
              />
            ))}

            <InputField
              errorMessage={errors.title}
              hasError={errors.title && touched.title}
              label='Tytuł'
              name='title'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Wpisz tytuł oferty...'
              value={values.title}
              required
            />

            <EditorField
              key={values._id}
              errorMessage={errors.body}
              hasError={errors.body && touched.body}
              onChange={(fieldName, value) =>
                handleEditor(fieldName, value, { setFieldValue, setFieldTouched })}
              name='body'
              value={values.body}
              label='Treść'
              placeholder='Wpisz treść'
              required
            />

            <FormTwoColumns>
              <InputField
                errorMessage={errors.applyUrl}
                hasError={errors.applyUrl && touched.applyUrl}
                label='Link do oferty'
                name='applyUrl'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz link do oferty...'
                value={values.applyUrl}
                required
              />
            </FormTwoColumns>

            <RequiredFieldsText />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  body: Yup.string()
    .required('Pole jest wymagane'),
  applyUrl: Yup.string()
    .url('Podany link musi być poprawnym URLem')
    .required('Pole jest wymagane')
})
export default AddJobOfferFormContainer
