import trade from '../../assets/icons/trade_1.svg'
import engineering from '../../assets/icons/industries/inzynieria.svg'
import consulting from '../../assets/icons/industries/konsultng.svg'
import marketing from '../../assets/icons/industries/marketing.svg'
import realEstate from '../../assets/icons/industries/nieruchomosci.svg'
import helpdesk from '../../assets/icons/industries/obsluga-klienta.svg'
import law from '../../assets/icons/industries/prawo-administracja.svg'
import production from '../../assets/icons/industries/produkcja.svg'
import transport from '../../assets/icons/industries/transport.svg'
import sale from '../../assets/icons/industries/sprzedaz.svg'
import tourism from '../../assets/icons/industries/turystyka.svg'

export default (icon) => {
  const icons = {
    it: trade,
    law: law,
    consulting: consulting,
    finance: trade,
    research: trade,
    hr: trade,
    tourism: tourism,
    engineering: engineering,
    marketing: marketing,
    helpdesk: helpdesk,
    production: production,
    transport: transport,
    sale: sale,
    'real-estate': realEstate
  }

  return icons[icon]
}
