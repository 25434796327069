import styled from 'styled-components'

import {
  PurpleColorM,
  PurpleColorXS
} from '../../../../assets/styles/colors'

export const ArticleItemContainer = styled.div`
  text-align: left;

  @media (min-width: 1200px) {
    margin: 0 30px 30px 0;
    width: calc(25% - 30px);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 30px 30px 0;
    width: calc(33.33333% - 30px);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 25px 25px 0;
    width: calc(50% - 25px);
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 0 25px;
  }
`

export const ArticleItemImageContainer = styled.div`
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
`

export const ArticleItemTitleContainer = styled.div``

export const ArticleItemTitle = styled.h2`
  color: ${PurpleColorM};
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`

export const ArticleItemText = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 22px;
  padding: 8px 0 0;
`

export const ArticleItemButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0;

  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
`

export const ArticleItemDateContainer = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 12px 1fr;
`

export const ArticleItemDateIconContainer = styled.div`
  & g {
    fill: ${PurpleColorXS};
    stroke: ${PurpleColorXS};
  }
`

export const ArticleItemDate = styled.div`
  color: ${PurpleColorM};
  font-size: 10px;
  line-height: 16px;
`
