import React from 'react'
import PropTypes from 'prop-types'

import TrainingItem from './TrainingItem'
import TrainingsHeader from './TrainingsHeader'
import { DateTime } from 'luxon'
import isExpoDay from '../../utils/isExpoDay'

const dates = [
  { date: '01.04.2020', start: '14:00', end: '20:00' },
  { date: '02.04.2020', start: '14:00', end: '20:00' }
]

const Trainings = ({
  dedicatedTraining,
  dedicatedTrainingText,
  isEditable,
  itemsList,
  onClick,
  onClickSign,
  onClickUnsign,
  withHeader
}) => {
  let sign = () => onClickSign

  if (dates.length > 0) {
    if (isExpoDay(dates)) {
      sign = (url) => () => openInNewTab(url)
    }
  }

  return (
    <>
      <TrainingsHeader withHeader={withHeader} />

      {
        itemsList && (
          <>
            {
              itemsList.map((item, id) => (
                <TrainingItem
                  dedicatedTraining={dedicatedTraining}
                  dedicatedTrainingText={dedicatedTrainingText}
                  isEditable={isEditable}
                  isOpen={item.isOpen}
                  key={id}
                  onClick={onClick}
                  onClickSign={sign(item.url)}
                  onClickUnsign={onClickUnsign}
                  trainingItemElement={{ ...item, title: `${shouldShowLive(dates, item.day, item.timeStart, item.timeEnd)}${item.title}` }}
                />
              ))
            }
          </>
        )
      }
    </>
  )
}

export default Trainings

Trainings.propTypes = {
  dedicatedTraining: PropTypes.bool,
  dedicatedTrainingText: PropTypes.string,
  isEditable: PropTypes.bool,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      isPublished: PropTypes.bool,
      place: PropTypes.string,
      price: PropTypes.string,
      speakersList: PropTypes.arrayOf(
        PropTypes.shape({
          avatar: PropTypes.string,
          company: PropTypes.string,
          name: PropTypes.string,
          position: PropTypes.string
        })
      ),
      tagsList: PropTypes.arrayOf(
        PropTypes.shape({
          isSelected: PropTypes.bool,
          label: PropTypes.string
        })
      ),
      timeEnd: PropTypes.string,
      timeStart: PropTypes.string,
      title: PropTypes.string
    })
  ),
  onClick: PropTypes.func,
  onClickSign: PropTypes.func,
  withHeader: PropTypes.bool
}

Trainings.defaultProps = {
  isEditable: false,
  // TODO onClick: () => null
  withHeader: false
}

const shouldShowLive = (dates, dayId, timeStart, timeEnd) => {
  const { date } = dates[dayId === 'day-1' ? 0 : 1]

  const dateTimeStart = DateTime.fromFormat(`${date} ${timeStart}`, 'dd.MM.yy HH:mm')
  const dateTimeEnd = DateTime.fromFormat(`${date} ${timeEnd}`, 'dd.MM.yy HH:mm')

  return dateTimeStart.diffNow('seconds') < 0 && dateTimeEnd.diffNow('seconds') >= 0 ? '[TRWA] ' : ' '
}

const openInNewTab = (url) => {
  const win = window.open(url, '_blank')
  win.focus()
}
