import styled from 'styled-components'

import bannerBgImagePath from '../../assets/img/banner_bg.jpg'

import {
  BannerBoxShadowColor,
  WhiteColor
} from '../../assets/styles/colors'

export const BannerItemContainer = styled.div`
  background-image: url(${bannerBgImagePath});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 35px;

  @media (min-width: 1200px) {
    box-shadow: 0 60px 100px 0 ${BannerBoxShadowColor};
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 50% auto;
    padding: 56px 140px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    box-shadow: 0 50px 80px 0 ${BannerBoxShadowColor};
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 1fr auto;
    padding: 56px 100px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    box-shadow: 0 45px 70px 0 ${BannerBoxShadowColor};
    padding: 30px 60px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    box-shadow: 0 40px 60px 0 ${BannerBoxShadowColor};
    padding: 20px 30px;
  }
`

export const BannerItemTextContainer = styled.div`
  text-align: left;
  
  @media (min-width: 320px) and (max-width: 991px) {
    text-align: center;
  }
`

export const BannerItemTitle = styled.h2`
  color: ${WhiteColor};
  font-weight: 600;
  
  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 68px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const BannerItemDescription = styled.div`
  color: #B4A4FF;
  font-size: 18px;
  line-height: 28px;
  margin: 40px 0 0;
  
  @media (min-width: 576px) and (max-width: 991px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0 0;
  }
`

export const BannerItemButtonContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: flex-end;
  
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px 0 0;
  }
`
