import styled from 'styled-components'
import {SubtitleContainer, SubtitleIconContainer} from '../../../components/Subtitle/components'
import {ButtonComponentContainer} from '../../../components/Buttons/ButtonComponent/component'
import {Label, Underline} from '../../../components/Section/Title/components'

import chevron from '../../../assets/img/chevron.svg'

import {
  PinkColor,
  PurpleColorM
} from '../../../assets/styles/colors'

export const CreatorCvStepThreeChooseColorContainer = styled.div`
  display: inline-flex;
  margin-top: 30px;
`

export const CreatorCvStepThreeDescriptionContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

export const CreatorCvStepThreeIframe = styled.iframe`
  border: 1px solid #f3f3f3;
  box-shadow: 0 10px 15px 0 rgba(217,209,255,0.3);
  display: block;
  height: 810px;
  margin: 0 auto 60px;
  max-width: 810px;
  width: 100%;
`

export const StepThreeWrapper = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    ${SubtitleContainer} {
      flex-direction: column;
      align-items: center;
    }

    ${SubtitleIconContainer} {
      margin-bottom: 15px;
    }
  }

  ${Label} {
    br {
      @media screen and (max-width: 768px) {
        display:none;
      }
    }
  }

  ${Underline} {
    display: none
  }
`

export const CvWaterMark = styled.div`
  position:relative;
  margin-top:30px;

  @media (max-width: 768px) {
    height: 650px

    iframe {
      -webkit-transform: scale(.4) translateX(-50%);
      -ms-transform: scale(.4) translateX(-50%);
      transform: scale(.4) translateX(-50%);
      width: 200vw;
      -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      position: absolute;
      top: 0;
      left: 50%;
      height: 1300px;
    }
  }

  &:after {
    ${props => {
      switch (props.subscriptonIsActive) {
        case true: return 'content: none;'
        case false: return "content: 'CVworks';"
        default: return ''
      }
    }}
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(331deg);
    -moz-transform: rotate(331deg);
    -o-transform: rotate(331deg);
    transform: rotate(331deg);
    font-size: 12em;
    color: rgba(224, 5,123, 0.2);

    @media (max-width: 768px) {
      -webkit-transform: rotate(331deg) scale(.4);
      -moz-transform: rotate(331deg) scale(.4);
      -o-transform: rotate(331deg) scale(.4);
      transform: rotate(331deg) scale(.4);

      top: -.5em
    }
  }

  &:before {
    ${props => {
      switch (props.subscriptonIsActive) {
        case true: return 'content: none;'
        case false: return "content: 'Napis zniknie po wybraniu i zrealizowaniu płatności za wybrany pakiet.';"
        default: return ''
      }
    }}
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(331deg);
    -moz-transform: rotate(331deg);
    -o-transform: rotate(331deg);
    transform: rotate(331deg);
    font-size: 1.2em;
    color: rgba(224, 5,123, 0.2);
    top: 4em;
    left: 2em;
    z-index:99

    @media (max-width: 768px) {
      -webkit-transform: rotate(331deg) scale(.5);
      -moz-transform: rotate(331deg) scale(.5);
      -o-transform: rotate(331deg) scale(.5);
      transform: rotate(331deg) scale(.5);

      top: -2em;
    }
  }
`

export const CreatorCvStepThreeButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & div + div {
    margin-left: 20px;
  }

  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const CvPreviewContainer = styled.div`
  margin-bottom: 80px;
  box-shadow: 0 10px 15px 0 rgba(217,209,255,0.3);
  border: 1px solid #f3f3f3;
`

export const HalfWidth = styled.div`
  @media (min-width: 890px) {
    width: 50%;
    float: left;
  }

  ${props => {
    switch (props.selected) {
      case undefined: return 'display: none;'
      default: return ''
    }
  }}
`

export const Clear = styled.div`
  clear: both;
`

export const RodoContainer = styled.div`
  margin-bottom: 50px;
`

export const CvCreatorPageGuidlineStepsContainer = styled.div`
  margin-top: 50px;
`

export const CvCreatorBenefitsBoxes = styled.div`
  margin-top: 100px;
`

export const BenefitsList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  margin-top: 20px;
`

export const BenefitsElement = styled.div`
  line-height: 2em;
  color: ${PurpleColorM};
  display: flex;
  font-size: 1.4em;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  width: 80%;
  margin:0 auto;

  @media screen and (max-width:768px) {
    line-height: 1.5em;
    padding: 10px 0;

    display: block;
    width: 100%;
    font-size: 1.1em;
  }

  ${ButtonComponentContainer} {
    margin-left: 10px;

     @media screen and (max-width:768px) {
        margin-top: 10px;
     }
  }

  @media screen and (max-width:992px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &:before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(${chevron});
    background-size: cover;
    transform: rotate(-90deg) translateX(0px);
    flex-shrink: 0;

    @media screen and (max-width:768px) {
      margin-top: -5px;
      display: block;
      float: left
    }
  }

`

export const CvCreatorPageVideoContainer = styled.div`
  margin-top: 50px;

  > div {
    > div {
      @media screen and (max-width: 992px) {
        flex-direction: column
      }
      > div {
        width: auto;

        @media screen and (max-width: 992px) {
          width: 100%;

          iframe {
            height: 400px !important;
          }
        }

        &:first-of-type {
          flex: 1;

          > div {
            height: 100%;

            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
`

export const CvCreatorPageVideoContainerRight = styled.div`
  text-align: left;
`
export const CvCreatorPageVideoContainerHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1em
`
export const CvCreatorPageVideoContainerList = styled.div`
  
`

export const CvCreatorPageVideoContainerListElement = styled.div`
  line-height: 2em;
  color: ${PurpleColorM};
  display: flex;
`

export const CvCreatorPageVideoContainerListElementIndex = styled.div`
  margin-right: 10px;
  font-weight: bold;
  color: ${PinkColor}
`

export const CvCreatorPageVideoButton = styled.div`
  margin-top: 1em
`

export const HiddenCourseInfo = styled.div`
  margin-bottom: 30px;
`

export const CvCreatorPageSolid = styled.div`
  > div {
    > div {
      @media screen and (max-width: 992px) {
        flex-direction: column
      }
      > div {
        @media screen and (max-width: 992px) {
          width: 100%;
        }
        /*width: auto;*/
        display: flex;
        align-items: center;

        &:last-of-type {
          /*flex: 1;*/
        }
      }
    }
`
export const CvCreatorPageSolidList = styled.div`
  text-align: left;
`
export const CvCreatorPageSolidImage = styled.div`

`
export const CvCreatorPageSolidListElement = styled.div`
  line-height: 2em;
  color: ${PurpleColorM};
  display: flex;
  font-size: 1.2em;
  /*align-items: center;*/

  &:before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(${chevron});
    background-size: cover;
    transform: rotate(-90deg);
    flex-shrink: 0;
  }
`

export const Security = styled.div`
  margin-top:150px;
  font-size:14px;

  p {
    margin-top: 10px;
  }
`