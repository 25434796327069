import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import AddExperienceForm from '../../../components/Form/FormComponents/AddExperienceForm'
import ResetAndSaveButtons from '../../../components/Form/FormComponents/ResetAndSaveButtons'

import handleMultiSelect from '../../../utils/forms/handleMultiSelect'
import handleCheckbox from '../../../utils/forms/handleCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'
import ApiService from '../../../store/services/ApiService'
import * as recruitmentApi from '../../../store/api/recruitment'
import { toast } from 'react-toastify'
import translate from '../../../stabs/errorMessagesTranslations'
import handleField from '../../../utils/forms/handleField'

const AddExperienceFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const cvId = sessionStorage.getItem('cv')
  const cv = useSelector(cvSelectors.getCvData)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    const newValues = {
      position: values.position,
      location: values.location,
      startDate: `${values.startDateMonth}.${values.startDateYear}`,
      stillOngoing: values.stillOngoing,
      companyName: values.companyName,
      description: values.description,
      biggestSuccess: values.biggestSuccess,
      haveLearned: values.haveLearned
    }

    if (!newValues.stillOngoing) {
      newValues.endDate = `${values.endDateMonth}.${values.endDateYear}`
    }

    if (!newValues.stillOngoing && (values.endDateMonth === '' || values.endDateYear === '')) {
      setSubmitting(false)

      toast.error('Musisz uzupełnić do końca datę zakończenia.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      return
    }

    ApiService
      .fetch(state.isEditing ? recruitmentApi.updateJob(cvId, state.editingItemId, newValues) : recruitmentApi.addJob(cvId, newValues))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
        resetForm()
        setState({ ...state, editingItemId: null })
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(recruitmentApi.deleteJob(cvId, itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = cv.jobs.filter((job) => job._id === itemId)[0]

    const newItem = {
      position: editingItem.position,
      location: editingItem.location,
      companyName: editingItem.companyName,
      description: editingItem.description,
      stillOngoing: editingItem.stillOngoing,
      haveLearned: editingItem.haveLearned,
      biggestSuccess: editingItem.biggestSuccess,
      startDateMonth: editingItem.startDate.split('.')[0],
      startDateYear: editingItem.startDate.split('.')[1],
      endDateMonth: '',
      endDateYear: ''
    }

    if (!newItem.stillOngoing) {
      newItem.endDateMonth = editingItem.endDate.split('.')[0]
      newItem.endDateYear = editingItem.endDate.split('.')[1]
    }

    setValues(newItem)
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: `${item.companyName} - ${item.position}`,
    description: `Od ${item.startDate} ${item.stillOngoing ? 'i ciągle trwa' : `do ${item.endDate}`}.`,
    isEditing: state.editingItemId === item._id,
    _id: item._id
  }))

  return (
    <Formik
      initialValues={{
        position: '',
        location: '',
        companyName: '',
        description: '',
        biggestSuccess: '',
        haveLearned: '',
        stillOngoing: false,
        startDateMonth: '',
        startDateYear: '',
        endDateMonth: '',
        endDateYear: ''
      }}
      onSubmit={onSubmit}
      validationSchema={ExperienceCVSchema}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddExperienceForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleMultiSelect={(fieldName, item, isMulti) =>
              handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
            handleTextarea={(fieldName, item) => {
              setFieldTouched(fieldName, true)
              setFieldValue(fieldName, item)
            }}
            handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
            handleCheckbox={(fieldName) => handleCheckbox(fieldName, values, { setFieldValue, setFieldTouched })}
            touched={touched}
            values={values}
            handleDelete={onDelete}
            handleEdit={onEdit(setValues)}
            isEditing={state.isEditing}
            isLoading={state.isLoading}
            items={mapItemToDisplay(cv.jobs)}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

Yup.addMethod(Yup.string, 'dropDown', function(field) {
    const { message } = field;
    return this.test('test-name', message, function(value) {
        const { path, createError } = this;

        if (value == null){
          document.getElementById('experience-field-'+field).style.borderColor = 'red';
          return false;
        } else {
          document.getElementById('experience-field-'+field).style.borderColor = null;
          return true;
        }
    });
});

const ExperienceCVSchema = Yup.object().shape({
  position: Yup.string()
    .required('Pole jest wymagane'),
  location: Yup.string().required('Pole jest wymagane'),
  companyName: Yup.string().required('Pole jest wymagane'),
  startDateMonth: Yup.string().required('Pole jest wymagane').dropDown('startDateMonth'),
  startDateYear: Yup.string().required('Pole jest wymagane').dropDown('startDateYear'),
  endDateMonth: Yup.string(),
  endDateYear: Yup.string(),
  stillOngoing: Yup.boolean()
    .required('Pole jest wymagane'),
  description: Yup.string(),
  biggestSuccess: Yup.string(),
  haveLearned: Yup.string()
})

export default AddExperienceFormContainer
