export default [
  { label: 'Podstawowe' },
  { label: 'Gimnazjalne' },
  { label: 'Zawodowe' },
  { label: 'Średnie' },
  { label: 'Wyższe - licencjat' },
  { label: 'Wyższe - inżynier' },
  { label: 'Wyższe - magister' },
  { label: 'Wyższe - doktor' },
  { label: 'Wyższe - profesor' },
  { label: 'Wyższe - podyplomowe' }
]
