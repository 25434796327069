import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const VideoItemContainer = styled.div`
  width: 100%;
`

export const VideoItemContent = styled.div`
  position: relative;
  border-radius: 20px;
  height: 185px;
  overflow: hidden;
  position: relative;
`

export const VideoItemButtonPlayContainer = styled.div`
  bottom: 0;
  height: 80px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
`

export const VideoItemTitle = styled.div`
  color: ${PurpleColorM};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 0 0;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`
