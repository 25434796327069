import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Form/FormBasicElements/Icon'

import {
  InfoIconTextContainer,
  InfoIconTextContent,
  InfoIconTextDescription,
  InfoIconTextIconContainer,
  InfoIconTextTitle
} from './Atoms'

const InfoIconText = ({
  description,
  icon,
  title
}) => (
  <InfoIconTextContainer data-test='InfoIconTextContainer'>
    {
      icon && (
        <InfoIconTextIconContainer data-test='InfoIconTextIconContainer'>
          <Icon
            data-test='InfoIconTextIcon'
            icon={icon}
          />
        </InfoIconTextIconContainer>
      )
    }

    <InfoIconTextContent data-test='InfoIconTextContent'>
      {
        title && (
          <InfoIconTextTitle data-test='InfoIconTextTitle'>
            {title}
          </InfoIconTextTitle>
        )
      }

      {
        description && (
          <InfoIconTextDescription data-test='InfoIconTextDescription'>
            {description}
          </InfoIconTextDescription>
        )
      }
    </InfoIconTextContent>
  </InfoIconTextContainer>
)

export default InfoIconText

InfoIconText.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
}
