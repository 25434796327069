import React from 'react'

import Subtitle from '../../../components/Subtitle'
import AddBasicUserDataCVFormContainer
  from '../../../containers/RegisterContainers/AddBasicUserDataCVFormContainer'
import { CreatorCvStepOneDescriptionContainer } from './components'

const CreatorCvStepOne = () => (
  <>
    <Subtitle
      data-test='CreatorCvStepOneSubtitleBaseData'
      icon='user'
      label='Zacznij uzupełniać swój szablon CV'
    />

    <CreatorCvStepOneDescriptionContainer data-test='CreatorCvStepOneCreatorCvStepOneDescriptionContainerBaseData'>
      
    </CreatorCvStepOneDescriptionContainer>

    <AddBasicUserDataCVFormContainer />
  </>
)

export default CreatorCvStepOne
