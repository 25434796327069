import React from 'react'

import AddBasicUserDataFormContainer from '../../../containers/PlatformContainer/UserStepsForms/AddBasicUserDataFormContainer'
import Subtitle from '../../../components/Subtitle'

const FormUserStepOne = ({ primaryEvent }) => (
  <>
    <Subtitle
      icon='pin'
      label={`${primaryEvent.name}, ${primaryEvent.date + ' ' + primaryEvent.timeStart + ' - ' + primaryEvent.timeEnd}, ${primaryEvent.place}`}
    />

    <AddBasicUserDataFormContainer />
  </>
)

export default FormUserStepOne
