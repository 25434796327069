import styled from 'styled-components'

import {
  PurpleColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const SiteHeaderContainer = styled.div`
  background-color: ${WhiteColor};
  // border-bottom: 3px solid #efebff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`

export const SiteContent = styled.div`
  color: ${PurpleColor};

  @media (min-width: 1200px) {
    padding: 93px 0 0;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 83px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 73px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 64px 0 0;
  }
`

export const SiteFooterContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 250px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 200px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 150px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 120px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 100px 0 0;
  }
`
