import React from 'react'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import Site from '../../components/Wrappers/Site'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import Subtitle from '../../components/Subtitle'

import RichTextEditorField from '../../components/RichTextEditorField'

const TestScreen = () => {
  return (
    <Site withNav>
      <InnerWrapper>
        <FormPanel
          boxShadow='noShadow'
          paddingSize='sizeS'
        >
          <Subtitle
            icon='star'
            label='Pokój rozmów'
            type='noTopMargin'
          />
          <RichTextEditorField value={'<p>text-a <b>bold</b> text-b</p>'} onChange={(data) => console.log(data)} placeholder={'Type'}/>
        </FormPanel>
      </InnerWrapper>
    </Site>
  )
}

export default TestScreen
