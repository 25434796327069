import React from 'react'
import PropTypes from 'prop-types'

import ChooseFile from '../../FormBasicElements/ChooseFile'
import ChooseIconsForm from '../../FormComponents/ChooseIconsForm'
import Errors from '../../FormBasicElements/Errors'
import InnerWrapper from '../../../Wrappers/InnerWrapper'
import Label from '../../FormBasicElements/Label'
import Modal from '../../../Modal'
import UploadFile from '../../FormBasicElements/UploadFile'

import {
  ChooseFileFieldContainer
} from './components'

class ChooseFileField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const handleOnClick = this.toggleShow
    const handleChooseIconsFormOnClick = this.props.onClick

    return (
      <ChooseFileFieldContainer>
        <Label
          label={this.props.label}
          required={this.props.required}
        />

        {this.props.type === 'onChange' &&
          <UploadFile
            buttonLabel={this.props.buttonLabel}
            disabled={this.props.disabled}
            hasError={this.props.hasError}
            id={this.props.id}
            isLoading={this.props.isLoading} // TODO
            name={this.props.name}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            text={this.props.text}
            value={this.props.value}
          />}

        {this.props.type === 'onClick' &&
          <>
            <ChooseFile
              buttonLabel={this.props.buttonLabel}
              hasError={this.props.hasError}
              onClick={handleOnClick}
              text={this.props.text}
            />

            {this.state.isOpen &&
              <Modal hasClose>
                <InnerWrapper>
                  <ChooseIconsForm
                    itemsList={this.props.itemsList}
                    onClick={handleChooseIconsFormOnClick}
                    toggleShow={this.toggleShow}
                  />
                </InnerWrapper>
              </Modal>}
          </>}

        <Errors
          errorMessage={this.props.errorMessage}
          hasError={this.props.hasError}
        />
      </ChooseFileFieldContainer>
    )
  }
}

export default ChooseFileField

ChooseFileField.propTypes = {
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string
    })
  ),
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf(['onChange', 'onClick'])
}

ChooseFileField.defaultProps = {
  type: 'onChange'
}
