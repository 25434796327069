import React from 'react'
import { useSelector } from 'react-redux'

import { myUserSelectors } from '../ducks/my-user'
import { cvSelectors } from '../ducks/my-cv'
import * as pdfAPI from '../api/pdf'
import { toast } from 'react-toastify'

import translations from '../../translations'

function downloadPDF (name, pdf) {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')
  const fileName = `${name}.pdf`

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

const useCvGenerator = (args) => {
  const [isLoading, setLoading] = React.useState(false)

  const cv = useSelector(cvSelectors.getCvData)
  const user = useSelector(myUserSelectors.getMyUserData)

  return {
    isLoading,
    generateCv: async () => {
      setLoading(true)
      try {
        const response = await fetch('https://gd8u2j3344.execute-api.eu-west-1.amazonaws.com/dev/generate', pdfAPI.getPDF({
          templateId: cv.templateId,
          data: {
            color: JSON.parse(cv.color) ?? { r: 74, g: 144, b: 226 },
            email: cv.email === ''
              ? user.email
              : cv.email,
            courses: cv.courses,
            educations: cv.educations,
            hobbies: cv.hobbies.split(', '),
            jobs: cv.jobs,
            languages: cv.languages.map(
              language => ({
                ...language,
                level: (language.level / 6) * 100,
                levelName: language.level
              })),
            skills: cv.skills.map(
              skill => ({
                ...skill,
                level: (skill.level / 4) * 100
              })),
            summary: cv.summary,
            position: cv.position,
            portfolioUrl: cv.portfolioUrl,
            linkedinUrl: user.linkedinUrl,
            birthday: user.birthday,
            cityOfLiving: user.cityOfLiving,
            country: user.country,
            firstName: user.firstName,
            lastName: user.lastName,
            nationality: user.nationality,
            phone: user.phone,
            avatar: user.avatar,
            rodo: cv.rodo,
            translations: translations(cv.templateId, cv.cvLanguage)
          },
          isLandscape: false,
          ...args
        }))

        if (response.status !== 200) {
          throw Error()
        }

        const data = await response.text()

        if (args?.onlyHtml) {
          return data
        }

        downloadPDF(`${user.firstName} ${user.lastName} - CV`, data)
      } catch (e) {
        console.error(e)
        toast.error('Nastąpił problem!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      }
      setLoading(false)
    }
  }
}

export default useCvGenerator
