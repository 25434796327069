import styled from 'styled-components'

import {
  PinkColor,
  PurpleColorXS,
  Transition
} from '../../../assets/styles/colors'

export const OutsideSliderContainer = styled.div`
  padding: ${({ withDots }) => withDots
    ? '0 0 40px'
    : '0'
  };

  @media (max-width: 767px) {
    padding-bottom:0;
  }
`

export const OutsideSliderDotsContainer = styled.div`
  bottom: -40px !important;
`

export const OutsideSliderDotsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const OutsideSliderPaging = styled.div`
  background-color: ${PinkColor};
  border-radius: 50%;
  height: 16px;
  transition: ${Transition};
  width: 16px;

  &:hover {
    background-color: ${PurpleColorXS};
  }
`
