import React from 'react'
import PropTypes from 'prop-types'

import {
  ChooseColorItemContainer
} from './components'

const ChooseColorItem = ({
  bgColor,
  isSelected,
  onClick
}) => (
  <>
    {bgColor &&
      <ChooseColorItemContainer
        onClick={onClick}
        isSelected={isSelected}
        bgColor={bgColor}
      />}
  </>
)

export default ChooseColorItem

ChooseColorItem.propTypes = {
  bgColor: PropTypes.string,
  isSelected: PropTypes.bool
}

ChooseColorItem.defaultProps = {
  isSelected: false
}
