import React from 'react'

import ButtonComponent from '../../components/Buttons/ButtonComponent'
import ButtonPlay from '../../components/Buttons/ButtonPlay'
import Card from '../../components/Card'
import ChooseCV from '../../components/ChooseCV'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import GuidlineStep from '../../components/GuidlineStep'
import Image from '../../components/Form/FormBasicElements/Image'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import PrimaryEvent from '../../components/PrimaryEvent'
import Section from '../../components/Section'
import Site from '../../components/Wrappers/Site'
import SliderArrowsWrapper from '../../components/Sliders/SliderArrowsWrapper'
import SpacingLine from '../../components/SpacingLine'
import SquarePanel from '../../components/SquarePanel'
import Steps from '../../components/Steps/Steps'
import Subtitle from '../../components/Subtitle'

import {
  HomePageCardsContainer,
  HomePageChooseTemplateButtonContainer,
  HomePageChooseTemplateContainer,
  HomePageCreatorCVContainer,
  HomePageCvTemplatesContainer,
  HomePageEmployerContainer,
  HomePageEmployerFeatureButtonContainer,
  HomePageEmployerFeaturesContainer,
  HomePageEmployerFeaturesLeftSideContainer,
  HomePageEmployerFeaturesRightSideContainer,
  HomePageEmployerFeaturesRightSideContent,
  HomePageEmployersListContainer,
  HomePageGuidlineStepsContainer,
  HomePageJobFairButtonContainer,
  HomePageJobFairIndustryContainer,
  HomePageJobFairIndustryContent,
  HomePageJobFairIndustryHeader,
  HomePageJobFairLeftContainer,
  HomePageJobFairRightContainer,
  HomePageJobFairRightContent,
  HomePageJobFairText,
  HomePageJobFairsContainer,
  HomePageReviewContainer,
  HomePageReviewerContainer,
  HomePageReviewsContainer,
  HomePageReviewsImageContainer,
  HomePageStepsButtonContainer,
  HomePageStepsContainer,
  HomePageTopButtonContainer,
  HomePageTopContainer,
  HomePageTopContent,
  HomePageTopImage,
  HomePageTopImageContainer
} from './components'

import AuthService from '../../store/services/AuthService'
import ModalController from '../../components/ModalController'
import homepageBgImage from '../../assets/img/homepageBg.svg'
import jobFairBgImagePath from '../../assets/img/homepage/jobFairBg.jpg'
import reviewsBgImage from '../../assets/img/homepage/reviewsBg.jpg'
import translationsList from '../../stabs/translationsList'
import isExpoDay from '../../utils/isExpoDay'

const HomePage = ({
  changeCvTemplate,
  companyModalSlug,
  event,
  modal,
  selectedCvTemplate
}) => {
  return (
    <>
      <Site
        isLoggedOn={AuthService.loggedIn()}
        menuItemsList={isExpoDay()
          ? translationsList.topMenu.linksList_live
          : translationsList.topMenu.linksList}
        withNav
      >
        <InnerWrapper>
          <HomePageTopContainer>
            <HomePageTopContent>
              <Section
                description='Stwórz piękne i skuteczne CV, określ idealnego Pracodawcę i przekonaj go do siebie na targach pracy Career EXPO.'
                title='Pomożemy Ci znaleźć wymarzoną pracę... na targach pracy online!'
                level='h1'
              />

              <HomePageTopButtonContainer>
                <ButtonComponent
                  bgColor='pinkColor'
                  color='whiteColor'
                  display='inline-flex'
                  fontBold
                  label='Załóż bezpłatne konto'
                  link='/rejestracja'
                  size='sizeM'
                  type='internal'
                />
              </HomePageTopButtonContainer>
            </HomePageTopContent>

            <HomePageTopImageContainer>
              <HomePageTopImage src={homepageBgImage} />
            </HomePageTopImageContainer>
          </HomePageTopContainer>

          <HomePageCardsContainer>
            {translationsList.homepage.cards.map((card, key) =>
              <Card
                description={card.description}
                icon={card.icon}
                key={key}
                title={card.title}
              />
            )}
          </HomePageCardsContainer>

          <SpacingLine
            id='cv'
            isFixed
          />

          <HomePageCreatorCVContainer>
            <Section
              alignCenter
              description='Stwórz skuteczne CV w mniej niż 10 minut.'
              title='Bezpłatny kreator CV'
              level='h2'
            />
          </HomePageCreatorCVContainer>

          <HomePageGuidlineStepsContainer>
            {translationsList.homepage.guidlineSteps.map((step, key) =>
              <GuidlineStep
                description={step.description}
                key={key}
                number={step.number}
                title={step.title}
              />
            )}
          </HomePageGuidlineStepsContainer>

          <HomePageChooseTemplateContainer>
            <Subtitle
              icon='star'
              label='Wybierz swój szablon CV'
              type='noTopMargin'
            />
          </HomePageChooseTemplateContainer>

          <HomePageCvTemplatesContainer>
            <SliderArrowsWrapper type='outside'>
              {translationsList.homepage.cvTemplates.map((template, key) =>
                <ChooseCV
                  id={template.id}
                  image={template.image}
                  key={key}
                  onClick={() => changeCvTemplate(template.id)} // TODO
                  selected={selectedCvTemplate === template.id}
                  title={template.title}
                />
              )}
            </SliderArrowsWrapper>
          </HomePageCvTemplatesContainer>

          <HomePageChooseTemplateButtonContainer>
            <ButtonComponent
              bgColor='pinkColor'
              color='whiteColor'
              display='inline-flex'
              fontBold
              label='Chcę stworzyć skuteczne CV'
              link='/rejestracja'
              size='sizeM'
              type='internal'
            />
          </HomePageChooseTemplateButtonContainer>

          <HomePageEmployerFeaturesContainer>
            <FormTwoColumns>
              <HomePageEmployerFeaturesLeftSideContainer>
                <Section
                  description='Najpierw powiedz nam, kim jesteś i czego oczekujesz od życia i miejsca pracy, a my zarekomendujemy Ci Pracodawcę, który odpowiada Twoim wartościom. Choć związek z firmą najczęściej nie jest wieczny, to w pracy spędzamy 1/3 swojego życia - dopasuj więc kulturę organizacyjną do Twoich preferencji, abyś czuł się jak ryba w wodzie!'
                  title='Określ cechy idealnego Pracodawcy'
                  level='h2'
                />

                <HomePageEmployerFeatureButtonContainer>
                  <ButtonComponent
                    bgColor='pinkColor'
                    color='whiteColor'
                    display='inline-flex'
                    fontBold
                    label='Załóż bezpłatne konto'
                    link='/rejestracja'
                    size='sizeM'
                    type='internal'
                  />
                </HomePageEmployerFeatureButtonContainer>
              </HomePageEmployerFeaturesLeftSideContainer>

              <HomePageEmployerFeaturesRightSideContainer>
                <HomePageEmployerFeaturesRightSideContent>
                  <SquarePanel
                    description={translationsList.homepage.squarePanels.itemsList[0].description}
                    icon={translationsList.homepage.squarePanels.itemsList[0].icon}
                    selected={translationsList.homepage.squarePanels.itemsList[0].selected}
                    title={translationsList.homepage.squarePanels.itemsList[0].title}
                  />
                  <SquarePanel
                    description={translationsList.homepage.squarePanels.itemsList[1].description}
                    icon={translationsList.homepage.squarePanels.itemsList[1].icon}
                    selected={translationsList.homepage.squarePanels.itemsList[1].selected}
                    title={translationsList.homepage.squarePanels.itemsList[1].title}
                  />
                  <SquarePanel
                    description={translationsList.homepage.squarePanels.itemsList[2].description}
                    icon={translationsList.homepage.squarePanels.itemsList[2].icon}
                    selected={translationsList.homepage.squarePanels.itemsList[2].selected}
                    title={translationsList.homepage.squarePanels.itemsList[2].title}
                  />
                  <SquarePanel
                    selected={translationsList.homepage.squarePanels.selected.selected}
                    title={translationsList.homepage.squarePanels.selected.title}
                  />
                </HomePageEmployerFeaturesRightSideContent>
              </HomePageEmployerFeaturesRightSideContainer>
            </FormTwoColumns>
          </HomePageEmployerFeaturesContainer>

          <HomePageEmployersListContainer>
            <SliderArrowsWrapper type='outside'>
              {translationsList.homepage.employersList.map((image, id) => (
                <HomePageEmployerContainer key={id}>
                  <Image
                    scaleMe={false}
                    source={image}
                  />
                </HomePageEmployerContainer>
              ))}
            </SliderArrowsWrapper>
          </HomePageEmployersListContainer>
        </InnerWrapper>

        <SpacingLine
          id='meet'
          isFixed
        />

        <HomePageJobFairsContainer>
          <FormTwoColumns columnReverse>
            <HomePageJobFairLeftContainer>
              <Image
                scaleMe={false}
                source={jobFairBgImagePath}
              />

              <ButtonPlay link='/play' />
            </HomePageJobFairLeftContainer>

            <HomePageJobFairRightContainer>
              <HomePageJobFairRightContent>
                <Section
                  description='Najlepiej zrób to osobiście na targach pracy Career EXPO we Wrocławiu, które odbywają się wiosną i jesienią każdego roku. Setka Pracodawców otwarta na takie talenty jak Ty, dziesiątki szkoleń i prelekcji, konsultacje kariery i CV, mini sesje coachingowe, Szybkie Rekrutacje, profesjonalne zdjęcie do CV… Mam wymieniać dalej? To może inaczej:'
                  title='Poznajcie się… i aplikuj!'
                  level='h2'
                />

                <HomePageJobFairText>'Career EXPO to największe rozwojowe wydarzenie w Twoim mieście'</HomePageJobFairText>

                <HomePageJobFairButtonContainer>
                  <ButtonComponent
                    bgColor='pinkColor'
                    color='whiteColor'
                    display='inline-flex'
                    fontBold
                    label='Sprawdź agendę!'
                    link='/targi-pracy-wroclaw'
                    size='sizeM'
                    type='internal'
                  />
                </HomePageJobFairButtonContainer>

                <HomePageJobFairIndustryContainer>
                  <HomePageJobFairIndustryHeader>Najbliższe targi:</HomePageJobFairIndustryHeader>

                  <HomePageJobFairIndustryContent>
                    <PrimaryEvent
                      date='22-23.10.2019'
                      name={event.name}
                      place={event.place}
                    />
                  </HomePageJobFairIndustryContent>

                </HomePageJobFairIndustryContainer>
              </HomePageJobFairRightContent>
            </HomePageJobFairRightContainer>
          </FormTwoColumns>
        </HomePageJobFairsContainer>

        <HomePageReviewsContainer>
          <HomePageReviewsImageContainer>
            <Image
              scaleMe={false}
              source={reviewsBgImage}
            />
          </HomePageReviewsImageContainer>

          <HomePageReviewContainer>
            <Section
              alignCenter
              description='Career Expo to najlepsze co mogło mnie spotkać. Dzięki nim znalazłam pracę w branży o której mogłam tylko marzyć. To cudowne jak piękne CV wypływa z tej strony. To wszystko dzięki niemu. Kłaniam się Panie Expo.'
              title='Co o nas mówią?'
              level='h2'
            />

            <HomePageReviewerContainer>'Andżela, 28, Project Manager'</HomePageReviewerContainer>
          </HomePageReviewContainer>
        </HomePageReviewsContainer>

        <InnerWrapper>
          <HomePageStepsContainer>
            <Steps
              itemsList={translationsList.steps.landingPageSteps.stepsList}
              stepsTitle={translationsList.steps.landingPageSteps.labels.titleLabel}
            />

            <HomePageStepsButtonContainer>
              <ButtonComponent
                align='center'
                bgColor='pinkColor'
                color='whiteColor'
                display='inline-flex'
                fontBold
                label={translationsList.steps.landingPageSteps.labels.buttonLabel}
                link='/rejestracja'
                size='sizeL'
                type='internal'
              />
            </HomePageStepsButtonContainer>
          </HomePageStepsContainer>
        </InnerWrapper>
      </Site>

      <ModalController type={modal} companyModalSlug={companyModalSlug} />
    </>
  )
}

export default HomePage
