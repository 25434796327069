import styled from 'styled-components'

import { ImageComponent, ImageComponentContainer } from '../../Form/FormBasicElements/Image/components'

export const StyledJoyRide = styled.div`

`
export const JoyrideImage = styled.div`
  padding-bottom: 20px;

  .__floater__arrow {
    width: auto
  }

  ${ImageComponentContainer} {
    ${props => {
      if( props.imagePosition == 'absolute' ) {
          return '&:before {content: "PROFESOR";position:absolute;bottom: 10px;width: 100%;text-align: center;color: #fff;font-size: 12px;}'
        }
      }}
    }

  ${ImageComponentContainer} {
    ${props => {
      if( props.imagePosition == 'absolute' ) {
          return 'background: #3d3072;border-radius: 200px;padding: 0 8px 15px 8px'
        }
      }}
  }

  ${props => {
    var styles = ''
    if( props.width ) {
      styles += 'flex-shrink: 0;margin: 0 auto; width: '+props.width
    }
    if( props.imagePosition == 'absolute' ) {
      styles += ';position: absolute;left: 50%;transform: translateX(-50%);top: -90px;border: 5px solid #fff;padding: 0;border-radius: 400px;z-index: 9'
    }

    return styles
  }}
`