import styled from 'styled-components'

import {
  PinkColor,
  PinkColorM,
  PurpleBoxShadowColor,
  Transition,
  WhiteColor
} from '../../assets/styles/colors'

export const ChooseCVContainer = styled.div`
  padding: 10px;
`

export const ChooseCVContent = styled.div`
  border-radius: 35px;
  box-shadow: ${PurpleBoxShadowColor};
  display: inline-block;
  overflow: hidden;
  position: relative;
  transform: scale(0.95);
  transition: ${Transition};
  
  cursor: ${({ disabled }) => disabled
    ? 'default'
    : 'pointer'
  };

  img {
-webkit-backface-visibility: hidden; 
-ms-transform: translateZ(0); /* IE 9 */
-webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
transform: translateZ(0);
backface-visibility: hidden;
/*image-rendering: pixelated;
image-rendering:-webkit-optimize-contrast;*/

filter: blur(0);
-webkit-filter: blur(0);
transform: translateZ(0);
-webkit-transform: translateZ(0);
  }

  &:hover {
    transform: scale(1.0);

    ${props => {
      if( props.isHome ) {
        return `
        transform: scale(2);z-index: 99;
        position:relative;

        &:after {
          content: 'Dostosuj to CV';
          position: absolute;
          bottom: 10%;
          left: 50%;
          transform: translate3d(-50%,0,0);
          background: #fd007a;
          border-radius: 40px;
          font-size: 11px;
          color: #fff;
          padding: 3px 15px;
          cursor:pointer;
        }
        `
      }
    }} 

    ${props => {
      if( props.isRegister ) {
        return `
        transform: scale(1.8);z-index: 99;
        position:relative;
        `
      }
    }} 

    @media (max-width: 767px) {
      ${props => {
        if( props.isHome ) {
          return 'transform: scale(0.95);z-index: 99'
        }
      }} 

      ${props => {
        if( props.isRegister ) {
          return 'transform: scale(0.95);z-index: 99'
        }
      }} 
    }
  }



  margin: ${({ noMargin }) => noMargin
    ? '0'
    : '0 10px'
  };

  & > div {
    padding: 0;
  }

  ${({ halfWidth }) => halfWidth
    ? 'max-width: 70%;'
    : `
      @media (min-width: 1200px) {
          & img {
            width: 220px;
          }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          & img {
            width: 250px;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          & img {
            width: 230px;
          }
        }

        @media (max-width: 767px) {
          width: 100%;
          & img {
            width: 100%;
            transform: scale(1);
          }
        }
      `
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
`

export const ChooseCVFaderContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ChooseCVFader = styled.div`
  background-image: linear-gradient(106deg, ${PinkColor}, ${PinkColorM});
  border-radius: 35px;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
`

export const ChooseCVFaderIconContainer = styled.div`
  background-color: ${PinkColorM};
  border-radius: 50%;
  height: 50px;
  left: 0;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 60px;
  width: 50px;
  z-index: 1;
`

export const ChooseCVFaderTitle = styled.div`
  bottom: 40px;
  color: ${WhiteColor};
  font-size: 18px;
  left: 0;
  padding: 0 10px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;
`
