import React, { Suspense, lazy } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import AuthRoute from './containers/AuthContainers/AuthRoute'
import ProtectedRoute from './containers/AuthContainers/ProtectedRoute'
import Logout from './containers/AuthContainers/Logout'
import PlatformContainer from './containers/PlatformContainer'
import DashboardContainer from './containers/DashboardContainer'
import CvCreatorPage from './pages/CvCreatorPage'
import TestScreen from './pages/TestScreen'

import RegisterContainer from './containers/RegisterContainer'

import Loader from './components/Loader'
import { PageView } from './utils/AnalyticsProvider'
import retry from './utils/retry'

const ActivateContainer = lazy(async () => await retry(() => import('./containers/PageContainers/ActivateContainer')))
const ErrorPage = lazy(async () => await retry(() => import('./pages/ErrorPage')))
const ForgotPasswordPage = lazy(async () => await retry(() => import('./pages/ForgotPasswordPage')))
const RegisterSuccessPage = lazy(async () => await retry(() => import('./pages/RegisterSuccessPage')))
const ResendActivationContainer = lazy(async () => await retry(() => import('./containers/PageContainers/ResendActivationContainer')))
const ResetPasswordPage = lazy(async () => await retry(() => import('./pages/ResetPasswordPage')))
const UnsubscribeContainer = lazy(async () => await retry(() => import('./containers/PageContainers/UnsubscribeContainer')))
const SettingsPage = lazy(async () => await retry(() => import('./pages/SettingsPage')))
const ContactPage = lazy(async () => await retry(() => import('./pages/ContactPage')))
const PrivacyPolicyPage = lazy(async () => await retry(() => import('./pages/PrivacyPolicyPage')))
const RegulationsPage = lazy(async () => await retry(() => import('./pages/RegulationsPage')))
const ThankYouPage = lazy(async () => await retry(() => import('./pages/ThankYouPages')))

const RouterCV = ({ history }) => {
  history.listen(() => {
    PageView()
  })

  return (
    <Suspense fallback={<Loader isLoading />}>
      <Switch>
        <Route path='/' exact component={() => <CvCreatorPage />} />

        <Route path='/pl' exact component={() => changeLanguage('pl-PL')} />
        <Route path='/en' exact component={() => changeLanguage('en-US')} />

        <AuthRoute path='/logowanie' exact component={() => <CvCreatorPage modal='LOGIN' />} />

        <AuthRoute path='/przypomnij-haslo' exact component={ForgotPasswordPage} />
        <AuthRoute path='/zresetuj-haslo' exact component={ResetPasswordPage} />
        <AuthRoute path='/powodzenie-rejestracji' exact component={RegisterSuccessPage} />

        <Route path='/kontakt' exact component={ContactPage} />
        <Route path='/polityka-prywatnosci' exact component={PrivacyPolicyPage} />
        <Route path='/regulamin' exact component={RegulationsPage} />

        <Route path='/dziekujemy/miesieczny' exact component={ThankYouPage} />
        <Route path='/dziekujemy/polroczny' exact component={ThankYouPage} />
        <Route path='/dziekujemy/dozywotni' exact component={ThankYouPage} />

        <Route path='/wypisz-sie' exact component={UnsubscribeContainer} />
        <Route path='/aktywuj' exact component={ActivateContainer} />
        <Route path='/wyslij' exact component={ResendActivationContainer} />
        <Route path='/test' exact component={TestScreen} />

        <Route path='/rejestracja' exact component={() => <RegisterContainer type='cv' step={1} />} />
        <ProtectedRoute path='/rejestracja/szablon' minimalType='user' exact component={() => <RegisterContainer type='cv' step={2} />} />
        <ProtectedRoute path='/rejestracja/metryka' minimalType='user' exact component={() => <RegisterContainer type='cv' step={3} />} />
        <ProtectedRoute path='/rejestracja/profil' minimalType='user' exact component={() => <RegisterContainer type='cv' step={4} />} />
        <ProtectedRoute path='/rejestracja/podsumowanie' minimalType='user' exact component={() => <RegisterContainer type='cv' step={5} />} />

        <Route path='/kreator-cv/podstawowe-dane' exact component={() => <PlatformContainer type='cv' step={1} />} />
        <ProtectedRoute path='/kreator-cv/doswiadczenie' minimalType='user' exact component={() => <PlatformContainer type='cv' step={2} />} />
        <ProtectedRoute path='/kreator-cv/szablon' minimalType='user' exact component={() => <PlatformContainer type='cv' step={3} />} />

        <ProtectedRoute path='/platforma' minimalType='user' exact component={() => <DashboardContainer page='cv' />} />
        <ProtectedRoute path='/ustawienia' minimalType='user' exact component={SettingsPage} />
        <ProtectedRoute path='/wyloguj' minimalType='user' exact component={Logout} />

        <Route path='/500' exact component={() => <ErrorPage error={{ status: '500', title: 'Nastąpił problem.' }} />} />
        <Route path='/404' exact component={ErrorPage} />
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  )
}

const changeLanguage = (lang, redirect = '/') => {
  window.localStorage.setItem('language', lang.toLowerCase())
  window.location.href = redirect

  return null
}

export default withRouter(RouterCV)