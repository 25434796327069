import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../store/services/ApiService'
import * as recruitmentAPI from '../../../store/api/recruitment'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'

import FormPanel from '../../../components/Form/FormWrappers/FormPanel'
import InputField from '../../../components/Form/FormFields/InputField'
import ResetAndSaveButtons from '../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../stabs/errorMessagesTranslations'
import { myUserActions } from '../../../store/ducks/my-user'

const AddHobbyFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isLoading: false
  })

  const cvId = sessionStorage.getItem('cv')
  const cv = useSelector(cvSelectors.getCvData)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })

    const hobbies = values.hobby.split(',').map((hobby) => hobby.trim()).filter((hobby) => hobby.length > 0)

    ApiService
      .fetch(recruitmentAPI.updateMyCv(cvId, { hobbies: hobbies.join(', ') }))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        setState({ error: null, isLoading: false })

        dispatch(cvActions.fetchCvSuccess(data.cv, 'edit'))
        dispatch(myUserActions.fetchMyUserSuccess(data.user))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  return (
    <Formik
      initialValues={{ hobby: cv.hobbies }}
      validationSchema={Schema}
      enableReinitialize
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel errors={state.error}>
            <InputField
              errorMessage={errors.hobby}
              hasError={errors.hobby && touched.hobby}
              id='hobby'
              label='Hobby'
              name='hobby'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Wpisz swoje zainteresowania (odzielając je przecinkami)'
              required
              value={values.hobby}
            />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={handleReset}
            disabledSave={isSubmitting}
            isEditing={false}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const Schema = Yup.object().shape({
  hobby: Yup.string()
    .required('Pole jest wymagane')
})

export default AddHobbyFormContainer
