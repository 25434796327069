import styled from 'styled-components'

import { PopUpBgColor } from '../../assets/styles/colors'

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PopUpBgColor};
  z-index: 99999;
`

export const LoaderContent = styled.div`
  width: 300px;
`
