import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import Icon from '../Icon'
import InternalOrExternal from '../../../Buttons/InternalOrExternal'

import {
  MultiSelectDropDownContainer,
  MultiSelectDropDownFieldContainer,
  MultiSelectDropDownIconContainer,
  MultiSelectDropDownIconContent,
  MultiSelectDropDownInputPlaceholder,
  MultiSelectDropDownItemContainer,
  MultiSelectDropDownItemContent,
  MultiSelectDropDownItemIcon,
  MultiSelectDropDownListContainer,
  MultiSelectDropDownListElement
} from './components'

import arrowIconPath from '../../../../assets/icons/arrow_down.svg'
import dropDownIconPath from '../../../../assets/icons/check.svg'

class MultiSelectDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClickOutside () {
    this.setState({ isOpen: false })
  }

  setIsActive (item) {
    if (Array.isArray(this.props.value)) {
      if (item.value === undefined) {
        return this.props.value.includes(item.label)
      } else {
        return this.props.value.includes(item.value)
      }
    }

    return this.props.value === item.value || this.props.value === item.label
  }

  render () {
    const handleOnClick = this.toggleShow

    return (

      <MultiSelectDropDownContainer>
        {this.props.label && (
          <label>{this.props.label}</label>
        )}
        <InternalOrExternal
          data-test='InternalOrExternalOne'
          onClick={handleOnClick}
        >
        
          <MultiSelectDropDownFieldContainer>
            <MultiSelectDropDownInputPlaceholder
              hasError={this.props.hasError}
              isOpen={this.state.isOpen}
              id={this.props.type+'-field-'+this.props.name}
            >
              {this.props.placeholder}
            </MultiSelectDropDownInputPlaceholder>

            <MultiSelectDropDownIconContainer isOpen={this.state.isOpen} label={this.props.label}>
              <MultiSelectDropDownIconContent>
                <Icon
                  data-test='IconOne'
                  icon={arrowIconPath}
                />
              </MultiSelectDropDownIconContent>
            </MultiSelectDropDownIconContainer>
          </MultiSelectDropDownFieldContainer>
        </InternalOrExternal>

        {this.state.isOpen && this.props.itemsList &&
          <MultiSelectDropDownListContainer hasError={this.props.hasError}>
            {this.props.itemsList.map((item) => ({ ...item, isActive: this.setIsActive(item) })).map((item, id) =>
              <MultiSelectDropDownListElement key={id}>
                <InternalOrExternal
                  data-test='InternalOrExternalTwo'
                  onClick={() => {
                    this.props.onClick(this.props.name, item, this.props.isMulti)
                    if (this.props.closeOnClick) {
                      this.toggleShow()
                    }
                  }}
                >
                  <MultiSelectDropDownItemContainer>
                    <MultiSelectDropDownItemContent value={item.value}>{item.label}</MultiSelectDropDownItemContent>

                    {item.isActive &&
                      <MultiSelectDropDownItemIcon>
                        <Icon
                          data-test='IconTwo'
                          icon={dropDownIconPath}
                        />
                      </MultiSelectDropDownItemIcon>}
                  </MultiSelectDropDownItemContainer>
                </InternalOrExternal>
              </MultiSelectDropDownListElement>
            )}
          </MultiSelectDropDownListContainer>}
      </MultiSelectDropDownContainer>
    )
  }
}

export default onClickOutside(MultiSelectDropDown)

MultiSelectDropDown.propTypes = {
  hasError: PropTypes.bool,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string
      ])
    })
  ),
  onClick: PropTypes.func,
  placeholder: PropTypes.string
}

MultiSelectDropDown.defaultProps = {
  hasError: false,
  placeholder: ''
}
