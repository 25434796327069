import styled from 'styled-components'

export const AddBasicUserDataFormContainer = styled.div`
  display: grid;
  grid-column-gap: 30px;

  @media (min-width: 1200px) {
    grid-template-columns: 360px auto;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: 280px auto;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 240px auto;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
