import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import ButtonComponent from '../../Buttons/ButtonComponent'
import Icon from '../../Form/FormBasicElements/Icon'

import {
  PricingBoxBenefitsTypeOneContainer,
  PricingBoxBenefitsTypeTwoContainer,
  PricingBoxButtonContainer,
  PricingBoxContainer,
  PricingBoxContent,
  PricingBoxHeaderContainer,
  PricingBoxListElement,
  PricingBoxListElementIconContainer,
  PricingBoxListElementLabel,
  PricingBoxPrice,
  PricingBoxPriceContainer,
  PricingBoxPriceCurrency,
  PricingBoxSubTitle,
  PricingBoxExtraText
} from './Atoms'

const PricingBox = ({
  benefitsListTypeOne,
  benefitsListTypeTwo,
  buttonLabel,
  buttonOnClick,
  currency,
  iconTypeOne,
  iconTypeTwo,
  price,
  salePrice,
  subTitle,
  extraText
}) => {
function createMarkup() { return {__html: subTitle}; };
  return (
  <PricingBoxContainer data-test='PricingBoxContainer'>
    <PricingBoxHeaderContainer data-test='PricingBoxHeaderContainer' sale={salePrice ? "true" : "" }>
      {
        price && (
          <PricingBoxPriceContainer data-test='PricingBoxPriceContainer' sale={salePrice ? "true" : "" }>
            <PricingBoxPrice data-test='PricingBoxPrice'>
              {price}
            </PricingBoxPrice>

            {
              currency && (
                <PricingBoxPriceCurrency data-test='PricingBoxPriceCurrency'>
                  {currency}
                </PricingBoxPriceCurrency>
              )
            }
          </PricingBoxPriceContainer>
        )
      }

      {
        salePrice && (
          <PricingBoxPriceContainer data-test='PricingBoxPriceContainer'>
            <PricingBoxPrice data-test='PricingBoxPrice'>
              {salePrice}
            </PricingBoxPrice>

            {
              currency && (
                <PricingBoxPriceCurrency data-test='PricingBoxPriceCurrency'>
                  {currency}
                </PricingBoxPriceCurrency>
              )
            }
          </PricingBoxPriceContainer>
        )
      }

      {
        subTitle && (
          <PricingBoxSubTitle data-test='PricingBoxSubTitle'>
            <div dangerouslySetInnerHTML={createMarkup()} />
          </PricingBoxSubTitle>
        )
      }

      {extraText && 
        <PricingBoxExtraText><Link to="//careeracademy.pl/jakstworzyccv/" target="_blank">{extraText}</Link></PricingBoxExtraText>
      }
    </PricingBoxHeaderContainer>

    <PricingBoxContent data-test='PricingBoxContent'>
    

      <PricingBoxButtonContainer data-test='PricingBoxButtonContainer'>
        <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='PricingBoxButtonComponent'
          display='inline-flex'
          fontBold
          label={buttonLabel}
          onClick={buttonOnClick}
          size='sizeM'
          type='onClick'
        />
      </PricingBoxButtonContainer>
    </PricingBoxContent>
  </PricingBoxContainer>
)}

export default PricingBox

PricingBox.propTypes = {
  benefitsListTypeOne: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  benefitsListTypeTwo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  currency: PropTypes.string,
  iconTypeOne: PropTypes.string,
  iconTypeTwo: PropTypes.string,
  price: PropTypes.string,
  salePrice: PropTypes.string,
  subTitle: PropTypes.string,
  extraText: PropTypes.string
}
