import React from 'react'

import AddedItem from '../../../List/AddedItem'
import CheckBoxWithTextLinkText from '../../FormBasicElements/CheckBoxWithTextLinkText'
import EditorField from '../../FormFields/EditorField'
import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import TwoMultiSelectDropDownsField from '../../FormFields/TwoMultiSelectDropDownsField'

import {
  AddExperienceFormCheckBoxWithTextLinkTextContainer,
  AddExperienceFormEditorFieldContainer
} from './components'

import months from '../../../../stabs/months'
import generateYears from '../../../../utils/generateYears'
import TextAreaField from '../../FormFields/TextAreaField'
import translationsList from '../../../../stabs/translationsList'
import { CreatorCvStepOneEditorFieldContainer } from '../AddUserForm/components'

const AddExperienceForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleMultiSelect,
  handleCheckbox,
  handleEdit,
  handleField,
  isLoading,
  isEditing,
  items,
  touched,
  values
}) => (
  <FormPanel
    data-test='formPanel'
    errors={apiError}
  >
    {
      items.map((item, index) => (
        <AddedItem
          data-test='addedItem'
          isInsideForm
          key={index}
          title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
          description={item.description}
          onClickDelete={() => handleDelete(item._id)}
          onClickEdit={() => handleEdit(item._id)}
          isLoading={isLoading}
        />
      ))
    }

    <FormTwoColumns data-test='formTwoColumnsOne'>
      <InputField
        data-test='position'
        errorMessage={errors.position}
        hasError={errors.position && touched.position}
        id='position'
        label='Stanowisko'
        name='position'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='np. Graphic Designer'
        required
        value={values.position}
      />

      <InputField
        data-test='location'
        errorMessage={errors.location}
        hasError={errors.location && touched.location}
        id='location'
        label='Lokalizacja'
        name='location'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='np. Warszawa'
        required
        value={values.location}
      />
    </FormTwoColumns>

    <InputField
      data-test='companyName'
      errorMessage={errors.companyName}
      hasError={errors.companyName && touched.companyName}
      id='companyName'
      label='Nazwa firmy'
      name='companyName'
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='Wpisz nazwę firmy...'
      required
      value={values.companyName}
    />

    <AddExperienceFormEditorFieldContainer data-test='AddExperienceFormEditorFieldContainerCustomText'>
      <EditorField
        label='Jaki był Twój zakres obowiązków?'
        placeholder='Opisz krótko swoje zajęcia na tym stanowisku i wspomnij o swoich sukcesach.'
        errorMessage={errors.description}
        hasError={errors.description && touched.description}
        name='description'
        onChange={(value) => handleField('description', value)}
        value={values.description}
        isEditing={isEditing}
      />
    </AddExperienceFormEditorFieldContainer>

    <AddExperienceFormEditorFieldContainer data-test='AddExperienceFormEditorFieldContainerBigSuccess'>
      <EditorField
        label='Największe sukcesy'
        placeholder='Opisz krótko, swoje największe sukcesy.'
        data-test='userBiggestSuccess'
        errorMessage={errors.biggestSuccess}
        hasError={errors.biggestSuccess && touched.biggestSuccess}
        name='biggestSuccess'
        onChange={(value) => handleField('biggestSuccess', value)}
        value={values.biggestSuccess}
        isEditing={isEditing}
      />
    </AddExperienceFormEditorFieldContainer>

    <AddExperienceFormEditorFieldContainer data-test='AddExperienceFormEditorFieldContainerHaveLearned'>
      <EditorField
        label='Ta praca nauczyła mnie'
        placeholder='Opisz pokrótce, czego nauczyła Cię ta praca.'
        data-test='userHaveLearned'
        errorMessage={errors.haveLearned}
        hasError={errors.haveLearned && touched.haveLearned}
        name='haveLearned'
        onChange={(value) => handleField('haveLearned', value)}
        value={values.haveLearned}
        isEditing={isEditing}
      />
    </AddExperienceFormEditorFieldContainer>

    <FormTwoColumns data-test='formTwoColumnsTwo'>
      <TwoMultiSelectDropDownsField
        data-test='twoMultiSelectDropDownsFieldOne'
        hasErrorFirst={errors.startDateMonth && touched.startDateMonth}
        hasErrorSecond={errors.startDateYear && touched.startDateYear}
        itemsListFirst={months}
        itemsListSecond={generateYears}
        label='Od'
        nameFirst='startDateMonth'
        nameSecond='startDateYear'
        handleMultiSelect={handleMultiSelect}
        placeholderFirst={values.startDateMonth !== '' ? months.filter((month) => month.value === values.startDateMonth)[0].label : 'Miesiąc'}
        placeholderSecond={values.startDateYear !== '' ? values.startDateYear : 'Rok'}
        required
        values={values}
        type='experience'
      />

      <TwoMultiSelectDropDownsField
        data-test='twoMultiSelectDropDownsFieldTwo'
        errors={errors}
        touched={touched}
        hasErrorFirst={errors.endDateMonth && touched.endDateMonth}
        hasErrorSecond={errors.endDateYear && touched.endDateYear}
        itemsListFirst={months}
        itemsListSecond={generateYears}
        label='DO'
        nameFirst='endDateMonth'
        nameSecond='endDateYear'
        handleMultiSelect={handleMultiSelect}
        placeholderFirst={values.endDateMonth !== '' ? months.filter((month) => month.value === values.endDateMonth)[0].label : 'Miesiąc'}
        placeholderSecond={values.endDateYear !== '' ? values.endDateYear : 'Rok'}
        values={values}
      />
    </FormTwoColumns>

    <AddExperienceFormCheckBoxWithTextLinkTextContainer
      data-test='addExperienceFormCheckBoxWithTextLinkTextContainer'
    >
      <CheckBoxWithTextLinkText
        data-test='checkBoxWithTextLinkText'
        checked={values.stillOngoing}
        onChange={() => handleCheckbox('stillOngoing')}
        textBeforeLink='nadal trwa'
      />
    </AddExperienceFormCheckBoxWithTextLinkTextContainer>
  </FormPanel>
)

export default AddExperienceForm
