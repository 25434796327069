import styled from 'styled-components'

import {
  OrangeColor,
  PurpleColor
} from '../../../assets/styles/colors'

export const Label = styled.div`
  color: ${PurpleColor};
  font-weight: 600;

  ${props => {
    switch (props.alignCenter) {
      case true: return 'text-align: center;'
      default: return 'text-align: left;'
    }
  }}
  
  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const LabelH1 = styled.h1`
  color: ${PurpleColor};
  font-weight: 600;

  ${props => {
    switch (props.alignCenter) {
      case true: return 'text-align: center;'
      default: return 'text-align: left;'
    }
  }}
  
  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const LabelH2 = styled.h2`
  color: ${PurpleColor};
  font-weight: 600;

  ${props => {
    switch (props.alignCenter) {
      case true: return 'text-align: center;'
      default: return 'text-align: left;'
    }
  }}
  
  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const Underline = styled.div`
  background-color: ${OrangeColor};
  height: 4px;
  width: 62px;

  ${props => {
    switch (props.alignCenter) {
      case true: return `
        @media (min-width: 1200px) {
          margin: 40px auto;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 30px auto;
        }
  
        @media (min-width: 768px) and (max-width: 991px) {
          margin: 25px auto;
        }
  
        @media (min-width: 576px) and (max-width: 767px) {
          margin: 20px auto;
        }
  
        @media (min-width: 320px) and (max-width: 575px) {
          margin: 15px auto;
        }
      `
      default: return `
        @media (min-width: 1200px) {
          margin: 40px 0;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 30px 0;
        }
  
        @media (min-width: 768px) and (max-width: 991px) {
          margin: 25px 0;
        }
  
        @media (min-width: 576px) and (max-width: 767px) {
          margin: 20px 0;
        }
  
        @media (min-width: 320px) and (max-width: 575px) {
          margin: 15px 0;
        }
      `
    }
  }}
`
