import styled from 'styled-components'

import { PurpleColorXS } from '../../../assets/styles/colors'

export const ChooseColorItemContainer = styled.div`
  border-radius: 50%;
  cursor: pointer;

  ${props => {
    switch (props.bgColor) {
      case 'Aqua': return 'background-color: #50E3C2;'
      case 'Blue': return 'background-color: #4A90E2;'
      case 'Green': return 'background-color: #B8E986;'
      case 'Orange': return 'background-color: #F5A623;'
      case 'LightPlum': return `background-color: ${PurpleColorXS};`
      case 'Pink': return 'background-color: #FF93D6;'
      case 'Plum': return 'background-color: #CF9BFF;'
      case 'Purple': return 'background-color: #9013FE;'
      case 'Red': return 'background-color: #FF556A;'
      case 'Yellow': return 'background-color: #F8E71C;'
      default: return ''
    }
  }}



  ${props => {
    switch (props.isSelected) {
      case true: 
        switch (props.bgColor) {
          case 'Aqua': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(80,227,194,0.4);'
          case 'Blue': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(74,144,226,0.4);'
          case 'Green': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(184,233,134,0.4);'
          case 'Orange': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(245,166,35,0.4);'
          case 'LightPlum': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(80,227,194,0.4);'
          case 'Pink': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(255,147,214,0.4);'
          case 'Plum': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(207,155,255,0.4);'
          case 'Purple': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(144,19,254,0.4);'
          case 'Red': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(255,85,106,0.4);'
          case 'Yellow': return 'width: 29px;height: 29px;box-shadow: 0 7px 15px 0 rgba(248,231,28,0.4);'
        }
      default: return `
        width: 21px;
        height: 21px;
      `
    }
  }}
`
