import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, Slate, useSlate, withReact } from 'slate-react'
import { createEditor, Editor, Text, Transforms } from 'slate'
import { jsx } from 'slate-hyperscript'
import escapeHtml from 'escape-html'

import Icon from '../Form/FormBasicElements/Icon'
import IconBold from '../../assets/icons/editor/bold.svg'
import IconItalic from '../../assets/icons/editor/italic.svg'
import IconUnderline from '../../assets/icons/editor/underline.svg'
import IconOList from '../../assets/icons/editor/list-ol.svg'
import IconUList from '../../assets/icons/editor/list-ul.svg'

import { Button, TextAreaContainer, Toolbar } from './atoms'

import './styles.css'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const loadValue = (value) => {
  if (value === undefined || value.length === 0) {
    return initialValue
  }

  const document = new DOMParser().parseFromString(`<div>${value}</div>`, 'text/html')
  const state =  deserialize(document.body)

  return state
}

const RichTextEditorField = ({ value, onChange, placeholder, isEditing }) => {
  let [editorValue, setEditorValue] = useState(loadValue(value))
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withReact(createEditor()), [])

  React.useEffect(() => {
      setEditorValue(loadValue(value))
  }, [isEditing])


  return (
    <Slate editor={editor} value={editorValue} onChange={newValue => {
      setEditorValue(newValue)
      console.log(newValue)
      onChange(serialize({ children: newValue }))
    }}>
      <Toolbar>
        <MarkButton format="bold" icon="bold" />
        <MarkButton format="italic" icon="italic" />
        <MarkButton format="underline" icon="underline" />
        <BlockButton format="numbered-list" icon="list_numbered" />
        <BlockButton format="bulleted-list" icon="list_bulleted" />
      </Toolbar>

      <TextAreaContainer>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </TextAreaContainer>
    </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.italic) {
    children = <i>{children}</i>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon icon={getIcon(icon)} />
    </Button>
  )
}

const getIcon = (icon) => {
  switch (icon) {
    case 'bold':
      return IconBold;
    case 'italic':
      return IconItalic
    case 'underline':
      return IconUnderline
    case 'list_numbered':
      return IconOList
    case 'list_bulleted':
      return IconUList
  }
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      icon={icon}
    >
      <Icon icon={getIcon(icon)} />
    </Button>
  )
}

const serialize = (node) => {
  if (Text.isText(node)) {
    let text = escapeHtml(node.text)

    if (node.bold) {
      text = `<b>${text}</b>`
    }

    if (node.italic) {
      text = `<i>${text}</i>`
    }

    if (node.underline) {
      text = `<u>${text}</u>`
    }

    return text
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'bold':
      return `<b>${children}</b>`
    case 'italic':
      return `<i>${children}</i>`
    case 'underline':
      return `<u>${children}</u>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'paragraph':
      if (children.length === 0) {
        return `<br />`
      }

      return `<p>${children}</p>`
    default:
      return children
  }
}

const ELEMENT_TAGS = {
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  UL: () => ({ type: 'bulleted-list' }),
}

const TEXT_TAGS = {
  I: () => ({ italic: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName, childNodes } = el

  const children = Array.from(childNodes)
  .map(deserialize)
  .flat()

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    if (children.length === 0) {
      return ''
    }

    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }

  return children
}

export default RichTextEditorField
