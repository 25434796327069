export const BannerBoxShadowColor = 'rgba(61,50,112,0.24)'
export const InsetPurpleBoxShadowColor = 'inset 0 0 10px 3px rgba(217,209,255,0.3)'
export const OrangeColor = '#FCD0A3'
export const PinkButtonBoxShadowColor = 'rgba(224,5,123,0.1)'
export const PinkColor = '#FD007A'
export const PinkColorM = '#E0057B'
export const PinkColorS = '#E6BDD3'
export const PopUpBgColor = 'rgba(34, 27, 65, 0.68)'
export const PurpleBoxShadowColor = '0 10px 15px 0 rgba(217,209,255,0.3)'
export const PurpleColor = '#3D3270'
export const PurpleColorL = '#6B677E'
export const PurpleColorM = '#9A95B1'
export const PurpleColorS = '#EFEBFF'
export const PurpleColorXL = '#9882FF'
export const PurpleColorXS = '#D9D1FF'
export const RedColor = '#FF1919'
export const SilverColor = '#BBB'
export const SilverColorM = '#FBFAFF'
export const SilverColorS = '#F9F9F9'
export const SilverColorXS = '#F8F8F8'
export const WhiteColor = '#FFF'
export const WhiteColorM = '#F8F7FE'

export const CheckBoxSelectedColor = '#3F3174'
export const FBColor = '#4167B2'
export const LinkedinColor = '#0077B5'
export const TwitterColor = '#1da1f2'
export const YTColor = '#E62218'

export const TransitionEffect = 'ease-in-out'
export const TransitionTime = '200ms'
export const TransitionType = 'all'
export const Transition = `${TransitionType} ${TransitionTime} ${TransitionEffect}`
