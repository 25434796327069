import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify'

import CreatorCvStepOne from '../../../pages/RegisterSteps/StepOne'
import CreatorCvStepTwo from '../../../pages/RegisterSteps/StepTwo'
import CreatorCvStepThree from '../../../pages/RegisterSteps/StepThree'
import CreatorCvStepFour from '../../../pages/RegisterSteps/StepFour'
import CreatorCvStepFive from '../../../pages/RegisterSteps/StepFive'

import Description from '../../Section/Description'
import FormCompanyStepFive from '../../../pages/PlatformCompanySteps/CompanyStepFive'
import FormCompanyStepFour from '../../../pages/PlatformCompanySteps/CompanyStepFour'
import FormCompanyStepOne from '../../../pages/PlatformCompanySteps/CompanyStepOne'
import FormCompanyStepThree from '../../../pages/PlatformCompanySteps/CompanyStepThree'
import FormCompanyStepTwo from '../../../pages/PlatformCompanySteps/CompanyStepTwo'
import FormUserStepOne from '../../../pages/PlatformUserSteps/UserStepOne'
import FormUserStepThree from '../../../pages/PlatformUserSteps/UserStepThree'
import FormUserStepTwo from '../../../pages/PlatformUserSteps/UserStepTwo'
import InnerWrapper from '../InnerWrapper'
import LinkComponent from '../../Buttons/LinkComponent'
import Site from '../Site'
import Steps from '../../Steps/Steps'

import {
  FormStepsWrapperArrowNextContainer,
  FormStepsWrapperArrowPrevContainer,
  FormStepsWrapperArrowsContainer,
  FormStepsWrapperContainer,
  FormStepsWrapperDescriptionContainer,
  FormStepsWrapperStepsContainer
} from './components'

import arrowIconPath from '../../../assets/icons/arrow_right.svg'
import isExpoDay from '../../../utils/isExpoDay'
import translationsList from '../../../stabs/translationsList'
import AuthService from '../../../store/services/AuthService'

const selectStep = (
  option,
  primaryEvent
) => {
  switch (option) {
    case 'companyStep1': return <FormCompanyStepOne primaryEvent={primaryEvent} />
    case 'companyStep2': return <FormCompanyStepTwo primaryEvent={primaryEvent} />
    case 'companyStep3': return <FormCompanyStepThree primaryEvent={primaryEvent} />
    case 'companyStep4': return <FormCompanyStepFour primaryEvent={primaryEvent} />
    case 'companyStep5': return <FormCompanyStepFive primaryEvent={primaryEvent} />
    case 'creatorCvStep1': return <CreatorCvStepOne />
    case 'creatorCvStep2': return <CreatorCvStepTwo />
    case 'creatorCvStep3': return <CreatorCvStepThree />
    case 'creatorCvStep4': return <CreatorCvStepFour />
    case 'creatorCvStep5': return <CreatorCvStepFive />
    case 'userStep1': return <FormUserStepOne primaryEvent={primaryEvent} />
    case 'userStep2': return <FormUserStepTwo primaryEvent={primaryEvent} />
    case 'userStep3': return <FormUserStepThree primaryEvent={primaryEvent} />
    default: return null
  }
}

const selectDescriptionText = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepOne.description
    case 'companyStep2': return translationsList.form.companySteps.companyStepTwo.description
    case 'companyStep3': return translationsList.form.companySteps.companyStepThree.description
    case 'companyStep4': return translationsList.form.companySteps.companyStepFour.description
    case 'companyStep5': return translationsList.form.companySteps.companyStepFive.description
    case 'creatorCvStep1': return ''
    case 'creatorCvStep2': return translationsList.form.creatorCv.creatorCvStepTwo.description
    case 'creatorCvStep3': return ''
    case 'creatorCvStep4': return ''
    case 'creatorCvStep3': return translationsList.form.creatorCv.creatorCvStepThree.description
    case 'userStep1': return translationsList.form.stepOne.description
    case 'userStep2': return translationsList.form.stepTwo.description
    case 'userStep3': return translationsList.form.stepThree.description
    default: return ''
  }
}

const selectLeftLabel = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return translationsList.stepsController.back
    case 'companyStep3': return translationsList.stepsController.back
    case 'companyStep4': return translationsList.stepsController.back
    case 'companyStep5': return translationsList.stepsController.back
    case 'creatorCvStep1': return null
    case 'creatorCvStep2': return translationsList.stepsController.back
    case 'creatorCvStep3': return translationsList.stepsController.back
    case 'creatorCvStep4': return translationsList.stepsController.back
    case 'creatorCvStep5': return translationsList.stepsController.back
    case 'userStep1': return null
    case 'userStep2': return translationsList.stepsController.back
    case 'userStep3': return translationsList.stepsController.back
    default: return ''
  }
}

const selectLeftLink = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return '/platforma/podstawowe-dane'
    case 'companyStep3': return '/platforma/szczegoly'
    case 'companyStep4': return '/platforma/rekrutacja'
    case 'companyStep5': return '/platforma/benefity-i-wyroznienia'
    case 'creatorCvStep1': return '/'
    case 'creatorCvStep2': return '/rejestracja'
    case 'creatorCvStep3': return '/rejestracja/szablon'
    case 'creatorCvStep4': return '/rejestracja/metryka'
    case 'creatorCvStep5': return '/rejestracja/profil'
    case 'userStep1': return null
    case 'userStep2': return '/platforma/podstawowe-dane'
    case 'userStep3': return '/platforma/moje-szkolenia'
    default: return ''
  }
}

const selectRightLabel = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return translationsList.stepsController.skip
    case 'companyStep3': return translationsList.stepsController.skip
    case 'companyStep4': return translationsList.stepsController.skip
    case 'companyStep5': return translationsList.stepsController.homePage
    case 'creatorCvStep1': return translationsList.stepsController.next
    case 'creatorCvStep2': return translationsList.stepsController.next
    case 'creatorCvStep3': return translationsList.stepsController.next
    case 'creatorCvStep4': return translationsList.stepsController.next
    case 'creatorCvStep5': return translationsList.stepsController.myCv
    case 'userStep1': return null
    case 'userStep2': return translationsList.stepsController.skip
    case 'userStep3': return translationsList.stepsController.homePage
    default: return ''
  }
}

const selectRightLink = (
  option
) => {
  switch (option) {
    case 'companyStep1': return null
    case 'companyStep2': return '/platforma/rekrutacja'
    case 'companyStep3': return '/platforma/benefity-i-wyroznienia'
    case 'companyStep4': return '/platforma/edukacja'
    case 'companyStep5': return '/'
    case 'creatorCvStep1': return '/rejestracja/szablon'
    case 'creatorCvStep2': return '/rejestracja/metryka'
    case 'creatorCvStep3': return '/rejestracja/profil'
    case 'creatorCvStep4': return '/rejestracja/podsumowanie'
    case 'creatorCvStep5': return '/moje-cv'
    case 'userStep1': return null
    case 'userStep2': return '/platforma/pobierz-bilet'
    case 'userStep3': return '/'
    default: return ''
  }
}

let selectStepsList = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepsList1
    case 'companyStep2': return translationsList.form.companySteps.companyStepsList2
    case 'companyStep3': return translationsList.form.companySteps.companyStepsList3
    case 'companyStep4': return translationsList.form.companySteps.companyStepsList4
    case 'companyStep5': return translationsList.form.companySteps.companyStepsList5
    case 'creatorCvStep1': return translationsList.registerSteps.registerStepsList.stepsList1
    case 'creatorCvStep2': return translationsList.registerSteps.registerStepsList.stepsList2
    case 'creatorCvStep3': return translationsList.registerSteps.registerStepsList.stepsList3
    case 'creatorCvStep4': return translationsList.registerSteps.registerStepsList.stepsList4
    case 'creatorCvStep5': return translationsList.registerSteps.registerStepsList.stepsList5
    case 'userStep1': return isExpoDay()
      ? translationsList.form.stepsList1_live
      : translationsList.form.stepsList1
    case 'userStep2': return isExpoDay()
      ? translationsList.form.stepsList2_live
      : translationsList.form.stepsList2
    case 'userStep3': return isExpoDay()
      ? translationsList.form.stepsList3_live
      : translationsList.form.stepsList3
    default: return []
  }
}

// selectStepsList = selectStepsList.shift();

const selectStepsTitle = (
  option
) => {
  switch (option) {
    case 'companyStep1': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep2': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep3': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep4': return translationsList.form.companySteps.companyStepsTitle
    case 'companyStep5': return translationsList.form.companySteps.companyStepsTitle
    case 'creatorCvStep1': return 'Stwórz piękne i skuteczne CV w 5 krokach!'
    case 'creatorCvStep2': return 'Teraz pokażemy Ci, jakie możliwości daje nasz kreator!'
    case 'creatorCvStep3': return 'Jesteś już blisko nowego, lepszego CV!'
    case 'creatorCvStep4': return 'Wypełnij CV korzystając z naszych wskazówek'
    case 'creatorCvStep5': return 'Pobierz swoje CV i zacznij aplikować o wymarzoną pracę!'
    case 'userStep1': return translationsList.form.labels.titleLabel
    case 'userStep2': return translationsList.form.labels.titleLabel
    case 'userStep3': return translationsList.form.labels.titleLabel
    default: return ''
  }
}


const FormStepsWrapper = ({
  option,
  primaryEvent
}) => { 





const changeStep = (link) => {
  console.log(link)
  let yourImage = document.getElementById('yourImage')

  if( yourImage ) {
    let form = document.getElementsByTagName("form")[0];
    let elements = document.body.getElementsByTagName('label')
    let inputIDarray = []
    let filled = true
    let email = true

    for(var i = 0, max = elements.length; i < max; i++) {
      if( elements[i].hasChildNodes()) {
        if( elements[i].firstChild.nodeType == 1 ) {
          var inputID = elements[i].getAttribute('for')
          inputIDarray.push(inputID);
        }
      } 
    }

    inputIDarray.forEach(function(item, index, array) {
      let element = document.getElementById(item);
      if( element.value == '' ) {
        filled = false;
      }

      if( element.getAttribute('type') == 'email' ) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        email = re.test(String(element.value).toLowerCase());
      }
    });

    console.log(filled)

    if (!filled && email ) {
      toast.error('Musisz uzupełnić wymagane pola zanim przejdziesz do następnego kroku!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }

    let next = document.getElementById('nextStepButton')
    next.click()
  } else {
    window.location.href = link
  }

  
}
let stepIndex = 0
  return (
  <Site
    menuItemsList={
      option.includes('company')
        ? [{
          fontBold: false,
          inNewWindow: true,
          label: 'Złóż zamówienie na targi',
          link: 'https://dla-firm.careerexpo.pl/targi/zamowienie/'
        }]
        : []
    }
    withNav={option.includes('company')}
    isLoggedOn={AuthService.loggedIn()}
  >
  
    <InnerWrapper data-tesst='InnerWrapper'>
    {option !== 'creatorCvStep1' &&
      <FormStepsWrapperStepsContainer data-test='FormStepsWrapperStepsContainer'>
        <Steps
          data-test='Steps'
          itemsList={selectStepsList(option)}
          title={selectStepsTitle(option)}
          id={option}
        />
      </FormStepsWrapperStepsContainer>
    }

      <FormStepsWrapperDescriptionContainer data-test='FormStepsWrapperDescriptionContainer'>
        <Description
          alignCenter
          data-test='Description'
          description={selectDescriptionText(option)}
        />
      </FormStepsWrapperDescriptionContainer>

      {
        option !== 'userStep1' &&
        option !== 'companyStep1' && (
          <FormStepsWrapperArrowsContainer data-test='FormStepsWrapperArrowsContainer'>
            {
              option !== 'creatorCvStep2' && option !== 'creatorCvStep1' && (
                <FormStepsWrapperArrowPrevContainer>
                  <LinkComponent
                    colorStroke='purpleColor'
                    colorText='purpleColor'
                    data-test='LinkComponentLeft'
                    fontSize='sizeXL'
                    iconBefore={arrowIconPath}
                    label={selectLeftLabel(option)}
                    link={selectLeftLink(option)}
                    rotateIcons='180'
                    type='internal'
                  />
                </FormStepsWrapperArrowPrevContainer>
              )
            }

            {
              option !== 'creatorCvStep5' && option !== 'creatorCvStep1' && (
                <FormStepsWrapperArrowNextContainer data-test='FormStepsWrapperArrowNextContainer'>
                  <LinkComponent
                    colorStroke='purpleColor'
                    colorText='purpleColor'
                    data-test='LinkComponentRight'
                    fontSize='sizeL'
                    iconAfter={arrowIconPath}
                    label={selectRightLabel(option)}
                    link={selectRightLink(option)}
                    type='onClick'
                    onClick={(e) => changeStep(selectRightLink(option))}
                  />
                </FormStepsWrapperArrowNextContainer>
              )
            }
          </FormStepsWrapperArrowsContainer>
        )
      }

      <FormStepsWrapperContainer data-test='FormStepsWrapperContainer'>
        {selectStep(option, primaryEvent)}
      </FormStepsWrapperContainer>
    </InnerWrapper>
  </Site>
)}

export default FormStepsWrapper

FormStepsWrapper.propTypes = {
  option: PropTypes.string
}
