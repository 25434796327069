import React from 'react'
import PropTypes from 'prop-types'

import Errors from '../../FormBasicElements/Errors'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import Label from '../../FormBasicElements/Label'
import MultiSelectDropDown from '../../FormBasicElements/MultiSelectDropDown'

import {
  TwoMultiSelectDropDownsFieldContainer
} from './components'

const TwoMultiSelectDropDownsField = ({
  errorMessage,
  handleMultiSelect,
  hasErrorFirst,
  hasErrorSecond,
  itemsListFirst,
  itemsListSecond,
  label,
  nameFirst,
  nameSecond,
  placeholderFirst,
  placeholderSecond,
  required,
  values,
  type
}) => (
  <TwoMultiSelectDropDownsFieldContainer>
    <Label
      label={label}
      required={required}
    />

    <FormTwoColumns>
      <MultiSelectDropDown
        data-test='first'
        hasError={hasErrorFirst}
        itemsList={itemsListFirst}
        name={nameFirst}
        onClick={handleMultiSelect}
        placeholder={placeholderFirst}
        value={values[nameFirst]}
        closeOnClick
        type={type}
      />

      <MultiSelectDropDown
        data-test='second'
        hasError={hasErrorSecond}
        itemsList={itemsListSecond}
        name={nameSecond}
        onClick={handleMultiSelect}
        placeholder={placeholderSecond}
        value={values[nameSecond]}
        closeOnClick
        type={type}
      />
    </FormTwoColumns>

    <Errors
      errorMessage={errorMessage}
      hasError={hasErrorFirst || hasErrorSecond}
    />
  </TwoMultiSelectDropDownsFieldContainer>
)

export default TwoMultiSelectDropDownsField

TwoMultiSelectDropDownsField.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  itemsListFirst: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string
      ])
    })
  ),
  itemsListSecond: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string
      ])
    })
  ),
  label: PropTypes.string,
  onClickFirst: PropTypes.func,
  onClickSecond: PropTypes.func,
  placeholderFirst: PropTypes.string,
  placeholderSecond: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool
}

// TODO: add error message
