import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../../../assets/styles/colors'

export const AddUserFormNameAndSurnameContainer = styled.div``

export const AddUserFormCustomTitle = styled.div`
  padding: 0 0 12px;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  color: ${PurpleColor};
  text-align: left;
`

export const AddUserFormCustomDescription = styled.div`
  padding: 0 0 20px;
  line-height: 21px;
  font-size: 14px;
  color: ${PurpleColorM};
  text-align: left;
`

export const CreatorCvStepOneNameAndSurnameContainer = styled.div``

export const CreatorCvStepOneEditorFieldContainer = styled.div`
  & .rdw-inline-wrapper [title="Strikethrough"],
  & .rdw-inline-wrapper [title="Monospace"],
  & .rdw-inline-wrapper [title="Superscript"],
  & .rdw-inline-wrapper [title="Subscript"],
  & .rdw-block-wrapper,
  & .rdw-fontsize-wrapper,
  & .rdw-fontfamily-wrapper,
  & .rdw-list-wrapper,
  & .rdw-text-align-wrapper,
  & .rdw-colorpicker-wrapper,
  & .rdw-link-wrapper,
  & .rdw-embedded-wrapper,
  & .rdw-emoji-wrapper,
  & .rdw-image-wrapper,
  & .rdw-remove-wrapper,
  & .rdw-history-wrapper {
    display: none;
  }
`

export const CreatorCvStepOneCustomTitle = styled.div`
  color: ${PurpleColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 0 12px;
  text-align: left;
`

export const CreatorCvStepOneCustomDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 14px;
  line-height: 21px;
  padding: 0 0 20px;
  text-align: left;
`
