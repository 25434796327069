import testImage from '../assets/img/cheerful_man.jpg'

import annaScislowskaImagePath from '../assets/img/contact/staff_anna_scislowska.png'
import jacekCiechanowskiImagePath from '../assets/img/contact/staff_jacek_ciechanowski.png'
import karolinaMerdaImagePath from '../assets/img/contact/staff_karolina_merda.jpg'

import HomepageCardClassroomIconPath from '../assets/icons/homepage/classroom.svg'
import HomepageCardSuccessIconPath from '../assets/icons/homepage/success.svg'
import HomepageCardWorkIconPath from '../assets/icons/homepage/work.svg'

import HomepageCvTemplate1ImagePath from '../assets/img/homepage/1.jpg'
import HomepageCvTemplate2ImagePath from '../assets/img/homepage/2.jpg'
import HomepageCvTemplate3ImagePath from '../assets/img/homepage/3.jpg'

import HomepageSquarePanel1IconPath from '../assets/icons/homepage/squarePanel1.svg'
import HomepageSquarePanel2IconPath from '../assets/icons/homepage/squarePanel2.svg'
import HomepageSquarePanel3IconPath from '../assets/icons/homepage/squarePanel3.svg'

import HomepageEmployersAmazonImagePath from '../assets/icons/homepage/employers/amazon.png'
import HomepageEmployersCeneoImagePath from '../assets/icons/homepage/employers/ceneo.png'
import HomepageEmployersHpImagePath from '../assets/icons/homepage/employers/hp.png'
import HomepageEmployersImpelImagePath from '../assets/icons/homepage/employers/impel.png'
import HomepageEmployersQatarImagePath from '../assets/icons/homepage/employers/qatar.png'
import HomepageEmployersVolvoImagePath from '../assets/icons/homepage/employers/volvo.png'

import TestimonialOne from '../assets/img/cv/1.png'
import TestimonialTwo from '../assets/img/cv/2.png'
import TestimonialThree from '../assets/img/cv/3.png'
import TestimonialFour from '../assets/img/cv/4.png'

import ebookImage from '../assets/img/ebook.png'

const translationsListPL = {
  ticketAdditionalInfo: 'Bilet imienny upoważnia do wstępu jednej osoby na targi. Bilet ważny jest tylko za okazaniem dowodu potwierdzającego tożsamość.',
  banners: {
    bannersList: [
      {
        buttonLabel: 'Stwórz CV',
        description: 'Skorzystaj z naszego kreatora CV. Wybierz jeden z wielu szablonów i dostosuj go do własnych potrzeb.',
        link: '/rejestracja',
        title: 'Stwórz swoje niepowtrzalane CV'
      }
    ]
  },
  buttonSwitch: {
    itemsList: [
      {
        isActive: true,
        label: 'Wszystkie'
      },
      {
        isActive: false,
        label: 'Zapisane'
      }
    ]
  },
  buttons: {
    labels: {
      addNewButtonLabel: 'Dodaj kolejny'
    }
  },
  chooseColor: {
    labels: {
      buttonLabel: 'stwórz własny',
      headerLabel: 'Wybierz kolor przewodni twojego CV',
      subtitleLabel: 'Bądź wyjątkowy'
    }
  },
  chooseImage: {
    labels: {
      chooseImageInfo: 'Maksymalnie rozmiar zdjęcia to 320x320 (500kb)',
      chooseImageRemoveButtonLabel: 'Usuń',
      chooseImageSelectButtonLabel: 'Wybierz zdjęcie',
      imageLabel: 'Twoje zdjęcie'
    }
  },
  cities: {
    labels: {
      titleLabel: 'Wybierz miasto'
    },
    citiesList: [
      {
        _id: 0,
        label: 'Wrocław',
        isSelected: false
      }
    ]
  },
  companies: {
    labels: {
      employer: 'pracodawcĂłw',
      found: 'Znaleziono'
    }
  },
  controls: {
    labels: {
      buttonHide: 'Ukryj',
      buttonShowMore: 'Pokaż więcej'
    }
  },
  employerProfiles: {
    labels: {
      complianceLabel: 'zgodności',
      subtitleSimilarEmployersLabel: 'Podobni pracodawcy'
    }
  },
  errorsMessages: {
    userDataForm: {
      userAddressErrorMessage: 'Wpisz poprawny adres',
      userCityErrorMessage: 'Wpisz poprawne miasto',
      userCountryErrorMessage: 'Wpisz poprawny kraj',
      userDateOfBirthErrorMessage: 'Wpisz poprawną datę urodzenia ',
      userEmailErrorMessage: 'Wpisz poprawny adres e-mail',
      userNameErrorMessage: 'Wpisz poprawne imię',
      userNationalityErrorMessage: 'Wpisz poprawne obywatelsto',
      userPhoneErrorMessage: 'Wpisz poprawny numer telefonu',
      userPostCodeErrorMessage: 'Wpisz poprawny kod pocztowy',
      userSocialMediaDescriptionErrorMessage: 'Wpisz poprawny opis',
      userSocialMediaErrorMessage: 'Wpisz poprawny link',
      userSurnameErrorMessage: 'Wpisz poprawne nazwisko'
    },
    notSaved: 'Nastąpił problem z zapisywaniem. Spróbuj ponownie później. Jeżeli problem się ponawia skontaktuj się z nami.',
    validationError: 'Wprowadzono nie poprawne dane.',
    userExist: 'Użytkownik z takim emailem już istnieje.',
    userNotExist: 'Nieprawidłowy email lub hasło.'
  },
  events: {
    labels: {
      addressLabel: 'Adres',
      dateLabel: 'Data',
      nameAndSurnameLabel: 'Imię i nazwisko',
      placeLabel: 'Miejsce',
      positionLabel: 'Stanowisko',
      timeLabel: 'Godzina',
      titleLabel: 'Zapraszamy'
    },
    eventsList: [
      {
        eventAdditionalInfo: 'Bilet imienny upoważnia do wstępu jednej osoby na targi. Bilet ważny jest tylko za okazaniem dowodu potwierdzającego tożsamość.',
        eventAddress: 'Hala Stulecia, Wystawowa 1, Wrocław',
        eventDate: '20.05.2018',
        eventTimeEnd: '18:00',
        eventTimeStart: '10:00'
      }
    ]
  },
  faq: {
    labels: {
      faqPage: 'Pomoc'
    },
    itemsList: [
      {
        title: '01. Co muszę zrobić, żeby wziąć udział w targach Career EXPO online?',
        text: 'Wystarczy, że zarejestrujesz się za pośrednictwem naszej strony internetowej i utworzysz swoje konto użytkownika, wypełnisz swój profil i możesz korzystać ze wszystkich aktywności.'
      },
      {
        title: '02. Kiedy odbywa się Career EXPO?',
        text: 'Targi online odbywać się będą w godzinach 14:00-20:00, zarówno pierwszego jak i drugiego dnia. (1 i 2 kwietnia 2020).'
      },
      {
        title: '03. Czy udział w targach online jest płatny?',
        text: 'Nie, udział w Career EXPO, jak zwykle, jest bezpłatny.'
      },
      {
        title: '04. Czy mogę zalogować się za pomocą tego samego konta, które zakładałem w celu pobrania biletu?',
        text: 'Dokładnie tak! Profil, który stworzyłeś na potrzeby pobrania biletu na targi, będzie teraz służył jako Twój panel użytkownika podczas targów ONLINE.'
      },
      {
        title: '05. Czy muszę założyć konto przed targami?',
        text: 'Spokojnie, możesz założyć konto w dowolnym momencie. Wcześniejsze zarejestrowanie się i uzupełnienie podstawowych danych umożliwi Ci jednak szybsze i sprawniejsze korzystanie ze wszystkich możliwości, jakie oferują targi pracy online - m.in. rozmów z rekruterami na czatach i swobodne dołączanie do szkoleń online.'
      },
      {
        title: '06. Co zrobić, jeśli nie mogę się zarejestrować?',
        text: 'Jeśli masz problemy z rejestracją i nie znalazłeś odpowiedzi w Pomoc, napisz do nas za pomocą naszego fanpage na Facebooku (https://www.facebook.com/pl.careerEXPO) i opisz dokładnie problem, który napotkałeś. Postaramy się jak najszybciej pomóc. W trakcie wydarzenia online uruchomimy też dedykowany czat, na którym będziesz mógł na żywo uzyskać poradę od członków zespołu Career EXPO.'
      },
      {
        title: '07. Czy będę mógł wziąć udział w Career EXPO online z telefonu?',
        text: 'Rekomendujemy korzystanie z naszego serwisu podczas targów z poziomu komputera. Na pewno wpłynie to na komfort korzystania ze wszystkich aktywności. Jeśli jednak zechcesz dołączyć do wydarzenia wykorzystując stronę mobilną, wciąż będziesz mógł/mogła to zrobić, jednak nie gwarantujemy, że komfort będzie równie wysoki.'
      },
      {
        title: '08. Can I participate at the online event, when I’m english speaking person?',
        text: `Yes, you can! :) Most of our exhibitors speak english or other languages and they look for employees with language skills. Our team is english-speaking as well so you can ask us whatever you want. So feel free to take part in Career EXPO online. If you’ve got any issues just write us by facebook fanpage or mail.
During the event will also run direct livechat where you can talk with our team about every problems.`
      },
      {
        title: '09. Czy na rozmowy online z rekruterami muszę się wcześniej zapisać? ',
        text: 'Nie musisz! Wystarczy, że w dniach trwania targów, zalogujesz się, wybierzesz interesującą Cię firmę, a także rekrutera i rozpoczniesz z nim bezpośrednią rozmowę. Być może będziesz musiał/a chwilę poczekać, jeśli rekruter będzie akurat zajęty. W oczekiwaniu na rozmowę, możesz jednak dołączyć do któregoś z trwających webinarów :)'
      },
      {
        title: '10. Czy rekruterzy będą mnie widzieć?',
        text: 'Nie, podczas tej edycji rekruterzy będą rozmawiać z Tobą na czatach, więc nie będziecie się widzieć. Pracodawcy mają jednak możliwość zaproszenia do video-rozmowy najbardziej obiecujących kandydatów. Możesz zatem swobodnie korzystać z możliwości targowych, siedząc w swojej ulubionej piżamie, ale na wszelki wypadek miej pod ręką przynajmniej górę stroju :)'
      },
      {
        title: '11. Czy mogę kontaktować się z więcej niż jednym rekruterem na raz?',
        text: `I tak i nie ;) Otwarcie czatu z wybraną firmą uniemożliwia Ci dołączenie w tym samym czasie do rozmowy z rekruterem innej firmy. Możesz jednak otworzyć naszą stronę w nowej karcie przeglądarki i tym sposobem rozpocząć czat z innym rekruterem. /n
Rekomendujemy jednak skupienie się na rozmowie z jednym rekruterem, by uzyskać i przekazać maksymalnie dużo informacji, a jednocześnie nie blokować dostępu innym użytkownikom`
      },
      {
        title: '12. Jak przygotować się do udziału w targach online?',
        text: `Zapoznaj się z profilami wystawców i ofertami pracy na naszej stronie, wybierz wcześniej te, którymi jesteś zainteresowany/a. Dowiedz się czegoś więcej o wybranych pracodawcach, zrób notatki - to zaprocentuje podczas rozmowy online! Przygotuj się do niej jak do rozmowy kwalifikacyjnej, bądź pewny siebie i znaj swoją wartość. 
Czas i formuła czatu działają na Twoją korzyść - możesz przygotować i zapisać wcześniej gotowe pytania do rekruterów, zaplanować scenariusz całej rozmowy, a w trakcie czatu posiłkować się nawet ściągami ;)`
      },
      {
        title: '13. Czy powinienem przygotować wcześniej swoje dokumenty aplikacyjne?',
        text: `Dobrze mieć przygotowane przed targami CV oraz pozostałe dokumenty aplikacyjne w wersji gotowej do przesłania online (najlepiej plik .pdf). Z dużym prawdopodobieństwem rekruterzy podczas rozmowy poproszą Cię o ich przesłanie celem weryfikacji Twojej kandydatury. 
Jeśli martwisz się o bezpieczeństwo swoich danych osobowych, bądź pewny, że zarówno Career EXPO, jak i pracodawcy mają na uwadze standardy RODO`
      },
      {
        title: '14. Czy potrzebuje dodatkowego sprzętu/oprogramowania do wzięcia udziału w Career EXPO?',
        text: 'Do udziału w Career EXPO wystarczy tylko przeglądarka (preferowana to Google Chrome), nie ma potrzeby instalacji żadnych programów. Rozmowy z rekruterami będą odbywać się na czatach tekstowych, jednak warto mieć pod reką słuchawki z mikrofonem/mikrofon i kamerkę (w które wyposażony jest obecnie niemalże każdy laptop), na wypadek gdyby rekruterzy zaprosili Cię na wideorozmowę).'
      },
      {
        title: '15. Co zrobić, by wziąć udział w szkoleniach online (webinarach)? Czy muszę się wcześniej zapisywać?',
        text: `Wystarczy, że zalogujesz się do systemu i naciśniesz przycisk “Zapisz się” przy wybranych szkoleniach z aktualnej listy. Wybrane szkolenia zapiszą się w Twoim profilu użytkownika, gdzie będziesz mógł/a nimi zarządzać. Po zapisaniu się, otrzymasz też od nas maila z potwierdzeniem i bezpośrednim linkiem do webinaru.
Nie martw się, że zapomnisz o szkoleniu. Odpowiednio wcześnie przypomnimy Ci o jego rozpoczęciu za pomocą wiadomości email. Dla pewności, warto jednak zapisać jego termin w kalendarzu :) 
Pamiętaj, że wszystkie szkolenia odbywają się na żywo!`
      },
      {
        title: '16. Co zrobić, spóźniłem się na rozpoczęcie szkolenia online?',
        text: 'Nic takiego, możesz dołączyć i opuścić je w dowolnym momencie jego trwania, nikomu nie przeszkadzając :)'
      },
      {
        title: '17. Czy szkolenia są certyfikowane? ',
        text: 'Szkolenia nie są certyfikowane, jednak wszystkie prowadzone są przez specjalistów z dużym doświadczeniem i ogromną dawką wiedzy do przekazania! Z pewnością da się z nich wiele wyciągnąć, do czego serdecznie namawiamy!'
      },
      {
        title: '18. Czy mogę wziąć udział w więcej niż jednym szkoleniu online? ',
        text: 'Oczywiście! Jeśli chcesz, możesz wziąć udział we wszystkich szkoleniach! Podczas tej edycji nie ma limitu ilości szkoleń, w których możesz uczestniczyć. Śledź na bieżąco harmonogram i dołączaj do webinarów!'
      },
      {
        title: '19. Gdzie znajdę więcej wskazówek, jak znaleźć pracę na targach?',
        text: `Z pewnością warto zajrzeć do zakładki “Wiedza” na naszej stronie internetowej https://careerexpo.pl/artykuly-eksperckie. Znajdziesz tam sporo ciekawych tekstów, porad i praktycznych informacji, które przybliżą Ci specyfikę rynku pracy i oczekiwania pracodawców, a także podpowiedzą Ci, jak skutecznie aplikować. Zapraszamy również na nasz kanał na YouTube - gdzie znajdziesz znajdziesz materiały wideo z poprzednich edycji, a także rozmowy z naszymi ekspertami czy uczestnikami targów. 
Śledź także na bieżąco nasz fanpage na Facebooku (www.facebook.com/pl.careerEXPO) Polub go, zapisz się do wydarzenia i zaproś znajomych - niech również skorzystają ze swojej szansy!`
      },
      {
        title: '20. Jakie branże znajdą pracę na Career EXPO?',
        text: `To co z pewnością wyróżnia nasze targi to ich multibranżowość. Firmy, które się u nas wystawiają reprezentują rozmaite branże. Na Career EXPO pracy mogą szukać zarówno finansiści, marketingowcy czy programiści, ale także osoby z branży logistycznej czy produkcyjnej. 
Ilość branż i różnorodnych ofert pracy, na różnych stanowiskach sprawia że na targach szukają pracy zarówno osoby doświadczone, jak i te dopiero rozpoczynające swoją karierę. 

Zachęcamy do zapoznania się z profilami firm i aktualnymi ofertami pracy na naszej stronie.`
      },
      {
        title: '21. Czy po targach od razu dostanę pracę? ',
        text: 'Wszystko zależy od Twojego zaangażowania i podejścia oraz dopasowania do oferowanych stanowisk pracy. Oprócz rozmów z rekruterami, na naszej stronie będą dostępne oferty pracy od firm biorących udział w Career EXPO. Warto przejrzeć je jeszcze raz, bo są na bieżąco aktualizowane. Rekruterzy są mocno nastawieni na znalezienie nowych, kompetentnych pracowników, dlatego istnieje wysoka szansa, na to, że uda Ci się znaleźć pracę bardzo szybko. :)'
      },
      {
        title: '22. Nie znalazłem odpowiedzi na moje pytanie - z kim mogę się skontaktować?',
        text: `Jeśli nie możesz znaleźć rozwiązania swojego problemu, napisz do nas wiadomość prywatną na naszym fanpage: www.facebook.com/pl.careerEXPO lub wyślij maila z pytaniem na adres: info@careerexpo.pl. Postaramy pomóc się jak najszybciej! 
  W trakcie wydarzenia online uruchomimy też na stronie głównej dedykowany czat, na którym będziesz mógł na żywo uzyskać pomoc od członków zespołu Career EXPO.`
      }
    ]
  },
  footer: {
    leftColumn: {
      title: 'CVworks',
      linksList: [
        {
          label: 'Dlaczego warto',
          link: '/#dlaczego-warto',
          type: 'onClick'
        },
        {
          label: 'Zrób CV teraz!',
          link: '/#stworz-cv',
          type: 'onClick'
        },
        {
          label: 'Cennik',
          link: '/#cennik',
          type: 'onClick'
        }
      ]
    },
    centerColumn: {
      title: 'Informacje',
      linksList: [
        {
          label: 'Regulamin serwisu',
          link: '/regulamin',
          type: 'internal'
        },
        {
          label: 'Polityka prywatności',
          link: '/polityka-prywatnosci',
          type: 'internal'
        },
        {
          label: 'Kontakt',
          link: '/kontakt',
          type: 'internal'
        }
      ]
    },
    rightColumn: {
      linksList: [
        
      ]
    }
  },
  registerSteps: {
    registerStepsList: {
      stepsList1: [
        {
          link: '/rejestracja/szablon',
          number: '1',
          title: 'Wybierz szablon',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/rejestracja/metryka',
          number: '2',
          title: 'Uzupełnij metrykę',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/rejestracja/profil',
          number: '3',
          title: 'Uzupełnij profil zawodowy',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/rejestracja/podsumowanie',
          number: '4',
          title: 'Pobierz CV',
          type: 'isNext',
          withLinks: false
        }
      ],
      stepsList2: [
        {
          link: '/rejestracja/szablon',
          number: '1',
          title: 'Wybierz szablon',
          type: 'isCurrent',
          withLinks: true
        },
        {
          link: '/rejestracja/metryka',
          number: '2',
          title: 'Uzupełnij metrykę',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/rejestracja/profil',
          number: '3',
          title: 'Uzupełnij profil zawodowy',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/rejestracja/podsumowanie',
          number: '4',
          title: 'Pobierz CV',
          type: 'isNext',
          withLinks: true
        }
      ],
      stepsList3: [
        {
          link: '/rejestracja/szablon',
          number: '1',
          title: 'Wybierz szablon',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/metryka',
          number: '2',
          title: 'Uzupełnij metrykę',
          type: 'isCurrent',
          withLinks: true
        },
        {
          link: '/rejestracja/profil',
          number: '3',
          title: 'Uzupełnij profil zawodowy',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/rejestracja/podsumowanie',
          number: '4',
          title: 'Pobierz CV',
          type: 'isNext',
          withLinks: true
        }
      ],
      stepsList4: [
        {
          link: '/rejestracja/szablon',
          number: '1',
          title: 'Wybierz szablon',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/metryka',
          number: '2',
          title: 'Uzupełnij metrykę',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/profil',
          number: '3',
          title: 'Uzupełnij profil zawodowy',
          type: 'isCurrent',
          withLinks: true
        },
        {
          link: '/rejestracja/podsumowanie',
          number: '4',
          title: 'Pobierz CV',
          type: 'isNext',
          withLinks: true
        }
      ],
      stepsList5: [
        {
          link: '/rejestracja/szablon',
          number: '1',
          title: 'Wybierz szablon',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/metryka',
          number: '2',
          title: 'Uzupełnij metrykę',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/profil',
          number: '3',
          title: 'Uzupełnij profil zawodowy',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/rejestracja/podsumowanie',
          number: '4',
          title: 'Pobierz CV',
          type: 'isCurrent',
          withLinks: true
        }
      ],
    }
  },
  form: {
    creatorCv: {
      creatorCvTitle: 'Stwórz skuteczne CV w 3 prostych krokach',
      creatorCvStepOne: {
        description: 'Idealne CV to składowa odpowiednio dobranego formatu i struktury, konkretnego przekazu oraz wyjątkowej osobowości. O perfekcyjny format i układ treści nie musisz się martwić, korzystając z naszego kreatora. O konkretny przekaz dbamy podpowiadając Ci, jakie ważne informacje zamieścić w CV, a wyjątkową osobowość tworzysz Ty sam.'
      },
      creatorCvStepTwo: {
        description: ''
      },
      creatorCvStepThree: {
        description: ''
      },
      creatorCvStepFour: {
        description: 'Wybierz odpowiedni pakiet, aby pobrać CV, które pomoże Ci zdobyć wymarzoną pracę!'
      },
      creatorCvSteps: {
        stepsList1: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Podstawowe dane',
            type: 'isCurrent',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenie',
            number: '2',
            title: 'Doświadczenie',
            type: 'isNext',
            withLinks: false
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Szablon',
            type: 'isNext',
            withLinks: false
          }
        ],
        stepsList2: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Podstawowe dane',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenie',
            number: '2',
            title: 'Doświadczenie',
            type: 'isCurrent',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Szablon',
            type: 'isNext',
            withLinks: false
          }
        ],
        stepsList3: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Podstawowe dane',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenie',
            number: '2',
            title: 'Doświadczenie',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Szablon',
            type: 'isCurrent',
            withLinks: true
          }
        ],
        stepsList4: [
          {
            link: '/kreator-cv/podstawowe-dane',
            number: '1',
            title: 'Podstawowe dane',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/doswiadczenie',
            number: '2',
            title: 'Doświadczenie',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/szablon',
            number: '3',
            title: 'Szablon',
            type: 'isPrevious',
            withLinks: true
          },
          {
            link: '/kreator-cv/platnosc-i-pobranie-cv',
            number: '4',
            title: 'Płatność i pobranie CV',
            type: 'isCurrent',
            withLinks: true
          }
        ]
      }
    },
    companySteps: {
      companyStepOne: {
        description: 'Nawet największa podróż zaczyna się od pierwszego kroku. Podaj swoje dane i pochwal się światu Twoją firmą.'
      },
      companyStepTwo: {
        description: 'Dlaczego robicie to, co robicie? Ale co ważniejsze - dlaczego inni mieliby do Was dołączyć? To idealny czas na inspirację.'
      },
      companyStepThree: {
        description: 'Kogo szukacie i kto jest odpowiedzialny za rekrutację? Nie bądź anonimowy - to Twoja szansa na przyciągnięcie prawdziwych talentów.'
      },
      companyStepFour: {
        description: 'Pokaż kandydatom, co Twoja firma może zaoferować w zamian za ich umiejętności (zakładka: Benefity). Sukcesami firmy też warto się pochwalić ;-) (zakładka: Wyróżnienia)'
      },
      companyStepFive: {
        description: 'Wszyscy chcemy rozwijać się oraz pracować z najlepszymi i dla najlepszych. Podziel się z innymi swoją wiedzą i doświadczeniem, aby pomóc im wybrać właściwą ścieżkę kariery.',
        guidelinesLabel: 'Wytyczne',
        guidelinesText: 'Artykuły oraz informacje o autorze dodawane są przez przedstawicieli firm samodzielnie. Długość artykułu - długości artykułu: min. 5500 znaków, max 8500 znaków ze spacjami. Zaleca się dołączenie do treści uzupełniających ją grafik i zdjęć (jeśli zostanie to pominięte, administrator serwisu sam dopasuje odpowiednie materiały do publikacji). Grafika nagłówkowa powinna mieć wymiary 930x380 pikseli. Teksty na bieżąco są weryfikowane i po akceptacji publikowane. Zastrzegamy sobie prawo do nieopublikowania artykułu niespełniającego wytycznych (np. krótszego niż 5500 znaków, reklamowego, zawierającego błędy ortograficzne czy logiczne, pozbawione biogramu autora itp.)',
        howArticleShouldLookLabel: 'Jak powinien wyglądać artykuł?',
        howArticleShouldLookText: 'Artykuły eksperckie powinny mieć charakter merytoryczny, nie informacyjny. Zachęcamy do poruszania tematyki, która będzie ciekawa dla odwiedzających. Dotychczas napisane artykuły znajdą Państwo na stronie: https://careerexpo.pl/wiedza/eksperci. Mogą one stanowić dla Państwa wskazówkę.',
        howArticleShouldNotLookLabel: 'Jak NIE powinien wyglądać artykuł?',
        howArticleShouldNotLookText: 'Artykuły eksperckie nie mogą mieć charakteru stricte reklamowego, nie mogą zatem dotyczyć historii, produktów firmy, a także jej programów praktyk, procesów rekrutacyjnych i innych zagadnień bezpośrednio dotyczących tej konkretnej organizacji. Takie artykuły nie przejdą weryfikacji.',
        visibleText: 'Artykuły eksperckie to materiały umieszczone w sekcji “Wiedza”, których zadaniem jest przygotowanie odwiedzających do targów i zwiększenie ich świadomości zawodowej. Autor wciela się tu w rolę profesjonalnego doradcy reprezentującego daną organizację. Pisze tekst dotyczący wybranego zagadnienia leżącego w kręgu tematów związanych z rekrutacją, rynkiem pracy, rozwojem osobistym. Mile widziane są też teksty branżowe, case studies. Jest to atrakcyjna forma wspierająca wizerunek eksperta, która wzbudza zaufanie zarówno poszukujących pracy, jak i partnerów biznesowych.'
      },
      companyStepsList1: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Podstawowe dane',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Szczegóły',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Rekrutacja',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefity i wyróżnienia',
          type: 'isNext',
          withLinks: false
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Edukacja',
          type: 'isNext',
          withLinks: false
        }
      ],
      companyStepsList2: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Podstawowe dane',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Szczegóły',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Rekrutacja',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefity i wyróżnienia',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Edukacja',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList3: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Podstawowe dane',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Szczegóły',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Rekrutacja',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefity i wyróżnienia',
          type: 'isNext',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Edukacja',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList4: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Podstawowe dane',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Szczegóły',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Rekrutacja',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefity i wyróżnienia',
          type: 'isCurrent',
          withLinks: false
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Edukacja',
          type: 'isNext',
          withLinks: true
        }
      ],
      companyStepsList5: [
        {
          link: '/platforma/podstawowe-dane',
          number: '1',
          title: 'Podstawowe dane',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/szczegoly',
          number: '2',
          title: 'Szczegóły',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/rekrutacja',
          number: '3',
          title: 'Rekrutacja',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/benefity-i-wyroznienia',
          number: '4',
          title: 'Benefity i wyróżnienia',
          type: 'isPrevious',
          withLinks: true
        },
        {
          link: '/platforma/edukacja',
          number: '5',
          title: 'Edukacja',
          type: 'isCurrent',
          withLinks: false
        }
      ],
      companyStepsTitle: 'Stwórz swój unikalny profil Pracodawcy w 5 krokach'
    },
    labels: {
      buttonNextLabel: 'Następny',
      buttonNextStepLabel: 'Następny krok',
      buttonPrevLabel: 'Wstecz',
      buttonPreview: 'Podgląd',
      buttonResetLabel: 'Wyczyść',
      buttonSaveLabel: 'Zapisz',
      buttonSelectLabel: 'Wybierz',
      buttonSkipLabel: 'Pomiń',
      fastRecruitment: 'Szybkie rekrutacje',
      favouriteEmployers: 'Ulubieni pracodawcy',
      requiredFieldsText: '* Pola obowiązkowe',
      titleLabel: 'Przygotuj się na targi pracy Career EXPO w 3 krokach!',
      trainingsTimetableLabel: 'Harmonogram szkoleń'
    },
    userDataFormLabels: {
      userAddressLabel: 'Adres',
      userCityLabel: 'Miasto',
      userCountryLabel: 'Kraj',
      userCustomTextLabel: 'Cel zawodowy',
      userDateOfBirthLabel: 'Data urodzenia',
      userEmailLabel: 'Adres e-mail',
      userNameLabel: 'Imię',
      userNationalityLabel: 'Obywatelstwo',
      userPhoneLabel: 'Telefon',
      userPictureLabel: 'Twoje zdjęcie',
      userPostCodeLabel: 'Kod pocztowy',
      userSocialMediaDescriptionLabel: 'Social Media',
      userSocialMediaDescriptionText: 'Tutaj możesz dołączyć linki do swoich profili w Social Media oraz do swoich portfolio.',
      userSocialMediaLinkLabel: 'Link',
      userSocialMediaTitleLabel: 'Profile Social Media',
      userSurnameLabel: 'Nazwisko'
    },
    stepsList1: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia',
        type: 'isNext',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList1_live: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia online',
        type: 'isNext',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList2: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList2_live: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia online',
        type: 'isCurrent',
        withLinks: false
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isNext',
        withLinks: false
      }
    ],
    stepsList3: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isCurrent',
        withLinks: false
      }
    ],
    stepsList3_live: [
      {
        link: '/platforma/podstawowe-dane',
        number: '1',
        title: 'Podstawowe dane',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/moje-szkolenia',
        number: '2',
        title: 'Zapisy na szkolenia online',
        type: 'isPrevious',
        withLinks: true
      },
      {
        link: '/platforma/pobierz-bilet',
        number: '3',
        title: 'Poznaj firmy',
        type: 'isCurrent',
        withLinks: false
      }
    ],
    stepOne: {
      description: 'Podaj swoje podstawowe dane, aby móc uczestniczyć w targach. W kolejnym kroku zapiszesz się na szkolenia online (webinary), a w kolejnym poznasz firmy, które będą dostępne dla Ciebie na żywo już wkrótce!',
      subtitle: 'Targi Pracy Career EXPO, 27.03.2019, 10:00-18:00, Aleja Śląska 1 - Stadion Wrocław'
    },
    stepTwo: {
      description: 'Pomożemy Ci stać się lepszym. Wybierz obszary, które Cię interesują, a następnie zapisz się na szkolenia online, które podniosą Twoje kompetencje!',
      subtitle: 'Wybierz interesujące Cię obszary szkoleń i prelekcji:'
    },
    stepThree: {
      description: 'Poznaj firmy i znajdź taką, do której pasujesz! Rozmawiaj NA ŻYWO już 1-2 kwietnia (14:00-20:00) przez czat na stronie.',
      descriptionTwo: 'Uzupełnij dane i zdobądź punkty za wypełnienie wszystkich formularzy',
      subtitle: 'Wybierz kolor przewodni Twojego CV',
      subtitleFive: 'Języki obce',
      subtitleFour: 'Doświadczenie',
      subtitleHobby: 'Zainteresowania',
      subtitleSix: 'Umiejętności',
      subtitleThree: 'Edukacja (Twoje ostatnie najważniejsze szkoły)',
      subtitleTwo: 'Podstawowe dane'
    },
    stepFour: {
      description: 'Pomożemy Ci znaleźć idealną firmę, która odpowiada Twoim wartościom. Najpierw jednak określ, jak chciałbyś, aby wyglądała Twoja zawodowa przyszłość!',
      employersLabel: 'pracodawców',
      employersAboveLabel: 'pracodawców powyżej',
      foundLabel: 'Znaleziono',
      subtitleValues: 'Najpierw wybierz 10 wartości, które są dla Ciebie istotne.',
      subtitleImportantValues: 'Następnie z tych 10 wybierz 3 najważniejsze (zmienią kolor na malinowy).',
      subtitleTwo: 'Wybierz branżę, którą chciałbyś współtworzyć i rozwijać:',
      valueLabel: 'wartości'
    },
    stepFive: {
      description: 'Na Career EXPO czeka Cię mnóstwo bardzo interesujących wydarzeń. Pobierz harmonogram tego wyjątkowego dnia!',
      buttonDownloadSummary: 'Pobierz podsumowanie',
      getTicket: 'Pobierz bilet',
      subtitle: 'Twój unikalny bilet',
      subtitleTwo: 'Zobacz, z jakimi Pracodawcami będziesz miał szansę porozmawiać podczas targów pracy Career EXPO!'
    }
  },
  locations: {
    labels: {
      addToCalendarButtonLabel: 'Dodaj do kalendarza',
      buttonLabel: 'Znajdź połączenie',
      dateAndTimeLabel: 'Data i godzina',
      placeLabel: 'Miejsce'
    }
  },
  registryAndLogin: {
    labels: {
      doYouAlreadyHaveAnAccountLabel: 'Masz już konto?',
      forgotPassword: 'Zapomniałem hasła',
      gotAcquaintedWithLabel: 'zapoznałem/am się z',
      loginLabel: 'Zaloguj się',
      privacyPolicyLabel: 'Politykę Prywatności',
      registryLabel: 'Nie masz konta? Stwórz je wraz z CV',
      regulationsLabel: 'Regulamin',
      rightSideDescriptionLabel: 'Chcesz podnieść swoje kwalifikacje, a może dowiedzieć się, w czym tkwi tajemnica sukcesu liderów biznesu? Wybierz coś dla siebie z naszej listy szkoleń i prelekcji z ciekawymi gośćmi - zapisz się i chłoń wiedzę.',
      rightSideTitleLabel: 'Prelekcje i szkolenia',
      siteNameLabel: 'Career EXPO',
      youDontHaveAnAccountLabel: 'Nie masz konta?'
    }
  },
  recruitment: {
    labels: {
      assessmentCenterLabel: 'Assesment Center',
      departmentsLabel: 'Działy',
      employmentTypesLabel: 'Rodzaj zatrudnienia',
      jobInterviewLabel: 'Rozmowa kwalifikacyjna',
      needsCvLabel: 'Curriculum Vitae (CV)',
      needsMotivationalListLabel: 'List motywacyjny',
      placeLabel: 'Miejsca pracy',
      preferredCandidatesLabel: 'Preferowani kandydaci',
      preferredDegreeCoursesLabel: 'Kierunki studiów',
      recruiterLabel: 'Osoba odpowiedzialna za rekrutacje',
      whoWeAreLookingForLabel: 'Kogo szukamy'
    }
  },
  SocialMediaCE: {
    facebook: 'https://www.facebook.com/AkademiaTwojejKariery',
  },
  subPages: {
    contact: {
      companyAddress: 'Lubańska 16',
      companyCity: '59-724 Osiecznica, Poland',
      companyKRS: '0000869394',
      companyNIP: '6121872340',
      companyName: 'Mapa Kariery sp. z o.o.',
      companyREGON: '387522260',
      pageTitle: 'Kontakt',
      staffList: [
        {
          email: 'karolina.merda@careerexpo.pl',
          image: karolinaMerdaImagePath,
          name: 'Karolina',
          phone: '+48781103305',
          phoneLabel: '+ 48 781 103 305',
          position: 'Promocja i Public Relations',
          surname: 'Merda'
        },
        {
          email: 'jacek.ciechanowski@careerexpo.pl',
          image: jacekCiechanowskiImagePath,
          name: 'Jacek',
          phone: '+ 48516007713',
          phoneLabel: '+ 48 516 007 713',
          position: 'Udział w targach - Pracodawcy',
          surname: 'Ciechanowski'
        },
        {
          email: 'help@careerexpo.pl',
          image: annaScislowskaImagePath,
          name: 'Anna',
          phone: '+48883219900',
          phoneLabel: '+ 48 883 219 900',
          position: 'Udział w targach - Uczestnicy',
          surname: 'Ścisłowska'
        }
      ]
    }
  },
  summary: {
    labels: {
      buttonBackToStepLabel: 'Wróć do kroku',
      fastRecruitment: 'Szybkich Rekrutacjach',
      favouriteEmployers: 'ulubionych pracodawców',
      trainings: 'szkolenia',
      trainingsLabel: 'Harmonogram szkoleń',
      youHave: 'Masz',
      youHaveAssignedFor: 'Zapisałeś się na',
      youParticipateIn: 'Bierzesz udział w'
    }
  },
  steps: {
    landingPageSteps: {
      labels: {
        titleLabel: 'To jak, widzimy się na targach?',
        buttonLabel: 'Zdobądź bilet'
      },
      stepsList: [
        {
          description: 'i uzupełnij podstawowe dane.',
          number: '1',
          title: 'Załóż konto',
          type: 'isCurrent'
        },
        {
          description: 'Weź udział w naszych szkoleniach  online, które podniosą Twoją wartość na rynku pracy!',
          number: '2',
          title: 'Zdobądź wiedzę',
          type: 'isNext'
        },
        {
          description: 'Sprawdź, jakie możliwości oferują Ci Pracodawcy z Twojego regionu i weź udział w czatach z rekruterami na żywo.',
          number: '3',
          title: 'Aplikuj!',
          type: 'isNext'
        }
      ]
    }
  },
  tabs: {
    tabsList: [
      {
        label: 'Pokój rozmów',
        value: 'chat'
      },
      {
        label: 'Ogólne',
        value: 'general'
      },
      {
        label: 'Rekrutacja',
        value: 'recruitment'
      },
      {
        isBold: true,
        label: 'Oferty pracy',
        value: 'jobs'
      },
      {
        label: 'Benefity',
        value: 'benefits'
      },
      {
        label: 'Wyróżnienia',
        value: 'awards'
      },
      {
        label: 'Oddziały',
        value: 'offices'
      }
    ],
    tabsList_live: [
      {
        isBold: true,
        label: 'Pokój rozmów',
        value: 'chat'
      },
      {
        label: 'Ogólne',
        value: 'general'
      },
      {
        label: 'Rekrutacja',
        value: 'recruitment'
      },
      {
        label: 'Oferty pracy',
        value: 'jobs'
      },
      {
        label: 'Benefity',
        value: 'benefits'
      },
      {
        label: 'Wyróżnienia',
        value: 'awards'
      },
      {
        label: 'Oddziały',
        value: 'offices'
      }
    ]
  },
  topMenu: {
    linksList: [
      {
        inNewWindow: false,
        label: 'Pracodawcy',
        link: '#companies'
      },
      {
        inNewWindow: false,
        label: 'Rekrutacja',
        link: '#recruitment'
      },
      {
        inNewWindow: false,
        label: 'Oferty pracy',
        link: '/oferty-pracy'
      },
      {
        inNewWindow: false,
        label: 'Szkolenia',
        link: '#trainings'
      },
      {
        inNewWindow: false,
        label: 'Pomoc',
        link: '/pomoc'
      },
      {
        fontBold: true,
        inNewWindow: true,
        label: 'Dołącz do Wystawców!',
        link: 'https://careerexpo.pl/wspolpraca'
      }
    ],
    linksList_live: [
      {
        inNewWindow: false,
        label: 'Pracodawcy',
        link: '#companies'
      },
      {
        inNewWindow: false,
        label: 'Rekrutacja',
        link: '#recruitment'
      },
      {
        inNewWindow: false,
        label: 'Oferty pracy',
        link: '/oferty-pracy'
      },
      {
        inNewWindow: false,
        label: 'Szkolenia',
        link: '#trainings'
      },
      {
        inNewWindow: false,
        label: 'Pomoc',
        link: '/pomoc'
      }
    ],
    linksListJobs: [
      {
        label: 'Dlaczego warto',
        link: '#dlaczego-warto'
      },
      {
        label: 'Zrób CV teraz!',
        link: '#stworz-cv'
      },
      {
        label: 'Cennik',
        link: '#cennik'
      }
    ],
    linksListJobs_live: [
      {
        inNewWindow: false,
        label: 'Pracodawcy',
        link: '/#companies'
      },
      {
        inNewWindow: false,
        label: 'Rekrutacja',
        link: '/#recruitment'
      },
      {
        inNewWindow: false,
        label: 'Oferty pracy',
        link: '/oferty-pracy'
      },
      {
        inNewWindow: false,
        label: 'Szkolenia',
        link: '/#trainings'
      },
      {
        inNewWindow: false,
        label: 'Pomoc',
        link: '/pomoc'
      }
    ],
    // linksListCreatorCV: [
    //   {
    //     label: 'Dlaczego warto',
    //     link: '#dlaczego-warto'
    //   },
    //   {
    //     inNewWindow: false,
    //     label: 'Jak zrobić skuteczne CV?',
    //     link: '#stworz-cv'
    //   },
    //   {
    //     inNewWindow: false,
    //     label: 'Kreator CV',
    //     link: '#stworz-cv'
    //   },
    //   {
    //     label: 'Cennik',
    //     link: '#cennik'
    //   }
    // ]
  },
  trainings: {
    labels: {
      trainingsButtonBuyLabel: 'Zapisz się',
      trainingsButtonUnBuyLabel: 'Wypisz się',
      trainingsDescriptionLabel: 'Opis',
      trainingsHourFieldLabel: 'Godzina',
      trainingsPlaceLabel: 'Scena',
      trainingsSpeakersFieldLabel: 'Prelegenci',
      trainingsSubjectFieldLabel: 'Temat',
      trainingsTagsListLabel: 'Tagi'
    }
  },
  login: 'Zaloguj się',
  logout: 'Wyloguj się',
  register: 'Zarejestruj się',
  sectionOne: {
    title: 'Porozmawiajmy o pracy w czasie kryzysu i po kryzysie',
    description: 'Znajdź wymarzoną pracę we Wrocławiu i zapytaj rekruterów online o Twoją przyszłość na zmieniającym się rynku pracy.',
    title_live: 'Witaj na targach pracy Career EXPO online!',
    description_live: 'Rozmawiaj teraz na żywo z rekruterami najlepszych pracodawców, aplikuj na konkretne oferty pracy i dołączaj do kilkudziesięciu webinarów live!'
  },
  sectionTwo: {
    title: 'Targi pracy Career EXPO online',
    icon: 'pin',
    alt: 'icon pin'
  },
  sectionThree: {
    title: 'Szkolenia online',
    title_live: 'WEBINARY live',
    description: 'Chcesz podnieść swoje kwalifikacje lub zdobyć nowe umiejętności? Wybierz coś dla siebie z naszej listy szkoleń i prelekcji online - zapisz się i otrzymaj powiadomienie o zbliżającym się webinarze.',
    description_live: 'Dołączaj bezpłatnie do szkoleń prowadzonych na żywo przez najlepszych trenerów i specjalistów. Sprawdź listę trwających i zbliżających się webinarów.',
    subtitle: {
      alt: 'icon tag',
      icon: 'tag',
      text: 'Co Cię interesuje? Wybierz obszary Twojego rozwoju:'
    }
  },
  sectionFour: {
    title: 'Prelekcje i szkolenia',
    description: 'Chcesz podnieść swoje kwalifikacje, a może dowiedzieć się w czym tkwi tajemnica sukcesu liderów biznesu? Wybierz coś dla siebie z listy prelekcji i szkoleń - zapisz się i chłoń wiedzę!',
    legend: {
      hour: 'Godzina',
      subject: 'Temat',
      speakers: 'Prelegenci'
    }
  },
  sectionFive: {
    title: 'Pracodawcy',
    title_live: 'Pracodawcy ONLINE',
    description: 'Porozmawiaj online na żywo z czołowymi pracodawcami z Dolnego Śląska i przekonaj ich, aby zatrudnili właśnie Ciebie. Sprawdź, jakie firmy i oferty pracy będą dostępne online 1-2.04 w godz. 14:00-20:00',
    description_live: 'Porozmawiaj teraz na żywo z dostępnymi rekruterami lub aplikuj bezpośrednio na wybrane oferty pracy!',
    subtitle: {
      alt: 'icon star',
      icon: 'star',
      text: 'Wybierz poniżej branżę:'
    }
  },
  sectionSeven: {
    title: 'Strefy',
    description: 'Zobacz, dlaczego jeszcze warto przyjść na targi pracy Career EXPO!'
  },
  sectionEight: {
    createCV: 'Stwórz CV'
  },
  sectionRecruitment: {
    description: 'Będziemy dostępni dla Ciebie online - zapytaj o kwestie dotyczące rekrutacji już 1-2 kwietnia od 14-20.',
    subtitle: {
      alt: 'icon star',
      icon: 'star',
      text: 'Jesteśmy dostępni dla Ciebie już dziś od 11:00 do 19:00. Porozmawiaj z nami o Twojej karierze w naszych organizacjach! '
    },
    title: 'Rekrutacja online'
  },
  copyright: '© Copyright by Mapa Kariery sp. z.o.o.. Wszelkie prawa zastrzeżone.',
  loginAndRegisterSliderItems: [
    {
      image: testImage,
      isSelected: false,
      text: 'Stworzenie skutecznego CV to formalność, która pomoże Ci przejść do kolejnego etapu. Jednak może być ono również konkretną przeszkodą, jeśli robisz to źle. Dzięki CVworks nie tylko dostaniesz świetne szablony, ale podpowiedzi, co robić, aby dobrze je wypełnić! Razem osiągniemy Twoje cele zawodowe.',
      title: 'Jesteś o jeden krok od nowej pracy!'
    },
    {
      image: testImage,
      isSelected: true,
      text: 'Chcesz podnieść swoje kwalifikacje, a może dowiedzieć się w czym tkwi tajemnica sukcesu liderów biznesu? Wybierz coś dla siebie z listy prelekcji i szkoleń - zapisz się i chłoń wiedzę! Career EXPO przyciąga najlepszych trenerów i szkoleniowców.',
      title: 'Ucz się od najlepszych'
    },
    {
      image: testImage,
      isSelected: false,
      text: 'Wygeneruj piękne i skuteczne CV, a potem skonsultuj je z naszymi ekspertami już bezpośrednio na wydarzeniu. Zrobisz sobie również profesjonalne zdjęcie. Jednym słowem - zmaksymalizujesz szanse!',
      title: 'Wygeneruj i skonsultuj CV!'
    }
  ],
  creatorCv: {
    bannersList: [
      {
        buttonLabel: 'Chcę stworzyć nowe, lepsze CV!',
        description: 'Ty chcesz prawdziwej kariery. Możesz ją mieć, jak 21 742 naszych klientów.',
        link: '/rejestracja',
        title: 'Nie chcesz zwykłej pracy!'
      }
    ],
    beneflistList: [
      {
        description: 'Jeśli nie tworzysz świadomie kariery, pozwalasz, by przypadkowo się ona “wydarzała”! Na początek potrzebujesz przede wszystkim zdefiniować kierunek Twojego rozwoju i plan realizacji.',
        icon: HomepageCardWorkIconPath,
        title: 'Wizja kariery i cel zawodowy'
      },
      {
        description: 'Zanim zaczniesz tworzyć CV zastanów się, jak chcesz przekonać pracodawcę, aby Cię zatrudnił. Potrzebujesz nauczyć się pisać o swoich osiągnięciach i przedstawiać je w atrakcyjny dla rekrutera sposób.',
        icon: HomepageCardSuccessIconPath,
        title: 'Wyeksponowanie mocnych stron'
      },
      {
        description: 'Poszukując szablonu CV, wybierz taki, który pozwoli Ci nie tylko dobrze zaprezentować Twoją kandydaturę, ale także podkreśli Twoją osobowość lub charakter firmy, do której aplikujesz. ',
        icon: HomepageCardClassroomIconPath,
        title: 'Oprawa wizualna'
      }
    ],
    shortVideo: {
      iframe: '<iframe lazy="true" width="560" height="315" src="https://www.youtube.com/embed/CgHBDN4igME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    videoSection: {
        iframe: "<iframe width='560' height='315' src='https://www.youtube.com/embed/1T3az04b6uE' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
        heading: 'Lista wszystkich lekcji:',
        list: [
          '3 elementy, których szuka pracodawca',
          'Dodatkowe narzędzia analizy swojego potencjału',
          'Czego oczekują pracodawcy',
          'Części twojego CV',
          'W prawidłowej kolejności',
          'Cel zawodowy',
          'Merytorycznie o doświadczeniu',
          'Skutecznie o wykształceniu',
          'Dodatkowe kursy, umiejętności',
          'Hobby i zainteresowania',
          'Obróbka graficzna',
          'W drodze do rekrutera'
        ],
    },
    solidSection: {
      ebookImage: ebookImage,
      list: [
        'listę 10 najczęściej powtarzanych błędów w CV',
        'przewodnik, jak w 12 krokach stworzyć perfekcyjne CV',
        '15 przykładów dobrych CV ',
        'checklistę – co powinno się znaleźć w Twoim CV',
        'BONUS – listę 100 najczęściej pojawiających się pytań na rozmowie kwalifikacyjnej'
      ]
    },
    guidlineSteps: [
      {
        description: 'Wszystko, co potrzebujesz wiedzieć, by stworzyć jedyne w swoim rodzaju CV, znajdziesz w naszym kursie online, który przeprowadzi Cię przez kolejne sekcje CV - od celu zawodowego przez opisywanie doświadczenia, aż po kluczowe błędy popełniane przez kandydatów. ',
        number: 1,
        title: 'Praktyczne szkolenie video',
        html: true
      },
      {
        description: 'Dzięki poradnikowi “Jak stworzyć piekielnie skuteczne CV?”, który otrzymasz w pakiecie, <strong>uporządkujesz ogrom wiedzy</strong> zdobytej podczas kursu i <strong>przygotujesz najlepszą wersję swojego CV</strong> w oparciu o konkretne przykłady.',
        number: 2,
        title: 'Instrukcja krok po kroku',
        html: true
      },
      {
        description: 'Do wyboru masz aż <strong>180 unikalnych wariantów o doskonałym designie i optymalnym układzie treści.</strong> Możesz je personalizować, edytować i pobierać bez limitu! A co ważne - naszych szablonów rekruterzy nie znają na pamięć ;-)',
        number: 3,
        title: 'Wybór idealnego szablonu',
        html: true
      }
    ],
    pricingBenefits: [
      'dostęp do 180 sprawdzonych szablonów CV bez limitu pobrań',
      'szkolenie video od Rekrutera: “Skuteczne CV”',
      '66 stronnicowy eBook “Jak stworzyć piekielnie skuteczne CV?”'
    ],
    pricingList: [
      {
        subTitle: '',
        price: '7',
        type: 'monthly',
        benefitsList: [],
        selected: 'true',
      }
    ],
    topMenu: {
      linksList: [
        {
          inNewWindow: false,
          label: 'Dlaczego warto',
          link: '#dlaczego-warto'
        },
        {
          inNewWindow: false,
          label: 'Jak zrobić skuteczne CV?',
          link: '#stworz-cv'
        },
        {
          inNewWindow: false,
          label: 'Zrób CV teraz!',
          link: '#szablony'
        },
        {
          inNewWindow: false,
          label: 'Opinie',
          link: '#opinie'
        },
        {
          inNewWindow: false,
          label: 'Cennik',
          link: '#cennik'
        }
      ]
    },
    testimonialsList: [
      {
        image: TestimonialTwo,
        info: 'Dzięki poradom od rekruterów byłam w stanie podkreślić najbardziej istotne treści i przyciągnąć uwagę rekrutera. W końcu dostałam wymarzoną pracę!',
        signature: 'Joanna K., 31 lat, Project Manager'
      },
      {
        image: TestimonialThree,
        info: 'Kreator CV cenię za łatwość edycji i atrakcyjne szablony, które wyróżniają moją kandydaturę na tle pozostałych aplikacji.',
        signature: 'Jagoda Z., 25 lat, Młodszy Specjalista ds. Księgowości'
      },
      {
        image: TestimonialFour,
        info: 'Mega ciekawy układ treści spowodował, że wyciągnąłem esencję swojego doświadczenia i pozwoliło mi to na przejście do kolejnego etapu.',
        signature: 'Robert K., 25 lat, Front-End Developer'
      },
      {
        image: TestimonialOne,
        info: 'CV to tak naprawdę sprzedaż swojej persony na rynku pracy. Ten kreator CV pomógł mi stworzyć perfekcyjne CV bez dużego wkładu czasowego.',
        signature: 'Monika S., 31 lat, Starszy Specjalista ds. Sprzedaży'
      }
    ],
    steps: {
      stepTwo: {
        experienceDescriptionOne: 'Zacznij od doświadczenia najbardziej aktualnego. Jeśli to jest pierwsza praca, warto dodać wakacyjne praktyki czy staże. Jednak jeśli jesteś specjalistą z doświadczeniem, skup się przede wszystkim na pracy związanej bezpośrednio ze stanowiskiem i pomiń mało istotne etapy Twojej kariery zawodowej, jak np. fizyczna praca za granicą.',
        experienceDescriptionTwo: 'Zakres swoich obowiązków wypisz „od punktów”. Każdy punkt kończ średnikiem lub przecinkiem, a ostatni zakończ kropką. Dzięki temu CV będzie miało atrakcyjną i poprawną strukturę. Z kolei „Wybrane osiągnięcia” opisz w jednej lini, po przecinku.',
        educationDescription: 'Zacznij od edukacji, która jest najbardziej aktualna. Jeżeli masz ukończone studia, nie warto dodawać szkoły podstawowej czy też gimnazjum. Liceum czy też inna edukacja może być jednak  wartościowa, jeżeli jest związana z Twoim aktualnym profilem zawodowym.',
        languageDescription: 'Upewnij się, że podajesz realny poziom znajomości języka. Bardzo prawdopodobne, że podczas rozmowy rekrutacyjnej te umiejętności zostaną sprawdzone przez rekrutera. Musisz mieć pewność, że sobie poradzisz.'
      }
    }
  },
  homepage: {
    cards: [
      {
        description: 'Rekruter potrzebuje tylko 14 sekund, aby odrzucić Twoje CV i 4 minuty, aby je zaakceptować. Spraw, by zainteresował się Twoją kandydaturą i zaprosił Cię do kolejnego etapu rekrutacji!',
        icon: HomepageCardClassroomIconPath,
        title: 'Stwórz skuteczne CV'
      },
      {
        description: 'Dla jednego międzynarodowa firma to zaleta, a dla drugiego wada.  Praca zdalna może być zbawieniem lub udręką. Pokażemy Ci, którzy Pracodawcy mają to, czego szukasz!',
        icon: HomepageCardSuccessIconPath,
        title: 'Określ cechy idealnego Pracodawcy'
      },
      {
        description: 'Przyjdź na targi pracy Career EXPO i przekonaj wybranego Pracodawcę, aby zatrudnił właśnie Ciebie! Weź udział w Szybkich Rekrutacjach, bezpłatnych szkoleniach i konsultacjach kariery.',
        icon: HomepageCardWorkIconPath,
        title: 'Poznajcie się… i aplikuj!'
      }
    ],
    cvTemplates: [
      {
        id: 1,
        image: HomepageCvTemplate1ImagePath,
        selected: false,
        title: 'Formalny'
      },
      {
        id: 2,
        image: HomepageCvTemplate2ImagePath,
        selected: false,
        title: 'Luźny'
      },
      {
        id: 3,
        image: HomepageCvTemplate3ImagePath,
        selected: false,
        title: 'Modernistyczny'
      }
    ],
    employersList: [
      HomepageEmployersAmazonImagePath,
      HomepageEmployersCeneoImagePath,
      HomepageEmployersVolvoImagePath,
      HomepageEmployersImpelImagePath,
      HomepageEmployersHpImagePath,
      HomepageEmployersQatarImagePath
    ],
    events: {
      labels: {
        dateLabel: 'Data',
        placeLabel: 'Miejsce'
      },
      primaryEvent: {
        date: '21.10.2019',
        name: 'Targi pracy Career EXPO online',
        place: 'Stadion Wrocław'
      }
    },
    guidlineSteps: [
      {
        description: 'Wybierz jeden z wielu unikalnych szablonów, stworzonych przez grafików i rekruterów! Stwórz CV, które zachwyci Twojego przyszłego Pracodawcę.',
        number: 1,
        title: 'Wybierz szablon'
      },
      {
        description: 'Uzupełnij najważniejsze informacje i dodaj zdjęcie. Zapisz szablon jako jeden z wielu i dostosowuj go do różnych stanowisk, na które aplikujesz.',
        number: 2,
        title: 'Uzupełnij dane'
      },
      {
        description: 'Pobierz CV, by wykorzystać je do zewnętrznych rekrutacji lub aplikuj bezpośrednio z Career EXPO! Całkowicie bezpłatnie.',
        number: 3,
        title: 'Pobierz swoje CV'
      }
    ],
    squarePanels: {
      itemsList: [
        {
          description: 'Wolisz samodzielną pracę i formalną atmosferę?',
          icon: HomepageSquarePanel1IconPath,
          title: 'Mamy takich Pracodawców!'
        },
        {
          description: 'Preferujesz pracę zdalną i dużą dozę zaufania?',
          icon: HomepageSquarePanel2IconPath,
          title: 'Mamy takich Pracodawców!'
        },
        {
          description: 'Chcesz pracować w dużym zespole i jeździć na światowej rangi konferencje?',
          icon: HomepageSquarePanel3IconPath,
          title: 'Mamy takich Pracodawców!'
        }
      ],
      selected: {
        selected: true,
        title: 'Mamy takich Pracodawców!'
      }
    }
  },
  zones: {
    one: {
      description: 'Aplikuj i zdobądź wymarzoną pracę natychmiast! Strefa Szybkich Rekrutacji to miejsce dla osób, które oczekują szybkich informacji zwrotnych.',
      label: 'Strefa Szybkich Rekrutacji'
    },
    two: {
      description: 'Eksperci podpowiedzą Ci, co poprawić w Twoim CV, by było idealne. W tym miejscu zrobisz sobie również profesjonalne zdjęcie.',
      label: 'Strefa Konsultingu CV'
    },
    three: {
      description: 'Skorzystaj z mini sesji coachingowych, aby odpowiedzieć sobie na nurtujące Cię pytania. Odpowiedź jest w Tobie… Trzeba tylko odpowiednio zadać pytanie.',
      label: 'Strefa Coachingu'
    },
    four: {
      description: 'Odwiedź Strefę z najświeższymi ofertami pracy i zobacz, jakie wymagania mają Pracodawcy.',
      label: 'Strefa Ofert Pracy'
    },
    five: {
      description: 'Skończyły Ci się CV? Nie szkodzi. U nas wydrukujesz swoje dokumenty aplikacyjne.',
      label: 'Strefa Ksero'
    },
    six: {
      description: 'Zmęczyłeś się przemierzając labirynt stoisk? Odpocznij przy kawie i dźwiękach muzyki, by odzyskać siły przed polowaniem na najlepsze oferty',
      label: 'Strefa Relaksu'
    }
  },
  days: {
    day1: 'Dzień 1',
    day2: 'Dzień 2'
  },
  companyPanel: {
    officesLabel: 'Oddziały',
    phoneLabel: 'T:',
    ourMission: 'Nasza Misja',
    howWeDoIt: 'Jak to robimy?',
    aboutUs: 'O nas?',
    videoGallery: 'Galeria Video',
    articles: 'Artykuły Eksperckie',
    moreButton: 'Więcej',
    dateAndPlaceLabel: 'Data i miejsce',
    prizelabel: 'Nagroda',
    jobBack: 'Wróć do wszyskich ofert'
  },
  partners: {
    supportingPartners: 'Partnerzy Wspierający',
    honoraryPartners: 'Partnerzy Honorowi',
    medialPartners: 'Partnerzy Medialni',
    partners: 'Partnerzy'
  },
  contactUsTitle: 'Kariera zawodowa, zmiana pracy czy nowe CV – nieważne, na jakim jesteś etapie.',
  knowledge: {
    title: 'Artykuły eksperckie',
    subTitle: 'Czytaj i rozwijaj się - po prostu zadbaj o swoją zawodową przyszłość',
    tagTitle: 'Co Cię interesuje? Wybierz!',
    latest: 'Najnowsze',
    mostInteresting: 'Najciekawsze',
    recommended: 'Rekomendowane'
  },
  jobOffers: {
    backButton: 'Wróć do wszyskich ofert',
    title: 'Oferty Pracy',
    show: 'Zobacz',
    apply: 'Aplikuj'
  },
  activation: {
    success: 'Email został wysłany!',
    error: 'Nastąpił problem!'
  },
  unsubscribe: {
    success: 'Wypisano!',
    error: 'Nastąpił problem!',
    errorTitle: 'Nie udało się wypisać z listy',
    errorDescription: 'Spróbuj ponownie. Jeśli błąd będzie się powtarzać skontaktuj się z nami.'
  },
  registerSuccess: {
    title: 'Dziękujemy za założenie konta!',
    body: 'Sprawdź swoją skrzynkę e-mail i kliknij w linka potwierdzającego. Jeśli e-mail się nie pojawi w ciągu kilku minut, prosimy o sprawdzenie folderu SPAM, a następnie upewnienie się, że został wpisany poprawny adres e-mail (możesz to zrobić poprzez próbę kolejnej rejestracji wpisując ten sam adres e-mail).',
    greetings: 'Życzymy samych sukcesów!',
    teamCE: 'Zespół Career EXPO',
    sendEmail: 'Wyślij ponownie e-maila',
    sendEmailSuccess: 'Wysłano!',
    sendEmailError: 'Coś poszło nie tak, spróbuj ponownie.',
    or: 'lub',
    goBack: 'Wróć do rejestracji'
  },
  resetPassword: {
    error: 'Nastąpił problem!',
    title: 'Ustaw nowe hasło',
    passwordLabel: 'Hasło',
    passwordPlaceholder: 'Wpisz Hasło',
    passwordConfirmationLabel: 'Potwierdź Hasło',
    passwordConfirmationPlaceholder: 'Wpisz ponownie Hasło',
    send: 'Wyślij',
    validationErrors: {
      tooShort: 'Hasło jest krótkie!',
      required: 'Pole jest wymagane',
      equal: 'Hasła muszą być takie same'
    }
  },
  forgotPassword: {
    success: 'Wysłano, sprawdź swojego emaila po dalsze instrukcje!',
    userDoesNotExist: 'Użytkownik o takim adresie email nie istnieje!',
    error: 'Nastąpił problem!',
    title: 'Zapomniałem hasła',
    placeholder: 'Wpisz E-mail',
    send: 'Wyślij',
    validationErrors: {
      required: 'Pole jest wymagane',
      email: 'Błędny Email'
    }
  },
  expoRegister: {
    title: 'Szybka Rejestracja',
    firstNameLabel: 'Imię',
    firstNamePlaceholder: 'Wpisz swoje imię...',
    lastNameLabel: 'Nazwisko',
    lastNamePlaceholder: 'Wpisz swoje nazwisko...',
    phoneLabel: 'Telefon',
    phonePlaceholder: 'Przypomnimy Ci o wydarzeniu! Nic więcej.',
    positionLabel: 'Stanowisko',
    positionPlaceholder: 'Wpisz stanowisko, jakie piastujesz / na jakie aplikujesz',
    emailPlaceholder: 'Wpisz Email',
    passwordLabel: 'Hasło',
    passwordPlaceholder: 'Wpisz Hasło',
    checkboxes: {
      all: 'Zaznacz wszystkie zgody',
      accept: 'Akceptuję',
      statuteDescription: 'w celu bezpłatnego korzystania z serwisu careerexpo.pl',
      notifications: 'Chcę otrzymywać przypomnienia o targach, szkoleniach i innych obszarach związanych z Career EXPO',
      shareProfile: 'Chcę udostępniać swój profil Pracodawcom',
      recommendations: 'Chcę otrzymywać informacje o rekomendowanych kandydatach'
    },
    saveLabel: 'Zarejestruj się',
    validationErrors: {
      regulations: 'Musisz zaakceptować regulamin',
      policyPrivacy: 'Musisz zaakceptować polityke prywatności',
      required: 'Pole jest wymagane',
      email: 'Błędny Email',
      tooShort: 'Hasło jest krótkie!'
    }
  },
  controlButtons: {
    edit: 'Anuluj Edytowanie',
    clear: 'Wyczyść formularz',
    reverse: 'Cofnij zmiany'
  },
  registerSuccessMessage: 'Twoje konto zostało aktywowane! Możesz się zalogować.',
  uploadAvatar: {
    buttonSelectLabel: 'Wybierz zdjęcie (PNG/JPG)',
    chooseFileButtonDescription: 'Zaznacz obszar zdjęcia:',
    chooseFileButtonFieldlabel: 'Wybierz plik:',
    chooseFileButtonLabel: 'Wybieram plik',
    chooseFileButtonSendLabel: 'Wyślij zdjęcie',
    chooseFileTitle: 'Dodajesz zdjęcie do swojego CV!',
    croppedImageHeadingText: 'Podgląd:'
  },
  userStepTwo: {
    signUpSuccess: 'Zostałeś zapisany na szkolenie',
    noPlaces: 'Nie ma już miejsc na to szkolenie!',
    unSignUpSuccess: 'Zostałeś wypisany na szkolenie',
    error: 'Nastąpił błąd podczas wypisywania!',
    nextPageError: 'Nastąpił błąd podczas przechodzenia na kolejną stronę!',
    allTrainings: 'Wszystkie szkolenia',
    saved: 'Moje szkolenia',
    showTrainings: 'Zobacz listę dostępnych szkoleń',
    noTrainings: 'Nie zapisałeś się jeszcze na żadne szkolenie!'
  },
  userStepThree: {
    companies: 'Te firmy czekają na Twoją aplikację. Dowiedz się o nich więcej:',
    more: 'Więcej'
  },
  stepsController: {
    back: 'Poprzedni krok',
    homePage: 'Strona główna',
    next: 'Następny krok',
    myCv: 'Kokpit',
    skip: 'Pomiń'
  },
  thankYouPage: {
    sectionTitle: 'Twoje zamówienie zostało zrealizowane, Dziękujemy!',
    summaryTitle: 'Podsumowanie zamówienia',
    summaryOrderID: 'Numer',
    summaryOrderDate: 'Data',
    summaryOrderPackage: 'Pakiet',
    summaryOrderPrice: 'Cena',
    redirectMessage: 'Za kilka sekund zostaniesz przekierowany na stronę z Twoim CV. Jeśli to się nie wydarzy,'
  }
}

export default translationsListPL
