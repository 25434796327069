import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'
import * as recruitmentApi from '../../../store/api/recruitment'
import ApiService from '../../../store/services/ApiService'

import AddedItem from '../../../components/List/AddedItem'
import FormPanel from '../../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../../components/Form/FormFields/InputField'
import ResetAndSaveButtons from '../../../components/Form/FormComponents/ResetAndSaveButtons'
import TwoMultiSelectDropDownsField from '../../../components/Form/FormFields/TwoMultiSelectDropDownsField'

import translate from '../../../stabs/errorMessagesTranslations'
import months from '../../../stabs/months'
import generateYears from '../../../utils/generateYears'
import handleMultiSelect from '../../../utils/forms/handleMultiSelect'

const AddCourseFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const cvId = sessionStorage.getItem('cv')
  const cv = useSelector(cvSelectors.getCvData)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    const newValues = {
      name: values.name,
      dateStart: `${values.startDateMonth}.${values.startDateYear}`,
      dateEnd: `${values.endDateMonth}.${values.endDateYear}`
    }

    ApiService
      .fetch(state.isEditing ? recruitmentApi.updateCourse(cvId, state.editingItemId, newValues) : recruitmentApi.addCourse(cvId, newValues))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
        resetForm()
        setState({ ...state, editingItemId: null })
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(recruitmentApi.deleteCourse(cvId, itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = cv.courses.filter((education) => education._id === itemId)[0]

    const newItem = {
      name: editingItem.name,
      startDateMonth: editingItem.dateStart.split('.')[0],
      startDateYear: editingItem.dateStart.split('.')[1],
      endDateMonth: editingItem.dateEnd.split('.')[0],
      endDateYear: editingItem.dateEnd.split('.')[1]
    }

    setValues(newItem)
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: item.name,
    description: `Od ${item.dateStart} do ${item.dateEnd}`,
    isEditing: state.editingItemId === item._id,
    _id: item._id
  }))

  return (
    <Formik
      initialValues={{
        name: '',
        startDateMonth: '',
        startDateYear: '',
        endDateMonth: '',
        endDateYear: ''
      }}
      validationSchema={EducationCVSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel errors={state.error}>
            {mapItemToDisplay(cv.courses).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isLoading={state.isLoading}
              />
            ))}

            <InputField
              errorMessage={errors.name}
              hasError={errors.name && touched.name}
              id='courseName'
              label='Nazwa kursu'
              name='name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Wpisz nazwę kursu'
              required
              value={values.name}
            />

            <FormTwoColumns>
              <TwoMultiSelectDropDownsField
                data-test='twoMultiSelectDropDownsFieldFrom'
                errors={errors}
                touched={touched}
                itemsListFirst={months}
                itemsListSecond={generateYears}
                label='Od'
                nameFirst='startDateMonth'
                nameSecond='startDateYear'
                handleMultiSelect={(fieldName, item, isMulti) =>
                  handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
                placeholderFirst={values.startDateMonth !== '' ? months.filter((month) => month.value === values.startDateMonth)[0].label : 'Miesiąc'}
                placeholderSecond={values.startDateYear !== '' ? values.startDateYear : 'Rok'}
                required
                values={values}
                type='courses'
              />

              <TwoMultiSelectDropDownsField
                data-test='twoMultiSelectDropDownsFieldFrom'
                errors={errors}
                touched={touched}
                itemsListFirst={months}
                itemsListSecond={generateYears}
                label='DO'
                nameFirst='endDateMonth'
                nameSecond='endDateYear'
                handleMultiSelect={(fieldName, item, isMulti) =>
                  handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
                placeholderFirst={values.endDateMonth !== '' ? months.filter((month) => month.value === values.endDateMonth)[0].label : 'Miesiąc'}
                placeholderSecond={values.endDateYear !== '' ? values.endDateYear : 'Rok'}
                values={values}
                type='courses'
              />
            </FormTwoColumns>
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

Yup.addMethod(Yup.string, 'dropDown', function(field) {
    const { message } = field;
    return this.test('test-name', message, function(value) {
        const { path, createError } = this;

        if (value == null){
          document.getElementById('courses-field-'+field).style.borderColor = 'red';
          return false;
        } else {
          document.getElementById('courses-field-'+field).style.borderColor = null;
          return true;
        }
    });
});

const EducationCVSchema = Yup.object().shape({
  name: Yup.string().required('Pole jest wymagane'),
  startDateMonth: Yup.string().required('Pole jest wymagane').dropDown('startDateMonth'),
  startDateYear: Yup.string().required('Pole jest wymagane').dropDown('startDateYear'),
  endDateMonth: Yup.string().required('Pole jest wymagane').dropDown('endDateMonth'),
  endDateYear: Yup.string().required('Pole jest wymagane').dropDown('endDateYear')
})

export default AddCourseFormContainer
