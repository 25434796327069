import React from 'react'
import PropTypes from 'prop-types'

import LinkComponent from '../../../Buttons/LinkComponent'

import {
  CheckBoxWithTextLinkTextContainer,
  CheckBoxWithTextLinkTextInput,
  CheckBoxWithTextLinkTextLabel,
  CheckBoxWithTextLinkTextLabelText
} from './components'

const CheckBoxWithTextLinkText = ({
  checked,
  inNewWindow,
  label,
  link,
  name,
  onChange,
  textAfterLink,
  textBeforeLink,
  type
}) => (
  <CheckBoxWithTextLinkTextContainer>
    <CheckBoxWithTextLinkTextInput
      checked={checked}
      onChange={() => onChange(name)}
      type='checkbox'
    />

    <CheckBoxWithTextLinkTextLabel>
      <CheckBoxWithTextLinkTextLabelText data-test='textBeforeLink'>{textBeforeLink}</CheckBoxWithTextLinkTextLabelText>

      {label &&
        <LinkComponent
          colorText='pinkColorM'
          fontSize='sizeS'
          inNewWindow={inNewWindow}
          label={label}
          link={link}
          type={type}
        />}

      <CheckBoxWithTextLinkTextLabelText data-test='textAfterLink'>{textAfterLink}</CheckBoxWithTextLinkTextLabelText>
    </CheckBoxWithTextLinkTextLabel>
  </CheckBoxWithTextLinkTextContainer>
)

export default CheckBoxWithTextLinkText

CheckBoxWithTextLinkText.propTypes = {
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  inNewWindow: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  textAfterLink: PropTypes.string,
  textBeforeLink: PropTypes.string,
  type: PropTypes.oneOf(['external', 'internal', 'onClick'])
}

CheckBoxWithTextLinkText.defaultProps = {
  textAfterLink: '',
  textBeforeLink: ''
}
