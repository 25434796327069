import React, {useEffect} from 'react'
import { useDebouncedCallback } from 'use-debounce'

import CVAvatarUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CVAvatarUploadContainer'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import SpacingLine from '../../../SpacingLine'
import Image from '../../FormBasicElements/Image'

import safety from '../../../../assets/img/safety.svg'

import easyScroll from 'easy-scroll';

import {
  AddUserFormCustomDescription,
  AddUserFormCustomTitle,
  AddUserFormNameAndSurnameContainer, CreatorCvStepOneEditorFieldContainer,
  Safety
} from './components'

import translationsList from '../../../../stabs/translationsList'
import { CreatorCvStepOneDescriptionContainer } from '../../../../pages/CreatorCvSteps/StepOne/components'
import TextAreaField from '../../FormFields/TextAreaField'
import MultiSelectDropDown from '../../FormBasicElements/MultiSelectDropDown'
import ApiService from '../../../../store/services/ApiService'
import { forwardMailchimp } from '../../../../store/api/user'

const Test = (errors) => {
  var FormElement = document.getElementById('FormStart');

if( Object.keys(errors).length != 0 && FormElement) {
  var elementPosition = FormElement.offsetTop;

  // window.scrollTo({
  //   top: elementPosition - 70,
  //   behavior: "smooth"
  // });
  // 
  // easyScroll({
  //     'scrollableDomEle': window,
  //     'direction': 'bottom',
  //     'duration': 2000,
  //     'easingPreset': 'easeInOutCubic',
  //     'scrollAmount': elementPosition - 500
  // });
}
}

const AddUserForm = ({
  apiError,
  errors,
  isEditing,
  handleBlur,
  handleChange,
  handleField,
  handleMultiSelect,
  touched,
  values
}) => {
  const sendToMailchimp = useDebouncedCallback(() => {
    if (/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/.test(values.email) && !isEditing) {
      ApiService.fetch(forwardMailchimp({ email: values.email }))
        .catch((e) => {
          console.error(e)
        })
    }
  }, 1000)

  const handleBlurEmail = (...args) => {
    handleBlur(...args)

    sendToMailchimp.callback()
  }

Test(errors)
  return (
  <FormPanel errors={apiError} data-test='CreatorCvStepOneFormPanelBaseData'>

    <FormTwoColumns data-test='formTwoColumnsOne'>
      <InputField
        data-test='CreatorCvStepOneUserName'
        errorMessage={errors.firstName}
        hasError={errors.firstName && touched.firstName}
        id='userName'
        label={translationsList.form.userDataFormLabels.userNameLabel}
        name='firstName'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz swoje imię'
        required
        value={values.firstName}
      />
      <InputField
          data-test='CreatorCvStepOneUserSurname'
          errorMessage={errors.lastName}
          hasError={errors.lastName && touched.lastName}
          id='userSurname'
          label={translationsList.form.userDataFormLabels.userSurnameLabel}
          name='lastName'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Wpisz swoje nazwisko'
          required
          value={values.lastName}
        />
    </FormTwoColumns>

    <InputField
      data-test='CreatorCvStepOneUserEmail'
      errorMessage={errors.email}
      hasError={errors.email && touched.email}
      id='userEmail'
      label={translationsList.form.userDataFormLabels.userEmailLabel}
      name='email'
      onBlur={handleBlurEmail}
      onChange={handleChange}
      placeholder='Wpisz swój najlepszy adres e-mail'
      required
      type='email'
      value={values.email}
    />

    
  </FormPanel>
)}

export default AddUserForm

// TODO Add onClick + Fix Languages
