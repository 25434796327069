import styled from 'styled-components'

import {
  InsetPurpleBoxShadowColor,
  PurpleColorM,
  WhiteColor
} from '../../assets/styles/colors'

export const NavigationContainer = styled.div`
  @media (min-width: 320px) and (max-width: 1199px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const NavigationMobileButton = styled.button`
  border: 0 solid transparent;
  padding: unset;

  @media (min-width: 1200px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    cursor: pointer;
    display: block;
    height: 26px;
    width: 26px;
  }
`

export const NavigationContent = styled.div`
  /*> div {
    &:last-of-type {
      background: #fd007a;
      border-radius: 40px;
      padding: 8px 25px;

      div {
        &:last-child {
          color: #fff;
        }
      }
    }
  }*/
  @media (min-width: 1200px) {
    align-items: center;
    display: flex;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    ${props => props.isOpen ? `
        background-color: ${WhiteColor};
        border-bottom-left-radius: 15px;
        box-shadow: ${InsetPurpleBoxShadowColor};
        display: block;
        position: absolute;
        right: 0;
        top: 100%;

        @media (min-width: 768px) and (max-width: 1199px) {
          padding: 10px 15px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          padding: 10px;
        }
      `
      : 'display: none;'
    }
  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    left: 50%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    left: 40%;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    border-bottom-left-radius: 0;
    left: 0;
  }
`

export const NavigationItemContainer = styled.div`
  @media (min-width: 1320px) {
    margin: 0 20px;
    padding: 8px 5px;
  }

  @media (min-width: 1200px) and (max-width: 1319px) { /* Fix to properly render header with menu*/
    margin: 0 10px;
    padding: 8px 0;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    + div {
      border-top: 1px solid ${PurpleColorM};
    }

    & a {
      display: block;
    }

    & a > div {
      display: block;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 8px 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 8px 10px;
  }
`
