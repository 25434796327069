import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Form/FormBasicElements/Icon'
import Image from '../Form/FormBasicElements/Image'

import {
  ChooseCVContainer,
  ChooseCVContent,
  ChooseCVFader,
  ChooseCVFaderContainer,
  ChooseCVFaderIconContainer,
  ChooseCVFaderTitle
} from './components'

import checkIconPath from '../../assets/icons/check.svg'

const ChooseCV = ({
  disabled,
  halfWidth,
  id,
  image,
  noMargin,
  onClick,
  selected,
  title,
  isHome,
  isRegister
}) => (
  <ChooseCVContainer data-test='ChooseCVContainer'>
    <ChooseCVContent
      disabled
      data-test='ChooseCVContent'
      halfWidth={halfWidth}
      noMargin={noMargin}
      onClick={() => onClick(id)}
      isHome={isHome}
      isRegister={isRegister}
    >
      <Image
        data-test='ChooseCVContentImage'
        scaleMe
        source={image}
      />

      {
        selected && (
          <ChooseCVFaderContainer data-test='ChooseCVFaderContainer'>
            <ChooseCVFaderIconContainer data-test='ChooseCVFaderIconContainer'>
              <Icon
                data-test='ChooseCVFaderIcon'
                icon={checkIconPath}
              />
            </ChooseCVFaderIconContainer>

            <ChooseCVFader data-test='ChooseCVFader' />

            <ChooseCVFaderTitle data-test='ChooseCVFaderTitle'>
              {title}
            </ChooseCVFaderTitle>
          </ChooseCVFaderContainer>
        )
      }
    </ChooseCVContent>
  </ChooseCVContainer>
)

export default ChooseCV

ChooseCV.propTypes = {
  halfWidth: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  image: PropTypes.string,
  noMargin: PropTypes.bool,
  isHome: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string
}
