import styled from 'styled-components'

export const TrainingsText = styled.div`
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 15px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin: 0 0 10px;
    }
`
