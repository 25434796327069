import React from 'react'

import MyCvContainer from './MyCvContainer'
import ErrorPage from '../../components/ErrorPage'
import AuthService from '../../store/services/AuthService'
import Site from '../../components/Wrappers/Site'

const DashboardContainer = ({ page }) => {
  const pages = {
    cv: MyCvContainer
  }

  if (pages[page] === undefined) {
    return <ErrorPage />
  }

  const Page = pages[page]

  return (
    <Site
      data-test='Site'
      isLoggedOn={AuthService.loggedIn()}
      menuItemsList={[]}
      withNav
    >
      <Page />
    </Site>
  )
}

export default DashboardContainer
