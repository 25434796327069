import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import useDidMount from '@rooks/use-did-mount'

import ApiService from '../../../store/services/ApiService'
import { myUserActions, myUserSelectors } from '../../../store/ducks/my-user'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'
import * as recruitmentAPI from '../../../store/api/recruitment'

import Image from '../../../components/Form/FormBasicElements/Image'

import AddUserForm from '../../../components/Form/FormComponents/AddUserFormRegister'

import translate from '../../../stabs/errorMessagesTranslations'
import handleField from '../../../utils/forms/handleField'
import AuthService from '../../../store/services/AuthService'
import ButtonComponent from '../../../components/Buttons/ButtonComponent'
import translationsList from '../../../stabs/translationsList'
import { CreatorCvStepOneButtonContainer } from '../../../pages/CreatorCvSteps/StepOne/components'
import handleMultiSelect from '../../../utils/forms/handleMultiSelect'

import safety from '../../../assets/img/safety.svg'

import {
  Safety
} from './components'

function createMarkup() { return {__html: 'Zawsze będziemy chronili Twoje dane osobowe i przetwarzali je zgodnie z korzystną dla Ciebie <a href="/polityka-prywatnosci">polityką prywatności</a> oraz <a href="/regulamin">regulaminem serwisu.</a><br/>Twoje bezpieczeństwo jest dla nas absolutnym priorytetem.'}; };

const AddBasicUserDataCVFormContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(myUserSelectors.getMyUserData)
  const cv = useSelector(cvSelectors.getCv)

  const cvId = sessionStorage.getItem('cv')

  const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  // 
  useEffect(() => {
      return history.listen((location) => { 
         console.log(`You changed the page to: ${location.pathname}`) 
      }) 
   },[history]) 

  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const initialValues = {
    avatar: user.avatar,
    firstName: user.firstName,
    position: 'Specjalista ds. Obsługi Klienta',
    title: 'Demo',
    lastName: user.lastName,
    phone: user.phone,
    email: user.email !== cv.data.email && cv.data.email !== '' ? cv.data.email : user.email,
    birthday: user.birthday,
    nationality: user.nationality,
    summary: cv.data.summary,
    cityOfLiving: user.cityOfLiving,
    country: user.country,
    linkedinUrl: user.linkedinUrl,
    portfolioUrl: cv.data.portfolioUrl,
    cvLanguage: 'pl'
  }

  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })
    const endpoint = cvId === null ? recruitmentAPI.createMyCV(values) : recruitmentAPI.updateMyCv(cvId, values)

    // ApiService.fetch(recruitmentAPI.registerCA({ chatUrl:'test' })).catch(error => {
    //     setSubmitting(false)
    //     console.log(error)
    //     console.log(error.details)
    //   })



    ApiService
      .fetch(endpoint)
      .then(data => {
        setSubmitting(false)
        setState({ error: null, isLoading: false })

        toast.success('Utworzyliśmy Ci konto, a hasło wysłaliśmy na maila!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data.cv, 'edit'))
        dispatch(myUserActions.fetchMyUserSuccess(data.user))

        sessionStorage.setItem('cv', data.cv._id)

        if (!AuthService.loggedIn()) {
          AuthService.setToken(data.token)
        }

        history.push('/rejestracja/szablon')
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BasicUserDataCVSchema}
      onSubmit={onSubmit}
      enableReinitialize
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit} id="FormStart">
          <AddUserForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
            handleMultiSelect={(fieldName, item, isMulti) =>
              handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
            touched={touched}
            values={values}
            isEditing={initialValues.summary !== ''}
          />

          <Safety>
            <Image source={safety} />
            <div>{'Twoje dane są bezpieczne i nie są nikomu przekazywane.'}</div>
            <p dangerouslySetInnerHTML={createMarkup()} />
          </Safety>

          <CreatorCvStepOneButtonContainer data-test='CreatorCvStepOneButtonContainer'>
            <ButtonComponent
              align='center'
              bgColor='pinkColor'
              color='whiteColor'
              data-test='buttonComponent'
              display='inline-flex'
              isLoading={state.isLoading}
              fontBold
              label={'Dalej'}
              buttonType='submit'
              size='sizeL'
              id={'nextStepButton'}
            />
          </CreatorCvStepOneButtonContainer>
        </form>
      )}
    />
  )
}

let mcListId = "41e87e554b";
  let API_KEY = "80d9167a7ee99d3695967f31a449996a";

  


Yup.addMethod(Yup.string, 'mail', function(field) {
    const { message } = field;
    return this.test('test-name', message, function(value) {
        const { path, createError } = this;
        
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(value).toLowerCase());

        if( isValid ) {
          // let axiosConfig = {
          //   headers: {
          //     'Authorization': "Basic 80d9167a7ee99d3695967f31a449996a",
          //     'Access-Control-Allow-Origin': 'http://localhost:3000',
          //     'Content-Type': 'application/x-www-form-urlencoded',
          //     'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
          //     'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          //     'Access-Control-Max-Age': 86400,
          //     'Access-Control-Allow-Credentials': true
          //   }
          // };

          // var postData = {
          //   email_address: value,
          //   status: "subscribed",
          //   email_type: "html",
          //   tags: ["CVworks"]
          // };

          // try {
          //     let mcResponse = axios.post('https://us7.api.mailchimp.com/3.0/lists/' + mcListId + '/members?skip_merge_validation=1', postData, axiosConfig)
          //     console.log(axiosConfig)
          //     console.log("Mailchimp List Response: ", mcResponse);

          // } catch(err) {
          //     console.log("Mailchimp Error: ", err);
          //     console.log("Mailchimp Error: ", err["response"]["data"]);
          // }

          return true;
        } else {
          return createError({ path, message: field });
        }
    });
});

const BasicUserDataCVSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Pole jest wymagane'),
  lastName: Yup.string()
    .required('Pole jest wymagane'),
  email: Yup.string()
    .mail('Błędny Email')
    .required('Pole jest wymagane')
})

export default AddBasicUserDataCVFormContainer
