import React, { useState } from 'react'

import ChooseImageField from '../../../../components/Form/FormFields/ChooseImageField'
import toBase64 from '../../../../utils/toBase64'
import ApiService from '../../../../store/services/ApiService'
import * as userApi from '../../../../store/api/user'
import translationsList from '../../../../stabs/translationsList'

const AvatarUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: null
  })

  const handleChange = async (file, onSuccess) => {
    const avatar = await toBase64(file)

    ApiService.fetch(userApi.updateMe({ avatar }))
      .then((data) => {
        setState({ ...state, isLoading: false })
        handleField('avatar', avatar)
        onSuccess()
      })
      .catch(err => setState({ ...state, apiError: err }))
  }

  return (
    <ChooseImageField
      buttonSelectLabel={translationsList.uploadAvatar.buttonSelectLabel}
      chooseFileButtonDescription={translationsList.uploadAvatar.chooseFileButtonDescription}
      chooseFileButtonFieldlabel={translationsList.uploadAvatar.chooseFileButtonFieldlabel}
      chooseFileButtonLabel={translationsList.uploadAvatar.chooseFileButtonLabel}
      chooseFileButtonSendLabel={translationsList.uploadAvatar.chooseFileButtonSendLabel}
      chooseFileTitle={translationsList.uploadAvatar.chooseFileTitle}
      croppedImageHeadingText={translationsList.uploadAvatar.croppedImageHeadingText}
      errorMessage={error}
      hasError={error && touched}
      image={value}
      isLoading={state.isLoading}
      onChange={handleChange}
      withHash={false}
    />
  )
}

export default AvatarUploadContainer
