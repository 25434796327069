import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../../store/services/ApiService'
import * as userAPI from '../../../../store/api/user'

import { myUserActions, myUserSelectors } from '../../../../store/ducks/my-user'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import AddBasicUserDataForm from '../../../../components/Form/FormComponents/AddBasicUserDataForm'

import { FormUserStepOneButtonContainer } from '../../../../pages/PlatformUserSteps/UserStepOne/components'
import translationsList from '../../../../stabs/translationsList'
import translate from '../../../../stabs/errorMessagesTranslations'

import handleField from '../../../../utils/forms/handleField'

const AddBasicUserDataFormContainer = ({ history }) => {
  const myUser = useSelector(myUserSelectors.getMyUserData)

  useEffect(() => {
    window.mt('send', 'pageview', { userstep1: true })
  })

  const dispatch = useDispatch()
  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })

    ApiService
      .fetch(userAPI.updateMe({ ...values, step: 'userStep2' }))
      .then(data => {
        setState({ error: null, isLoading: false })

        dispatch(myUserActions.fetchMyUserSuccess(data))

        window.mt('send', 'pageview', { userstep2: true })
        history.push('/platforma?moje-szkolenia')
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        setState({ error: translate('pl', error.details.msg), isLoading: false })
      })
  }
  return (
    <Formik
      initialValues={{
        firstName: myUser.firstName,
        lastName: myUser.lastName,
        phone: myUser.phone,
        position: myUser.position,
        avatar: myUser.avatar
      }}
      validationSchema={BasicUserDataSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <AddBasicUserDataForm
              apiError={state.error}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
              touched={touched}
              values={values}
            />

            <FormUserStepOneButtonContainer>
              <ButtonComponent
                align='center'
                bgColor='pinkColor'
                color='whiteColor'
                disabled={isSubmitting}
                display='inline-flex'
                buttonType='submit'
                fontBold
                isLoading={state.isLoading}
                label={translationsList.form.labels.buttonNextStepLabel}
                size='sizeL'
              />
            </FormUserStepOneButtonContainer>
          </form>
        </>
      )}
    />
  )
}

const BasicUserDataSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(translationsList.expoRegister.validationErrors.required),
  lastName: Yup.string()
    .required(translationsList.expoRegister.validationErrors.required),
  phone: Yup.string(),
  position: Yup.string()
})

export default withRouter(AddBasicUserDataFormContainer)
