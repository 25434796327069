import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { Base64 } from 'js-base64'

import easyScroll from 'easy-scroll';

import ChooseColorContainer from '../../../containers/ComponentContainers/ChooseColorContainer'
import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'
import cvTemplates from '../../../stabs/cvTemplates'
import FormPanel from '../../../components/Form/FormWrappers/FormPanel'

import ChooseCvContainer from '../../../containers/CvCreatorContainers/ChooseCvContainer'
import PricingAndDownload from '../../../containers/RegisterContainers/PricingAndDownload'

import Grid from '../../../components/Grid'
import InfoIconText from '../../../components/InfoIconText'
import Image from '../../../components/Form/FormBasicElements/Image'

import Section from '../../../components/Section'

import ButtonComponent from '../../../components/Buttons/ButtonComponent'

import {
  CreatorCvStepThreeChooseColorContainer, CreatorCvStepThreeIframe, CvPreviewContainer, HalfWidth, Clear, RodoContainer, CvWaterMark, StepThreeWrapper, CvCreatorPageGuidlineStepsContainer, CvCreatorBenefitsBoxes, BenefitsList, BenefitsElement,
  CvCreatorPageVideoContainer,
  CvCreatorPageVideoContainerRight,
  CvCreatorPageVideoContainerHeading,
  CvCreatorPageVideoContainerList,
  CvCreatorPageVideoContainerListElement,
  CvCreatorPageVideoContainerListElementIndex,
  CvCreatorPageVideoButton,
  HiddenCourseInfo,
  CvCreatorPageSolidList,
  CvCreatorPageSolid,
  CvCreatorPageSolidImage,
  CvCreatorPageSolidListElement,
  Security
} from './components'

import translationsList from '../../../stabs/translationsList'

import { myUserSelectors } from '../../../store/ducks/my-user'

import {
  useDispatch,
  useSelector
} from 'react-redux'
import useDidMount from '@rooks/use-did-mount'
import useDidUpdate from '@rooks/use-did-update'
import {
  cvActions,
  cvSelectors
} from '../../../store/ducks/my-cv'
import useCvGenerator from '../../../store/hooks/useCvGenerator'
import AddRodoFormContainer from '../../../containers/CvCreatorContainers/AddRodoFormContainer'

import HomepageBenefitsImageOne from '../../../assets/icons/homepage/templates.svg'
import HomepageBenefitsImageTwo from '../../../assets/icons/homepage/dostep-premium.svg'
import HomepageBenefitsImageThree from '../../../assets/icons/homepage/gwarancja.svg'

import benefit_icon from '../../../assets/img/benefit_icon.svg'

const CreatorCvStepThree = () => {
  const { generateCv } = useCvGenerator({ onlyHtml: true })
  const cv = useSelector(cvSelectors.getCv)
  const dispatch = useDispatch()
  const [cvHtmlContent, setCvHtmlContent] = React.useState('')
  const [cvHtml, setCvHtml] = React.useState('')

  useDidMount( () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  useDidUpdate(async () => {
    if (cv.isFetched && !cv.isHtmlFetched && cv.data.templateId) {
      dispatch(cvActions.setHtmlAsFetched())

      const newCvHtml = await generateCv()

      setCvHtmlContent(newCvHtml)

      setCvHtml(Base64.encode(newCvHtml))
    }
  }, [dispatch, cv])
  
 const user = useSelector(myUserSelectors.getMyUserData)

 const [showCourse, setShowCourse] = React.useState(false)
 const [showEbook, setShowEbook] = React.useState(false)

 useEffect(() => {
    // window.scrollTo(0,0)
  })

  return (
    <StepThreeWrapper>
      <Section
        alignCenter
        data-test='CvCreatorPageChooseCVSection'
        title='Tak wygląda Twoje unikalne CV'
        html
      />

      <CvWaterMark subscriptonIsActive={user.subscriptionIsActive}>
        <CreatorCvStepThreeIframe src={`data:text/html;charset=utf-8;base64,${cvHtml}`} data-test='CreatorCvStepThreeIframe' />
      </CvWaterMark>

      <ButtonComponent
          align='center'
          bgColor='pinkColor'
          color='whiteColor'
          data-test='buttonComponent'
          display='inline-flex'
          fontBold
          label={'Pobierz CV'}
          size='sizeL'
          id={'nextStepButton'}
          type='onClick'
          onClick={() => {
            var element = document.getElementById("pricing");
            const y = element.getBoundingClientRect().top - 100;

            easyScroll({
                'scrollableDomEle': window,
                'direction': 'bottom',
                'duration': 2000,
                'easingPreset': 'easeInOutCubic',
                'scrollAmount': y
            });
          }}
        />

      <Subtitle
        data-test='CvCreatorPageChooseCVSubtitle'
        icon='star'
        label='Widzisz już, jak piękne i skuteczne CV jesteś w stanie stworzyć z CVworks'
        id='pricing'
      />

      <Section
        alignCenter
        data-test='CvCreatorPageChooseCVSection'
        title='Pobierz je teraz i odblokuj dostęp do:'
        html
      />

      <BenefitsList>
          <BenefitsElement>{'Kreatora CV [nawet 180 szablonów bez limitu pobrań]'}</BenefitsElement>
          <BenefitsElement>
          {'1# BONUS DO KREATORA CV: Szkolenie video “Skuteczne CV”'}
          
          <ButtonComponent
            align='center'
            bgColor='pinkColor'
            color='whiteColor'
            data-test='buttonComponent'
            display='inline-block; width:100%'
            fontBold
            icon={<Image source={benefit_icon} />}
            label={'Dowiedz się więcej'}
            size='sizeS'
            type='onClick'
            onClick={() => {
              setShowCourse(prevCheck => !prevCheck)
            }}
          />
          </BenefitsElement>


          { showCourse ?
          <HiddenCourseInfo>
            <FormPanel>
              <Section
                alignCenter
                data-test='CvCreatorPageChooseCVSection'
                description='Zobacz fragment jednej z lekcji kursu online “Skuteczne CV”, do którego pełen dostęp dostaniesz wraz z kreatorem CV.'
              />

              <CvCreatorPageVideoContainer>
                <Grid
                  columns={2}
                  data-test='CvCreatorPageVideoContainerGrid'
                >
                  <div dangerouslySetInnerHTML={{__html: translationsList.creatorCv.videoSection.iframe }}></div>
                  <CvCreatorPageVideoContainerRight>
                    <CvCreatorPageVideoContainerHeading>
                      {translationsList.creatorCv.videoSection.heading}
                    </CvCreatorPageVideoContainerHeading>
                    <CvCreatorPageVideoContainerList>
                      {
                        translationsList.creatorCv.videoSection.list.map((element, index) => (
                          <CvCreatorPageVideoContainerListElement><CvCreatorPageVideoContainerListElementIndex>{index + 1+'. '}</CvCreatorPageVideoContainerListElementIndex>{element}</CvCreatorPageVideoContainerListElement>
                        ))
                      }
                    </CvCreatorPageVideoContainerList>
                  </CvCreatorPageVideoContainerRight>
                </Grid>
              </CvCreatorPageVideoContainer>
            </FormPanel>
          </HiddenCourseInfo>
          : null }



          <BenefitsElement>
          {'2# BONUS DO KREATORA CV: Konsultacje z rekruterami na zamkniętej grupie na Facebooku'}
          </BenefitsElement>

          { showEbook ?
          <HiddenCourseInfo>
            <FormPanel>
              <Section
                alignCenter
                data-test='CvCreatorPageChooseCVSection'
                description='W 66-stronicowym eBooku “Jak stworzyć piekielnie skuteczne CV” znajdziesz m.in.:'
              />

              <CvCreatorPageSolid>
                <Grid
                  columns={2}
                  data-test='CvCreatorPageVideoContainerGrid'
                >
                  <CvCreatorPageSolidList>
                    {
                      translationsList.creatorCv.solidSection.list.map((element, index) => (
                        <CvCreatorPageSolidListElement>{element}</CvCreatorPageSolidListElement>
                      ))
                    }
                  </CvCreatorPageSolidList>
                  <CvCreatorPageSolidImage>
                    <Image source={translationsList.creatorCv.solidSection.ebookImage} />
                  </CvCreatorPageSolidImage>
                </Grid>
              </CvCreatorPageSolid>
            </FormPanel>
          </HiddenCourseInfo>
          : null }
      </BenefitsList>

      <SpacingLine
        data-test='CvCreatorPageChooseCVSpacingLine'
        isFixed
        option='SizeS'
      />

      <PricingAndDownload/>

      <Security>
        <strong>Twoje dane są bezpiecznie przechowywane i nigdy nie zostaną nikomu udostępnione. Dokonując zakupu, akceptujesz regulamin oraz politykę prywatności.</strong>
        <p>Otrzymujesz dostęp do swojego konta i możliwość korzystania ze wszystkich funkcjonalności kreatora CVworks przez 7 dni. Po upływie tego czasu nastąpi automatyczne odnowienie subskrypcji w cenie 39,90 zł/mc. Korzystasz, jak długo potrzebujesz, w dowolnym momencie możesz zrezygnować.</p>
        <strong>Oferujemy PEŁNĄ GWARANCJĘ SATYSFAKCJI przez pierwsze 7 dni, jeśli kreator CV nie spełni Twoich oczekiwań.</strong>
      </Security>

      <CvCreatorBenefitsBoxes>
        <Grid
          columns={3}
          data-test='CvCreatorPageBenefitsBoxGrid'
        >
          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageOne}
            key='0'
            title='Natychmiastowy dostęp do kreatora CV, kursu i ebooka'
          />

          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageTwo}
            key='0'
            title='Możliwość edycji i pobierania CV bez limitu'
          />

          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageThree}
            key='0'
            title='Gwarancja satysfakcji (7 dni) albo zwrot pieniędzy'
          />
        </Grid>
      </CvCreatorBenefitsBoxes>
    </StepThreeWrapper>
  )
}

export default CreatorCvStepThree
