import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import MultiSelectDropDownField from '../../FormFields/MultiSelectDropDownField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import TextAreaField from '../../FormFields/TextAreaField'

const binaryChoice = [
  { label: 'Tak', value: true, id: 0 },
  { label: 'Nie', value: false, id: 1 }
]

const employmentTypes = [
  { isActive: false, label: 'Praca' },
  { isActive: false, label: 'Praktyka' },
  { isActive: false, label: 'Staż' },
  { isActive: false, label: 'Praca tymczasowa' }
]

const preferredCandidates = [
  { isActive: false, label: 'Studenci' },
  { isActive: false, label: 'Absolwenci' },
  { isActive: false, label: 'Specjaliści' }
]

// Todo Move out logic

const AddWhoWeAreLookingForForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleMultiSelect,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
    noMargin
  >
    <FormTwoColumns>
      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldOne'
        label='CV'
        errorMessage={errors.needsCv}
        hasError={errors.needsCv && touched.needsCv}
        name='needsCv'
        closeOnClick
        itemsList={binaryChoice}
        onClick={handleMultiSelect}
        placeholder={values.needsCv === '' ? 'Wybierz' : `${values.needsCv ? binaryChoice[0].label : binaryChoice[1].label}`}
        value={values.needsCv}
        required
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldTwo'
        label='List motywacyjny'
        errorMessage={errors.needsMotivationalList}
        hasError={errors.needsMotivationalList && touched.needsMotivationalList}
        name='needsMotivationalList'
        closeOnClick
        itemsList={binaryChoice}
        onClick={handleMultiSelect}
        placeholder={values.needsMotivationalList === '' ? 'Wybierz' : `${values.needsMotivationalList ? binaryChoice[0].label : binaryChoice[1].label}`}
        value={values.needsMotivationalList}
        required
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldThree'
        label='Rozmowa kwalifikacyjna'
        errorMessage={errors.jobInterview}
        hasError={errors.jobInterview && touched.jobInterview}
        name='jobInterview'
        closeOnClick
        itemsList={binaryChoice}
        onClick={handleMultiSelect}
        placeholder={values.jobInterview === '' ? 'Wybierz' : `${values.jobInterview ? binaryChoice[0].label : binaryChoice[1].label}`}
        value={values.jobInterview}
        required
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldFour'
        label='Assesment Center'
        errorMessage={errors.assessmentCenter}
        hasError={errors.assessmentCenter && touched.assessmentCenter}
        name='assessmentCenter'
        closeOnClick
        itemsList={binaryChoice}
        onClick={handleMultiSelect}
        placeholder={values.assessmentCenter === '' ? 'Wybierz' : `${values.assessmentCenter ? binaryChoice[0].label : binaryChoice[1].label}`}
        value={values.assessmentCenter}
        required
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldFive'
        label='Rodzaj zatrudnienia'
        errorMessage={errors.employmentTypes}
        hasError={errors.employmentTypes && touched.employmentTypes}
        name='employmentTypes'
        itemsList={employmentTypes}
        onClick={handleMultiSelect}
        placeholder={values.employmentTypes.length === 0 ? 'Wybierz' : values.employmentTypes.join(', ')}
        value={values.employmentTypes}
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldSix'
        label='Preferowani kandydaci'
        errorMessage={errors.preferredCandidates}
        hasError={errors.preferredCandidates && touched.preferredCandidates}
        name='preferredCandidates'
        itemsList={preferredCandidates}
        onClick={handleMultiSelect}
        placeholder={values.preferredCandidates.length === 0 ? 'Wybierz' : values.preferredCandidates.join(', ')}
        value={values.preferredCandidates}
      />

      <InputField
        data-test='cities'
        errorMessage={errors.cities}
        hasError={errors.cities && touched.cities}
        id='cities'
        label='Miasta'
        name='cities'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz miasto'
        value={values.cities}
      />

      <InputField
        data-test='foreignLanguages'
        errorMessage={errors.foreignLanguages}
        hasError={errors.foreignLanguages && touched.foreignLanguages}
        id='foreignLanguages'
        label='Wymagane języki obce'
        name='foreignLanguages'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz język'
        value={values.foreignLanguages}
      />

      <InputField
        data-test='fieldsOfStudy'
        errorMessage={errors.preferredDegreeCourses}
        hasError={errors.preferredDegreeCourses && touched.preferredDegreeCourses}
        id='fieldsOfStudy'
        label='Kierunki studiów'
        name='preferredDegreeCourses'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz kierunek'
        value={values.preferredDegreeCourses}
      />
    </FormTwoColumns>

    <TextAreaField
      errorMessage={errors.additionalInfo}
      hasError={errors.additionalInfo && touched.additionalInfo}
      id='AdditionalInfo'
      label='Dodatkowe informacje'
      maxLength={1000}
      name='additionalInfo'
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='Wpisz treść'
      value={values.additionalInfo}
    />

    <RequiredFieldsText />
  </FormPanel>
)

export default AddWhoWeAreLookingForForm

// TODO Fix Translations
