import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../Buttons/ButtonComponent'
import Image from '../../Form/FormBasicElements/Image'

import {
  RecruiterBoxButtonContainer,
  RecruiterBoxCompany,
  RecruiterBoxCompanyContainer,
  RecruiterBoxContainer,
  RecruiterBoxContent,
  RecruiterBoxImageContainer,
  RecruiterBoxNameAndSurname,
  RecruiterBoxNameAndSurnameContainer,
  RecruiterBoxPosition,
  RecruiterBoxPositionContainer
} from './Atoms'

const RecruiterBox = ({
  avatarUrl,
  chatURL,
  companyName,
  firstName,
  lastName,
  position
}) => (
  <RecruiterBoxContainer data-test='RecruiterBoxContainer'>

    {avatarUrl && (
      <RecruiterBoxImageContainer data-test='RecruiterBoxImageContainer'>
        <Image
          alt={`${firstName} ${lastName}`}
          data-test='RecruiterBoxImage'
          scaleMe={false}
          source={avatarUrl}
        />
      </RecruiterBoxImageContainer>
    )}

    <RecruiterBoxContent data-test='RecruiterBoxContent'>
      <RecruiterBoxNameAndSurnameContainer data-test='RecruiterBoxNameAndSurnameContainer'>
        <RecruiterBoxNameAndSurname data-test='RecruiterBoxNameAndSurname'>
          {firstName + ' ' + lastName}
        </RecruiterBoxNameAndSurname>
      </RecruiterBoxNameAndSurnameContainer>

      <RecruiterBoxPositionContainer data-test='RecruiterBoxPositionContainer'>
        <RecruiterBoxPosition data-test='RecruiterBoxPosition'>
          {position}
        </RecruiterBoxPosition>
      </RecruiterBoxPositionContainer>

      <RecruiterBoxCompanyContainer data-test='RecruiterBoxCompanyContainer'>
        <RecruiterBoxCompany data-test='RecruiterBoxCompany'>
          {companyName}
        </RecruiterBoxCompany>
      </RecruiterBoxCompanyContainer>

      {chatURL && (
        <RecruiterBoxButtonContainer data-test='RecruiterBoxButtonContainer'>
          <ButtonComponent
            align='center'
            bgColor='pinkColor'
            buttonType='button'
            color='whiteColor'
            data-test='RecruiterBoxButton'
            display='inline-flex'
            fontBold
            label='Zacznij rozmowę'
            link={chatURL}
            size='sizeM'
            type='internal'
          />
        </RecruiterBoxButtonContainer>
      )}
    </RecruiterBoxContent>
  </RecruiterBoxContainer>
)

export default RecruiterBox

RecruiterBox.propTypes = {
  avatarUrl: PropTypes.string,
  chatURL: PropTypes.string,
  companyName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.string
}

RecruiterBox.defaultProps = {
  avatarUrl: '',
  chatURL: '',
  companyName: '',
  firstName: '',
  lastName: '',
  position: ''
}
