import styled from 'styled-components'

import {
  CheckBoxSelectedColor,
  PurpleColorL,
  PurpleColorS
} from '../../../../assets/styles/colors'

export const CheckBoxWithTextLinkTextContainer = styled.label`
  position: relative;
  display: inline-grid;
  font-size: 12px;
  color: ${PurpleColorL};
  cursor: pointer;
  user-select: none;
`

export const CheckBoxWithTextLinkTextInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked ~ div:after {
    display: block;
  }
`

export const CheckBoxWithTextLinkTextLabel = styled.div`
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  align-items: center;
  
  &:before {
    content: '';
    display: flex;
    align-self: flex-start;
    width: 25px;
    height: 25px;
    margin: 0 8px 0 0;
    background-color: ${PurpleColorS};
    border-radius: 5px;
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${CheckBoxSelectedColor};
    transform: rotate(45deg);
    border-width: 0 3px 3px 0;
  }
`

export const CheckBoxWithTextLinkTextLabelText = styled.div``
