import React, { useState } from 'react'

import MultiSelectDropDownField from '../../components/Form/FormFields/MultiSelectDropDownField'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import { Formik } from 'formik'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'
import ApiService from '../../store/services/ApiService'
import * as companiesAPI from '../../store/api/company'
import { toast } from 'react-toastify'

const AdminAddUserToCompany = ({ companies }) => {
  const [state, setState] = useState({
    selectedCompany: null,
    selectedCompanyLabel: null,
    error: null,
    isLoading: false
  })

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService.fetch(companiesAPI.addUserToCompany(state.selectedCompany, values))
      .then(() => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        resetForm()
        setState({
          selectedCompany: null,
          selectedCompanyLabel: null,
          error: null,
          isLoading: false
        })
      }).catch((error) => {
        setSubmitting(false)
        console.log(error)
        toast.error('Bład!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={null}
            noMargin
          >
            <MultiSelectDropDownField
              label='Firma'
              errorMessage=''
              hasError={false}
              name='training'
              itemsList={companies.map((company) => ({
                label: `${company.name || company.primaryEmail || 'Bez nazwy'}`,
                value: company._id
              }))}
              onClick={(fieldName, item) => setState({
                ...state,
                selectedCompany: item.value,
                selectedCompanyLabel: item.label
              })}
              placeholder={state.selectedCompany === null ? 'Wybierz' : state.selectedCompanyLabel}
              value={state.selectedCompany}
              closeOnClick
            />
            <FormTwoColumns data-test='FormTwoColumnsOne'>

              <InputField
                errorMessage={errors.email}
                hasError={errors.email && touched.email}
                label='Email'
                name='email'
                type='email'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj email...'
                value={values.email}
              />
              <InputField
                errorMessage={errors.password}
                hasError={errors.password && touched.password}
                label='Hasło'
                name='password'
                type='password'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Podaj email...'
                value={values.password}
              />
            </FormTwoColumns>

            <ResetAndSaveButtons
              data-test='ResetAndSaveButtonsOne'
              onClickReset={handleReset}
              disabledSave={isSubmitting}
              isLoadingSave={state.isLoading}
              isSaveSubmitting
            />
          </FormPanel>
        </form>
      )}
    />
  )
}

export default AdminAddUserToCompany
