import React from 'react'
import PropTypes from 'prop-types'

import {
  GuidlineStepContainer,
  GuidlineStepDescription,
  GuidlineStepLeft,
  GuidlineStepRight,
  GuidlineStepTitle
} from './components'

const GuidlineStep = ({
  description,
  number,
  title,
  html
}) => (
  <GuidlineStepContainer data-test='GuidlineStepContainer'>
    <GuidlineStepLeft data-test='GuidlineStepLeft'>{number}</GuidlineStepLeft>
    <GuidlineStepRight data-test='GuidlineStepRight'>
      <GuidlineStepTitle data-test='GuidlineStepTitle'>{title}</GuidlineStepTitle>
      {
        html
        ? <GuidlineStepDescription data-test='GuidlineStepDescription' dangerouslySetInnerHTML={{__html: description}}></GuidlineStepDescription>
        : <GuidlineStepDescription data-test='GuidlineStepDescription'>{description}</GuidlineStepDescription>
      }
    </GuidlineStepRight>
  </GuidlineStepContainer>
)

export default GuidlineStep

GuidlineStep.propTypes = {
  description: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  title: PropTypes.string.isRequired,
  html: PropTypes.bool
}
