import styled from 'styled-components'

import { PurpleColorM } from '../../assets/styles/colors'

export const CopyrightsText = styled.div`
  font-size: 13px;
  color: ${PurpleColorM};

  @media (min-width: 1200px) {
    padding: 0 0 140px;
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    padding: 0 0 60px;
  }

  @media (max-width: 575px) {
    padding: 0 0 30px;
  }
`
