import styled from 'styled-components'

import { PurpleColorM } from '../../../assets/styles/colors'

export const CreatorCvStepTwoDescriptionContainer = styled.div`
  color: ${PurpleColorM};
  
  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const CreatorCvStepTwoButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`
