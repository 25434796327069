import styled from 'styled-components'

export const Safety = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  flex-wrap: wrap;
  margin-top: 50px;

  p {
  	flex: 100%;
  	font-weight: normal;
  	font-size: 12px;
  	margin-top:10px;
  	margin-bottom: 0;

  	a {
  		color: #FD007A;
  	}
  }

  img {
    height: 30px;
    display: block;
    width: auto;
    margin-right: 10px;
  }
`