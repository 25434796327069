import React from 'react'
import PropTypes from 'prop-types'

import ChooseColorItem from './ChooseColorItem'
import FormPanel from '../Form/FormWrappers/FormPanel'
import Label from '../Form/FormBasicElements/Label'

import {
  ChooseColorListContainer,
  ChooseColorListElement
} from './components'

import translationsList from '../../stabs/translationsList'

const colors = {
  blue: JSON.stringify({r: 74, g: 144, b: 226}),
  aqua: JSON.stringify({r: 80, g: 227, b: 194}),
  green: JSON.stringify({r: 184, g: 233, b: 134}),
  yellow: JSON.stringify({r: 248, g: 231, b: 28}),
  orange: JSON.stringify({r: 245, g: 166, b: 35}),
  red: JSON.stringify({r: 255, g: 85, b: 106}),
  pink: JSON.stringify({r: 255, g: 147, b: 214}),
  plum: JSON.stringify({r: 207, g: 155, b: 255}),
  purple: JSON.stringify({r: 144, g: 19, b: 254}),
}


const ChooseColor = ({
  onClick,
  selected,
  value
}) => {

  console.log('wybarny'+selected)

if( !selected ) {
  selected = '{"r":74,"g":144,"b":226}';
}
  return (
  <FormPanel noMargin>
    <ChooseColorListContainer>
      <ChooseColorListElement data-test='BlueContainer'>
        <ChooseColorItem
          bgColor='Blue'
          data-test='Blue'
          isSelected={selected === colors.blue}
          onClick={() => onClick(colors.blue)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='AquaContainer'>
        <ChooseColorItem
          bgColor='Aqua'
          data-test='Aqua'
          isSelected={selected === colors.aqua}
          onClick={() => onClick(colors.aqua)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='GreenContainer'>
        <ChooseColorItem
          bgColor='Green'
          data-test='Green'
          isSelected={selected === colors.green}
          onClick={() => onClick(colors.green)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='YellowContainer'>
        <ChooseColorItem
          bgColor='Yellow'
          data-test='Yellow'
          isSelected={selected === colors.yellow}
          onClick={() => onClick(colors.yellow)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='OrangeContainer'>
        <ChooseColorItem
          bgColor='Orange'
          data-test='Orange'
          isSelected={selected === colors.orange}
          onClick={() => onClick(colors.orange)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='RedContainer'>
        <ChooseColorItem
          bgColor='Red'
          data-test='Red'
          isSelected={selected === colors.red}
          onClick={() => onClick(colors.red)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='PinkContainer'>
        <ChooseColorItem
          bgColor='Pink'
          data-test='Pink'
          isSelected={selected === colors.pink}
          onClick={() => onClick(colors.pink)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='PlumContainer'>
        <ChooseColorItem
          bgColor='Plum'
          data-test='Plum'
          isSelected={selected === colors.plum}
          onClick={() => onClick(colors.plum)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement data-test='PurpleContainer'>
        <ChooseColorItem
          bgColor='Purple'
          data-test='Purple'
          isSelected={selected === colors.purple}
          onClick={() => onClick(colors.purple)}
        />
      </ChooseColorListElement>

      <ChooseColorListElement />

      {/* <ChooseColorListElement data-test={'AddNewContainer'}> */}
      {/*  <ChooseColorAddNewButtonContainer> */}
      {/*    <Icon icon={addNewIconPath} /> */}
      {/*    <ChooseColorAddNewInput */}
      {/*      type='color' */}
      {/*      value={value} */}
      {/*      onChange={(e) => _.debounce(() => onClick(e.target.value), 2000)} */}
      {/*    /> */}
      {/*  </ChooseColorAddNewButtonContainer> */}

      {/*  <ChooseColorAddNewText>{translationsList.chooseColor.labels.buttonLabel}</ChooseColorAddNewText> */}
      {/* </ChooseColorListElement> */}
    </ChooseColorListContainer>
  </FormPanel>
)}

export default ChooseColor

ChooseColor.propTypes = {
  value: PropTypes.string
}
