import React from 'react'
import PropTypes from 'prop-types'

import Grid from '../../Grid'
import Label from '../../Form/FormBasicElements/Label'
import RecruiterCardBox from '../../Boxes/RecruiterCardBox'
import RecruitmentDetailsList from './RecruitmentDetailsList'

import {
  RecruitmentContainer,
  RecruitmentDetailsListContainer,
  RecruitmentRecruiterCardBoxContainer,
  RecruitmentWhoWeAreLookingFor
} from './components'

import translationsList from '../../../stabs/translationsList'
import { GeneralText } from '../General/components'

const Recruitment = ({
  recruitersList,
  recruitmentDetails
}) => (
  <RecruitmentContainer>
    {
      recruitersList.length !== 0 && (
        <>
          <Label
            data-test='Recruiter'
            label='Będziemy dostepni dla Ciebie online'
          />

          <RecruitmentRecruiterCardBoxContainer>
            <Grid
              columns={2}
              noOutsideMargin
            >
              {
                recruitersList.map((recruiter, index) => (
                  <RecruiterCardBox
                    avatarUrl={recruiter.avatarUrl}
                    data-test='RecruiterCardBox'
                    dateOne={recruiter.dateOne}
                    dateTwo={recruiter.dateTwo}
                    email={recruiter.email}
                    firstName={recruiter.firstName}
                    key={index}
                    lastName={recruiter.lastName}
                    linkedin={recruiter.linkedin}
                    position={recruiter.position}
                    showAvailability={recruiter.showAvailability}
                    timeOne={recruiter.timeOne}
                    timeTwo={recruiter.timeTwo}
                  />
                ))
              }
            </Grid>
          </RecruitmentRecruiterCardBoxContainer>
        </>
      )
    }

    {
      recruitmentDetails && (
        <>
          <Label
            data-test='WhoWeAreLookingFor'
            label={translationsList.recruitment.labels.whoWeAreLookingForLabel}
          />

          <RecruitmentDetailsListContainer>
            <RecruitmentDetailsList
              assessmentCenter={recruitmentDetails.assessmentCenter}
              departments={recruitmentDetails.departments}
              employmentTypes={recruitmentDetails.employmentTypes}
              jobInterview={recruitmentDetails.jobInterview}
              needsCv={recruitmentDetails.needsCv}
              needsMotivationalList={recruitmentDetails.needsMotivationalList}
              place={recruitmentDetails.place}
              preferredCandidates={recruitmentDetails.preferredCandidates}
              preferredDegreeCourses={recruitmentDetails.preferredDegreeCourses}
            />
          </RecruitmentDetailsListContainer>
        </>
      )
    }

    {
      recruitmentDetails.additionalInfo.trim() !== '' && (
        <RecruitmentWhoWeAreLookingFor>
          <Label
            data-test='AboutUs'
            label='Dodatkowe informacje'
          />

          <GeneralText>{recruitmentDetails.additionalInfo}</GeneralText>
        </RecruitmentWhoWeAreLookingFor>
      )
    }
  </RecruitmentContainer>
)

export default Recruitment

Recruitment.propTypes = {
  recruitersList: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      email: PropTypes.string,
      itemsList: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          type: PropTypes.string
        })
      ),
      name: PropTypes.string,
      role: PropTypes.string
    })
  ),
  recruitmentDetails: PropTypes.shape({
    assessmentCenter: PropTypes.bool,
    departments: PropTypes.string,
    employmentTypes: PropTypes.arrayOf(PropTypes.string),
    jobInterview: PropTypes.bool,
    needsCv: PropTypes.bool,
    needsMotivationalList: PropTypes.bool,
    place: PropTypes.string,
    preferredCandidates: PropTypes.arrayOf(PropTypes.string),
    preferredDegreeCourses: PropTypes.string
  })
}
