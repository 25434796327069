import React from 'react'
import { useParams } from 'react-router-dom'
import { Base64 } from 'js-base64'

import ChooseColorContainer from '../../../containers/ComponentContainers/ChooseColorContainer'
import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'
import Section from '../../../components/Section'
import cvTemplates from '../../../stabs/cvTemplates'

import ButtonComponent from '../../../components/Buttons/ButtonComponent'
import Image from '../../../components/Form/FormBasicElements/Image'
import FormPanel from '../../../components/Form/FormWrappers/FormPanel'
import Grid from '../../../components/Grid'
import InfoIconText from '../../../components/InfoIconText'

import benefit_icon from '../../../assets/img/benefit_icon.svg'

import ChooseCvContainer from '../../../containers/CvCreatorContainers/ChooseCvContainer'
import PricingAndDownload from '../../../containers/RegisterContainers/PricingAndDownload'

import HomepageBenefitsImageOne from '../../../assets/icons/homepage/templates.svg'
import HomepageBenefitsImageTwo from '../../../assets/icons/homepage/dostep-premium.svg'
import HomepageBenefitsImageThree from '../../../assets/icons/homepage/gwarancja.svg'

import {
  CreatorCvStepThreeChooseColorContainer, CreatorCvStepThreeIframe, CvPreviewContainer, HalfWidth, Clear, RodoContainer, CvWaterMark,
  BenefitsList,
  BenefitsElement,
  HiddenCourseInfo,
  CvCreatorPageSolid,
  CvCreatorPageSolidList,
  CvCreatorPageSolidImage,
  CvCreatorPageSolidListElement,
  CvCreatorPageVideoContainer,
  CvCreatorPageVideoContainerRight,
  CvCreatorPageVideoContainerHeading,
  CvCreatorPageVideoContainerList,
  CvCreatorPageVideoContainerListElement,
  CvCreatorPageVideoContainerListElementIndex,
  CvCreatorPageVideoButton,
  CvCreatorBenefitsBoxes
} from './components'

import translationsList from '../../../stabs/translationsList'

import { myUserSelectors } from '../../../store/ducks/my-user'

import {
  useDispatch,
  useSelector
} from 'react-redux'
import useDidMount from '@rooks/use-did-mount'
import useDidUpdate from '@rooks/use-did-update'
import {
  cvActions,
  cvSelectors
} from '../../../store/ducks/my-cv'
import useCvGenerator from '../../../store/hooks/useCvGenerator'
import AddRodoFormContainer from '../../../containers/CvCreatorContainers/AddRodoFormContainer'

const CreatorCvStepThree = () => {
  const [cvHtml, setCvHtml] = React.useState('')
  const [cvHtmlContent, setCvHtmlContent] = React.useState('')
  const { generateCv } = useCvGenerator({ onlyHtml: true })
  const cv = useSelector(cvSelectors.getCv)
  const dispatch = useDispatch()
  const [showCourse, setShowCourse] = React.useState(false)
  const [showEbook, setShowEbook] = React.useState(false)

  let cvId = sessionStorage.getItem('cv')
  const { cvId: id } = useParams()

  console.log('cvId: '+cvId)

  if (id) {
    cvId = id
    sessionStorage.setItem('cv', id)
  }

  useDidMount(() => {
    if (!cv.isFetched) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  }, [dispatch, cv])

  useDidUpdate(async () => {
    if (cv.isFetched && !cv.isHtmlFetched && cv.data.templateId) {
      dispatch(cvActions.setHtmlAsFetched())

      const newCvHtml = await generateCv()

      setCvHtmlContent(newCvHtml)

      setCvHtml(Base64.encode(newCvHtml))
    }
  }, [dispatch, cv])

  function createPreview () { return { __html: cvHtmlContent } };

  const user = useSelector(myUserSelectors.getMyUserData)

  const cev = useSelector(cvSelectors.getCvData)
  const [prefix, selectedCvId, selectedCvVariant] = cev.templateId.split('-')
  const selectedCvTemplate = cvTemplates.find((template) => template.id === `${prefix}-${selectedCvId}`)

  console.log(selectedCvTemplate)

  return (
    <>
      <Subtitle
        data-test='SubtitleChooseCv'
        icon='star'
        label='Wybierz swój szablon CV'
      />

      <ChooseCvContainer data-test='ChooseCvContainer' />

       <Subtitle
        data-test='SubtitleColors'
        icon='badge'
        label={translationsList.form.stepThree.subtitle}
        id="colors"
       />

       <CreatorCvStepThreeChooseColorContainer data-test='CreatorCvStepThreeChooseColorContainer'>
        <ChooseColorContainer data-test='ChooseColorContainer' />
       </CreatorCvStepThreeChooseColorContainer>

      <Clear/>

      {/* <CreatorCvStepThreeChooseColorContainer data-test='CreatorCvStepThreeChooseColorContainer'> */}
      {/*  <ChooseColorContainer data-test='ChooseColorContainer' /> */}
      {/* </CreatorCvStepThreeChooseColorContainer> */}

      <Subtitle
        data-test='SubtitlePreview'
        icon='badge'
        label='Podgląd'
        id="preview"
      />

      {/* <CvPreviewContainer dangerouslySetInnerHTML={createPreview()} /> */}

      <CvWaterMark subscriptonIsActive={user.subscriptionIsActive}>
        <CreatorCvStepThreeIframe src={`data:text/html;charset=utf-8;base64,${cvHtml}`} data-test='CreatorCvStepThreeIframe' />
      </CvWaterMark>

      <RodoContainer>
        <Subtitle
          data-test='SubtitleColors'
          icon='badge'
          label={'Zmień stopkę RODO'}
         />

        <AddRodoFormContainer />
      </RodoContainer>

      <Subtitle
        data-test='CvCreatorPageChooseCVSubtitle'
        icon='star'
        label='Widzisz już, jak piękne i skuteczne CV jesteś w stanie stworzyć z CVworks'
        id='pricing'
      />

      <Section
        alignCenter
        data-test='CvCreatorPageChooseCVSection'
        title='Pobierz je teraz i odblokuj dostęp do:'
        html
      />

      <BenefitsList>
          <BenefitsElement>{'Kreatora CV [nawet 180 szablonów bez limitu pobrań]'}</BenefitsElement>
          <BenefitsElement>
          {'1# BONUS DO KREATORA CV: Szkolenie video “Skuteczne CV”'}
          
          <ButtonComponent
            align='center'
            bgColor='pinkColor'
            color='whiteColor'
            data-test='buttonComponent'
            display='inline-block; width:100%'
            fontBold
            icon={<Image source={benefit_icon} />}
            label={'Dowiedz się więcej'}
            size='sizeS'
            type='onClick'
            onClick={() => {
              setShowCourse(prevCheck => !prevCheck)
            }}
          />
          </BenefitsElement>


          { showCourse ?
          <HiddenCourseInfo>
            <FormPanel>
              <Section
                alignCenter
                data-test='CvCreatorPageChooseCVSection'
                description='Zobacz fragment jednej z lekcji kursu online “Skuteczne CV”, do którego pełen dostęp dostaniesz wraz z kreatorem CV.'
              />

              <CvCreatorPageVideoContainer>
                <Grid
                  columns={2}
                  data-test='CvCreatorPageVideoContainerGrid'
                >
                  <div dangerouslySetInnerHTML={{__html: translationsList.creatorCv.videoSection.iframe }}></div>
                  <CvCreatorPageVideoContainerRight>
                    <CvCreatorPageVideoContainerHeading>
                      {translationsList.creatorCv.videoSection.heading}
                    </CvCreatorPageVideoContainerHeading>
                    <CvCreatorPageVideoContainerList>
                      {
                        translationsList.creatorCv.videoSection.list.map((element, index) => (
                          <CvCreatorPageVideoContainerListElement><CvCreatorPageVideoContainerListElementIndex>{index + 1+'. '}</CvCreatorPageVideoContainerListElementIndex>{element}</CvCreatorPageVideoContainerListElement>
                        ))
                      }
                    </CvCreatorPageVideoContainerList>
                  </CvCreatorPageVideoContainerRight>
                </Grid>
              </CvCreatorPageVideoContainer>
            </FormPanel>
          </HiddenCourseInfo>
          : null }



          <BenefitsElement>
          {'2# BONUS DO KREATORA CV: eBook “Jak napisać CV”'}
          
          <ButtonComponent
            align='center'
            bgColor='pinkColor'
            color='whiteColor'
            data-test='buttonComponent'
            display='inline-block;width:100%'
            fontBold
            icon={<Image source={benefit_icon} />}
            label={'Dowiedz się więcej'}
            size='sizeS'
            type='onClick'
            onClick={() => {
              setShowEbook(prevCheck => !prevCheck)
            }}
          />
          </BenefitsElement>

          { showEbook ?
          <HiddenCourseInfo>
            <FormPanel>
              <Section
                alignCenter
                data-test='CvCreatorPageChooseCVSection'
                description='W 66-stronicowym eBooku “Jak stworzyć piekielnie skuteczne CV” znajdziesz m.in.:'
              />

              <CvCreatorPageSolid>
                <Grid
                  columns={2}
                  data-test='CvCreatorPageVideoContainerGrid'
                >
                  <CvCreatorPageSolidList>
                    {
                      translationsList.creatorCv.solidSection.list.map((element, index) => (
                        <CvCreatorPageSolidListElement>{element}</CvCreatorPageSolidListElement>
                      ))
                    }
                  </CvCreatorPageSolidList>
                  <CvCreatorPageSolidImage>
                    <Image source={translationsList.creatorCv.solidSection.ebookImage} />
                  </CvCreatorPageSolidImage>
                </Grid>
              </CvCreatorPageSolid>
            </FormPanel>
          </HiddenCourseInfo>
          : null }
      </BenefitsList>

      <SpacingLine
        data-test='CvCreatorPageChooseCVSpacingLine'
        isFixed
        option='SizeS'
      />

      <PricingAndDownload/>

      <CvCreatorBenefitsBoxes>
        <Grid
          columns={3}
          data-test='CvCreatorPageBenefitsBoxGrid'
        >
          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageOne}
            key='0'
            title='Natychmiastowy dostęp do kreatora CV, kursu i ebooka'
          />

          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageTwo}
            key='0'
            title='Możliwość edycji i pobierania CV bez limitu'
          />

          <InfoIconText
            data-test='CvCreatorPageInfoIconText'
            icon={HomepageBenefitsImageThree}
            key='0'
            title='Gwarancja satysfakcji (7 dni) albo zwrot pieniędzy'
          />
        </Grid>
      </CvCreatorBenefitsBoxes>
    </>
  )
}

export default CreatorCvStepThree
