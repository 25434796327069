import styled from 'styled-components'

export const ChooseImageFieldContainer = styled.div`
  @media (min-width: 992px) {
   
  }

  @media (min-width: 320px) and (max-width: 991px) {
    padding: 0 0 15px;
  }

  ${props => {
  	if( props.id ) {
  		return 'padding-bottom:0 !important;margin-bottom:20px;width:max-content'
  	}
  }}
`
