export default {
  label_aboutMe: 'About me',
  label_biggestSuccess: 'My greatest achievements',
  label_contact: 'Contact',
  label_courses: 'Courses',
  label_education: 'Education',
  label_experience: 'Experience',
  label_haveLearned: 'This job taught me',
  label_hobbies: 'Hobbies',
  label_info: 'Info',
  label_languages: 'Languages',
  label_skills: 'Skills',
  label_socialMedia: 'Social media',
  label_stillGoing: 'Still going',
  user_address: 'Address',
  user_city: 'City',
  user_country: 'Country',
  user_dateOfBirth: 'Date of birth',
  user_email: 'E-mail',
  user_nationality: 'Nationality',
  user_phone: 'Phone'
}
