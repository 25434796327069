import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import * as recruitmentApi from '../../../store/api/recruitment'
import ApiService from '../../../store/services/ApiService'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'

import ChooseCV from '../../../components/ChooseCV'
import Grid from '../../../components/Grid'

import cvTemplates from '../../../stabs/cvTemplates'
import useDidMount from '@rooks/use-did-mount'
import Subtitle from '../../../components/Subtitle'

import easyScroll from 'easy-scroll';

const HalfWidth = styled.div`
  @media (min-width: 890px) {
    width: 50%;
    float: left;
  }
`

const ChooseCvContainer = ({ disabled, isHome }) => {
  const cv = useSelector(cvSelectors.getCvData)
  const cvId = sessionStorage.getItem('cv')

  // const cvId = '6068243f7740fbe43015e3d0'

  useDidMount(() => {
    console.log(cvId);
    if (cvId !== null && !disabled) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  let defaultColor = cv.color

  if( !cv.color ) {
    defaultColor = '{"r":74,"g":144,"b":226}'
  }


  const dispatch = useDispatch()
  const selectTemplate = id => {
    ApiService.fetch(recruitmentApi.updateMyCv(cvId, { templateId: id, color: defaultColor }))
      .then(data => {
        

        dispatch(cvActions.fetchCvSuccess(data.cv))

        
        setSelectedVariant('')

        var element = document.getElementById("wariant");
        const y = element.getBoundingClientRect().top - 100;

        easyScroll({
            'scrollableDomEle': window,
            'direction': 'bottom',
            'duration': 2000,
            'easingPreset': 'easeInOutCubic',
            'scrollAmount': y
        });

        // window.scrollTo({top: y, behavior: 'smooth'});

        var old_parts = cv.templateId.split('-');
        var old_variant = old_parts.pop();

        var new_parts = id.split('-');
        var variant_id = new_parts[2]
        var new_variant = new_parts.pop();

        if( (old_parts[1] == new_parts[1] && old_variant != new_variant) || selectedVariant == '' ) {
          toast.success('Teraz wybierz kolor Twojego CV!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })

          setSelectedVariant(variant_id)
            
          var element = document.getElementById("colors");
          const y = element.getBoundingClientRect().top - 100;

          easyScroll({
              'scrollableDomEle': window,
              'direction': 'bottom',
              'duration': 2000,
              'easingPreset': 'easeInOutCubic',
              'scrollAmount': y
          });
        } else {
          toast.success('Teraz wybierz wariant Twojego CV!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        }
      })
      .catch(error => {
        toast.error('Coś poszło nie tak!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  const [prefix, selectedCvId, selectedCvVariant] = cv.templateId.split('-')
  const [selectedVariant, setSelectedVariant] = useState(true);
  const selectedCvTemplate = cvTemplates.find((template) => template.id === `${prefix}-${selectedCvId}`)

  const history = useHistory()

  return (
    <>
    <Grid
      columns={4}
      dontWrapChildrens
      noOutsideMargin
      alignCenter
    >
      {
        cvTemplates.map((template, key) =>
          <>
          <ChooseCV
            id={template.id}
            image={template.variants[template.default].image}
            key={key}
            disabled
            onClick={disabled
              ? () => isHome ? window.location.href = '/rejestracja' : null
              : () => selectTemplate(`${template.id}-${template.default}`)}
            selected={isHome
              ? false
              : template.id === `${prefix}-${selectedCvId}` }
            // selected={template.id === `${prefix}-${selectedCvId}`}
            // title={`${template.title} - ${template.variants[template.default].title}`}
            title={`${template.title}`}
            isHome
          />

          { isHome &&
            <ChooseCV
              id={template.id}
              image={template.variants.dark.image}
              key={key}
              disabled
              onClick={disabled
                ? () => window.location.href = '/rejestracja'
                : () => selectTemplate(`${template.id}-${template.default}`)}
              selected={isHome
                ? false
                : template.id === `${prefix}-${selectedCvId}` }
              // selected={template.id === `${prefix}-${selectedCvId}`}
              // title={`${template.title} - ${template.variants[template.default].title}`}
              title={`${template.title}`}
              isHome
            />
          }
          </>
        )
      }
    </Grid>

      { (selectedCvTemplate !== undefined && Object.keys(selectedCvTemplate.variants).length > 1 && !isHome) && (
        <>
          <Subtitle
            data-test='SubtitleChooseCv'
            icon='star'
            label='Wybierz swój wariant CV'
            id="wariant"
          />

          <Grid
            columns={4}
            dontWrapChildrens
            noOutsideMargin
            alignCenter
          >
            {
              Object.keys(selectedCvTemplate.variants).map((variantId) =>
                <ChooseCV
                  id={`${prefix}-${selectedCvId}-${variantId}`}
                  image={selectedCvTemplate.variants[variantId].image}
                  key={variantId}
                  disabled
                  onClick={disabled
                    ? () => null
                    : () => selectTemplate(`${prefix}-${selectedCvId}-${variantId}`)}
                  selected={variantId === selectedCvVariant}
                  title={`${selectedCvTemplate.title} - ${selectedCvTemplate.variants[variantId].title}`}
                />
              )
            }
          </Grid>
        </>
      )}
    </>
  )
}

export default ChooseCvContainer
