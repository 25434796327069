import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../InternalOrExternal'

import {
  LinkComponentContainer,
  LinkComponentIconContainer,
  LinkComponentText
} from './components'

const LinkComponent = ({
  align,
  colorFill,
  colorStroke,
  colorText,
  disabled,
  display,
  fontBold,
  fontSize,
  iconAfter,
  iconBefore,
  inNewWindow,
  label,
  link,
  onClick,
  rotateIcons,
  type,
  footer
}) => {

  let home = false
  const location = useLocation();
  if( location.pathname == '/' ) {
    home = true
  }

  const handleClick = (link, e) => {

    e.preventDefault()
    let id = link.replace('#', '')
    id = id.replace('/', '')
    var el = document.getElementById(id)

    if( el && home) {
      el.scrollIntoView();
    } else {
      window.location.href = link
    }
  }

  return (
  <InternalOrExternal
    align={align}
    disabled={disabled}
    display={display}
    inNewWindow={inNewWindow}
    link={link}

    onClick={ (footer ) ? (e) => handleClick(link, e) : onClick}
    type={type}
  >
    <LinkComponentContainer
      colorText={colorText}
      fontBold={fontBold}
      fontSize={fontSize}
    >
      {iconBefore &&
        <LinkComponentIconContainer
          colorFill={colorFill}
          colorStroke={colorStroke}
          data-test='iconBeforeContainer'
          iconSize={fontSize}
          rotateIcons={rotateIcons}
        >
          <Icon
            data-test='iconBefore'
            icon={iconBefore}
          />
        </LinkComponentIconContainer>}

      {label &&
        <LinkComponentText>{label}</LinkComponentText>}

      {iconAfter &&
        <LinkComponentIconContainer
          colorFill={colorFill}
          colorStroke={colorStroke}
          data-test='iconAfterContainer'
          iconSize={fontSize}
          rotateIcons={rotateIcons}
        >
          <Icon
            data-test='iconAfter'
            icon={iconAfter}
          />
        </LinkComponentIconContainer>}
    </LinkComponentContainer>
  </InternalOrExternal>
)}

export default LinkComponent

LinkComponent.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  colorFill: PropTypes.oneOf(['pinkColorM', 'purpleColor', 'purpleColorM', 'purpleColorS', 'purpleColorXS', 'whiteColor']),
  colorStroke: PropTypes.oneOf(['pinkColorM', 'purpleColor', 'purpleColorM', 'purpleColorS', 'purpleColorXS', 'whiteColor']),
  colorText: PropTypes.oneOf(['pinkColorM', 'purpleColor', 'purpleColorM', 'purpleColorS', 'purpleColorXS', 'whiteColor']),
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['inline-flex']),
  fontBold: PropTypes.bool,
  fontSize: PropTypes.oneOf(['sizeXS', 'sizeS', 'sizeM', 'sizeL', 'sizeXL']),
  iconAfter: PropTypes.string,
  iconBefore: PropTypes.string,
  inNewWindow: PropTypes.bool,
  footer: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  rotateIcons: PropTypes.oneOf(['90', '180']),
  type: PropTypes.oneOf(['external', 'internal', 'onClick'])
}

LinkComponent.defaultProps = {
  fontBold: false
}
