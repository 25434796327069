import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../store/services/ApiService'
import * as recruitmentApi from '../../../store/api/recruitment'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'

import AddLanguageForm from '../../../components/Form/FormComponents/AddLanguageForm'
import ResetAndSaveButtons from '../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../stabs/errorMessagesTranslations'
import langaugeLevels from '../../../stabs/langaugeLevels'

import handleMultiSelect from '../../../utils/forms/handleMultiSelect'

const AddLanguageFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const cvId = sessionStorage.getItem('cv')
  const cv = useSelector(cvSelectors.getCvData)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ ...state, error: null, isLoading: true })

    const newValues = { level: values.level, name: values.language }

    ApiService
      .fetch(state.isEditing ? recruitmentApi.updateLanguage(cvId, state.editingItemId, newValues) : recruitmentApi.addLanguage(cvId, newValues))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
        resetForm()
        setState({ ...state, editingItemId: null })
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(recruitmentApi.deleteLanguage(cvId, itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(cvActions.fetchCvSuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = cv.languages.filter((language) => language._id === itemId)[0]

    setValues({ language: editingItem.name, level: editingItem.level })
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: item.name,
    description: langaugeLevels.filter((language) => language.value === item.level)[0].label,
    isEditing: state.editingItemId === item._id,
    _id: item._id
  }))

  return (
    <Formik
      initialValues={{ language: '', level: '' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddLanguageForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleMultiSelect={(fieldName, item, isMulti) =>
              handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
            handleDelete={onDelete}
            handleEdit={onEdit(setValues)}
            isEditing={state.isEditing}
            isLoading={state.isLoading}
            items={mapItemToDisplay(cv.languages)}
            touched={touched}
            values={values}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const Schema = Yup.object().shape({
  language: Yup.string()
    .required('Pole jest wymagane'),
  level: Yup.string()
    .required('Pole jest wymagane')
})

export default AddLanguageFormContainer
