import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../../Buttons/ButtonComponent'
import TrainingItemHeaderArrow from './TrainingItemHeaderArrow'
import TrainingItemHeaderSpeakers from './TrainingItemHeaderSpeakers'
import TrainingItemHeaderTime from './TrainingItemHeaderTime'

import {
  TrainingItemHeaderContainer,
  TrainingItemHeaderDedicatedTrainingContainer,
  TrainingItemHeaderDedicatedTrainingText,
  TrainingItemHeaderPrice,
  TrainingItemHeaderPriceAndButtonContainer,
  TrainingItemHeaderTitle
} from './components'

import translationsList from '../../../../stabs/translationsList'

const TrainingItemHeader = ({
  dedicatedTraining,
  dedicatedTrainingText,
  enrolled,
  isEditable,
  isEnrolled,
  isOpen,
  limit,
  onClick,
  onClickSign,
  onClickUnsign,
  price,
  speakersList,
  timeEnd,
  timeStart,
  title
}) => (
  <TrainingItemHeaderContainer isEditable={isEditable}>
    <TrainingItemHeaderTime
      timeEnd={timeEnd}
      timeStart={timeStart}
    />

    <TrainingItemHeaderTitle>{title}</TrainingItemHeaderTitle>

    {
      dedicatedTraining
        ? (
          <TrainingItemHeaderDedicatedTrainingContainer>
            <TrainingItemHeaderDedicatedTrainingText>
              {'Zaczynamy za:'}
            </TrainingItemHeaderDedicatedTrainingText>

            <TrainingItemHeaderDedicatedTrainingText>
              {dedicatedTrainingText}
            </TrainingItemHeaderDedicatedTrainingText>
          </TrainingItemHeaderDedicatedTrainingContainer>
        )

        : (
          <>
            {
              isEditable
                ? (
                  <TrainingItemHeaderPriceAndButtonContainer>
                    <TrainingItemHeaderPrice>{price}</TrainingItemHeaderPrice>

                    <ButtonComponent
                      bgColor={
                        isEnrolled
                          ? 'purpleColorS'
                          : 'purpleColor'
                      }
                      color={
                        isEnrolled
                          ? 'purpleColor'
                          : 'whiteColor'
                      }
                      disabled={limit !== 0 && limit === enrolled && !isEnrolled}
                      fontBold
                      fullWidth
                      label={
                        isEnrolled
                          ? translationsList.trainings.labels.trainingsButtonUnBuyLabel
                          : translationsList.trainings.labels.trainingsButtonBuyLabel
                      }
                      onClick={isEnrolled ? onClickUnsign : onClickSign}
                      size='sizeM'
                    />

                  </TrainingItemHeaderPriceAndButtonContainer>
                )

                : (
                  <TrainingItemHeaderSpeakers speakersList={speakersList} />
                )
            }
          </>
        )
    }

    <TrainingItemHeaderArrow
      isOpen={isOpen}
      onClick={onClick}
    />
  </TrainingItemHeaderContainer>
)

export default TrainingItemHeader

TrainingItemHeader.propTypes = {
  dedicatedTraining: PropTypes.bool,
  dedicatedTrainingText: PropTypes.string,
  isEditable: PropTypes.bool,
  onClick: PropTypes.func,
  onClickSign: PropTypes.func,
  isOpen: PropTypes.bool,
  price: PropTypes.string,
  speakersList: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      company: PropTypes.string,
      name: PropTypes.string,
      office: PropTypes.string
    })
  ),
  timeEnd: PropTypes.string,
  timeStart: PropTypes.string,
  title: PropTypes.string
}

TrainingItemHeader.defaultProps = {
  dedicatedTraining: false,
  isEditable: false,
  isOpen: false,
  price: '',
  speakersList: [],
  timeEnd: '',
  timeStart: '',
  title: ''
}
