import styled from 'styled-components'

import { DescriptionContainer } from '../../components/Section/Description/components'
import { Label, Underline } from '../../components/Section/Title/components'
import { TextValueTextValueContainer } from '../../components/Form/FormBasicElements/TextValueTextValue/components'

export const sectionCenterBreakepoints = () => `
  @media (min-width: 1200px) {
    margin: 100px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px auto 0;
  }

  @media (max-width: 575px) {
    margin: 50px auto 0;
  }
`

export const sectionBreakepoints = () => `
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const SectionTopContainer = styled.div`
  display: flex;

  @media (min-width: 1600px) {
    margin: 100px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 80px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 60px 0 0;
  }

  @media (min-width: 992px) {
    align-items: center;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    flex-direction: column-reverse;
    margin: 10px 0 0;
  }
`

export const SectionTopContent = styled.div`
  @media (min-width: 1200px) {
    max-width: 500px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 360px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 30px 0 0;

    ${Label}, 
    ${DescriptionContainer} {
      text-align: center;
    }

    ${Underline} {
      margin: 10px auto;
    }
  }
`

export const SectionTopButtonContainer = styled.div`
  @media (min-width: 992px) {
    text-align: left;
  }
  
  @media (max-width: 991px) {
    text-align: center;
  }

  ${sectionBreakepoints}
`

export const SectionTopImageContainer = styled.div``

export const SectionTopImage = styled.img``

export const SectionInfoBox = styled.div`
  max-width: 600px;

  ${sectionCenterBreakepoints}
`

export const SectionCommonContainer = styled.div`
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 900px;
  }
`

export const SectionCompanies = styled.div`
  ${TextValueTextValueContainer} {
    text-align: center;
  }

  ${sectionBreakepoints}
`

export const CompaniesContainer = styled.div`
  @media (min-width: 768px) {
    margin: 30px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 20px 0 0;
  }

  @media (max-width: 575px) {
    margin: 15px 0 0;
  }
`

export const SectionTrainings = styled.div`
  @media (min-width: 992px) {
    margin: 60px auto 0;
  }

  @media (min-width: 567px) and (max-width: 991px) {
    margin: 50px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 40px auto 0;
  }
`

export const TrainingsControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${sectionBreakepoints}
`

export const SectionSteps = styled.div`
  @media (min-width: 1200px) {
    margin: 150px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 120px auto 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 100px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 80px 0 0;
  }
`

export const StepsButtonContainer = styled.div`
  ${sectionCenterBreakepoints}
`
