import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'

import {
  AddBasicUserDataFormContainer
} from './components'
import AvatarUploadContainer
  from '../../../../containers/ComponentContainers/UploadContainers/AvatarUploadContainer'
import translationsList from '../../../../stabs/translationsList'

const AddBasicUserDataForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleField,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
  >
    <AddBasicUserDataFormContainer>
      <AvatarUploadContainer
        error={errors.avatar}
        touched={touched.avatar}
        value={values.avatar}
        handleField={handleField}
      />

      <FormTwoColumns>
        <InputField
          data-test='Name'
          errorMessage={errors.firstName}
          hasError={errors.firstName && touched.firstName}
          id='Name'
          label={translationsList.expoRegister.firstNameLabel}
          name='firstName'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.firstNamePlaceholder}
          required
          value={values.firstName}
        />

        <InputField
          data-test='Surname'
          errorMessage={errors.lastName}
          hasError={errors.lastName && touched.lastName}
          id='Surname'
          label={translationsList.expoRegister.lastNameLabel}
          name='lastName'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.lastNamePlaceholder}
          required
          value={values.lastName}
        />

        <InputField
          data-test='Phone'
          errorMessage={errors.phone}
          hasError={errors.phone && touched.phone}
          id='Phone'
          label={translationsList.expoRegister.phoneLabel}
          name='phone'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.phonePlaceholder}
          value={values.phone}
        />

        <InputField
          data-test='Position'
          errorMessage={errors.position}
          hasError={errors.position && touched.position}
          id='Position'
          label={translationsList.expoRegister.positionLabel}
          name='position'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.positionPlaceholder}
          value={values.position}
        />

        <RequiredFieldsText />
      </FormTwoColumns>
    </AddBasicUserDataFormContainer>
  </FormPanel>
)

export default AddBasicUserDataForm

// TODO Fix Translations
