import React from 'react'

import OutsideSliderButton from '../OutsideSliderButton'

import {
  OutsideSliderDotsContainer,
  OutsideSliderDotsList,
  OutsideSliderPaging
} from './Atoms'

export const defaultSettings = {
  appendDots: (dots) => (
    <OutsideSliderDotsContainer>
      <OutsideSliderDotsList>
        {dots}
      </OutsideSliderDotsList>
    </OutsideSliderDotsContainer>
  ),
  autoplay: true,
  customPaging: () => <OutsideSliderPaging />,
  dots: true,
  draggable: true,
  fade: true,
  infinite: true,
  lazyLoad: true,
  nextArrow: <OutsideSliderButton position='right' />,
  pauseOnHover: true,
  prevArrow: <OutsideSliderButton position='left' />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  swipeToSlide: true
}
