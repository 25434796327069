import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../assets/styles/colors'

export const InfoIconTextContainer = styled.div``

export const InfoIconTextIconContainer = styled.div`
  > div {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin: 0 0 30px;
  }

  @media (max-width: 767px) {
    margin: 0 0 20px;
  }
`

export const InfoIconTextContent = styled.div`
  text-align: center;
`

export const InfoIconTextTitle = styled.div`
  color: ${PurpleColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media (min-width: 768px) {
    margin: 0 0 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 15px;
  }

  @media (max-width: 575px) {
    margin: 0 0 10px;
  }
`

export const InfoIconTextDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  line-height: 24px;
`
