import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../store/services/ApiService'


import ResetAndSaveButtons from '../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../stabs/errorMessagesTranslations'
import { myCompanyActions } from '../../../store/ducks/my-company'
import { toast } from 'react-toastify'
import * as recruitmentAPI from '../../../store/api/recruitment'
import useDidMount from '@rooks/use-did-mount'
import { cvActions, cvSelectors } from '../../../store/ducks/my-cv'
import TextAreaField from '../../../components/Form/FormFields/TextAreaField'
import RequiredFieldsText from '../../../components/Form/FormBasicElements/RequiredFieldsText'
import FormPanel from '../../../components/Form/FormWrappers/FormPanel'

const defaultRODO = `Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji procesu rekrutacji (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).`

const AddRodoFormContainer = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const cvId = sessionStorage.getItem('cv')

  useDidMount(() => {
    if (cvId !== null) {
      dispatch(cvActions.fetchCvRequested(cvId))
    }
  })

  const cv = useSelector(cvSelectors.getCv)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })

    ApiService
      .fetch(recruitmentAPI.updateMyCv(cvId, values))
      .then(data => {
        setSubmitting(false)
        setState({ error: null, isLoading: false })

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        // dispatch(myCompanyActions.fetchMyCompanySuccess(data))
        dispatch(cvActions.fetchCvSuccess(data.cv, 'edit'))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onReset = (setFieldValue) => () => {
    setFieldValue('rodo', defaultRODO)
  }

  return (
    <Formik
      initialValues={{
        rodo: cv.data.rodo ?? defaultRODO,
      }}
      onSubmit={onSubmit}
      onReset={onReset}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel errors={state.error}>
            <TextAreaField
              data-test='companyMission'
              errorMessage={errors.rodo}
              hasError={errors.rodo && touched.rodo}
              id='companyMission'
              label='Stopka RODO'
              maxLength={500}
              name='rodo'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Wpisz stopkę Rodo'
              value={values.rodo}
            />

            <RequiredFieldsText />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(setFieldValue)}
            disabledSave={isSubmitting}
            isLoadingSave={state.isLoading}
            isClearing={false}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

export default AddRodoFormContainer
