import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthService from '../../store/services/AuthService'
import { primaryEventSelectors } from '../../store/ducks/primary-event'
import { myUserSelectors } from '../../store/ducks/my-user'
import { myCompanySelectors } from '../../store/ducks/my-company'

import FormStepsWrapper from '../../components/Wrappers/RegisterStepsWrapper'

const PlatformContainer = ({ step, type }) => {
  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const myUser = useSelector(myUserSelectors.getMyUser)
  const myCompany = useSelector(myCompanySelectors.getMyCompany)
  const history = useHistory()

  let option = `creatorCvStep${step}`

  return <FormStepsWrapper option={option} primaryEvent={primaryEvent.data} />
}

export default PlatformContainer
