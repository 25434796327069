import React from 'react'
import { Route, useHistory } from 'react-router-dom'

import AuthService from '../../../store/services/AuthService'
import ErrorPageScreen from '../../../pages/ErrorPage'

export default ({ component: Component, minimalType, ...rest }) => {
  const history = useHistory()

  if (!AuthService.loggedIn()) {
    history.push('/logowanie')
  }

  return (
    (
      <Route
        {...rest}
        render={() => (
          AuthService.isUserAllowed(minimalType)
            ? (
              <Component />
            )
            : (
              <ErrorPageScreen />
            )
        )}
      />
    )
  )
}
